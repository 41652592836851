/* eslint-disable */
import { RootState } from '@State/store'
import { combineEpics } from 'redux-observable'
import { Observable } from 'rxjs'
import { catchError } from 'rxjs/operators'

import { sendNotifcationAction } from './actions/appStateActions'
import { likePostEpic } from './epics/judgementEpics'
import { updateNamespaceEpic } from './epics/namespaceEpics'
import {
  addToPostEpic,
  createPostEpic,
  deletePostEpic,
  deleteSubPostEpic,
  editPostEpic,
  editSubPostEpic,
  linkPostEpic,
  publishPostEpic,
  revertPostEpic,
  searchPostEpic,
  upgradeSubPostEpic,
} from './epics/postEpics'
import {
  applicationEpic,
  editUsernameEpic,
  grantRoleEpic,
  toggleAvatarEpic,
  ungrantRoleEpic,
} from './epics/profileEpics'
import { publishTagsEpic, unpublishTagsEpic } from './epics/tagEpics'
import { testEpic } from './epics/testEpics'
import { deleteUriEpic, loadUriEpic, saveUriEpic } from './epics/uriEpics'



// Combine
export const epics = [
  // fetchGithubUserEpic,

  // Posts
  createPostEpic,
  addToPostEpic,

  editPostEpic,
  editSubPostEpic,

  upgradeSubPostEpic,
  revertPostEpic,
  linkPostEpic,

  deletePostEpic,
  deleteSubPostEpic,

  publishPostEpic,

  searchPostEpic,

  // Namespace
  updateNamespaceEpic,

  // Tags
  publishTagsEpic,
  unpublishTagsEpic,

  // Judgement
  likePostEpic,

  // Profile
  editUsernameEpic,
  toggleAvatarEpic,
  grantRoleEpic,
  applicationEpic,
  ungrantRoleEpic,

  // Uri
  loadUriEpic,
  saveUriEpic,
  deleteUriEpic,


  // Test
  testEpic
]

export const rootEpic = (action$: Observable<any>, store$, dependencies: any) =>
  combineEpics(...epics)(action$, store$, dependencies).pipe(
    catchError((error, source) => {
      // console.log('Epic!',  error);
      console.error('Epic!', error);
      dependencies.store.dispatch(sendNotifcationAction(`${error.message}`, 'error'))
      return source;
    })
  );
