import { FloatingWindowData } from '@Components/containers/ui/FloatingWindow'
import { Types } from '@Constants/config/types'
import { LongLatArray } from '@Constants/types/location'
import { Limits } from '@State/reducers/storageReducer'

export const CHANGE_THEME = 'CHANGE_THEME'

export const DRAWER_TYPES_TOGGLE = 'DRAWER_TYPES_TOGGLE'

export const TOGGLE_FILTER = 'TOGGLE_FILTER'
export const SET_LIMIT = 'SET_LIMIT'
export const TOGGLE_GRID = 'TOGGLE_GRID'
export const TOGGLE_SORT_DIRECTION = 'TOGGLE_SORT_DIRECTION'
export const TOGGLE_EDITED = 'TOGGLE_EDITED'

export const CHANGE_TYPE = 'CHANGE_TYPE'
export const CHANGE_SUB_TYPE = 'CHANGE_SUB_TYPE'

export const CHANGE_CAT = 'CHANGE_CAT'
export const CHANGE_TAGS = 'CHANGE_TAGS'
export const CHANGE_UID = 'CHANGE_UID'
export const CHANGE_VIS = 'CHANGE_VIS'
export const CHANGE_HAS_SUBPOSTS = 'CHANGE_HAS_SUBPOSTS'

export const RESET_FILTERS = 'RESET_FILTERS'

export const TOGGLE_MARKDOWN = 'TOGGLE_MARKDOWN'
export const TOGGLE_AUTOCOMPLETE_TITLE = 'TOGGLE_AUTOCOMPLETE_TITLE'

export const TOGGLE_FLOATING_WINDOW = 'TOGGLE_FLOATING_WINDOW'
export const MINIMIZE_FLOATING_WINDOW = 'MINIMIZE_FLOATING_WINDOW'

export const UPDATE_FLOATING_WINDOW_DATA = 'UPDATE_FLOATING_WINDOW_DATA'
export const SET_FLOATING_WINDOW_URL = 'SET_FLOATING_WINDOW_URL'

export const SET_ZOOM_LEVEL = 'SET_ZOOM_LEVEL'
export const SET_LONG_LAT = 'SET_LONG_LAT'
export const SET_MAP_RADIUS = 'SET_MAP_RADIUS'



interface ChangeThemeAction {
  type: typeof CHANGE_THEME
  theme?: string;
}

interface DrawerTypesToggleAction {
  type: typeof DRAWER_TYPES_TOGGLE
  open?: boolean;
}

interface ToggleFilterAction {
  type: typeof TOGGLE_FILTER
  open: boolean;
}

interface SetLimitAction {
  type: typeof SET_LIMIT
  limit: Limits;
}
interface ToggleGridAction {
  type: typeof TOGGLE_GRID
  open: boolean;
}
interface ToggleSortDirectionAction {
  type: typeof TOGGLE_SORT_DIRECTION
  open?: boolean;
}
interface ToggleEditedAction {
  type: typeof TOGGLE_EDITED
  open?: boolean;
}

interface ChangeTypeAction {
  type: typeof CHANGE_TYPE
  value: Types;
}
interface ChangeSubTypeAction {
  type: typeof CHANGE_SUB_TYPE
  value: Types;
}

interface ChangeCatAction {
  type: typeof CHANGE_CAT
  value: string;
}
interface ChangeTagsAction {
  type: typeof CHANGE_TAGS
  value: string[];
}
interface ChangeUidAction {
  type: typeof CHANGE_UID
  value: string;
}
interface ChangeVisAction {
  type: typeof CHANGE_VIS
  value: string;
}
interface ChangeHasSubPostsAction {
  type: typeof CHANGE_HAS_SUBPOSTS
  value: boolean;
}

interface ResetFiltersAction {
  type: typeof RESET_FILTERS
}

interface ToggleMarkdownAction {
  type: typeof TOGGLE_MARKDOWN
}

interface ToggleAutocompleteTitleAction {
  type: typeof TOGGLE_AUTOCOMPLETE_TITLE
}

interface ToggleFloatingWindowAction {
  type: typeof TOGGLE_FLOATING_WINDOW
  open?: boolean;
}
interface MinimizeFloatingWindowAction {
  type: typeof MINIMIZE_FLOATING_WINDOW
  minimize?: boolean;
}

interface UpdateFloatingWindowDataAction {
  type: typeof UPDATE_FLOATING_WINDOW_DATA
  data?: FloatingWindowData;
}

interface SetFloatingWindowUrlAction {
  type: typeof SET_FLOATING_WINDOW_URL
  url: string;
}


interface SetZoomLevelAction {
  type: typeof SET_ZOOM_LEVEL
  zoomLevel: number
}

interface SetLongLatAction {
  type: typeof SET_LONG_LAT
  longlat: LongLatArray
}

interface SetMapRadiusAction {
  type: typeof SET_MAP_RADIUS
  radius: number
}



export function changeThemeAction(theme?: string): ChangeThemeAction {
  return {
    type: CHANGE_THEME,
    theme
  };
}

export function drawerTypesToggleAction(open?: boolean): DrawerTypesToggleAction {
  return {
    type: DRAWER_TYPES_TOGGLE,
    open
  };
}

export function toggleFilterAction(open: boolean): ToggleFilterAction {
  return {
    type: TOGGLE_FILTER,
    open
  };
}

export function setLimitAction(limit: Limits): SetLimitAction {
  return {
    type: SET_LIMIT,
    limit
  };
}
export function toggleGridAction(open: boolean): ToggleGridAction {
  return {
    type: TOGGLE_GRID,
    open
  };
}

export function toggleSortDirectionAction(open: boolean): ToggleSortDirectionAction {
  return {
    type: TOGGLE_SORT_DIRECTION,
    open
  };
}

export function toggleEditedAction(open: boolean): ToggleEditedAction {
  return {
    type: TOGGLE_EDITED,
    open
  };
}

export function changeTypeAction(value: Types): ChangeTypeAction {
  return {
    type: CHANGE_TYPE,
    value
  };
}
export function changeSubTypeAction(value: Types): ChangeSubTypeAction {
  return {
    type: CHANGE_SUB_TYPE,
    value
  };
}
export function changeCatAction(value: string): ChangeCatAction {
  return {
    type: CHANGE_CAT,
    value
  };
}
export function changeTagsAction(value: string[]): ChangeTagsAction {
  return {
    type: CHANGE_TAGS,
    value
  };
}
export function changeVisAction(value: string): ChangeVisAction {
  return {
    type: CHANGE_VIS,
    value
  };
}
export function changeHasSubPostsAction(value: boolean): ChangeHasSubPostsAction {
  return {
    type: CHANGE_HAS_SUBPOSTS,
    value
  };
}
export function changeUidAction(value: string): ChangeUidAction {
  return {
    type: CHANGE_UID,
    value
  };
}


export function resetFiltersAction(): ResetFiltersAction {
  return {
    type: RESET_FILTERS
  };
}
export function toggleMarkdownAction(): ToggleMarkdownAction {
  return {
    type: TOGGLE_MARKDOWN
  };
}
export function toggleAutocompleteTitleAction(): ToggleAutocompleteTitleAction {
  return {
    type: TOGGLE_AUTOCOMPLETE_TITLE
  };
}
export function toggleFloatingWindowAction(open?: boolean): ToggleFloatingWindowAction {
  return {
    type: TOGGLE_FLOATING_WINDOW,
    open
  };

}
export function minimizeloatingWindowAction(minimize?: boolean): MinimizeFloatingWindowAction {
  return {
    type: MINIMIZE_FLOATING_WINDOW,
    minimize
  };

}
export function updateFloatingWindowDataAction(data: FloatingWindowData): UpdateFloatingWindowDataAction {
  return {
    type: UPDATE_FLOATING_WINDOW_DATA,
    data
  };
}
export function setFloatingWindowUrlAction(url: string): SetFloatingWindowUrlAction {
  return {
    type: SET_FLOATING_WINDOW_URL,
    url
  };
}

export function setZoomLevelAction(zoomLevel: number): SetZoomLevelAction {
  return {
    type: SET_ZOOM_LEVEL,
    zoomLevel
  };
}
export function setLongLatAction(longlat: LongLatArray): SetLongLatAction {
  return {
    type: SET_LONG_LAT,
    longlat
  };
}
export function setMapRadiusAction(radius: number): SetMapRadiusAction {
  return {
    type: SET_MAP_RADIUS,
    radius
  };
}
export type StorageActionTypes =
  ChangeThemeAction |
  DrawerTypesToggleAction |
  ToggleFilterAction |
  SetLimitAction |
  ToggleGridAction |
  ToggleSortDirectionAction |
  ToggleEditedAction |
  ChangeTypeAction |
  ChangeSubTypeAction |
  ChangeCatAction |
  ChangeTagsAction |
  ChangeUidAction |
  ChangeVisAction |
  ChangeHasSubPostsAction |
  ResetFiltersAction |
  ToggleMarkdownAction |
  ToggleAutocompleteTitleAction |
  ToggleFloatingWindowAction |
  MinimizeFloatingWindowAction |
  UpdateFloatingWindowDataAction |
  SetFloatingWindowUrlAction |
  SetZoomLevelAction |
  SetLongLatAction |
  SetMapRadiusAction