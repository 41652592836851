import checkFirebaseCompatible from '@Utils/regExp'
import urlFunc, { specificEncoder, URLObjectType } from '@Utils/urlFunc'


const getSavableParams = (urlObj: Partial<URLObjectType>): string | undefined => {
  const prefix = `_params`
  let paramPath = ''
  try {
    if (urlObj && urlObj.meta && urlObj.meta.type !== 'default') {
      // console.log('urlObj', urlObj)
      switch (urlObj.meta.brand) {
        case 'youtube':
          // TODO: If url is "youtu.be/<id>" save as v params at different url? But in future these domains should basically be aliased somehow, or maybe better to just transform the url when pasting it in the url form field...
          if (urlObj.params && urlObj.params.v) {
            // _params will contain all video ids
            paramPath = `${prefix}/v/${urlObj.params.v}/`
          }
          else if (urlObj.params && urlObj.params.t) {
            // This saves videos with the same timestamp at the same branch, idk if this is a good idea, we could save the params in firestore and filter there, but filtering by object is not possible, and filtering by url is unreliable because the order of params might differ, so this might be better.
            paramPath = `${prefix}/t/${urlObj.params.t}`
          }
          break;

        default:
          paramPath = `${prefix}/`
          break;
      }
    }
  } catch {
    throw new Error('Params error')
  }
  // console.log('savableParams:', paramPath)
  return paramPath
}

const gluePath = (urlObj: Partial<URLObjectType>): string => {
  let finalPath = ''
  try {
    // console.log("🚀 ~ urlObj", urlObj)
    // TLD
    if (urlObj.tld) {
      // What?
      finalPath = urlObj.tld
    } else if (urlObj.url) {
      finalPath = urlObj.url
    } else {
      throw new Error("TLD Error")
    }
    // Path
    if (urlObj.pathname && urlObj.pathname.length >= 1) {
      finalPath = `${finalPath}${urlObj.pathname}`
    }

    // console.log("🚀 ~ finalPath", finalPath)
  } catch {
    // console.log("🚀 ~ finalPath error", finalPath)
    // return ''
    throw new Error("Path Glue Error")
  }

  // console.log('glue', urlObj, finalPath)
  // TODO: If any invalid chars in path, split in special property ``, or encodeUrlComponent first
  // And if is last path and has . in it and 1/4 chars it is a file `file`
  // Also create a savable unique path from invalid path segment

  const savableParams = getSavableParams(urlObj)
  if (savableParams) {
    finalPath = `${finalPath}/${savableParams}`
  } else {
    finalPath = finalPath.replace(/\/$/, '')
    finalPath = specificEncoder(finalPath) // Imortant to replace . in path, but not in domain
  }

  // console.log('GLUE PATH', finalPath)
  return finalPath
}



export const validateSavablePath = (uri: string): string | boolean => {
  const urlObj = urlFunc(uri)
  // console.log(uri, urlObj)
  const path = gluePath(urlObj)
  const savablePath: string | boolean = path

  const correctedPathsArray: string[] = []

  // Check strings compatibility
  if (savablePath) {
    const pathsArray = savablePath.split('/')
    // console.log('pathsArray', pathsArray)
    pathsArray.map((pathItem) => {
      const isGood = checkFirebaseCompatible().test(pathItem)
      // console.log('pathItem', pathItem, isGood)
      if (!isGood && pathItem) {
        // ? decode url first before encoding, or not needed becaus URL() already does that?
        // ? Probably not here
        // encodeURI(decodeURI(""))

        const replacedPathItem = specificEncoder(pathItem)
        // console.log("🚀 ~ replaced", replacedPathItem)
        const isGoodNow = checkFirebaseCompatible().test(replacedPathItem)
        if (!isGoodNow) {
          // console.log("🚀 ~ isGoodNow?", isGoodNow)
          // Check which chracter is culprit
          let nomorePathErrors = true
          replacedPathItem.split('').map((char) => {
            // console.log("🚀 ~ char", char)
            if (!checkFirebaseCompatible().test(char)) {
              console.error("🚀 Invalid char", char)
              nomorePathErrors = false
            }
          })
          if (nomorePathErrors) {
            // Return path
            correctedPathsArray.push(replacedPathItem)
          } else {
            throw new Error('Invalid savable path')
          }
        } else {
          correctedPathsArray.push(pathItem)
        }
      } else {
        correctedPathsArray.push(pathItem)
      }
    })
  } else {
    throw new Error("No savablePath")
  }

  // console.log("🚀FINAL correctedPaths", correctedPathsArray.join('/'))
  // console.log("🚀FINAL savablePath", savablePath)
  // return savablePath
  return correctedPathsArray.join('/')
}


export const getSavablePaths = (uri: string): string => {
  // console.log('uri', uri)
  const valid = validateSavablePath(uri)
  // console.log('valid', valid)
  if (valid) {
    const urlObj = urlFunc(uri)
    // console.log('urlObj', urlObj)
    const path = gluePath(urlObj)
    // console.log('path', path)
    return path
  } else {
    throw new Error('Invalid Uri!')
  }
}
