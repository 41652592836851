import { FloatingWindowData } from '@Components/containers/ui/FloatingWindow'
import { brandVars } from '@Constants/config/brand'
import { UserRoles } from '@Constants/config/roleVars'
import { Types } from '@Constants/config/types'
import { defaultLongLat, defaultRadius, defaultZoomLevel, LatLongArray, LongLatArray } from '@Constants/types/location'
import { SET_MAP_RADIUS } from '../actions/storageActions'
import {
  CHANGE_CAT,
  CHANGE_SUB_TYPE,
  CHANGE_TAGS,
  CHANGE_THEME,
  CHANGE_TYPE,
  CHANGE_UID,
  CHANGE_VIS,
  CHANGE_HAS_SUBPOSTS,
  DRAWER_TYPES_TOGGLE,
  MINIMIZE_FLOATING_WINDOW,
  RESET_FILTERS,
  SET_FLOATING_WINDOW_URL,
  StorageActionTypes,
  TOGGLE_AUTOCOMPLETE_TITLE,
  TOGGLE_EDITED,
  TOGGLE_FILTER,
  TOGGLE_FLOATING_WINDOW,
  SET_LIMIT,
  TOGGLE_GRID,
  TOGGLE_MARKDOWN,
  TOGGLE_SORT_DIRECTION,
  UPDATE_FLOATING_WINDOW_DATA,
  SET_ZOOM_LEVEL,
  SET_LONG_LAT,
} from '@State/actions/storageActions'


export enum Limits {
  Twenty = 20,
  Fifty = 50,
  Hundred = 100,
  FiveHundred = 500,
  TwoThousand = 2000,
}

// Storage state is persistent storage
export interface StorageState {
  theme: string;
  primaryColor: string;
  secondaryColor: string;
  infoColor: string;
  errorColor: string;

  drawerTypesOpen: boolean;

  limit: Limits;
  listFilterOpen: boolean;
  gridEnabled: boolean;
  sortAscendingEnabled: boolean;
  sortByEditedEnabled: boolean;
  typeSelected: Types;
  subTypeSelected: Types;
  catSelected: string;
  tagsSelected: string[];
  visSelected: string;
  uidSelected: string;
  hasSubPosts: boolean;

  markdownEnabled: boolean;
  autoCompleteTitle: boolean;

  showFloatingWindow?: boolean;
  floatingWindowMinimized?: boolean
  floatingWindowData: FloatingWindowData;
  floatingWindowUrl: string;

  mapZoomLevel: number;
  lastLongLat: LongLatArray
  lastMapRadius: number
}

const defaultPanelSize = 200
export const defaultPanelRatio = 1.33

export const initialStorageState: StorageState = {
  theme: 'default',
  primaryColor: brandVars.primaryColor,
  secondaryColor: brandVars.secondaryColor,
  infoColor: brandVars.infoColor,
  errorColor: brandVars.errorColor,

  drawerTypesOpen: false,

  limit: Limits.Fifty,
  listFilterOpen: false,
  gridEnabled: false,
  sortAscendingEnabled: false,
  sortByEditedEnabled: true,
  typeSelected: '',
  subTypeSelected: '',
  catSelected: '',
  tagsSelected: [],
  visSelected: 'public',
  uidSelected: '',
  hasSubPosts: false,

  markdownEnabled: true,
  autoCompleteTitle: true,

  showFloatingWindow: false,
  floatingWindowData: {
    x: 10,
    y: 10,
    width: defaultPanelSize * defaultPanelRatio,
    height: defaultPanelSize,
  },
  floatingWindowUrl: '',
  floatingWindowMinimized: false,

  mapZoomLevel: defaultZoomLevel,
  lastLongLat: defaultLongLat,
  lastMapRadius: defaultRadius
}

export interface FirebaseProfile {
  isLoaded: boolean
  isEmpty: boolean
  token?: {
    token: string
    expirationTime: string
    authTime: string
    issuedAtTime: string
    signInProvider: string
    // signInSecondFactor: any
    claims: {
      name: string
      picture: string
      iss: string
      aud: string
      auth_time: number
      user_id: string
      sub: string
      iat: number
      exp: number
      email: string
      email_verified: boolean
    };
  }
}

export interface AppProfile extends FirebaseProfile {
  // [key: string]: unknown
  role: UserRoles;
}

export default function storageReducer(
  state = initialStorageState,
  action: StorageActionTypes
): StorageState {
  // console.log('appReducer', state, action)
  if (action.type === CHANGE_THEME) {
    return {
      ...state,
      ...(action.theme ? {
        theme: action.theme
      } : {
        theme: state.theme === 'dark' ? 'light' : 'dark'
      }),
      primaryColor: state.theme === 'dark' ? brandVars.primaryColor : brandVars.primaryColorDark,
      secondaryColor: state.theme === 'dark' ? brandVars.secondaryColor : brandVars.secondaryColorDark,
      infoColor: state.theme === 'dark' ? brandVars.infoColor : brandVars.infoColorDark,
      errorColor: state.theme === 'dark' ? brandVars.errorColor : brandVars.errorColorDark,
    }
  }
  else if (action.type === TOGGLE_FILTER) {
    return {
      ...state,
      listFilterOpen: action.open
    }
  }
  else if (action.type === DRAWER_TYPES_TOGGLE) {
    return {
      ...state,
      drawerTypesOpen: (typeof action.open === 'undefined') ? !state.drawerTypesOpen : action.open
    }
  }
  else if (action.type === SET_LIMIT) {
    return {
      ...state,
      limit: action.limit
    }
  }
  else if (action.type === TOGGLE_GRID) {
    return {
      ...state,
      gridEnabled: action.open
    }
  }
  else if (action.type === TOGGLE_SORT_DIRECTION) {
    return {
      ...state,
      sortAscendingEnabled: (typeof action.open === 'undefined') ? !state.sortAscendingEnabled : action.open
    }
  }
  else if (action.type === TOGGLE_EDITED) {
    return {
      ...state,
      sortByEditedEnabled: (typeof action.open === 'undefined') ? !state.sortByEditedEnabled : action.open
    }
  }
  else if (action.type === CHANGE_TYPE) {
    return {
      ...state,
      typeSelected: action.value
    }
  }
  else if (action.type === CHANGE_SUB_TYPE) {
    return {
      ...state,
      subTypeSelected: action.value
    }
  }
  else if (action.type === CHANGE_CAT) {
    return {
      ...state,
      catSelected: action.value
    }
  }
  else if (action.type === CHANGE_TAGS) {
    return {
      ...state,
      tagsSelected: action.value
    }
  }
  else if (action.type === CHANGE_VIS) {
    return {
      ...state,
      visSelected: action.value
    }
  }
  else if (action.type === CHANGE_HAS_SUBPOSTS) {
    return {
      ...state,
      hasSubPosts: action.value
    }
  }
  else if (action.type === CHANGE_UID) {
    return {
      ...state,
      uidSelected: action.value
    }
  }
  else if (action.type === RESET_FILTERS) {
    return {
      ...state,
      typeSelected: '',
      tagsSelected: [],
      catSelected: '',
      uidSelected: '',
      visSelected: 'public',
      hasSubPosts: false
    }
  }
  else if (action.type === TOGGLE_MARKDOWN) {
    return {
      ...state,
      markdownEnabled: !state.markdownEnabled
    }
  }
  else if (action.type === TOGGLE_AUTOCOMPLETE_TITLE) {
    return {
      ...state,
      autoCompleteTitle: !state.autoCompleteTitle
    }
  }
  else if (action.type === TOGGLE_FLOATING_WINDOW) {
    // TODO: Check if window is out of bound due to resizing
    // TODO: Unminimize whenever going from hidden to visible
    return {
      ...state,
      showFloatingWindow: action.hasOwnProperty('open') ? action.open : !state.showFloatingWindow,
      floatingWindowMinimized: !state.showFloatingWindow
    }
  }
  else if (action.type === UPDATE_FLOATING_WINDOW_DATA) {
    return {
      ...state,
      floatingWindowData: {
        ...state.floatingWindowData,
        ...action.data
      }
    }
  }
  else if (action.type === SET_FLOATING_WINDOW_URL) {
    return {
      ...state,
      floatingWindowUrl: action.url
    }
  }
  else if (action.type === MINIMIZE_FLOATING_WINDOW) {
    return {
      ...state,
      floatingWindowMinimized: action.hasOwnProperty('minimize') ? action.minimize : !state.floatingWindowMinimized,
      ...(action.minimize ? {
        floatingWindowData: {
          ...state.floatingWindowData,
          x: 4,
          y: 4,
          height: 56,
          width: 200
        }
      } : {
        floatingWindowData: { ...initialStorageState.floatingWindowData }
      })
    }
  } else if (action.type === SET_ZOOM_LEVEL) {
    if (typeof action.zoomLevel === 'number') {
      return {
        ...state,
        mapZoomLevel: action.zoomLevel
      }
    } else {
      console.warn(`${action.type}: Not a number:`, action.zoomLevel)
      return { ...state }
    }
  } else if (action.type === SET_LONG_LAT) {
    if (action.longlat.length === 2) {
      return {
        ...state,
        lastLongLat: action.longlat
      }
    } else {
      console.warn(`${action.type}: trying to set invalid longlat`)
      return { ...state }
    }
  } else if (action.type === SET_MAP_RADIUS) {
    if (typeof action.radius === 'number') {
      return {
        ...state,
        lastMapRadius: action.radius
      }
    } else {
      console.warn(`${action.type}: Not a number`, action.radius)
      return { ...state }
    }
  } else {
    return state
  }
}
