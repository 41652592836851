import { ExistingPostData, NewPostData, PartialPostData, PostData } from '@Constants/post'
import urlFunc from '@Utils/urlFunc';

export interface FirebaseDate {
  toDate: () => string
}

function postDataFunc(item: Partial<PostData>): ExistingPostData | NewPostData | PartialPostData {
  // TODO: Fix empty strings not saving
  // console.log('postData', item)
  return {
    ...item,
    //   ...(!!item.type && {
    //     type: item.type
    //   }),
    //   ...(!!item.cat && {
    //     cat: item.cat
    //   }),
    //   ...(!!item.url && {
    //     url: item.url
    //   }),
    //   ...(item.url === '' && {
    //     url: ''
    //   }),
    ...(!!item.ref && {
      ref: item.ref.toLowerCase()
    }),
    //   ...(item.ref === '' && {
    //     ref: ''
    //   }),
    //   ...(!!item.tags && {
    //     tags: item.tags
    //   }),
    ...(!!item.isSource && {
      isSource: true
    }),
    ...(!item.isSource && {
      isSource: false
    }),
    //   ...(!!item.imgUrl && {
    //     imgUrl: item.imgUrl
    //   }),
    //   ...(!!item.date && {
    //     date: item.date
    //   }),
  }
}

export default postDataFunc

export function FromNow(date: Date | string, nowDate: Date | number = Date.now(), rft = new Intl.RelativeTimeFormat(undefined, { numeric: "auto" })) {
  const SECOND = 1000;
  const MINUTE = 60 * SECOND;
  const HOUR = 60 * MINUTE;
  const DAY = 24 * HOUR;
  const WEEK = 7 * DAY;
  const MONTH = 30 * DAY;
  const YEAR = 365 * DAY;
  const intervals = [
    { ge: YEAR, divisor: YEAR, unit: 'year' },
    { ge: MONTH, divisor: MONTH, unit: 'month' },
    { ge: WEEK, divisor: WEEK, unit: 'week' },
    { ge: DAY, divisor: DAY, unit: 'day' },
    { ge: HOUR, divisor: HOUR, unit: 'hour' },
    { ge: MINUTE, divisor: MINUTE, unit: 'minute' },
    { ge: 30 * SECOND, divisor: SECOND, unit: 'seconds' },
    { ge: 0, divisor: 1, text: 'just now' },
  ];
  let now
  if (typeof nowDate === 'object' && nowDate instanceof Date) {
    now = nowDate.getTime()
  } else {
    now = new Date(nowDate).getTime();
  }
  const diff = now - (typeof date === 'object' ? date : new Date(date)).getTime();
  const diffAbs = Math.abs(diff);
  for (const interval of intervals) {
    if (diffAbs >= interval.ge) {
      const x = Math.round(Math.abs(diff) / interval.divisor);
      const isFuture = diff < 0;
      // @ts-expect-error unit
      return interval.unit ? rft.format(isFuture ? x : -x, interval.unit) : interval.text || ''
    }
  }
  return ''
}


export const isUrlEmbed = (item: ExistingPostData) => {
  const theUrl = item?.url
  const urlItem = theUrl ? urlFunc(theUrl) : null
  const urlEmbed = urlItem ? urlItem.meta : null
  const isUrlEmb = urlEmbed ? urlEmbed.brand === 'twitter' : false
  return isUrlEmb
}