import { Coordinate } from 'ol/coordinate'
import Point from 'ol/geom/Point'
import { fromLonLat } from 'ol/proj'
import { Fill, Stroke, Style } from 'ol/style'
import { RFeature, RLayerVector, RMap, ROSM } from 'rlayers'

import { defaultZoomLevel, maxZoomLevel } from '@Constants/types/location'
import CircleStyle from 'ol/style/Circle'
import { Poi } from '@Components/map/Features/Poi'

export default function MapConstrained({
  coords = [0, 0],
  controls = false,
  zoomable = false,
  defaultZoom = defaultZoomLevel,
  maxZoom = maxZoomLevel,
  width = '100%',
  height = '100%',
}: {
  coords: number[]
  controls?: boolean
  zoomable?: boolean
  defaultZoom?: number
  maxZoom?: number
  width?: string | number
  height?: string | number
}): JSX.Element {
  const coordinates: Record<string, Coordinate> = {
    origin: coords,
  }
  return (
    <RMap
      width={width}
      height={height}
      initial={{ center: fromLonLat(coords), zoom: defaultZoom }}
      noDefaultControls={!controls}
      noDefaultInteractions={!zoomable}
      maxZoom={maxZoom}
      onClick={(e) => {
        e.stopPropagation()
      }}
    >
      <ROSM />
      <RLayerVector zIndex={10}>
        <RFeature
          geometry={new Point(fromLonLat(coordinates.origin))}
          style={Poi}
        ></RFeature>
      </RLayerVector>
    </RMap>
  )
}
