import Icon from '@mui/material/Icon'
import { styled, SxProps } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import urlFunc from '@Utils/urlFunc'
import classNames from 'classnames'
import React from 'react'

const PREFIX = 'Favicon'

const classes = {
  clickThrough: `${PREFIX}-clickThrough`,
}

const StyledIcon = styled(Icon)(({ theme }) => ({
  [`&.${classes.clickThrough}`]: {
    pointerEvents: 'none',
  },
}))

export default function Favicon({
  url,
  size = 'small',
  fetchSize = 128,
  className,
  shadow,
  sx,
}: {
  url: string
  size?: 'inherit' | 'medium' | 'large' | 'small' | undefined
  fetchSize?: number
  className?: string
  shadow?: boolean
  openable?: boolean // TODO: Implement

  sx?: SxProps
}) {
  const urlItem = urlFunc(url)
  // TODO: If youtube, replace with 'images/youtube-favicon.png' ?
  let faviconSrc = `https://www.google.com/s2/favicons?sz=${fetchSize}&domain=${url}`
  if (
    urlItem.meta &&
    urlItem.meta.brand === 'youtube' &&
    (urlItem.meta.type === 'video' || urlItem.meta.type === 'short')
  ) {
    faviconSrc = '/images/youtube-favicon.png'
  }
  // TODO: If link is self domain, show local favicon

  const getSize = () => {
    switch (size) {
      case 'small':
        return 12
      case 'medium':
        return 12
      case 'large':
        return 12

      default:
        return 36
    }
  }

  return (
    <StyledIcon
      className={classNames(classes.clickThrough, className)}
      sx={
        shadow
          ? {
              boxShadow: '5px 3px 4px 2px #000000',
              borderRadius: '50%',
              ...sx,
            }
          : { ...sx }
      }
      fontSize={size}
    >
      <img src={faviconSrc} height="100%" width="100%" alt="favicon" />
    </StyledIcon>
  )
}
