import { createEditableVoidPlugin } from '@Components/shared/object-editor/components/EditableVoid/createEditableVoidPlugin'
import { EditableVoid } from '@Components/shared/object-editor/components/EditableVoid/EditableVoid'
import {
  PostElement
} from '@Components/shared/object-editor/components/PostElement/PostElement'
import { createObjectEditorPlugins, ELEMENT_POST } from '@Components/shared/object-editor/ObjectEditor.types'


export const voidPlugins = createObjectEditorPlugins([
  // createEditableVoidPlugin({
  //   component: EditableVoid,
  // }),
  createEditableVoidPlugin({
    key: ELEMENT_POST,
    component: PostElement
  }),
])