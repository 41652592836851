import { History, LocationState, createBrowserHistory } from 'history'
import {
  FirebaseReducer,
  FirestoreReducer,
  firebaseReducer,
  getFirebase
} from 'react-redux-firebase'
import { Action, AnyAction, Store, applyMiddleware, combineReducers, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import { firestoreReducer } from 'redux-firestore'
import persistState from 'redux-localstorage'
import { createEpicMiddleware } from 'redux-observable'
import thunk from 'redux-thunk'

import * as AuthTypes from '@firebase/auth-types'
// import { reducer as formReducer } from 'redux-form'
import { AuthProfile } from '@Components/containers/AuthProvider'
import appReducer, { AppState } from '@State/reducers/appReducer'
import notificationReducer, { NotificationState } from '@State/reducers/notificationReducer'
import postReducer, { PostReducerState } from '@State/reducers/postReducer'
import storageReducer, { StorageState } from '@State/reducers/storageReducer'
import uriReducer, { UriPath, UriState } from '@State/reducers/uriReducer'

import { rootEpic } from './epics'


// import Entity = FirestoreReducer.Entity;

// import { configureStore } from '@reduxjs/toolkit'


// import { UserInfo } from 'firebase'
// import { usersReducer } from '@State/reducers/testReducers'

export const history = createBrowserHistory();

export interface Deps {
  history: typeof history;
  store: RootState;
}

interface MiddleWare<T> {
  type: Action<T>
  dependencies: Deps
}

const epicMiddleware = createEpicMiddleware<MiddleWare<unknown>>({
  dependencies: {
    history,
    /* eslint-disable @typescript-eslint/no-use-before-define */
    get store() {
      return store;
    }
  }
});

export interface FirebaseAuthState {
  displayName: string | null;
  email: string | null;
  phoneNumber: string | null;
  photoURL: string | null;
  providerId: string;
  uid: string;
  isLoaded: boolean
  isEmpty: boolean
  apiKey: string
  appName: string
  authDomain: string
  createdAt: string
  emailVerified: boolean
  isAnonymous: boolean
  lastLoginAt: string
  providerData: AuthTypes.UserInfo[] | null
  redirectEventId: null
  stsTokenManager: {
    accessToken: string
    apiKey: string
    expirationTime: number
    refreshToken: string
  }
}


export interface FirebaseState {
  auth: FirebaseAuthState //Record<string, unknown>
  data: {
    uri: UriPath
    users: {
      [userId: string]: any
    }
  }
}


export interface FirestoreState {
  users: {
    [userId: string]: any //Entity<User>
  }
}


export interface RootState {
  appState: AppState;
  firebase: FirebaseReducer.Reducer<AuthProfile, FirebaseState>;
  firestore: FirestoreReducer.Reducer //<FirestoreState>; // FIXME type
  posts: PostReducerState;
  notifications: NotificationState;
  storage: StorageState;
  uris: UriState;
}


const rootReducer = combineReducers<RootState>({
  appState: appReducer,
  firebase: firebaseReducer,
  firestore: firestoreReducer,
  posts: postReducer,
  notifications: notificationReducer,
  storage: storageReducer,
  uris: uriReducer
  // form: formReducer // Must be called 'form'
});

export type AppRootState = ReturnType<typeof rootReducer>


export const store: Store<RootState, AnyAction> = createStore(
  rootReducer,
  // FIXME: Only on dev
  composeWithDevTools(
    applyMiddleware(...[
      epicMiddleware,
      thunk.withExtraArgument({ getFirebase }),
    ]),
    // @ts-expect-error because I know
    persistState(['storage'])
  )
)

// export const newStore = configureStore({
//   reducer: rootReducer
// })

// export type RootStore = ReturnType<typeof store.getState>

// export type StoreType = typeof store
export type AppDispatch = typeof store.dispatch

export interface StoreWithDispatch {
  dispatch: AppDispatch
}

export interface StoreDependencies {
  store: StoreWithDispatch
  history: History<LocationState>
}

epicMiddleware.run(rootEpic);