export const fbConfig = process.env.REACT_APP_ENV === 'production' ? {
  apiKey: "AIzaSyD00adFheP6BMehZQ84E9V1iUD8r0yDD9w",
  authDomain: "yolobyte-6b3bf.firebaseapp.com",
  databaseURL: "https://yolobyte-6b3bf.firebaseio.com",
  projectId: "yolobyte-6b3bf",
  storageBucket: "yolobyte-6b3bf.appspot.com",
  messagingSenderId: "84988760604",
  appId: "1:84988760604:web:d736af1f8dd713ba1605d9",
  measurementId: "G-80S4XCQ1EM"
} : {
  apiKey: "AIzaSyAKNw6A8kALPqnd3Eq3nPllp2QLDAXa8IQ",
  authDomain: "yolodev-66fc0.firebaseapp.com",
  projectId: "yolodev-66fc0",
  storageBucket: "yolodev-66fc0.appspot.com",
  messagingSenderId: "299828515334",
  appId: "1:299828515334:web:e9b3d2d07956ab8a332987",
  measurementId: "G-LRFVYJFX0H"
};