// @ts-nocheck
import Backdrop from '@mui/material/Backdrop'
import Icon from '@mui/material/Icon'
import IconButton from '@mui/material/IconButton'
import MUIModal from '@mui/material/Modal'
import makeStyles from '@mui/styles/makeStyles'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import React from 'react'
import { animated, useSpring } from 'react-spring'
import { TypesOfReactChildren } from '@Utils/reactHelpers'
import Box from '@mui/material/Box'

const useStyles = makeStyles((theme) => ({
  modal: {
    flexGrow: 1,
    flexDirection: 'column',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    // marginTop: theme.spacing(2),
    // marginBottom: theme.spacing(2),
  },
  fade: {
    // display: 'inline-block',
    overflowX: 'auto',
    maxHeight: '95vh',
    maxWidth: '97vw',
    // width: '90%',
    [theme.breakpoints.up('md')]: {
      // width: '80%',
      // minWidth: '80vw',
      maxWidth: '80vw',
      // maxWidth: '1024px',
    },
    [theme.breakpoints.up('xl')]: {
      // width: '60%',
      // minWidth: '60vw',
      maxWidth: '50vw',
      // maxWidth: '1024px',
    },
    // overflowX: 'visible'
  },
  header: {
    backgroundColor: theme.palette.secondary.main,
    height: 48,
  },
  title: {
    float: 'left',
    padding: theme.spacing(1, 2, 1),
  },
  closeButton: {
    float: 'right',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    // padding: theme.spacing(2, 4, 3),
    // overflowY: 'auto',
    minWidth: '50vw', // important for content to fit
    // width: '100%',
  },
}))

interface FadeProps {
  children?: React.ReactElement
  in: boolean
  onEnter?: () => void
  onExited?: () => void
}

const Fade = React.forwardRef<HTMLDivElement, FadeProps>(function Fade(
  props,
  ref
) {
  // @ts-expect-error
  const { in: open, children, onEnter, onExited, ...other } = props
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter()
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited()
      }
    },
  })

  return (
    // @ts-expect-error
    <animated.div ref={ref} style={style} {...other}>
      {children}
    </animated.div>
  )
})

export default function SystemModal({
  title = 'Dialog',
  open,
  handleClose,
  children,
  className,
}: {
  open: boolean
  title?: string
  handleClose?: (e) => void
  children?: TypesOfReactChildren
  className?: string
}) {
  const classes = useStyles()

  return (
    <MUIModal
      // aria-labelledby="dailog-title"
      // aria-describedby="dailog-description"
      className={classes.modal}
      open={open}
      onClose={handleClose}
      closeAfterTransition
      // BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open} className={classes.fade}>
        <>
          <div className={classes.header}>
            <Typography variant="h5" className={classes.title}>
              {title}
            </Typography>

            <Tooltip
              followCursor
              title="Click to close"
              className={classes.closeButton}
            >
              <IconButton aria-label="close" onClick={handleClose} size="large">
                <Icon>close</Icon>
              </IconButton>
            </Tooltip>
          </div>
          <Box
            className={classes.paper}
            sx={{
              display: 'flex',
              width: '100%',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {children}
          </Box>
        </>
      </Fade>
    </MUIModal>
  )
}
