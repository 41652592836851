import { ExistingPostData } from '@Constants/post'
import { UriPostById } from '@State/reducers/uriReducer'

import { FirebaseDate, FromNow } from './postDataFunc'


function DateString(post: Partial<ExistingPostData & UriPostById>, noPrefix?: boolean, fromNow?: boolean): string {
  const date = post.published && post.published.toDate ? post.published.toDate()
    : post.created && post.created.toDate ? post.created.toDate()
      : post.edited && post.edited.toDate ? post.edited.toDate()
        : post.added && post.added.toDate ? post.added.toDate()
          : ''
  const rawDate = post.published || post.created || post.edited || post.added

  const displayDate = date ? new Date(date).toLocaleDateString(
    'en-gb',
    {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    }
  ) :
    // @ts-expect-error, because it is actually string?
    new Date(rawDate).toLocaleDateString(
      'en-gb',
      {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      }
    )

  const dateType = post.published ? 'Published'
    : post.created ? 'Created'
      : post.edited ? 'Edited'
        : post.added ? 'Added'
          : 'Appeared'

  const finalDateType = noPrefix ? '' : `${dateType}${fromNow ? '' : ': '}`

  return fromNow ? `${finalDateType} ${FromNow(date)}` : `${finalDateType}${displayDate}`
}


export function dateStringFrom(date: FirebaseDate): string {
  if (date) {
    const dater = date.toDate && date.toDate()
    const dat = new Date(dater).toLocaleDateString(
      'en-gb',
      {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      }
    )
    return dat ? `${dat}` : ''
  } else {
    return ''
  }
}

export default DateString