import 'firebase/compat/auth'
import 'firebase/compat/database'
import 'firebase/compat/firestore'
// import 'firebase/compat/storage'

import firebase from 'firebase/compat/app'
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { createFirestoreInstance } from 'redux-firestore'

import { store } from '@State/store'

import { fbConfig } from '../constants/config/fbConfig'


firebase.initializeApp(fbConfig);

firebase.firestore();


export const fbApp = initializeApp(fbConfig);
export const fbAuth = getAuth(fbApp)

export const auth = firebase.auth();
export const firestore = firebase.firestore();
// export const storage = firebase.storage();
export const googleProvider = new firebase.auth.GoogleAuthProvider();
// googleProvider.addScope('https://www.googleapis.com/auth/contacts.readonly');
const fb = firebase

// react-redux-firebase config
const rrfConfig = {
  userProfile: 'users',
  useFirestoreForProfile: true, // Needed for AuthProvider
  logErrors: true,
  // profileParamsToPopulate: [
  //   ['role:roles'], // populates user's role with matching role object from roles
  // ],
  // profileFactory: user => {
  //   const profile = {
  //     email: user.email || user.providerData[0].email,
  //     role: 'user',
  //   }
  //   if (user.providerData && user.providerData.length) {
  //     profile.providerData = user.providerData
  //   }
  //   return profile
  // },
  // enableClaims: true,
  oneListenerPerPath: true
}

export const rrfProps = {
  firebase: fb,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance // <- needed if using firestore
}

export default fbConfig;
