export const NOTIFICATION = 'NOTIFICATION'
export const CLOSE_NOTIFICATION = 'CLOSE_NOTIFICATION'

export const TOGGLE_LOGIN_DIALOG = 'TOGGLE_LOGIN_DIALOG'

export const TOGGLE_DRAWER = 'TOGGLE_DRAWER'
export const TOGGLE_RIGHT_DRAWER = 'TOGGLE_RIGHT_DRAWER'

export const SEARCH_QUERY = 'SEARCH_QUERY'

interface SendNotificationAction {
  type: typeof NOTIFICATION
  text: string;
  severity?: string;
  variant?: string;
}

interface CloseNotificationAction {
  type: typeof CLOSE_NOTIFICATION
}

interface ToggleLoginDialogAction {
  type: typeof TOGGLE_LOGIN_DIALOG
  open?: boolean;
}

interface ToggleDrawerAction {
  type: typeof TOGGLE_DRAWER
  state: boolean;
}
interface ToggleRightDrawerAction {
  type: typeof TOGGLE_RIGHT_DRAWER
  state?: boolean;
}

interface SearchQueryAction {
  type: typeof SEARCH_QUERY
  text: string
}

// App state
export function sendNotifcationAction(text: string, severity?: string, variant?: string): SendNotificationAction {
  return {
    type: NOTIFICATION,
    text,
    severity,
    variant
  };
}

export function closeNotifcationAction(): CloseNotificationAction {
  return {
    type: CLOSE_NOTIFICATION
  };
}

export function toggleLoginDialogAction(open?: boolean): ToggleLoginDialogAction {
  return {
    type: TOGGLE_LOGIN_DIALOG,
    open
  };
}


export function toggleDrawerAction(state: boolean): ToggleDrawerAction {
  return {
    type: TOGGLE_DRAWER,
    state
  };
}

export function toggleRightDrawerAction(state?: boolean): ToggleRightDrawerAction {
  return {
    type: TOGGLE_RIGHT_DRAWER,
    state
  };
}


export function searchQueryAction(text: string): SearchQueryAction {
  return {
    type: SEARCH_QUERY,
    text
  };
}

export type AppActionTypes =
  SendNotificationAction |
  CloseNotificationAction |
  ToggleLoginDialogAction |
  ToggleDrawerAction |
  ToggleRightDrawerAction |
  SearchQueryAction