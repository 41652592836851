import { plateComponents } from '@Components/shared/object-editor/components/plateComponents'
import { mentionPlugins } from '@Components/shared/object-editor/plugins/mentionPlugins'
import { readOnlyFormattingPlugins } from '@Components/shared/object-editor/plugins/readOnlyFormattingPlugins'
import { readOnlyMediaPlugins } from '@Components/shared/object-editor/plugins/readOnlyMediaPlugins'
import { voidPlugins } from '@Components/shared/object-editor/plugins/voidPlugins'

import { basicElementsPlugins } from './plugins/basicElementsPlugins'
import { basicMarksPlugins } from './plugins/basicMarksPlugins'
import { createObjectEditorPlugins } from './ObjectEditor.types'

export const readOnlyPlugins = createObjectEditorPlugins(
  [
    ...basicElementsPlugins,
    ...basicMarksPlugins,

    ...readOnlyFormattingPlugins,
    ...readOnlyMediaPlugins,
    ...mentionPlugins,

    ...voidPlugins,
  ],
  {
    components: plateComponents,
  }
)
