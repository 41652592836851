import { ChangeEventHandler, useCallback } from 'react'
import {
  AsProps,
  createComponentAs,
  createElementAs,
  HTMLPropsAs,
  mergeProps,
} from '@udecode/plate-core'
import { floatingLinkActions, floatingLinkSelectors } from './floatingLinkStore'
import Input from '@mui/material/Input'

export const useFloatingLinkTextInput = (
  props: HTMLPropsAs<'input'>
): HTMLPropsAs<'input'> => {
  const onChange: ChangeEventHandler<HTMLInputElement> = useCallback((e) => {
    floatingLinkActions.text(e.target.value)
  }, [])

  return mergeProps(
    {
      onChange,
      defaultValue: floatingLinkSelectors.text(),
    },
    {
      ...props,
      style: {
        ...props.style,
        margin: 4,
      },
    }
  )
}

export const FloatingLinkTextInput = createComponentAs<AsProps<'input'>>(
  (props) => {
    const htmlProps = useFloatingLinkTextInput(props)

    return createElementAs(Input, htmlProps)
  }
)
