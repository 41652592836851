// import {
//   ELEMENT_EXCALIDRAW,
//   TExcalidrawElement,
// } from '@udecode/plate-ui-excalidraw';
import { CSSProperties } from 'styled-components'

import {
  AutoformatRule,
  CreatePlateEditorOptions,
  DOMHandler,
  Decorate,
  DecorateEntry,
  EDescendant,
  EElement,
  EElementEntry,
  EElementOrText,
  ELEMENT_BLOCKQUOTE,
  ELEMENT_HR,
  ELEMENT_LI,
  ELEMENT_OL,
  ELEMENT_PARAGRAPH,
  ELEMENT_TABLE,
  ELEMENT_TD,
  ELEMENT_TODO_LI,
  ELEMENT_TR,
  ELEMENT_UL,
  EMarks,
  ENode,
  ENodeEntry,
  EText,
  ETextEntry,
  InjectComponent,
  InjectProps,
  KeyboardHandler,
  NoInfer,
  OnChange,
  OverrideByKey,
  PlateEditor,
  PlateId,
  PlatePlugin,
  PlatePluginComponent,
  PlatePluginInsertData,
  PlatePluginProps,
  PlateProps,
  PluginOptions,
  SerializeHtml,
  TElement,
  TNodeEntry,
  TReactEditor,
  TTableElement,
  TText,
  TTodoListItemElement,
  WithOverride,
  createPlateEditor,
  createPluginFactory,
  createPlugins,
  createTEditor,
  getTEditor,
  useEditorRef,
  useEditorState,
  usePlateActions,
  usePlateEditorRef,
  usePlateEditorState,
  usePlateSelectors,
  usePlateStates
} from '@udecode/plate'
import {
  TMentionElement,
  TMentionInputElement
} from '@Components/shared/object-editor/components/MentionInputElement/MentionInputElement'
import {
  ELEMENT_IMAGE,
  TImageElement
} from '@Components/shared/object-editor/plugins/media/imagePlugin'
import { ELEMENT_MEDIA_EMBED } from '@Components/shared/object-editor/plugins/media/media-embed/createMediaEmbedPlugin'
import { TMediaEmbedElement } from '@Components/shared/object-editor/plugins/media/media-embed/types'
import {
  ELEMENT_MENTION,
  ELEMENT_MENTION_INPUT
} from '@Components/shared/object-editor/plugins/mention/createMentionPlugin'
import { brandVars } from '@Constants/config/brand'
import { RefData } from '@Components/shared/object-editor/components/AsyncCombobox/RefCombobox'
import { PathData } from '@Components/shared/object-editor/components/AsyncCombobox/PathCombobox'
import { TagData } from '@Components/shared/object-editor/components/AsyncCombobox/TagCombobox'


//
// Custom PARTs /+!@
//

export const ELEMENT_PATH = 'path'
export const TRIGGER_PATH = '/'
export const COLOR_PATH = brandVars.primaryColorDark

export const ELEMENT_ADD = 'add'
export const TRIGGER_ADD = '+'
export const COLOR_ADD = '#000'

export const ELEMENT_REF = 'ref'
export const TRIGGER_REF = '@'
export const COLOR_REF = brandVars.infoColorDark

export const ELEMENT_TAG = 'tag'
export const TRIGGER_TAG = '!'
export const COLOR_TAG = brandVars.secondaryColorDark



export const MENTION_TYPES = [
  ELEMENT_TAG, ELEMENT_PATH, ELEMENT_REF, ELEMENT_ADD
]

// Autoformat Marks
export const TRIGGER_BOLD_ITALIC = '***'
export const TRIGGER_BOLD = '**'
export const TRIGGER_ITALIC = '*'
export const TRIGGER_ITALIC_ALT = '_'

export const TRIGGER_H1 = '# '
export const TRIGGER_H2 = '## '
export const TRIGGER_H3 = '### '
export const TRIGGER_H4 = '#### '

export const TRIGGER_BLOCKQUOTE = '> '
export const TRIGGER_UL = ['* ', '- ']
export const TRIGGER_OL = ['1. ', '1) ']
export const TRIGGER_CODE_BLOCK = '```'

// CODE_BLOCK
export const ELEMENT_CODE_BLOCK = 'code_block'
export const ELEMENT_CODE_LINE = 'code_line'
export const ELEMENT_CODE_SYNTAX = 'code_syntax'

export const ELEMENT_LINK = 'a';

// Void elems
export const ELEMENT_POST = 'post'


// Headers
export const ELEMENT_H1 = 'h1';
export const ELEMENT_H2 = 'h2';
export const ELEMENT_H3 = 'h3';
export const ELEMENT_H4 = 'h4';
export const ELEMENT_H5 = 'h5';
export const ELEMENT_H6 = 'h6';

export const KEYS_HEADING = [
  ELEMENT_H1,
  ELEMENT_H2,
  ELEMENT_H3,
  ELEMENT_H4,
  ELEMENT_H5,
  ELEMENT_H6,
];

/**
 * Post fields
 */

export const ELEMENT_TITLE = 'title'
export const ELEMENT_TEXT = 'text'


export type EmptyText = {
  text: '';
};

export type PlainText = {
  text: string;
};

export interface RichText extends TText {
  bold?: boolean;
  italic?: boolean;
  underline?: boolean;
  strikethrough?: boolean;
  code?: boolean;
  kbd?: boolean;
  subscript?: boolean;
  backgroundColor?: CSSProperties['backgroundColor'];
  fontFamily?: CSSProperties['fontFamily'];
  color?: CSSProperties['color'];
  fontSize?: CSSProperties['fontSize'];
  fontWeight?: CSSProperties['fontWeight'];
}

/**
 * Inline Elements
 */
export interface TLinkElement extends TElement { // TODO: Imports
  url: string;
  // target?: string;
}

export interface ObjectEditorLinkElement extends TLinkElement {
  type: typeof ELEMENT_LINK;
  children: RichText[];
}

export interface ObjectEditorMentionInputElement extends TMentionInputElement {
  type: typeof ELEMENT_MENTION_INPUT;
  children: [PlainText];
}

export interface ObjectEditorMentionElement extends TMentionElement {
  type: typeof ELEMENT_MENTION;
  children: [EmptyText];
}

export interface ObjectEditorPathElement extends TMentionElement, PathData {
  type: typeof ELEMENT_PATH;
  children: [EmptyText];
}

export interface ObjectEditorAddElement extends TMentionElement {
  type: typeof ELEMENT_ADD;
  children: [EmptyText];
}

export interface ObjectEditorRefElement extends TMentionElement, RefData {
  type: typeof ELEMENT_REF;
  children: [EmptyText];
}
export interface ObjectEditorTagElement extends TMentionElement, TagData {
  type: typeof ELEMENT_TAG;
  children: [EmptyText];
}

export type ObjectEditorInlineElement =
  | ObjectEditorLinkElement
  | ObjectEditorMentionElement
  | ObjectEditorMentionInputElement
  | ObjectEditorPathElement
  | ObjectEditorAddElement
  | ObjectEditorRefElement
  | ObjectEditorTagElement

export type ObjectEditorInlineDescendant = ObjectEditorInlineElement | RichText;
export type ObjectEditorInlineChildren = ObjectEditorInlineDescendant[];

/**
 * Block props
 */

export interface ObjectEditorIndentProps {
  indent?: number;
}

export interface ObjectEditorIndentListProps extends ObjectEditorIndentProps {
  listStart?: number;
  listRestart?: number;
  listStyleType?: string;
}

export interface ObjectEditorLineHeightProps {
  lineHeight?: CSSProperties['lineHeight'];
}

export interface ObjectEditorAlignProps {
  align?: CSSProperties['textAlign'];
}

export interface ObjectEditorBlockElement
  extends TElement,
  ObjectEditorIndentListProps,
  ObjectEditorLineHeightProps {
  id?: PlateId;
}

/**
 * Blocks
 */

export interface ObjectEditorParagraphElement extends ObjectEditorBlockElement {
  type: typeof ELEMENT_PARAGRAPH;
  children: ObjectEditorInlineChildren;
}

export interface ObjectEditorTitleElement extends ObjectEditorBlockElement {
  type: typeof ELEMENT_TITLE;
  children: ObjectEditorInlineChildren;
}

export interface ObjectEditorTextElement extends ObjectEditorBlockElement {
  type: typeof ELEMENT_TEXT;
  children: ObjectEditorInlineChildren;
}

export interface ObjectEditorH1Element extends ObjectEditorBlockElement {
  type: typeof ELEMENT_H1;
  children: ObjectEditorInlineChildren;
}

export interface ObjectEditorH2Element extends ObjectEditorBlockElement {
  type: typeof ELEMENT_H2;
  children: ObjectEditorInlineChildren;
}

export interface ObjectEditorH3Element extends ObjectEditorBlockElement {
  type: typeof ELEMENT_H3;
  children: ObjectEditorInlineChildren;
}

export interface ObjectEditorH4Element extends ObjectEditorBlockElement {
  type: typeof ELEMENT_H4;
  children: ObjectEditorInlineChildren;
}

export interface ObjectEditorBlockquoteElement extends ObjectEditorBlockElement {
  type: typeof ELEMENT_BLOCKQUOTE;
  children: ObjectEditorInlineChildren;
}

export interface ObjectEditorCodeBlockElement extends ObjectEditorBlockElement {
  type: typeof ELEMENT_CODE_BLOCK;
  children: ObjectEditorCodeLineElement[];
}

export interface ObjectEditorCodeLineElement extends TElement {
  type: typeof ELEMENT_CODE_LINE;
  children: PlainText[];
}

export interface ObjectEditorTableElement extends TTableElement, ObjectEditorBlockElement {
  type: typeof ELEMENT_TABLE;
  children: ObjectEditorTableRowElement[];
}

export interface ObjectEditorTableRowElement extends TElement {
  type: typeof ELEMENT_TR;
  children: ObjectEditorTableCellElement[];
}

export interface ObjectEditorTableCellElement extends TElement {
  type: typeof ELEMENT_TD;
  children: ObjectEditorNestableBlock[];
}

export interface ObjectEditorBulletedListElement extends TElement, ObjectEditorBlockElement {
  type: typeof ELEMENT_UL;
  children: ObjectEditorListItemElement[];
}

export interface ObjectEditorNumberedListElement extends TElement, ObjectEditorBlockElement {
  type: typeof ELEMENT_OL;
  children: ObjectEditorListItemElement[];
}

export interface ObjectEditorListItemElement extends TElement, ObjectEditorBlockElement {
  type: typeof ELEMENT_LI;
  children: ObjectEditorInlineChildren;
}

export interface ObjectEditorTodoListElement
  extends TTodoListItemElement,
  ObjectEditorBlockElement {
  type: typeof ELEMENT_TODO_LI;
  children: ObjectEditorInlineChildren;
}

export interface ObjectEditorImageElement extends TImageElement, ObjectEditorBlockElement {
  type: typeof ELEMENT_IMAGE;
  align?: string;
  children: [EmptyText];
}

export interface ObjectEditorMediaEmbedElement
  extends TMediaEmbedElement,
  ObjectEditorBlockElement {
  type: typeof ELEMENT_MEDIA_EMBED;
  children: [EmptyText];
}

export interface ObjectEditorHrElement extends ObjectEditorBlockElement {
  type: typeof ELEMENT_HR;
  children: [EmptyText];
}

export interface ObjectEditorPostElement extends ObjectEditorBlockElement {
  type: typeof ELEMENT_POST;
  children: [EmptyText];
  postId?: string
  namespace?: string
}

// export interface ObjectEditorExcalidrawElement
//   extends TExcalidrawElement,
//     ObjectEditorBlockElement {
//   type: typeof ELEMENT_EXCALIDRAW;
//   children: [EmptyText];
// }

export type ObjectEditorNestableBlock = ObjectEditorParagraphElement;

export type ObjectEditorBlock = Exclude<ObjectEditorElement, ObjectEditorInlineElement>;
export type ObjectEditorBlockEntry = TNodeEntry<ObjectEditorBlock>;

export type ObjectEditorRootBlock =
  | ObjectEditorParagraphElement
  | ObjectEditorTitleElement
  | ObjectEditorTextElement
  | ObjectEditorH1Element
  | ObjectEditorH2Element
  | ObjectEditorH3Element
  | ObjectEditorH4Element
  | ObjectEditorBlockquoteElement
  | ObjectEditorCodeBlockElement
  | ObjectEditorTableElement
  | ObjectEditorBulletedListElement
  | ObjectEditorNumberedListElement
  | ObjectEditorTodoListElement
  | ObjectEditorImageElement
  | ObjectEditorMediaEmbedElement
  | ObjectEditorHrElement
  | ObjectEditorInlineElement
  | ObjectEditorPostElement
// | ObjectEditorExcalidrawElement;

export type ObjectEditorValue = ObjectEditorRootBlock[];

/**
 * Editor types
 */

export type ObjectEditorType = PlateEditor<ObjectEditorValue> & { isDragging?: boolean };
export type ObjectEditorReactEditor = TReactEditor<ObjectEditorValue>;
export type ObjectEditorNode = ENode<ObjectEditorValue>;
export type ObjectEditorNodeEntry = ENodeEntry<ObjectEditorValue>;
export type ObjectEditorElement = EElement<ObjectEditorValue>;
export type ObjectEditorElementEntry = EElementEntry<ObjectEditorValue>;
export type ObjectEditorText = EText<ObjectEditorValue>;
export type ObjectEditorTextEntry = ETextEntry<ObjectEditorValue>;
export type ObjectEditorElementOrText = EElementOrText<ObjectEditorValue>;
export type ObjectEditorDescendant = EDescendant<ObjectEditorValue>;
export type ObjectEditorMarks = EMarks<ObjectEditorValue>;
export type ObjectEditorMark = keyof ObjectEditorMarks;

/**
 * Plate types
 */

export type ObjectEditorDecorate<P = PluginOptions> = Decorate<P, ObjectEditorValue, ObjectEditorType>;
export type ObjectEditorDecorateEntry = DecorateEntry<ObjectEditorValue>;
export type ObjectEditorDOMHandler<P = PluginOptions> = DOMHandler<P, ObjectEditorValue, ObjectEditorType>;
export type ObjectEditorInjectComponent = InjectComponent<ObjectEditorValue>;
export type ObjectEditorInjectProps = InjectProps<ObjectEditorValue>;
export type ObjectEditorKeyboardHandler<P = PluginOptions> = KeyboardHandler<
  P,
  ObjectEditorValue,
  ObjectEditorType
>;
export type ObjectEditorOnChange<P = PluginOptions> = OnChange<P, ObjectEditorValue, ObjectEditorType>;
export type ObjectEditorOverrideByKey = OverrideByKey<ObjectEditorValue, ObjectEditorType>;
export type ObjectEditorPlatePlugin<P = PluginOptions> = PlatePlugin<
  P,
  ObjectEditorValue,
  ObjectEditorType
>;
export type ObjectEditorPlatePluginInsertData = PlatePluginInsertData<ObjectEditorValue>;
export type ObjectEditorPlatePluginProps = PlatePluginProps<ObjectEditorValue>;
export type ObjectEditorPlateProps = PlateProps<ObjectEditorValue, ObjectEditorType>;
export type ObjectEditorSerializeHtml = SerializeHtml<ObjectEditorValue>;
export type ObjectEditorWithOverride<P = PluginOptions> = WithOverride<
  P,
  ObjectEditorValue,
  ObjectEditorType
>;

/**
 * Plate store, Slate context
 */

export const getObjectEditorEditor = (editor: ObjectEditorType) =>
  getTEditor<ObjectEditorValue, ObjectEditorType>(editor);
export const useObjectEditorEditorRef = () => useEditorRef<ObjectEditorValue, ObjectEditorType>();
export const useObjectEditorEditorState = () => useEditorState<ObjectEditorValue, ObjectEditorType>();
export const useObjectEditorPlateEditorRef = (id?: PlateId) =>
  usePlateEditorRef<ObjectEditorValue, ObjectEditorType>(id);
export const useObjectEditorPlateEditorState = (id?: PlateId) =>
  usePlateEditorState<ObjectEditorValue, ObjectEditorType>(id);
export const useObjectEditorPlateSelectors = (id?: PlateId) =>
  usePlateSelectors<ObjectEditorValue, ObjectEditorType>(id);
export const useObjectEditorPlateActions = (id?: PlateId) =>
  usePlateActions<ObjectEditorValue, ObjectEditorType>(id);
export const useObjectEditorPlateStates = (id?: PlateId) =>
  usePlateStates<ObjectEditorValue, ObjectEditorType>(id);

/**
 * Utils
 */
export const createObjectEditorEditor = () => createTEditor() as ObjectEditorType;
export const createObjectEditorPlateEditor = (
  options: CreatePlateEditorOptions<ObjectEditorValue, ObjectEditorType> = {}
) => createPlateEditor<ObjectEditorValue, ObjectEditorType>(options);
export const createObjectEditorPluginFactory = <P = PluginOptions>(
  defaultPlugin: PlatePlugin<NoInfer<P>, ObjectEditorValue, ObjectEditorType>
) => createPluginFactory(defaultPlugin);
export const createObjectEditorPlugins = (
  plugins: ObjectEditorPlatePlugin[],
  options?: {
    components?: Record<string, PlatePluginComponent>;
    overrideByKey?: ObjectEditorOverrideByKey;
  }
) => createPlugins<ObjectEditorValue, ObjectEditorType>(plugins, options);

export type ObjectEditorAutoformatRule = AutoformatRule<ObjectEditorValue, ObjectEditorType>;
