import { AppActionTypes, TOGGLE_DRAWER, TOGGLE_RIGHT_DRAWER, TOGGLE_LOGIN_DIALOG, SEARCH_QUERY } from "@State/actions/appStateActions"

// App state is temporary state lost on reload
export type AppState = {
  drawerOpen: boolean;
  rightDrawerOpen: boolean;
  loginDialogOpen: boolean;
  searchingQuery: boolean;
  queryText: string;
}

const initialAppState: AppState = {
  drawerOpen: false,
  rightDrawerOpen: false,
  loginDialogOpen: false,
  searchingQuery: false,
  queryText: ''
}

export default function appReducer(
  state = initialAppState,
  action: AppActionTypes
): AppState {
  // console.log('appReducer', state, action)
  if (action.type === TOGGLE_DRAWER) {
    return {
      ...state,
      drawerOpen: (typeof action.state !== 'undefined') ? action.state : !state.drawerOpen,
      rightDrawerOpen: false
    }
  } else if (action.type === TOGGLE_RIGHT_DRAWER) {
    return {
      ...state,
      drawerOpen: false,
      rightDrawerOpen: (typeof action.state !== 'undefined') ? action.state : !state.rightDrawerOpen
    }
  } else if (action.type === TOGGLE_LOGIN_DIALOG) {
    return {
      ...state,
      loginDialogOpen: (typeof action.open !== 'undefined') ? action.open : !state.loginDialogOpen
    }
  } else if (action.type === SEARCH_QUERY) {
    return {
      ...state,
      searchingQuery: true,
      queryText: action.text
    }
  } else {
    return state
  }
}