import { useFocused, useSelected } from 'slate-react'

import Paper from '@mui/material/Paper'
import { Value, getHandler } from '@udecode/plate-core'
import { getRootProps } from '@udecode/plate-styled-components'
import {
  MentionInputElementProps,
  getMentionInputElementStyles,
} from '@udecode/plate-ui-mention'
import { CreateMentionNode, useElement } from '@udecode/plate'
import { TRIGGER_REF } from '@Components/shared/object-editor/ObjectEditor.types'
import { getMentionColorByTrigger } from '@Utils/plateHelpers'

import { Data, NoData } from '@udecode/plate-combobox'
import { TElement } from '@udecode/plate-core'

export interface TMentionElement extends TElement {
  value: string
}
export interface TMentionInputElement extends TElement {
  trigger: string
}
export interface MentionPlugin<TData extends Data = NoData> {
  createMentionNode?: CreateMentionNode<TData>
  id?: string
  insertSpaceAfterMention?: boolean
  trigger?: string
  inputCreation?: {
    key: string
    value: string
  }
}

export const MentionInputElement = <V extends Value>(
  props: MentionInputElementProps<V>
) => {
  const { attributes, children, nodeProps, as, onClick, element, editor } =
    props

  const rootProps = getRootProps(props)

  const selected = useSelected()
  const focused = useFocused()

  // TODO: Better way to get type (exclusive input-elems?)
  const mentionColor = getMentionColorByTrigger(props?.element)

  const styles = getMentionInputElementStyles({
    ...props,
    selected,
    focused,
  })

  return (
    <Paper
      sx={{
        display: 'inline-block',
        p: 0.2,
        px: 2,
        backgroundColor: mentionColor,
      }}
      variant="outlined"
      elevation={6}
      {...attributes}
      as={as}
      data-slate-value={element.value}
      className={styles.root.className}
      css={styles.root.css}
      onClick={getHandler(onClick, element)}
      {...rootProps}
      {...nodeProps}
    >
      {children}
    </Paper>
  )
}
