import React from 'react'

import Box from '@mui/material/Box'
import CardMedia from '@mui/material/CardMedia'
import MapConstrained from '@Components/map/MapConstrained'
import { GraphHeader } from '@Components/shared/graph/GraphHeader'
import { ExistingPostData } from '@Constants/post'
import { locationCoords } from '@Utils/geoFunc'
import { getCoverImage } from '@Utils/metaFunc'
import FlatTitle from '@Components/post/FlatTitle'

export const PostCardHeader: React.FC<{
  post: ExistingPostData
  height?: number
  showImage?: boolean
}> = ({ post, height, showImage }) => {
  const coverImage = getCoverImage(post)
  const faviconSrc = `https://www.google.com/s2/favicons?sz=${256}&domain=${
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    post.url
  }`
  const hasFavicon = !!post.url
  const imageUrl = coverImage ? coverImage : hasFavicon ? faviconSrc : ''
  const isTypeTerm = post?.type === 'term'
  const isTypeLocation = post?.type === 'location'
  const isTypeGraph = post?.type === 'graph'

  return (
    <>
      {isTypeLocation && (showImage || imageUrl) ? (
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            height,
            cursor: 'pointer',
          }}
        >
          <CardMedia
            component="img"
            height={height}
            image={imageUrl}
            alt={post.title}
            sx={{ width: '77%' }}
          />
          <MapConstrained
            coords={locationCoords(post)}
            defaultZoom={2}
            width="23%"
          />
        </Box>
      ) : isTypeLocation && !showImage ? (
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            height,
            cursor: 'pointer',
          }}
        >
          <MapConstrained coords={locationCoords(post)} defaultZoom={5} />
        </Box>
      ) : isTypeGraph && !showImage ? (
        <Box sx={{ width: '100%', height }}>
          <GraphHeader
            height={height}
            heightFactor={4}
            data={post.data}
            item={post}
          />
        </Box>
      ) : imageUrl || (imageUrl && showImage) ? (
        <CardMedia
          component="img"
          height={height}
          image={imageUrl}
          alt={post.title}
        />
      ) : isTypeTerm ? (
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            height,
            cursor: 'pointer',
          }}
        >
          <FlatTitle title={post.title} maxSize={height} />
        </Box>
      ) : null}
    </>
  )
}
