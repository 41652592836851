import firebase from 'firebase/compat/app'

import { firestore } from '@State/firebase'
import { DocumentData, DocumentSnapshot } from 'firebase/firestore'


// Unjudge

// const unjudgeByUid = ({ judgementsByUidRef, userPostIdRef, likeType, likeValue }: any) => {

//   userPostIdRef.update({
//     [likeType]: firebase.firestore.FieldValue.delete()
//   });

//   return uncountByUid({
//     judgementsByUidRef,
//     likeType,
//     likeValue
//   })
// }

// const uncountByUid = ({ judgementsByUidRef, likeType, likeValue }: any) => {
//   return firestore.runTransaction((transaction) => {
//     return transaction.get(judgementsByUidRef)
//       .then((userJudgeDoc: any) => {
//         const timestamp = firebase.firestore.FieldValue.serverTimestamp()
//         const countExists = userJudgeDoc.exists
//         const likeTypeCount = `${likeType}Count`

//         if (countExists) {
//           let newCount
//           newCount = userJudgeDoc.data()[likeTypeCount] - 1;

//           const emoteCountExists = likeType === 'emote' && userJudgeDoc.data()[`${likeValue}Count`]
//           let emoteCount
//           if (emoteCountExists) {
//             emoteCount = userJudgeDoc.data()[`${likeValue}Count`] - 1;
//           }

//           transaction.update(judgementsByUidRef, {
//             updated: timestamp,
//             [likeTypeCount]: newCount,
//             ...(likeType === 'emote' && {
//               [`${likeValue}Count`]: emoteCount
//             }),
//           })
//         } else {
//           // TODO?
//           // transaction.set(judgementsByUidRef, {
//           //   created: timestamp,
//           //   [likeTypeCount]: 1
//           // })
//         }
//       })
//   })
// }

export const uncountByPostId = ({ judgementsByPostIdRef, likeType, likeValue }: {
  judgementsByPostIdRef: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>,
  likeType: string
  likeValue: string
}) => {
  return firestore.runTransaction((transaction) => {
    return transaction.get(judgementsByPostIdRef)
      .then((postJudgeDoc) => {
        const timestamp = firebase.firestore.FieldValue.serverTimestamp()
        const countExists = postJudgeDoc.exists
        const docData = postJudgeDoc.data()
        const likeTypeCount = `${likeType}Count`
        if (countExists && docData) {
          const newCount = docData[likeTypeCount] - 1;
          const countProp = `${likeValue}Count`
          const emoteCountExists = likeType === 'emote' && docData[countProp]
          let emoteCount
          if (emoteCountExists) {
            emoteCount = docData[countProp] - 1;
          }

          transaction.update(judgementsByPostIdRef, {
            edited: timestamp,
            [likeTypeCount]: newCount,
            ...(likeType === 'emote' && {
              [countProp]: emoteCount
            }),
          })
        } else {
          // TODO?
          // transaction.set(judgementsByUidRef, {
          //   created: timestamp,
          //   [likeTypeCount]: 1
          // })
        }
      })
  })
}


export const unjudgeByPostId = ({ judgementsByPostIdRef, judgementsByUidRef, likeType, likeValue }: {
  judgementsByPostIdRef: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>
  judgementsByUidRef: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>
  likeType: string
  likeValue: string
}) => {

  judgementsByUidRef.delete().catch(function (error) {
    console.error("Error deleting judgement: ", error);
  })

  return uncountByPostId({
    judgementsByPostIdRef,
    likeType,
    likeValue
  })
}


// Judge

// const judgeByUid = ({ judgementsByUidRef, userPostIdRef, likeValue, likeType, type, uid, id }) => {
//   return firestore.runTransaction((transaction) => {
//     const timestamp = firebase.firestore.FieldValue.serverTimestamp()
//     return transaction.get(userPostIdRef)
//       .then((userPostIdDoc) => {
//         if (userPostIdDoc.exists) {
//           transaction.update(userPostIdRef, {
//             [likeType]: likeValue,
//             type,
//             updated: timestamp
//           })
//         } else {
//           transaction.set(userPostIdRef, {
//             [likeType]: likeValue,
//             type,
//             created: timestamp,
//             postId: id,
//             userId: uid
//           })
//         }
//       })
//       .then(() => {
//         return countByUid({
//           judgementsByUidRef,
//           likeType,
//           likeValue
//         })
//       })
//   })
// }

// const countByUid = ({ judgementsByUidRef, likeType, likeValue }) => {
//   const timestamp = firebase.firestore.FieldValue.serverTimestamp()
//   return firestore.runTransaction((transaction) => {
//     return transaction.get(judgementsByUidRef)
//       .then((userJudgeDoc: any) => {
//         const likeTypeCount = `${likeType}Count`
//         const countExists = userJudgeDoc.exists && userJudgeDoc.data()[likeTypeCount]
//         let newCount
//         if (countExists) {
//           newCount = userJudgeDoc.data()[likeTypeCount] + 1;
//         } else {
//           newCount = 1;
//         }

//         const emoteCountExists = likeType === 'emote' && userJudgeDoc.exists && userJudgeDoc.data()[`${likeValue}Count`]
//         let emoteCount
//         if (emoteCountExists) {
//           emoteCount = userJudgeDoc.data()[`${likeValue}Count`] + 1;
//         } else {
//           emoteCount = 1;
//         }

//         if (userJudgeDoc.exists) {
//           transaction.update(judgementsByUidRef, {
//             updated: timestamp,
//             [likeTypeCount]: newCount,
//             ...(likeType === 'emote' && {
//               [`${likeValue}Count`]: emoteCount
//             }),
//           })
//         } else {
//           transaction.set(judgementsByUidRef, {
//             created: timestamp,
//             [likeTypeCount]: newCount,
//             ...(likeType === 'emote' && {
//               [`${likeValue}Count`]: emoteCount
//             }),
//           })
//         }
//       })
//   })
// }




export const countByPostId = ({ judgementsByPostIdRef, likeType, likeValue }: {
  judgementsByPostIdRef: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>
  likeType: string
  likeValue: string
}) => {
  const timestamp = firebase.firestore.FieldValue.serverTimestamp()
  return firestore.runTransaction((transaction) => {
    return transaction.get(judgementsByPostIdRef)
      .then((postJudgeDoc) => {
        const likeTypeCount = `${likeType}Count`
        const docData = postJudgeDoc.data()
        const judgeDocExists = postJudgeDoc.exists
        let newCount
        if (judgeDocExists && docData) {
          newCount = docData[likeTypeCount] as number + 1;
        } else {
          newCount = 1;
        }

        const isEmote = likeType === 'emote'
        const emoteDocExists = isEmote && judgeDocExists
        let emoteCount
        if (emoteDocExists && docData) {
          const xCount = docData[`${likeValue}Count`]
          if (xCount) {
            emoteCount = xCount as number + 1;
          } else {
            emoteCount = 1
          }
        } else {
          emoteCount = 1
        }

        if (judgeDocExists) {
          transaction.update(judgementsByPostIdRef, {
            edited: timestamp,
            [likeTypeCount]: newCount,
            ...(likeType === 'emote' && {
              [`${likeValue}Count`]: emoteCount
            }),
          })
        } else {
          transaction.set(judgementsByPostIdRef, {
            created: timestamp,
            [likeTypeCount]: newCount,
            ...(likeType === 'emote' && {
              [`${likeValue}Count`]: emoteCount
            }),
          })
        }
      })
  })
}



export const judgeByPostId = ({ judgementsByPostIdRef, judgementsByUidRef, likeValue, likeType, type, uid, id }: {
  judgementsByPostIdRef: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>
  judgementsByUidRef: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>
  likeType: string
  likeValue: string
  type: string
  uid: string
  id: string
}) => {
  return firestore.runTransaction((transaction) => {
    const timestamp = firebase.firestore.FieldValue.serverTimestamp()
    return transaction.get(judgementsByUidRef)
      .then((likeTypeUidDoc) => {
        if (likeTypeUidDoc.exists) {
          transaction.update(judgementsByUidRef, {
            [likeType]: likeValue,
            type,
            edited: timestamp
          })
        } else {
          transaction.set(judgementsByUidRef, {
            postId: id,
            userId: uid,
            [likeType]: likeValue,
            type,
            created: timestamp
          })
        }
      })
      .then(() => {
        return countByPostId({
          judgementsByPostIdRef,
          likeType,
          likeValue
        })
      })
  })
}

