import { useContext } from 'react'
import { useReadOnly } from 'slate-react'

import {
  Box,
  CardContent,
  CardActions,
  CardHeader,
  SxProps,
  Typography,
} from '@mui/material'
import Badge from '@mui/material/Badge'
import Card from '@mui/material/Card'
import CardActionArea from '@mui/material/CardActionArea'
import Chip from '@mui/material/Chip'
import IconButton from '@mui/material/IconButton'
import PostBody from '@Components/post/PostBody'
import { PostCardHeader } from '@Components/post/PostCardHeader'
import { systemRoutes } from '@Constants/config/routeVars'
import { ExistingPostData } from '@Constants/post'
import { TypesOfReactChildren } from '@Utils/reactHelpers'
import DateString from '@Utils/DateString'

import { RouteContext } from '../containers/RouteProvider'
import TypeIcon from '../ui/TypeIcon'
import { WysiwygIcon } from '@Components/ui/WysiwygIcon'
import Stack from '@mui/material/Stack'

interface ListCardProps {
  post: ExistingPostData
  readOnly?: boolean
  imageOnly?: boolean
  imageHeight?: number
  showImage?: boolean
  sx?: SxProps
  children?: TypesOfReactChildren
}

export default function PostCard({
  post,
  // readOnly = true,
  imageOnly = false,
  imageHeight = 160,
  showImage,
  sx,
  children,
}: ListCardProps) {
  const {
    goUri,
    goId,
    goEditId,
    goItemOrPeek,
    getHref,
    goRef,
    goPostView,
    goViewNamespace,
  } = useContext(RouteContext)

  const readOnly = useReadOnly()

  const isTypeTerm = post?.type === 'term'
  const isTypeText = post?.type === 'text'
  const isTypeMedia = post?.type === 'media'
  const isTypeLocation = post?.type === 'location'

  const highHeader = isTypeMedia || isTypeLocation || isTypeTerm

  const goPost = (e: React.MouseEvent) => {
    e.preventDefault()
    e.stopPropagation()
    if (e.ctrlKey) {
      goPostView(post, true)
    } else {
      goPostView(post)
    }
  }
  const goNamespace = (e) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    e.preventDefault()
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    e.stopPropagation()
    if (post.namespace) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      if (e.ctrlKey) {
        goViewNamespace(post.namespace, true)
      } else {
        goViewNamespace(post.namespace)
      }
      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      if (e.key === 'o') {
        goViewNamespace(post.namespace, true)
      }
    }
  }

  const cardHeader = (
    <PostCardHeader
      post={post}
      // height={highHeader ? imageHeight * 2 : imageHeight}
      height={imageHeight}
      showImage={showImage}
    />
  )

  const titleComponent = (
    <Typography
      variant="subtitle2"
      sx={{
        display: 'block',
        whiteSpace: 'break-spaces',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
      }}
    >
      {post.title}
    </Typography>
  )

  const subheaderComponent = (
    <Typography variant="caption" color="text.secondary">
      {/* <Author uid={post.userId} variant="card" /> */}
      <span>{DateString(post, false, true)}</span>
    </Typography>
  )

  const actionComponents = (
    <Box sx={{ pt: 1, pr: 1.4 }}>
      {post.postCount ? (
        <Badge badgeContent={post.postCount} color="secondary">
          <IconButton aria-label="type">
            <TypeIcon type={post.type} mini />
          </IconButton>
        </Badge>
      ) : (
        <TypeIcon type={post.type} mini />
      )}
    </Box>
  )

  const cardBody = (
    <>
      {cardHeader}

      {isTypeText ? null : (
        <CardHeader
          title={titleComponent}
          subheader={subheaderComponent}
          action={actionComponents}
        />
      )}

      {post?.text ? (
        <CardContent
          sx={{
            py: 0,
            pt: isTypeText ? 3 : 0,
            pb: isTypeText ? 2 : 0,
          }}
        >
          <PostBody
            id={post.id}
            text={post?.text?.slice(0, 380) || ''}
            limit={360}
            mini
            sx={{ py: 0 }}
          />
        </CardContent>
      ) : null}
    </>
  )

  const cardActions = (
    <CardActions>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        {post.namespace && (
          <Chip
            label={post.namespace}
            variant="namespace"
            sx={{
              maxWidth: '75%',
            }}
            onClick={goNamespace}
            onKeyUp={goNamespace}
          />
        )}

        {children}

        {post.object ? <WysiwygIcon post={post} view /> : null}
      </Stack>
    </CardActions>
  )

  const hideCardActions = readOnly || !post?.namespace

  return imageOnly ? (
    cardHeader
  ) : (
    <Card sx={sx}>
      {readOnly ? (
        <>
          <CardActionArea
            href={getHref(systemRoutes.view, post)}
            onClick={goPost}
            sx={{ pb: 1 }}
          >
            {isTypeTerm ? cardHeader : cardBody}
          </CardActionArea>
          {hideCardActions ? null : cardActions}
        </>
      ) : (
        <>
          {cardBody}
          {cardActions}
        </>
      )}
    </Card>
  )
}
