import { ReactChildren } from 'react'

import CardActions from '@mui/material/CardActions'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import Icon from '@mui/material/Icon'
import Stack from '@mui/material/Stack'
import { ExtensionIcon } from '@Components/ui/ExtensionIcon'
import {
  ImgButton,
  LinkButton,
  RefButton,
  RefIdButton,
  SourceButton,
  UriButton,
  VideoButton,
} from '@Components/ui/LinkButtons'
import { useBreakpoints } from '@Constants/config/globalStyles'
import { UserRoles } from '@Constants/config/roleVars'
import { ExistingPostData } from '@Constants/post'
import { getEmbedVideo } from '@Utils/metaFunc'
import urlFunc from '@Utils/urlFunc'

export default function PostButtons({
  item,
  mini,
  role,
  isSubPost,
  toggleRightDrawerAction, // eslint-disable-line @typescript-eslint/no-shadow
  children,
}: {
  item: ExistingPostData
  mini?: boolean
  isSubPost?: boolean
  role?: UserRoles
  toggleRightDrawerAction?: (open: boolean) => void // eslint-disable-line @typescript-eslint/no-shadow
  children?: ReactChildren
}) {
  const url = item?.url && urlFunc(item.url)
  const notSubPostOrMini = !isSubPost && !mini
  const { matchesMedium, matchesMobile } = useBreakpoints()
  const metaMedia = item?.url ? getEmbedVideo(item.url) : null
  const hasMetaMediaAndUrl = item.url && metaMedia
  const shouldButtonsBeMini = matchesMedium || isSubPost || mini

  if (item.imgUrl || hasMetaMediaAndUrl || item.ref || children) {
    return (
      <CardActions
        // disableSpacing
        sx={{
          display: 'flex',
          // scrollSnapAlign: 'center'
          justifyContent: 'center',
          alignItems: 'center',
          p: 0, // override
        }}
      >
        <Grid container spacing={1} sx={{}}>
          <Grid
            item
            md={8}
            sm={children ? 12 : 12}
            xs={12}
            sx={{
              // overflow: 'hidden',
              scrollX: 'none',
              display: 'flex',
              justifyContent: {
                lg: 'flex-start',
                md: 'flex-start',
                sm: 'start',
                xs: 'start',
              },
              flexDirection: {
                lg: 'row',
                md: 'row',
                sm: 'column',
                xs: 'column',
              },
              alignItems: {
                lg: 'center',
                md: 'center',
                sm: 'start',
                xs: 'start',
              },
            }}
          >
            <Stack
              justifyContent="start"
              alignItems="start"
              direction={matchesMobile ? 'column' : 'row'}
              spacing={1}
              sx={{
                pb: 1,
                // flexWrap: 'wrap'
              }}
            >
              {item.imgUrl ? (
                <ImgButton imgUrl={item.imgUrl} mini={shouldButtonsBeMini} />
              ) : null}

              {item.url && metaMedia && metaMedia.type === 'video' ? (
                <VideoButton url={item.url} mini={shouldButtonsBeMini} />
              ) : null}
              {item.url && url && url.href ? ( // && (metaMedia && metaMedia.type !== 'video' )) ?
                <LinkButton
                  url={item.url}
                  href={url.href}
                  isSource={item.isSource}
                  mini={shouldButtonsBeMini}
                />
              ) : null}

              {item.uriRef ? (
                <UriButton
                  url={item.url}
                  uriRef={item.uriRef}
                  mini={shouldButtonsBeMini}
                />
              ) : null}

              {item.refId ? (
                <RefIdButton refId={item.refId} mini={shouldButtonsBeMini} />
              ) : null}

              {item.ref ? (
                <RefButton refNamespace={item.ref} mini={shouldButtonsBeMini} />
              ) : null}

              {(item.sourceType || item.sourceId) && (
                <SourceButton
                  sourceType={item.sourceType}
                  sourceId={item.sourceId}
                  mini={shouldButtonsBeMini}
                />
              )}
            </Stack>
          </Grid>

          {matchesMedium && children ? (
            <Divider style={{ width: '100%', marginBottom: '12px' }} />
          ) : null}

          <Grid
            item
            md={4}
            sm={children ? 12 : 12}
            xs={12}
            sx={{
              display: 'flex',
              justifyContent: {
                md: 'flex-end',
                xs: 'start',
              },
              flexDirection: {
                md: 'row',
                xs: 'column',
              },
              alignItems: {
                lg: 'start',
                xs: 'start',
              },
              p: 1,
            }}
          >
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={matchesMedium ? 1 : 2}
            >
              {item.longlat || item.geoHash ? <Icon>place</Icon> : null}
              {notSubPostOrMini ? (
                <ExtensionIcon post={item} role={role} />
              ) : null}

              {/* {notSubPostOrMini ? (
                <Tooltip title={`Judgements about this ${item.type}`}>
                  <Button
                    onClick={() => {
                      if (toggleRightDrawerAction) toggleRightDrawerAction(true)
                    }}
                    variant="contained"
                    size={matchesMedium ? 'small' : 'large'}
                    color="secondary"
                    startIcon={<Icon>question_answer</Icon>}
                    // endIcon={<Icon>view_sidebar</Icon>}
                  >
                    Judgements
                  </Button>
                </Tooltip>
              ) : null} */}
            </Stack>
          </Grid>

          {/* { (item.ref || item.url || item.imgUrl) ? <Divider className={classes.divider} /> : null} */}

          {children ? (
            <Grid item md={12} sm={12} xs={12}>
              {children}
            </Grid>
          ) : null}
        </Grid>
      </CardActions>
    )
  } else {
    return null
  }
}
