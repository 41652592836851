import { isEmpty, isLoaded } from 'react-redux-firebase'

import { SxProps } from '@mui/material'
import Skeleton from '@mui/material/Skeleton'
import { usePostIdWithSubposts, useSubPostId } from '@Utils/usePost'
import PostCard from '@Components/post/PostCard'

export default function SubPostByIds({
  parentId,
  subPostId,
  sx,
  delay = 111,
}: {
  parentId: string
  subPostId: string
  sx?: SxProps
  delay?: number
}) {
  const [subPost, loading] = useSubPostId(parentId, subPostId)
  // const notExists = !loading && isEmpty(subPost) && isLoaded(subPost)

  if (!loading && subPost) {
    return <PostCard post={subPost} sx={sx} />
  } else if (loading && !subPost) {
    return (
      <Skeleton variant="rectangular" width="100%">
        <div style={{ paddingTop: '50%' }} />
      </Skeleton>
    )
  } else {
    return <>Not found: {parentId}</>
  }
}
