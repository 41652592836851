import { useContext } from 'react'

import { SxProps } from '@mui/material'
import Typography from '@mui/material/Typography'
import { RouteContext } from '@Components/containers/RouteProvider'

export default function PostCat({
  cat,
  mini = false, // TODO: Implement
  view = false,
  edit = false,
  sx,
}: {
  cat: string
  mini?: boolean
  view?: boolean
  edit?: boolean
  sx?: SxProps
}) {
  const clickable = view && !edit

  const { goCat } = useContext(RouteContext)

  return (
    <Typography
      sx={{
        cursor: clickable ? 'pointer' : '',
        ...sx,
      }}
      variant="overline"
      component="h6"
      gutterBottom
      onClick={() => (clickable ? goCat(cat) : undefined)}
    >
      {cat}
    </Typography>
  )
}
