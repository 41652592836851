import clsx from 'clsx'
import React, { useContext, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'

import useScrollTrigger from '@mui/material/useScrollTrigger'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import CardActionArea from '@mui/material/CardActionArea'
import Divider from '@mui/material/Divider'
import Hidden from '@mui/material/Hidden'
import Icon from '@mui/material/Icon'
import IconButton from '@mui/material/IconButton'
import Slide from '@mui/material/Slide'
import Toolbar from '@mui/material/Toolbar'
import Tooltip from '@mui/material/Tooltip'
import makeStyles from '@mui/styles/makeStyles'
import SearchPopper from '@Components/containers/search/SearchPopper'
import SearchPosts from '@Components/containers/search/SearchPosts'
import { DRAWER_WIDTH } from '@Components/containers/ui/Drawer'
import { RouteContext } from '@Components/containers/RouteProvider'
import ProfileMenu from '@Components/profile/ProfileMenu'
import { brandVars } from '@Constants/config/brand'
import { useBreakpoints } from '@Constants/config/globalStyles'
import { roleVars } from '@Constants/config/roleVars'
import routeVars, {
  appRoutes,
  defaultRoutes,
} from '@Constants/config/routeVars'
import { Types } from '@Constants/config/types'
import { PrivateRouteShow, RenderRoute } from '@Routes/Routers'
import { toggleDrawerAction } from '@State/actions/appStateActions'
import { changeThemeAction } from '@State/actions/storageActions'
import {
  AppProfile,
  FirebaseProfile,
  StorageState,
} from '@State/reducers/storageReducer'
import { AppDispatch, FirebaseAuthState, RootState } from '@State/store'

import { TopBarFilter } from './TopBarFilter'

const useStyles = makeStyles((theme) => ({
  topBarRoot: {
    // flexGrow: 1,
    left: 0, // Fix weird mobile behaviour
    maxWidth: '100vw',
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  topBarRootShift: {
    width: `calc(100% - ${DRAWER_WIDTH}px)`,
    marginLeft: DRAWER_WIDTH,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  grow: {
    flexGrow: 2,
    [theme.breakpoints.down('sm')]: {
      flexGrow: 0,
    },
  },
  growMore: {
    flexGrow: 5,
    [theme.breakpoints.down('sm')]: {
      flexGrow: 0,
    },
  },
  header: {
    transition: 'all 0.35s',
  },
  logoArea: {
    maxHeight: 42,
    maxWidth: 150,
    marginTop: 6,
    marginBottom: 6,
    marginLeft: 4,
    marginRight: 16,
    // cursor: 'pointer',
    // [theme.breakpoints.down('lg')]: {
    //   maxWidth: 120,
    //   maxHeight: 34,
    //   marginLeft: 0
    // },
    [theme.breakpoints.down('lg')]: {
      maxWidth: 30,
      maxHeight: 30,
      marginLeft: 0,
      marginRight: 16,
    },
  },
  logo: {
    display: 'block',
    maxWidth: 150,
    maxHeight: 42,
    // marginRight: 16,
    // paddingBottom: 6,
    // cursor: 'pointer',
    // [theme.breakpoints.down('lg')]: {
    //   maxWidth: 120,
    //   maxHeight: 34,
    // },
    [theme.breakpoints.down('lg')]: {
      display: 'none',
    },
    [theme.breakpoints.down('md')]: {
      display: 'none',
      maxWidth: 30,
      maxHeight: 30,
    },
  },
  logoSmall: {
    display: 'none',
    [theme.breakpoints.down('lg')]: {
      display: 'block',
      maxHeight: 30,
      // maxWidth: 100,
    },
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      maxWidth: 24,
      maxHeight: 30,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  forwardButton: {
    marginTop: theme.spacing(-1.1),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
    // [theme.breakpoints.up('md')]: {
    //   display: 'none',
    // },
  },
  title: {
    flexGrow: 1,
    cursor: 'pointer',
  },
  buttonsRight: {
    marginRight: 8,
  },

  userIcon: {},
}))

interface HideOnScrollProps {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window
  children: React.ReactElement
}

function HideOnScroll(props: HideOnScrollProps) {
  const { children, window } = props
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger()

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  )
}

interface StateProps {
  storage: StorageState
  auth?: FirebaseAuthState
  profile: FirebaseProfile
  typeSelected: Types
  catSelected: string
  drawerOpen: boolean
}

interface DispatchProps {
  changeTheme: (theme?: string) => void
  toggleDrawer: (state: boolean) => void
}

interface OwnProps {}

type TopBarProps = StateProps & DispatchProps & OwnProps

function TopBar({
  storage,
  auth,
  profile,

  changeTheme,
  toggleDrawer,

  // typeSelected,
  // catSelected,
  drawerOpen,
}: TopBarProps) {
  const classes = useStyles()

  const location = useLocation()
  const routerHistory = useHistory()
  const [locationKeys, setLocationKeys] = useState([])
  const forwardNavAvailable = locationKeys.length >= 2

  const { theme } = storage
  const { role } = profile as AppProfile

  const darkTheme = theme === 'dark'

  const { matchesMobile, matchesMedium } = useBreakpoints()

  const { goHome, goPosts, goView, getHref, catQuery, typeQuery } =
    useContext(RouteContext)
  const showSearchBar = roleVars.topBar.searchBar.includes(role)
  const canGoToPosts = roleVars.actions.listPosts.includes(role)

  // useEffect(() => {
  //   const loggedIn$ = authState(fbAuth) //.pipe(filter(user => !!user));
  //   loggedIn$.subscribe(user => {
  //     // console.log('Topbar user:', user);
  //     // setCurrentUser(user)
  //   });
  // }, []);

  useEffect(() => {
    return routerHistory.listen((loc) => {
      if (routerHistory.action === 'PUSH') {
        // @ts-expect-error
        setLocationKeys([loc.key])
      }

      if (routerHistory.action === 'POP') {
        if (locationKeys[1] === loc.key) {
          setLocationKeys(([_, ...keys]) => keys)

          // Handle forward event
        } else {
          // @ts-expect-error
          setLocationKeys((keys) => [loc.key, ...keys])

          // Handle back event
        }
      }
    })
  }, [locationKeys])

  function clickHome(e) {
    // console.log(location)
    const path = location.pathname
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    if (e.ctrlKey) {
      return
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      e.preventDefault()
      if (path === '/' && canGoToPosts) {
        goPosts(true)
      } else if (path === appRoutes.posts) {
        goHome()
      } else {
        goHome()
      }
    }
  }

  function goBack() {
    routerHistory.goBack()
  }

  function goForward() {
    console.log('goForward')
    // FIXME: Avoid resetting fwd history
    history.forward()
  }

  function goFocus() {
    routerHistory.push('/test')
  }

  const handleThemeChange = () => {
    // setDarkState(!darkState);
    const newTheme = theme === 'dark' ? 'light' : 'dark'
    changeTheme(newTheme)
  }

  const logoUrl =
    theme === 'dark' || theme === 'default'
      ? '/main_logo_light.png'
      : theme === 'light'
      ? '/main_logo_dark.png'
      : '/main_logo_dark.png' // Just in case

  const logoSmall =
    theme === 'dark' || theme === 'default'
      ? '/main_logo_alt_light.png'
      : theme === 'light'
      ? '/main_logo_alt_dark.png'
      : '/main_logo_alt_dark.png' // Just in case

  return (
    // <HideOnScroll>
    <AppBar
      className={clsx(classes.topBarRoot, {
        [classes.topBarRootShift]: drawerOpen && !matchesMedium,
      })}
    >
      <Toolbar className={classes.header}>
        <RenderRoute exact path={routeVars.menu.hamburger}>
          <Tooltip title={drawerOpen ? 'Close Drawer' : 'Open Drawer'}>
            <IconButton
              onClick={(e) => {
                console.warn(`🚀 ~ e`, e)

                toggleDrawer(!drawerOpen)
              }}
              edge="start"
              className={classes.menuButton}
              aria-label="menu"
              size="large"
            >
              {drawerOpen ? <Icon>vertical_split</Icon> : <Icon>menu</Icon>}
            </IconButton>
          </Tooltip>
        </RenderRoute>

        <RenderRoute exact path={routeVars.menu.back}>
          <IconButton
            onClick={goBack}
            edge="start"
            className={classes.menuButton}
            aria-label="menu"
            size="large"
          >
            <Icon>chevron_left</Icon>
          </IconButton>
        </RenderRoute>

        {/* <Typography variant="h6" className={classes.title} noWrap onClick={clickHome}>
          Title
        </Typography> */}

        {/* <Hidden xsDown> */}
        <CardActionArea
          className={classes.logoArea}
          onClick={clickHome}
          href={getHref(defaultRoutes.home)}
        >
          <img
            src={logoUrl}
            className={classes.logo}
            alt={brandVars.brandName}
          />
          {forwardNavAvailable ? (
            <IconButton
              onClick={goForward}
              edge="start"
              className={classes.forwardButton}
              aria-label="menu"
              size="large"
            >
              <Icon>chevron_right</Icon>
            </IconButton>
          ) : (
            <img
              src={logoSmall}
              className={classes.logoSmall}
              alt={brandVars.brandName}
            />
          )}
        </CardActionArea>
        {/* </Hidden> */}

        <div className={classes.grow} />

        {showSearchBar ? (
          matchesMobile ? (
            <SearchPopper
              autoHighlight
              onChoose={(postItem) => {
                goView(postItem)
              }}
            />
          ) : (
            <SearchPosts autoHighlight />
          )
        ) : null}

        <div className={classes.grow} />

        <PrivateRouteShow exact path={routeVars.topBar.test}>
          <Hidden smDown>
            <Tooltip
              title={`Just gimme the light`}
              className={classes.buttonsRight}
            >
              <IconButton
                aria-label="switch-theme"
                onClick={goFocus}
                size="large"
                sx={{ ml: 1 }}
              >
                <Icon>flare</Icon>
              </IconButton>
            </Tooltip>
          </Hidden>
        </PrivateRouteShow>

        <div className={classes.grow} />

        <RenderRoute exact path={routeVars.topBar.clearFilters}>
          <Divider
            orientation="vertical"
            flexItem
            sx={{ mx: catQuery || typeQuery ? 2 : 1 }}
          />
        </RenderRoute>

        <Box sx={{ marginLeft: 'auto' }}>
          <TopBarFilter />
        </Box>

        {catQuery || typeQuery ? (
          <Divider
            orientation="vertical"
            flexItem
            sx={{
              marginLeft: matchesMobile ? 1 : 2,
              marginRight: matchesMobile ? 1 : 2,
            }}
          />
        ) : null}
        {/* <div className={classes.grow} /> */}

        <RenderRoute exact path={routeVars.topBar.darkMode}>
          <Tooltip
            title={`${darkTheme ? 'Light' : 'Dark'} mode`}
            className={classes.buttonsRight}
          >
            <IconButton
              aria-label="switch-theme"
              onClick={handleThemeChange}
              size="large"
            >
              <Icon>{darkTheme ? 'brightness_high' : 'dark_mode'}</Icon>
            </IconButton>
          </Tooltip>
        </RenderRoute>

        {/* <div className={classes.growMore} /> */}

        <Box sx={{ marginLeft: 'auto' }}>
          <ProfileMenu
            auth={auth}
            role={role}
            changeTheme={changeTheme}
            themeSelected={theme}
          />
        </Box>
      </Toolbar>
    </AppBar>
    // </HideOnScroll>
  )
}

const mapStateToProps = (state: RootState /*, ownProps*/) => {
  return {
    storage: state.storage,
    auth: state.firebase?.auth,
    profile: state.firebase.profile,
    typeSelected: state.storage?.typeSelected,
    catSelected: state.storage?.catSelected,
    drawerOpen: state.appState.drawerOpen,
  }
}

const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    changeTheme: (theme?: string) => dispatch(changeThemeAction(theme)),
    toggleDrawer: (state: boolean) => dispatch(toggleDrawerAction(state)),
  }
}

export default connect<StateProps, DispatchProps, OwnProps, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(TopBar)
