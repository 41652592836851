import { ExistingPostData, PostData } from '@Constants/post'
import { GeoPoint } from 'firebase/firestore'

// TODO: Get live location after request
export const defaultLongLat: LongLatArray = [4.86806847239767, 52.36713439249573]
export const defaultLatLong: LatLongArray = [52.36713439249573, 4.86806847239767]// [...defaultLongLat].reverse()

export const defaultZoomLevel = 6
export const defaultRadius = 50 // In meters
export const maxZoomLevel = 20

export type LongLatArray = [number, number] // Lon, lat
export type LatLongArray = [number, number] // Lat, long

interface PostGeo {
  // Location
  longlat?: number[];
  geoPoint?: GeoPoint;
  geoHash?: string
}

export interface LocationPostData extends PostGeo {
  entityRef?: string[]
}

export interface LocationPost extends PostData, LocationPostData {
  type: 'location'
}