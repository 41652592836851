import React, { useContext } from 'react'
import { connect } from 'react-redux'

import { Button, Icon, SxProps, Tooltip } from '@mui/material'
import PublishItem from '@Components/containers/post/PublishItem'
import { RouteContext } from '@Components/containers/RouteProvider'
import SystemModal from '@Components/shared/modal/SystemModal'
import { useBreakpoints } from '@Constants/config/globalStyles'
import { AnyExistingPostData } from '@Constants/post'
import { sendNotifcationAction } from '@State/actions/appStateActions'
import { AppDispatch, RootState } from '@State/store'

interface StateProps {
  auth: RootState['firebase']['auth']
  loading: boolean
}

interface DispatchProps {
  notification: (text: string, severity: string) => void
}

interface OwnProps {
  post: AnyExistingPostData
  enabled: boolean
  afterPublish?: (
    post: AnyExistingPostData,
    publish?: boolean,
    newNamespace?: string
  ) => void
  sx?: SxProps
}

type Props = StateProps & DispatchProps & OwnProps

export const PublishButton: React.FC<Props> = ({
  auth,
  post,
  enabled,
  afterPublish,
  sx,
}) => {
  const { uid } = auth
  const { matchesMedium } = useBreakpoints()
  const published = post?.visibility === 'public'
  const publishText = published ? 'Unpublish' : 'Publish'

  const [publishOpen, setPublishOpen] = React.useState(false)

  function openPublishDialog() {
    setPublishOpen(true)
  }

  function handleClose(
    cancel: boolean,
    publish?: boolean,
    newNamespace?: string
  ) {
    if (!cancel && afterPublish) {
      console.log(`🚀 ~ afterPublish`, post, publish)
      afterPublish(post, publish, newNamespace)
    }
    setPublishOpen(false)
  }

  return (
    <>
      <Tooltip
        title={
          !enabled && published
            ? 'A post once judged, cannot be unpublished'
            : 'Make item public or private'
        }
      >
        <Button
          aria-label={publishText}
          size={matchesMedium ? 'medium' : 'large'}
          color={published ? 'warning' : 'public'}
          variant={published ? 'outlined' : 'contained'}
          onClick={(e) => openPublishDialog()}
          disabled={!enabled}
          startIcon={<Icon>{published ? 'highlight_off' : 'publish'}</Icon>}
          sx={sx}
        >
          {publishText}
        </Button>
      </Tooltip>
      <SystemModal
        title={publishText}
        open={publishOpen}
        handleClose={() => setPublishOpen(false)}
      >
        <PublishItem
          uid={uid}
          id={post.id}
          published={published}
          item={post}
          handleClose={handleClose}
        />
      </SystemModal>
    </>
  )
}

const mapStateToProps = (state: RootState /*, ownProps*/) => {
  return {
    auth: state.firebase?.auth,
    loading: state.posts?.loading,
    subTypeSelected: state.storage?.subTypeSelected,
  }
}

const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    notification: (text: string, severity: string) =>
      dispatch(sendNotifcationAction(text, severity)),
  }
}

export default connect<StateProps, DispatchProps, OwnProps, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(PublishButton)
