import React from 'react'
import { HTMLPropsAs } from '@udecode/plate-core'
import { useMediaStore } from '@Components/shared/object-editor/plugins/MediaElements'

export const MediaEmbed = (props: HTMLPropsAs<'iframe'>) => {
  const { component: Component, ...embedData } = useMediaStore().get.urlData()

  if (!Component) return null

  return <Component {...embedData} {...props} />
}
