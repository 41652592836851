import { firestore } from '@State/firebase'
import firebase from 'firebase/compat/app'
import { ofType } from 'redux-observable'
import { mapTo, mergeMap } from 'rxjs/operators'

import { sendNotifcationAction } from '@State/actions/appStateActions'
import { epicRoutes } from '@Constants/config/routeVars'
import { EditUserNameAction, EDIT_USERNAME, GrantRoleAction, GRANT_ROLE, SendApplicationAction, SEND_APPLICATION, ToggleAvatarAction, TOGGLE_AVATAR, UngrantRoleAction, UNGRANT_ROLE } from '@State/actions/profileActions'
import { Observable } from 'rxjs'
import { RootState, StoreDependencies } from '@State/store'


export const editUsernameEpic = (action$: Observable<EditUserNameAction>, state$: RootState, { store }: StoreDependencies) => action$.pipe(
  ofType(EDIT_USERNAME),
  mergeMap(({ uid, username, description, namespace }) => {
    return firestore
      .collection('users')
      .doc(uid)
      .set({
        username,
        ...(description && { description }),
        ...(namespace && { namespace })
      }, { merge: true })
      .then(() => {
        console.log("Document edited", username);
        store.dispatch(sendNotifcationAction('Profile edited', 'success'))
      })
      .catch(function (error) {
        console.error("Error editing document: ", error);
      })
  }),
  mapTo({ type: 'USERNAME_EDITED' })
)

export const toggleAvatarEpic = (action$: Observable<ToggleAvatarAction>, state$: RootState, { store }: StoreDependencies) => action$.pipe(
  ofType(TOGGLE_AVATAR),
  mergeMap(({ uid, avatar }) => {
    return firestore
      .collection('users')
      .doc(uid)
      .set({
        avatar
      }, { merge: true })
      .then(() => {
        console.log("Avatar edited", avatar);
      })
      .catch(function (error) {
        console.error("Error editing document: ", error);
      })
  }),
  mapTo({ type: 'AVATAR_TOGGLED' })
)


export const applicationEpic = (action$: Observable<SendApplicationAction>, state$: RootState, { store, history }: StoreDependencies) => action$.pipe(
  ofType(SEND_APPLICATION),
  mergeMap(({
    uid,
    data,
  }) => {
    const timestamp = firebase.firestore.FieldValue.serverTimestamp()
    return firestore
      .collection('applications')
      .doc(uid)
      .set({
        ...data,
        uid,
        created: timestamp,
      })
      .then(() => {
        console.log("Application written with ID: ", uid);
        // @ts-ignore
        if (history?.push) {
          /* eslint @typescript-eslint/no-unsafe-call: 0 */
          history.push(epicRoutes.afterApplication())
        }
      })
  }),
  mapTo({ type: 'APPLICATION_SENT' })
)



export const grantRoleEpic = (action$: Observable<GrantRoleAction>, state$: RootState, { store }: StoreDependencies) => action$.pipe(
  ofType(GRANT_ROLE),
  mergeMap(({
    uid,
    role
  }) => {
    return firestore
      .collection('roles')
      .doc(uid)
      .set({
        role,
      })
      .then(() => {
        console.log("Role written for: ", uid);
        return firestore
          .collection('users')
          .doc(uid)
          .set({
            role
          },
            { merge: true }
          ).then(() => {
            return firestore
              .collection('applications')
              .doc(uid)
              .set({
                granted: true
              },
                { merge: true }
              ).then(() => {
                store.dispatch(sendNotifcationAction(`Role granted`, 'success'))
              })
          })
      })
  }),
  mapTo({ type: 'ROLE_GRANTED' })
)


export const ungrantRoleEpic = (action$: Observable<UngrantRoleAction>, state$: RootState, { store }: StoreDependencies) => action$.pipe(
  ofType(UNGRANT_ROLE),
  mergeMap(({
    uid,
    role
  }) => {
    return firestore
      .collection('roles')
      .doc(uid)
      .delete()
      .then(() => {
        console.log("Role unwritten for: ", uid);
        return firestore
          .collection('users')
          .doc(uid)
          .update({
            role: firebase.firestore.FieldValue.delete()
          }).then(() => {
            return firestore
              .collection('applications')
              .doc(uid)
              .set({
                granted: false
              },
                { merge: true }
              ).then(() => {
                store.dispatch(sendNotifcationAction(`Role ungranted`, 'success'))
              })
          })
      })
  }),
  mapTo({ type: 'ROLE_UNGRANTED' })
)

