import { useReadOnly, useSelected } from 'slate-react'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { useElement, useResizableStore } from '@udecode/plate'
import {
  HTMLPropsAs,
  createComponentAs,
  createElementAs,
} from '@udecode/plate-core'
import { TImageElement } from '@Components/shared/object-editor/plugins/media/imagePlugin'

import { useCaptionString } from '../hooks/useCaptionString'
import { CaptionTextarea } from './CaptionTextarea'

export interface CaptionProps extends HTMLPropsAs<'figcaption'> {
  readOnly?: boolean
}

export const useCaption = ({
  readOnly,
  ...props
}: CaptionProps = {}): HTMLPropsAs<'figcaption'> => {
  const width = useResizableStore().get.width()

  return {
    style: { width },
    ...props,
  }
}

export const useCaptionState = (props: CaptionProps) => {
  const captionString = useCaptionString()
  const selected = useSelected()
  const _readOnly = useReadOnly()
  const readOnly = props.readOnly || _readOnly

  return {
    captionString,
    selected,
    readOnly,
  }
}

export const CaptionRoot = createComponentAs<CaptionProps>((props) => {
  const htmlProps = useCaption(props)
  const { captionString, selected, readOnly } = useCaptionState(props)
  const captionEmpty = !captionString.length
  const elem = useElement<TImageElement>()
  const { align } = elem

  if (captionEmpty && (readOnly || !selected)) {
    return null
  }

  if (!captionEmpty && !selected) {
    return (
      <Box
        ref={htmlProps.ref}
        className="slate-ImageElement-figcaption"
        sx={{
          marginLeft: 'auto',
          marginRight: 'auto',
          display: 'flex',
          justifyContent: !align
            ? 'start'
            : align === 'right'
            ? 'end'
            : 'center',
        }}
      >
        <Typography
          variant="caption"
          className="slate-ImageElement-caption"
          // Copied from TextareaAutosize readOnly render
          style={{
            width: '50%',
            borderStyle: 'none',
            marginTop: '0.5rem',
            padding: '0px',
            resize: 'none',
            textAlign: 'center',
          }}
        >
          {captionString}
        </Typography>
      </Box>
    )
  }

  return createElementAs('figcaption', htmlProps)
})

export const Caption = {
  Root: CaptionRoot,
  Textarea: CaptionTextarea,
}
