import { ChangeEventHandler, useCallback, useEffect } from 'react'

import Input from '@mui/material/Input'
import {
  HTMLPropsAs,
  createComponentAs,
  createElementAs,
  focusEditor,
  mergeProps,
  useEditorRef,
  useElement,
  useHotkeys,
} from '@udecode/plate-core'

import { TMediaElement } from '../types'
import {
  floatingMediaActions,
  floatingMediaSelectors,
} from './floatingMediaStore'
import { submitFloatingMedia } from './submitFloatingMedia'

export type FloatingMediaUrlInputProps = HTMLPropsAs<'input'> & {
  pluginKey?: string
}

export const useFloatingMediaUrlInput = ({
  pluginKey,
  ...props
}: FloatingMediaUrlInputProps): HTMLPropsAs<'input'> => {
  const editor = useEditorRef()
  const element = useElement<TMediaElement>()

  useEffect(() => {
    return () => {
      floatingMediaActions.isEditing(false)
    }
  }, [])

  useHotkeys(
    'enter',
    (e) => {
      if (submitFloatingMedia(editor, { element, pluginKey })) {
        e.preventDefault()
      }
    },
    {
      enableOnTags: ['INPUT'],
    },
    []
  )

  useHotkeys(
    'escape',
    () => {
      if (floatingMediaSelectors.isEditing() && editor.selection) {
        floatingMediaActions.reset()
        focusEditor(editor, editor.selection)
      }
    },
    {
      enableOnTags: ['INPUT'],
      enableOnContentEditable: true,
    },
    []
  )

  const onChange: ChangeEventHandler<HTMLInputElement> = useCallback((e) => {
    floatingMediaActions.url(e.target.value)
  }, [])

  return mergeProps(
    {
      onChange,
      autoFocus: true,
      defaultValue: floatingMediaSelectors.url(),
    },
    {
      ...props,
      style: {
        ...props.style,
        margin: 8,
      },
    }
  )
}

export const FloatingMediaUrlInput =
  createComponentAs<FloatingMediaUrlInputProps>((props) => {
    const htmlProps = useFloatingMediaUrlInput(props)

    return createElementAs(Input, htmlProps)
  })
