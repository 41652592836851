import React from 'react'
import { Field } from 'react-final-form'

import { AutocompleteChangeReason } from '@mui/material/useAutocomplete'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import { usePostNamespaceSearch } from '@Utils/usePost'
import { Namespace } from '@Constants/types/namespace'

interface OwnProps {
  fieldName?: string
  label?: string
  defaultValue?: string
  autoFocus?: boolean
  required?: boolean
  onChange?: (option) => void
}

export default function SearchPostNamespaceField({
  fieldName = 'namespace',
  label = 'Namespace',
  defaultValue = '',
  autoFocus,
  required,
  onChange,
}: OwnProps) {
  const [query, setQuery] = React.useState(defaultValue)
  const [results, setSearchResults, loading] = usePostNamespaceSearch(query)

  return (
    <Field name={fieldName}>
      {(form) => {
        return (
          <Autocomplete<Namespace>
            label={label}
            required={required}
            loading={loading}
            options={results}
            sx={{ color: 'inherit', width: '100%' }}
            autoFocus={autoFocus}
            autoHighlight
            clearOnBlur
            blurOnSelect
            clearOnEscape
            disablePortal
            // @ts-ignore
            defaultValue={{
              namespace: form.input?.value || defaultValue,
            }}
            isOptionEqualToValue={(selectedItem, item): boolean => {
              return selectedItem.namespace === item.namespace
            }}
            getOptionLabel={(option: Namespace) => {
              return `${option.namespace}` // ${option.id ? `(${option.id})` : ''}`
            }}
            renderOption={(props, option, { selected }) => (
              <li {...props} key={option.id}>{`${option.namespace}`}</li>
            )}
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  // autoFocus={autoFocus} FIXME: This crashes things
                  required={required}
                  label={label}
                  variant="filled"
                />
              )
            }}
            onInputChange={(e, searchQuery, reason) => {
              if (reason === 'input') {
                setQuery(searchQuery)
                setSearchResults([])
                form.input.onChange(searchQuery)
              }
              console.log('onInputChange reason: ', reason, searchQuery)
            }}
            onChange={(
              e,
              option: Namespace | null,
              reason: AutocompleteChangeReason
            ) => {
              if (reason === 'clear') {
                setQuery('')
                form.input.onChange('')
                if (onChange) onChange('')
              } else if (
                reason === 'selectOption' &&
                option &&
                option.namespace
              ) {
                form.input.onChange(option.namespace)
                if (onChange) onChange(option.namespace)
              }
              console.log('onChange reason: ', reason, option)
            }}
            ListboxProps={{
              style: {
                maxHeight: '90vh',
                maxWidth: '90vw',
              },
            }}
          />
        )
      }}
    </Field>
  )
}
