import { ELEMENT_PARAGRAPH } from '@udecode/plate-paragraph'
import { ELEMENT_IMAGE } from '@Components/shared/object-editor/plugins/media/imagePlugin/createImagePlugin'
import { ELEMENT_MEDIA_EMBED } from '@Components/shared/object-editor/plugins/media/media-embed/createMediaEmbedPlugin'
import {
  ELEMENT_ADD,
  ELEMENT_CODE_BLOCK,
  ELEMENT_CODE_LINE,
  ELEMENT_LINK,
  ELEMENT_PATH,
  ELEMENT_TAG,
  ELEMENT_TEXT,
  ELEMENT_TITLE,
  ObjectEditorValue
} from '@Components/shared/object-editor/ObjectEditor.types'
import { mockTextLong } from '@Utils/test/mockText'


export const testObjectEditorValues: ObjectEditorValue = [
  {
    "type": ELEMENT_TITLE,
    "children": [
      {
        "text": "Test Title"
      }
    ],
  },
  {
    "type": ELEMENT_TEXT,
    "children": [
      {
        "text": "A "
      },
      {
        "type": ELEMENT_LINK,
        "url": "https://plate.udecode.io/docs/examples/editable-voids",
        "target": "_self",
        "children": [
          {
            "text": "Link "
          }
        ],

      },
      {
        "text": "that goes. "
      }
      ,
      {
        "text": "And a new paragraph with "
      },
      {
        "type": ELEMENT_TAG,
        "children": [
          {
            "text": ""
          }
        ],
        "value": "Tags ",
        "url": "https://www.youtube.com/c/AltShiftX/videos",
      },
      {
        "text": ""
      },
    ]
  },
  {
    "type": ELEMENT_MEDIA_EMBED,
    "url": "https://twitter.com/elonmusk/status/1594191387519373313",
    "relativeWidth": 50,
    "children": [
      {
        "text": ""
      }
    ]
  },
  {
    "type": ELEMENT_PARAGRAPH,
    "children": [
      {
        "text": "And "
      },
      {
        "type": ELEMENT_ADD,
        "children": [
          {
            "text": ""
          }
        ],
        "value": "Terms",
      },
      {
        "type": ELEMENT_PATH,
        "children": [
          {
            "text": ""
          }
        ],
        "value": "Paths",
      },
      {
        "text": " and "
      },
      {
        "type": ELEMENT_PATH,
        "children": [
          {
            "text": ""
          }
        ],
        "value": "Refs",
      },
      {
        "text": ", so that's "
      },
      {
        "text": "cool",
        "italic": true,
        "bold": true
      },
      {
        "text": "."
      }
    ],
  },
  {
    "type": "blockquote",
    "children": [
      {
        "text": "A wise man once said"
      }
    ]
  },
  {
    "type": ELEMENT_PARAGRAPH,
    "children": [
      {
        "text": ""
      }
    ]
  },
  {
    "type": "img",
    "url": "https://img.youtube.com/vi/mwg9IK-OKuc/0.jpg",
    "relativeWidth": 50,
    "children": [
      {
        "text": ""
      }
    ],
  },
  {
    "type": ELEMENT_PARAGRAPH,
    "children": [
      {
        "text": ""
      }
    ]
  },
  {
    "type": ELEMENT_PARAGRAPH,
    "children": [
      {
        "text": "Some text with a "
      },
      {
        "type": "a",
        "url": "https://plate.udecode.io/docs/examples/editable-voids",
        "target": "_self",
        "children": [
          {
            "text": "link"
          }
        ],

      },
      {
        "text": ". "
      }
    ],
  }
]

export const testMediaEditorValues: ObjectEditorValue = [
  {
    "type": ELEMENT_TITLE,
    "children": [
      {
        "text": "Media"
      }
    ],
  },
  {
    "type": ELEMENT_TEXT,
    "children": [
      {
        "text": "Mandatory Text"
      },
    ],
  },
  {
    "type": ELEMENT_PARAGRAPH,
    "children": [
      {
        "text": mockTextLong
      },
    ],
  },
  {
    "type": ELEMENT_PARAGRAPH,
    "children": [
      {
        "text": ""
      },
    ],
  },
  {
    "type": ELEMENT_IMAGE,
    "url": "https://img.youtube.com/vi/mwg9IK-OKuc/0.jpg",
    "relativeWidth": 40,
    "align": 'right',
    "children": [
      {
        "text": ""
      }
    ]
  },
  {
    "type": ELEMENT_PARAGRAPH,
    "children": [
      {
        "text": ""
      }
    ]
  },
  {
    "type": ELEMENT_MEDIA_EMBED,
    "url": "https://www.youtube.com/watch?v=5vHRUsP20dQ",
    "relativeWidth": 30,
    "children": [
      {
        "text": ""
      }
    ]
  },
  {
    "type": ELEMENT_PARAGRAPH,
    "children": [
      {
        "text": ""
      }
    ]
  },
  {
    "type": ELEMENT_CODE_BLOCK,
    "children": [
      {
        "type": "code_line",
        "children": [
          {
            "text": "if (this) {"
          }
        ],
      },
      {
        "type": ELEMENT_CODE_LINE,
        "children": [
          {
            "text": "  then(that)"
          }
        ],
      },
      {
        "type": ELEMENT_CODE_LINE,
        "children": [
          {
            "text": "}"
          }
        ],
      }
    ],

  },
  {
    "type": ELEMENT_PARAGRAPH,
    "children": [
      {
        "text": ""
      },
    ],
  },
  {
    "type": ELEMENT_MEDIA_EMBED,
    "url": "https://twitter.com/elonmusk/status/1595630109116989440",
    "relativeWidth": 50,
    "children": [
      {
        "text": ""
      }
    ]
  },

  {
    "type": ELEMENT_PARAGRAPH,
    "children": [
      {
        "text": ""
      }
    ]
  }
]
