export const epicRoutes = {
  afterCreate: (postId: string) => `/edit/id/${postId}`,
  afterRevert: (postId: string) => `/edit/id/${postId}`,
  // afterLinkPost: (postId: string) => `/edit/id/${postId}`,
  afterApplication: () => `/thanks?reason=application`,
  // afterPublishNamespace: (namespace: string) => `/view/${namespace}`,
  // afterPublishNoNamespace: (id: string) => `/edit/id/${id}`,
  // afterPublishViewId: (id: string) => `/id/${id}`,
}

export const rootRoute = '/'

export const appRoutes = {
  new: '/new',
  create: '/create',
  profile: '/profile',

  posts: '/posts',
  list: '/posts-list',
  locations: '/locations',

  name: '/@',
}

export const defaultRoutes = {
  home: "/", // WARNING: If you change this, use a trailing /, e.g. "/home/"
  about: "/about",
  contact: "/contact",

  drafts: `${appRoutes.profile}/drafts`
}

const listPages = [
  appRoutes.posts,
  appRoutes.list,
  "/users",
  "/popular", "/tags",
  "/urls", "/uris",
  "/cats", "/types",
  "/namespaces",
  "/tree", "/tree/:id"
]

const typePages = [
  // TODO: ...(Object.keys(typeData).map(type => `/${type.plural ? type.plural : type.val}`)),
  "/terms", "/texts", "/lists",
  "/timelines", "/objects", "/views",
  "/people", "/entities", "/locations",

  "/filter"
]

// Non-configurable routes
export const systemRoutes = {
  view: ["/id/:id", "/view/:id"], // Namespace needs to be second for getHref to work
  viewId: "/id/:id",
  viewNamespace: "/view/:id",

  editId: "/edit/id/:id",
  editNamespace: "/edit/:id",

  postNamespace: "/post/:namespace",
  postId: "/post/id/:id",
  viewPostId: "/view/post/id/:id",

  viewObject: '/r/:namespace',
  viewObjectSub: '/r/:namespace/f/:subNamespace'
}

export const postIdOrNamespace = [
  systemRoutes.postNamespace,
  systemRoutes.postId,
]

const editPages = [
  systemRoutes.editId,
  systemRoutes.editNamespace
]

export const objectPages = [
  systemRoutes.viewObject,
  systemRoutes.viewObjectSub
]


const systemPages = [
  "/terms", "/thanks",
]

const browsePages = [
  ...listPages,
  ...typePages,
  ...systemPages,
  "/test",
]

const postPages = [
  ...editPages,
  "/id/:id",
  "/id/:id/id/:subId",

  "/view/:id",

  ...postIdOrNamespace
]

const crudPages = [
  appRoutes.new,
  appRoutes.create,
  ...editPages
]

const filterPages = [
  appRoutes.profile, "/user/:id",

  "/tags",
  appRoutes.posts,

  // appRoutes.new,
  appRoutes.create,

  "/test",
  ...postPages,
  ...listPages
]

// Define what elements are shown on which routes
const routeVars = {
  hideAppNav: [
    ...objectPages
  ],
  menu: {
    hamburger: [
      rootRoute,
      appRoutes.profile,
      "/user/:id",
      "/login",
      "/apply",
      "/thanks",

      ...browsePages,

      ...postIdOrNamespace,

      "/peek/:id", "/peek/id/:id/",
      "/view/:id", "/id/:id",
      "/id/:id/id/:subId",
    ],
    back: [
      "/uri/:id",
      ...crudPages
    ]
  },
  topBar: {
    clearFilters: [
      ...filterPages,
      ...typePages,

      appRoutes.new,
      appRoutes.create,
    ],
    catSelector: [
      ...filterPages,
      ...typePages
    ],
    typeSelector: [
      ...filterPages,
      ...typePages
    ],
    darkMode: [
      rootRoute,
      // ...postPages
    ],
    test: [
      (
        process.env.REACT_APP_ENV !== 'production' ?
          "*"
          : ""
      )
    ]
  },
  fab: {
    add: [
      rootRoute,
      "/lists",
      appRoutes.posts,
      appRoutes.profile,
      "/filter",
      "/uri/:id"
    ],
    view: [
      systemRoutes.editId,
    ],
    visibilityNamespace: [
      systemRoutes.editNamespace
    ],
    edit: [
      systemRoutes.viewPostId,
      ...systemRoutes.view,
      systemRoutes.postId,
      // "/private/:id",
    ],
    editNamespace: [
      "/view/:id"
    ]
  }
}
export default routeVars