import { TweetProps } from '@udecode/plate'
import { TwitterEmbed } from '@Components/shared/embed/TwitterEmbed'
import { EmbedUrlData } from '@Components/shared/object-editor/plugins/MediaElements'

export const useMediaEmbedTweet = ({
  id,
  ...props
}: EmbedUrlData): TweetProps => {
  return {
    // @ts-ignore
    tweetId: id,
    ...props,
  }
}

export const MediaEmbedTweet = (props: EmbedUrlData) => {
  const htmlProps = useMediaEmbedTweet(props)
  // console.log(`🚀 ~ MediaEmbedTweet ~ htmlProps`, htmlProps)

  return <TwitterEmbed {...htmlProps} />
}
