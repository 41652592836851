import { Tooltip, IconButton, Icon } from '@mui/material'
import React, { useContext } from 'react'
import { RouteContext } from '@Components/containers/RouteProvider'
import { ExistingPostData } from '@Constants/post'

export const WysiwygIcon: React.FC<{
  post: ExistingPostData
  view?: boolean
}> = ({ post, view }) => {
  const { goPost, goPostView } = useContext(RouteContext)
  const hasObject = !!post.object
  return (
    <Tooltip title={view ? 'View Object' : 'Click to edit item'}>
      <IconButton
        aria-label="edit"
        onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()
          window.scrollTo(0, 0)
          if (view) {
            goPostView(post)
          } else {
            goPost(post)
          }
        }}
        size="large"
        color={hasObject ? 'default' : 'public'}
      >
        <Icon>wysiwyg</Icon>
      </IconButton>
    </Tooltip>
  )
}
