import { SxProps } from '@mui/material/styles'
import Icon from '@mui/material/Icon'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import { appVars } from '@Constants/config/app'
import {
  Types,
  basicTypeData,
  subTypeData,
  typeData,
} from '@Constants/config/types'
import { TypeData } from '@Constants/types/TypeData'
import { iconFontSize } from '@Styles/styleVars'
import { capitalizeFirstLetter } from '@Utils/textFunc'
import { shortTypeString } from '@Utils/typeFunc'

export default function TypeIcon({
  type,
  mini,
  tiny,
  color = 'inherit',
  edit,

  goType,
  showTooltip,
  toolTipText,
  sx,
}: {
  type: Types
  mini?: boolean
  tiny?: boolean
  color?:
    | 'inherit'
    | 'action'
    | 'disabled'
    | 'primary'
    | 'secondary'
    | 'error'
    | 'info'
    | 'success'
    | 'warning'
  edit?: boolean
  showTooltip?: boolean
  toolTipText?: string
  goType?: (type: Types, e) => void
  sx?: SxProps
}) {
  function getTypeName(allTheTypes: TypeData[]) {
    return type && allTheTypes && allTheTypes.find((t) => t.val === type)
  }

  const allTypes: TypeData[] = [...subTypeData, ...basicTypeData, ...typeData]
  const typeName = getTypeName(allTypes) || {
    icon: 'apps',
    val: appVars.things,
  }
  const typeIcon = typeName && typeName.icon

  const defaultToolTipTxt = showTooltip
    ? typeName
      ? `Type: '${typeName.val}'`
      : `Click to select type`
    : `${capitalizeFirstLetter(shortTypeString(type, true))}`

  const toolTipTxt = toolTipText ? toolTipText : defaultToolTipTxt

  const fontSize = tiny ? 'tiny' : mini ? 'mini' : 'large'

  const icon = (
    <Icon
      style={{
        fontSize: iconFontSize(fontSize),
      }}
      color={edit ? 'disabled' : color ? color : 'action'}
      sx={sx}
    >
      {typeIcon}
    </Icon>
  )

  if (goType) {
    return (
      <IconButton
        onClick={
          !edit
            ? (e) => {
                e.stopPropagation()
                goType(type, e)
              }
            : undefined
        }
        disabled={edit}
        sx={sx}
      >
        <Tooltip title={toolTipTxt}>{icon}</Tooltip>
      </IconButton>
    )
  } else if (showTooltip || toolTipTxt) {
    return (
      <Tooltip title={toolTipTxt} placement="bottom-end">
        {icon}
      </Tooltip>
    )
  } else {
    return icon
  }
}
