import {
  Value,
  findNodePath,
  getPluginOptions,
  setNodes,
} from '@udecode/plate-core'
import {
  StyledElementProps,
  getRootProps,
} from '@udecode/plate-styled-components'
import {
  CodeBlockPlugin,
  TCodeBlockElement,
} from '@Components/shared/object-editor/plugins/codeblock/codeBlockPlugin'
import { ELEMENT_CODE_BLOCK } from '@Components/shared/object-editor/ObjectEditor.types'

import { getCodeBlockElementStyles } from './CodeBlockElement.styles'
import { CodeBlockSelectElement } from './CodeBlockSelectElement'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'

export const CodeBlockElement = <V extends Value>(
  props: StyledElementProps<V, TCodeBlockElement>
) => {
  const { attributes, children, nodeProps, element, editor } = props

  const rootProps = getRootProps(props)

  const { lang } = element

  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  const { root } = getCodeBlockElementStyles(props as any)
  const { syntax } = getPluginOptions<CodeBlockPlugin, V>(
    editor,
    ELEMENT_CODE_BLOCK
  )
  const codeClassName = lang ? `${lang} language-${lang}` : ''

  return (
    <Box
      component="pre"
      {...attributes}
      // css={root.css}
      className={root.className}
      style={{
        fontSize: 14,
        tabSize: 2,
        lineHeight: 'normal',
      }}
      {...rootProps}
      {...nodeProps}
    >
      <Paper
        variant="outlined"
        sx={{
          p: 2,
          minHeight: 72, // Make sure dropdown fits
        }}
      >
        {syntax && (
          <CodeBlockSelectElement
            data-testid="CodeBlockSelectElement"
            lang={lang}
            onChange={(val: string) => {
              const path = findNodePath(editor, element)
              if (path) {
                setNodes<TCodeBlockElement>(editor, { lang: val }, { at: path })
              }
            }}
          />
        )}
        <code className={codeClassName}>{children}</code>
      </Paper>
    </Box>
  )
}
