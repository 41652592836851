import Favicon from '@Components/ui/Favicon'
import { styled } from '@mui/material/styles'
import Backdrop from '@mui/material/Backdrop'
import Button from '@mui/material/Button'
import Fade from '@mui/material/Fade'
import Icon from '@mui/material/Icon'
import Modal from '@mui/material/Modal'
import makeStyles from '@mui/styles/makeStyles'
import React from 'react'

import VideoEmbed from '@Components/shared/embed/VideoEmbed'
import { Box } from '@mui/system'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    flexDirection: 'column',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    // marginTop: 32,
    [theme.breakpoints.down('md')]: {},
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '90vh',
    maxWidth: '90vw',
    minWidth: '90vw',
    // minHeight: '90vh',
    overflowY: 'auto',
    overflowX: 'auto',
    cursor: 'pointer',
    backgroundColor: theme.palette.background.paper,
    [theme.breakpoints.up('lg')]: {
      maxWidth: '80vw',
      minWidth: '70vw',
    },
    [theme.breakpoints.up('xl')]: {
      maxWidth: '60vw',
      minWidth: '50vw',
    },
  },
  source: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1.5),
    paddingRight: theme.spacing(1.3),
  },
}))

export default function VideoModal({
  url,
  open = false,
  handleClose,
  toggleFloatingWindow,
  setFloatingWindowUrl,
}: {
  url: string
  open: boolean
  handleClose: (e) => void
  toggleFloatingWindow?: (open?: boolean) => void
  setFloatingWindowUrl?: (postId: string) => void
}) {
  const classes = useStyles()

  function clickButton() {
    window.open(url, '_blank')
  }

  function clickFloat(e) {
    if (toggleFloatingWindow && setFloatingWindowUrl) {
      toggleFloatingWindow(true)
      setFloatingWindowUrl(url)
      handleClose(e)
    }
  }

  return (
    <Box className={classes.root}>
      <Modal
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        // BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <VideoEmbed url={url} />
            <div className={classes.source}>
              <Button
                onClick={clickButton}
                startIcon={<Favicon url={url} />}
                endIcon={<Icon>open_in_new</Icon>}
                size="small"
                variant="outlined"
                sx={{ ml: 2 }}
              >
                Video Link
              </Button>
              {/* <Button
                onClick={clickFloat}
                // startIcon={<Favicon url={url} />}
                endIcon={<Icon>branding_watermark</Icon>}
                size="small"
                variant="contained"
                color="primary"
                sx={{ ml: 2 }}
              >
                Float
              </Button> */}
            </div>
          </div>
        </Fade>
      </Modal>
    </Box>
  )
}
