import { objectToPost } from '@Utils/objectFunc';
import firebase from 'firebase/compat/app'
import { ofType } from 'redux-observable'
import { debounceTime, mapTo, mergeMap } from 'rxjs/operators'

import { epicRoutes } from '@Constants/config/routeVars'
import { sendNotifcationAction } from '@State/actions/appStateActions'
import { updateNamespaceAction } from '@State/actions/namespaceActions'
import {
  ADD_TO_POST,
  CREATE_POST,
  DELETE_POST,
  DELETE_SUB_POST,
  EDIT_POST,
  EDIT_SUB_POST,
  LINK_POST,
  POSTS_SEARCHED,
  POST_ADDED_TO,
  POST_CREATED,
  POST_DELETED,
  POST_EDITED,
  POST_LINKED,
  POST_PUBLISHED,
  POST_REVERTED,
  PUBLISH_POST,
  REVERT_POST,
  SEARCH_POSTS,
  SUB_POST_DELETED,
  SUB_POST_EDITED,
  SUB_POST_UPGRADED,
  UPGRADE_SUB_POST,
  postsSearchedResultsAction,
  CreatePostAction,
  AddToPostAction,
  EditPostAction,
  EditSubPostAction,
  UpgradeSubPostAction,
  RevertPostAction,
  LinkPostAction,
  DeletePostAction,
  DeleteSubPostAction,
  PublishPostAction,
  SearchPostsAction
} from '@State/actions/postActions'
import { publishTagsAction, unpublishTagsAction } from '@State/actions/tagActions'
import { deleteUriAction, saveUriAction } from '@State/actions/uriActions'
import { firestore } from '@State/firebase'
import { Observable } from 'rxjs'
import { RootState, StoreDependencies } from '@State/store';


// Create

export const createPostEpic = (action$: Observable<CreatePostAction>, state$: RootState, { store, history }: StoreDependencies) => action$.pipe(
  ofType(CREATE_POST),
  mergeMap(async ({
    uid,
    data,
    form
  }) => {
    const timestamp = firebase.firestore.FieldValue.serverTimestamp()
    return firestore
      .collection('postsById')
      .add({
        ...data,
        created: timestamp,
        edited: timestamp,
        visibility: 'private',
        userId: uid
      })
      .then((postRef) => {
        console.log("New post created with ID: ", postRef.id);
        store.dispatch(sendNotifcationAction(`Created`, 'success'))
        if (form) { form.reset() }
        history.push(epicRoutes.afterCreate(postRef.id))
      })
  }),
  mapTo({ type: POST_CREATED })
)

// Create a sub post
export const addToPostEpic = (action$: Observable<AddToPostAction>, state$: RootState, { store, history }: StoreDependencies) => action$.pipe(
  ofType(ADD_TO_POST),
  mergeMap(async ({
    id,
    uid,
    data,
    form
  }) => {
    const timestamp = firebase.firestore.FieldValue.serverTimestamp()
    // TODO: Write created when not exists
    return firestore
      .collection('postsById')
      .doc(id)
      .collection('subPostsById')
      .add({
        ...data,
        added: timestamp,
        edited: timestamp,
        userId: uid,
        parentId: id,
      })
      .then((subPostRef) => {
        // TODO: Add ref to namespace if exists
        console.log("Document added to:", subPostRef.id);
        store.dispatch(sendNotifcationAction(`Added`, 'success'))
        if (form) { form.reset() }
        return firestore.collection('postsById')
          .doc(id)
          .update({
            postCount: firebase.firestore.FieldValue.increment(+1),
            subPostAdded: timestamp,
          })
      })
  }),
  mapTo({ type: POST_ADDED_TO })
)



// Edit

export const editPostEpic = (action$: Observable<EditPostAction>, state$: RootState, { store, history }: StoreDependencies) => action$.pipe(
  ofType(EDIT_POST),
  mergeMap(async ({
    id,
    data,
    form
  }) => {
    console.log(`🚀 ~ data`, data)
    const timestamp = firebase.firestore.FieldValue.serverTimestamp()
    const postRef = firestore
      .collection('postsById')
      .doc(id)

    return postRef.get().then((doc) => {
      const docData = doc.data()
      console.log("🚀 ~ existing docData", docData)
      // TODO: Don't save `id` field with edit
      // TODO: if ref is added, backlink ref
      const postPropsFromObject = data.object ? objectToPost(data.object) : null
      return postRef
        .set({
          ...data,
          // Make sure edited object title & text is saved
          ...(data.object && postPropsFromObject && { ...postPropsFromObject }),
          edited: timestamp
        }, { merge: true })
        .then(() => {
          console.log("Document edited: ", id);
          store.dispatch(sendNotifcationAction(`Edited`, 'success'))
          if (form) { form.reset() }
        })
    })

  }),
  mapTo({ type: POST_EDITED })
)

export const editSubPostEpic = (action$: Observable<EditSubPostAction>, state$: RootState, { store, history }: StoreDependencies) => action$.pipe(
  ofType(EDIT_SUB_POST),
  mergeMap(async ({
    parentId,
    // TODO: Rename to subPostId
    postId,
    data,
    type
  }) => {
    const timestamp = firebase.firestore.FieldValue.serverTimestamp()
    return firestore
      .collection('postsById')
      .doc(parentId)
      .collection('subPostsById')
      .doc(postId)
      .set({
        ...data,
        edited: timestamp,
      }, { merge: true })
      .then((subPostRef) => {
        // TODO: Add ref to namespace if exists
        console.log("Document edited:", postId);
        store.dispatch(sendNotifcationAction(`Edited`, 'success'))
        return firestore.collection('postsById')
          .doc(parentId)
          .update({
            subPostEdited: timestamp,
          })
      })
  }),
  mapTo({ type: SUB_POST_EDITED })
)


// Subpost mutations

// Move existing subPost data a new post, and reference that new post with a refId on existing subPost
export const upgradeSubPostEpic = (action$: Observable<UpgradeSubPostAction>, state$: RootState, { store, history }: StoreDependencies) => action$.pipe(
  ofType(UPGRADE_SUB_POST),
  mergeMap(async ({
    parentId,
    subPostId,
    item
  }) => {
    if (item.refId) {
      throw new Error(`Impossible to upgrade an already reffing subpost`)
    }
    const timestamp = firebase.firestore.FieldValue.serverTimestamp()
    return firestore
      .collection('postsById')
      .add({
        ...item,
        created: timestamp,
        visibility: 'private',
        parentId, // id of original parent post
        subPostId, // id of original subPost, so we can backref and revert later
      })
      .then((postRef) => {
        return firestore
          .collection('postsById')
          .doc(postRef.id)
          .set({
            id: postRef.id // OVERWRITE ORIGINAL ID!
          },
            { merge: true })
          .then((no) => {
            firestore
              .collection('postsById')
              .doc(parentId)
              .collection('subPostsById')
              .doc(subPostId)
              .set({
                // We override original title because it won't sync with the linked post title?
                // title: 'Connection',
                edited: timestamp,
                type: 'connection', // Set the type to connection so we have basically same behaviour like LINK_POST (the original type will be overwritten on revert)
                refId: postRef.id // When this prop has a value, Posts should render the reffed post
              }, { merge: true }).catch(() => {
                console.error('UPGRADE FAILED')
              })
            return postRef
          })
      })
      .then((postRef) => {
        console.log("Sub post upgraded with ID: ", postRef.id);
        store.dispatch(sendNotifcationAction(`Upgraded`, 'success'))
        history.push(epicRoutes.afterCreate(postRef.id))
        // DODO: back-LINK_POST
        return firestore.collection('postsById')
          .doc(parentId)
          .update({
            subPostEdited: timestamp,
          })
      })
  }),
  mapTo({ type: SUB_POST_UPGRADED })
)

// Move back a linked post to a subpost ("downgrade")
// Moves back the data verbatim to the orignal subPostId with the refId,
// Should not work with posts that were never upgraded
export const revertPostEpic = (action$: Observable<RevertPostAction>, state$: RootState, { store, history }: StoreDependencies) => action$.pipe(
  ofType(REVERT_POST),
  mergeMap(async ({
    postId, // Target
    parentId, // Original parent post
    subPostId, // Original subpost
    item
  }) => {
    // Failsafe
    if (!item.subPostId || !item.parentId) {
      throw new Error('Not possible to revert a post that has never been a subpost (was never upgraded)')
    }
    // TODO: Check if post has any subPosts with refIds before allowing revert/delete. But probably also determine this before invoking 'REVERT_POST' altogether
    // if (item.subPostIds || item.parentIds) {
    //   throw new Error('Not possible to revert a post that has been linked by other posts already')
    // }
    // Check if any subPosts or refs
    if (item?.linkCount && item?.linkCount > 0 || item?.postCount || item?.uriRef) {
      throw new Error('Post has subPosts or links')
    }
    const timestamp = firebase.firestore.FieldValue.serverTimestamp()
    return firestore
      .collection('postsById')
      .doc(parentId)
      .collection('subPostsById')
      .doc(subPostId)
      // No merge, overwriting old subpost! (which should be empty since upgrading)
      // So no updating postCount
      .set({
        ...item,
        id: subPostId, // OVERWRITE previous new postId, set to original subPostId again
        // Because we copy the post, it contains excess propetties we need to remove
        // TODO: Remove subPostId field entirely
        // We keep the original parentId
        subPostId: '',
        edited: timestamp,
        // TODO: Only add the `added` prop when it didn't exist yet (which will be the case when you first LINK_POSTed something, then reverted it back)
        added: timestamp,
        reverted: timestamp,
      })
      .then((postRef) => {
        history.push(epicRoutes.afterRevert(parentId))
        return firestore
          .collection('postsById')
          .doc(postId) // Upgraded post
          .delete()
      })
      .then((postRef) => {
        console.log("Post reverted", postRef);
        store.dispatch(sendNotifcationAction(`Reverted`, 'success'))

      })
  }),
  mapTo({ type: POST_REVERTED })
)


// Links an existing post id to another post by creating an empty subpost (basically only a title & type) with a refId to the existing post id
// So both posts should have a mutual connection with both having a post type 'connection' that references eachother
export const linkPostEpic = (action$: Observable<LinkPostAction>, state$: RootState, { store, history }: StoreDependencies) => action$.pipe(
  ofType(LINK_POST),
  mergeMap(async ({
    postId, // The postId that is being linked
    parentId, // The postId to add a new link/ref to; Add a new subPost with a refId to the original target postId
    item, // original post being linked
    uid,
    title
  }) => {
    // For now, don't allow adding a link (subPost with refId) to an upgraded post, to avoid
    if (item.subPostId || item.parentId) {
      throw new Error('Not possible to add a link to a post that was upgraded (yet)')
    }
    const timestamp = firebase.firestore.FieldValue.serverTimestamp()
    return firestore
      .collection('postsById')
      .doc(parentId)
      .collection('subPostsById')
      .add({
        // Don't add item data, because linked post will contain all item data. And avoid duplication and wrong data like dates
        added: timestamp,
        refId: postId,
        type: 'connection',
        cat: 'relation',
        title: item.title,
        userId: uid
      })
      .then((subPostRef) => {
        console.log("🚀  subPostRef", subPostRef)

        // Add subpost to target postId
        return firestore
          .collection('postsById')
          .doc(postId)
          .collection('subPostsById')
          .add({
            added: timestamp,
            refId: parentId,
            type: 'connection',
            cat: 'relation',
            title,
            userId: uid
          })
          .then(() => {
            // Update postCount stuff
            return firestore.collection('postsById')
              .doc(parentId)
              .update({
                postCount: firebase.firestore.FieldValue.increment(+1),
                subPostAdded: timestamp,
                linkCount: firebase.firestore.FieldValue.increment(+1),
                linkAdded: timestamp,
              }).then(() => {
                // Update postCount stuff
                return firestore.collection('postsById')
                  .doc(postId)
                  .update({
                    subPostAdded: timestamp,
                    postCount: firebase.firestore.FieldValue.increment(+1),
                    linkCount: firebase.firestore.FieldValue.increment(+1),
                    linkAdded: timestamp,
                  })
              })
          })
      })
      .then((postRef) => {
        console.log(`Post: ${postId} linked to:`, parentId);
        // dependencies.history.push(epicRoutes.afterLinkPost(parentId))
        store.dispatch(sendNotifcationAction(`Reverted`, 'success'))

      })
  }),
  mapTo({ type: POST_LINKED })
)



// Delete

export const deletePostEpic = (action$: Observable<DeletePostAction>, state$: RootState, { store, history }: StoreDependencies) => action$.pipe(
  ofType(DELETE_POST),
  mergeMap(async ({ postId }) => {
    const postRef = firestore
      .collection('postsById')
      .doc(postId)

    return postRef.delete().then(() => {
      console.log("Document deleted with ID: ", postId);
      // TODO: delete judgements? Or move for analysis?

      store.dispatch(sendNotifcationAction(`Deleted`, 'success'))

      // TODO: edit field subPostEdited? or deleted? or nothing?
    })

  }),
  mapTo({ type: POST_DELETED })
)

export const deleteSubPostEpic = (action$: Observable<DeleteSubPostAction>, state$: RootState, { store, history }: StoreDependencies) => action$.pipe(
  ofType(DELETE_SUB_POST),
  mergeMap(async ({
    postType,
    parentId,
    postId
  }) => {
    return firestore
      .collection('postsById')
      .doc(parentId)
      .collection('subPostsById')
      .doc(postId)
      .delete()
      .then(() => {
        console.log("Document deleted with ID: ", postId);
        store.dispatch(sendNotifcationAction(`Deleted`, 'success'))
        // TODO: Delete refs? Unless refs are namespaces
        // TODO: Count down linkCount when cat = 'relation'
        return firestore
          .collection('postsById')
          .doc(parentId)
          .update({
            postCount: firebase.firestore.FieldValue.increment(-1)
          })
      })

  }),
  mapTo({ type: SUB_POST_DELETED })
)




// Publish

export const publishPostEpic = (action$: Observable<PublishPostAction>, state$: RootState, { store, history }: StoreDependencies) => action$.pipe(
  ofType(PUBLISH_POST),
  mergeMap(async ({
    id,
    uid,
    namespace,
    data,
    handleClose
  }) => {
    // TODO: Abort when no uid
    const timestamp = firebase.firestore.FieldValue.serverTimestamp()
    const notPublic = (data.visibility !== 'public')
    return firestore
      .collection('postsById')
      .doc(id)
      .set({
        visibility: notPublic ? "public" : "private",
        tagsPublished: notPublic,
        ...(notPublic && {
          published: timestamp
        }),
        ...(!notPublic && {
          unpublished: timestamp
        }),
        ...(namespace && notPublic ? {
          namespace
        } : {
          namespace: ''
        }),
      }, { merge: true })
      .then(() => {
        const pubText = notPublic ? 'Published' : 'Unpublished'
        console.log(`Post ${pubText}: `, id);

        const nam = namespace ? namespace : data?.namespace || ''
        const nsData = {
          namespace: nam,
          publish: notPublic,
          data,
          id,
          uid,
        }

        store.dispatch(updateNamespaceAction(nsData))

        // Uri
        if (notPublic && data.url) {
          store.dispatch(saveUriAction(data.url, data.id, data.userId, nam))
        } else if (!notPublic && data.uriRef && data.url) {
          store.dispatch(deleteUriAction(data.url, data.id, data.uriRef))
        }

        // Tags
        if (notPublic) {
          store.dispatch(publishTagsAction({
            uid,
            id,
            data
          }))
        } else {
          store.dispatch(unpublishTagsAction({
            uid,
            id,
            data
          }))
        }

        if (handleClose) handleClose(false, notPublic, nam)
        return store.dispatch(sendNotifcationAction(pubText, 'success'))
        // return
      })
  }),
  mapTo({ type: POST_PUBLISHED })
)



export const searchPostEpic = (action$: Observable<SearchPostsAction>, state$: RootState, { store, history }: StoreDependencies) => action$.pipe(
  ofType(SEARCH_POSTS),
  debounceTime(3000),
  mergeMap(async ({
    query
    // TODO: Add filter options
  }) => {
    // let results = []
    return firestore
      .collection('postsById')
      .where('title', '>=', query)
      .where('visibility', '==', 'public')
      .limit(8)
      .get()
      .then((posts) => {
        // console.log('size', posts.size)
        // console.log("Posts found: ", query, posts.docs);
        const res: any[] = []
        if (posts.size > 0) {
          posts.docs.forEach((d, i) => {
            const newId = d && d.id
            res.push({
              ...d.data(),
              id: newId
            })
          });
        }
        return store.dispatch(postsSearchedResultsAction({
          results: res
        }))

      })
  }),
  mapTo({ type: POSTS_SEARCHED })
)

