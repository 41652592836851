import React, { useContext, useState } from 'react'
import { connect } from 'react-redux'
import { isEmpty } from 'react-redux-firebase'
import { useHistory } from 'react-router-dom'

import { Box, Collapse, ListItemButton } from '@mui/material'
import Avatar from '@mui/material/Avatar'
import Divider from '@mui/material/Divider'
import Icon from '@mui/material/Icon'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Switch from '@mui/material/Switch'
import Typography from '@mui/material/Typography'
import { AuthProfile } from '@Components/containers/AuthProvider'
import { RouteContext } from '@Components/containers/RouteProvider'
import { roleVars } from '@Constants/config/roleVars'
import { appRoutes } from '@Constants/config/routeVars'
import { Types, typeData } from '@Constants/config/types'
import { TypeData } from '@Constants/types/TypeData'
import { toggleDrawerAction } from '@State/actions/appStateActions'
import {
  changeTypeAction,
  drawerTypesToggleAction,
} from '@State/actions/storageActions'
import { auth as fbAuth } from '@State/firebase'
import { AppDispatch, RootState } from '@State/store'
import { capitalizeFirstLetter } from '@Utils/textFunc'
import { shortTypeString } from '@Utils/typeFunc'

export const DRAWER_WIDTH = 211

interface DrawerItemData {
  name?: string
  icon?: string
  fullPath?: string
  path?: string

  type?: string
  pathWithParams?: string
  pathWithType?: string

  disabled?: boolean
  hide?: boolean
  divider?: boolean
}

interface StateProps {
  auth: RootState['firebase']['auth']
  profile: AuthProfile
  drawerTypesOpen: boolean
  typeSelected: string
  catSelected: string
  tagsSelected: string[]
}
interface DispatchProps {
  changeType: typeof changeTypeAction
  toggleDrawer: typeof toggleDrawerAction
  drawerTypesToggle: typeof drawerTypesToggleAction
}
interface OwnProps {}

type DrawerBodyProps = StateProps & DispatchProps & OwnProps

function DrawerBody({
  toggleDrawer,
  drawerTypesToggle,
  drawerTypesOpen,
  auth,
  profile,
  changeType,
  typeSelected,
  catSelected,
  tagsSelected,
}: DrawerBodyProps) {
  const [adminMode, setAdminMode] = useState(false)
  const history = useHistory()

  const handleClickExpand = () => {
    drawerTypesToggle()
  }

  const { username, role } = profile

  const userEmail = auth && (auth.email || '')
  const avatarURL = auth?.photoURL || ''

  const { typeQuery, catQuery, tagQuery, goType, go } = useContext(RouteContext)

  const handleTypeChange = (newType: Types | '') => {
    changeType(newType)
  }

  function goPathWithParamsAppend(pathWithParams) {
    goType(typeQuery)
    history.push(
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      `${pathWithParams}${catQuery ? `&cat=${catQuery}` : ''}${
        tagQuery ? `&tag=${tagQuery}` : ''
      }`
    )
  }

  function goPathWithParams(path) {
    const anyQuery = typeQuery || catQuery || tagQuery
    history.push(
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      `${path}${anyQuery ? '?' : ''}${typeQuery ? `&type=${typeQuery}` : ''}${
        catQuery ? `&cat=${catQuery}` : ''
      }${tagQuery ? `&tag=${tagQuery}` : ''}`
    )
  }

  function goFullpathWithParams(path) {
    const anyQuery = typeQuery || catQuery || tagQuery
    history.push(
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      `${path}&${typeQuery ? `&type=${typeQuery}` : ''}${
        catQuery ? `&cat=${catQuery}` : ''
      }${tagQuery ? `&tag=${tagQuery}` : ''}`
    )
  }

  function goAndCloseDrawer(item: DrawerItemData) {
    console.log('goAndClose', item)
    if (!adminMode && item.path) {
      go(item.path)
      if (item.type) handleTypeChange(item.type)
      toggleDrawer(false)
    } else if (item.pathWithType) {
      console.log(item)
      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      goPathWithParamsAppend(`${item.pathWithType}?type=${item?.val || ''}`)
      if (item.type) handleTypeChange(item.type)
      toggleDrawer(false)
    } else if (item.pathWithParams) {
      goPathWithParamsAppend(item.pathWithParams)
      if (item.type) handleTypeChange(item.type)
      toggleDrawer(false)
    } else if (item.fullPath) {
      goFullpathWithParams(item.fullPath)
      if (item.type) handleTypeChange(item.type)
      toggleDrawer(false)
    } else if (item.path) {
      goPathWithParams(item.path)
      if (item.type) handleTypeChange(item.type)
      toggleDrawer(false)
    }
  }

  function logout() {
    toggleDrawer(false)
    return fbAuth.signOut()
  }

  function DrawerItem({
    item,
    closeDrawer,
  }: {
    item: DrawerItemData
    closeDrawer: (item: DrawerItemData) => void
  }) {
    // eslint-disable-line @typescript-eslint/no-shadow

    if (item.hide) {
      return null
    } else {
      return (
        <>
          <ListItem
            disablePadding
            // className={classes.drawerItem}
            onClick={() => closeDrawer(item)}
            onKeyUp={(e) => {
              if (e.key === 'Enter' || e.key === 'o') {
                closeDrawer(item)
              }
            }}

            // selected={item.type === typeSelected}
          >
            <ListItemButton disabled={item.disabled}>
              <ListItemIcon>
                <Icon>{item.icon}</Icon>
              </ListItemIcon>
              <ListItemText primary={item.name} />
            </ListItemButton>
          </ListItem>
          {item.divider ? <Divider /> : null}
        </>
      )
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-shadow
  function TypeDrawerItem({
    item,
    closeDrawer,
  }: {
    item: TypeData
    closeDrawer: (item: DrawerItemData) => void
  }) {
    // console.log(item.val, typeSelected)
    const typeName = shortTypeString(item.val)
    // const name = typeName && typeName.plural ? typeName.plural : typeName.val
    return (
      <ListItem
        // className={classes.drawerItem}
        onClick={() => {
          // console.log(item)
          if (!adminMode) {
            // const plural = `${item.val}s`
            closeDrawer({
              ...item,
              pathWithType: '/filter',
              // pathWithParams: `/filter?type=${item.val}`,
            })
          } else {
            closeDrawer({
              ...item,
              pathWithType: appRoutes.posts,
              // pathWithParams: `${appRoutes.posts}?type=${item.val}`,
            })
          }
        }}
        // disabled={item.disabled}
        disablePadding
        onKeyUp={(e) => {
          if (e.key === 'Enter' || e.key === 'o') {
            if (!adminMode) {
              // const plural = `${item.val}s`
              closeDrawer({
                ...item,
                pathWithType: '/filter',
                // pathWithParams: `/filter?type=${item.val}`,
              })
            } else {
              closeDrawer({
                ...item,
                pathWithType: appRoutes.posts,
                // pathWithParams: `${appRoutes.posts}?type=${item.val}`,
              })
            }
          }
        }}
      >
        <ListItemButton
          selected={item.val === typeSelected || item.val === typeQuery}
        >
          <ListItemIcon>
            <Icon>{item.icon}</Icon>
          </ListItemIcon>
          <ListItemText primary={`${capitalizeFirstLetter(typeName)}`} />
        </ListItemButton>
      </ListItem>
    )
  }

  const showMyStuff = roleVars.menu.myStuff.includes(role)
  const showUsersLink = roleVars.menu.users.includes(role)
  const showUris = roleVars.menu.uris.includes(role)
  const showUrls = roleVars.menu.urls.includes(role)
  const showTags = roleVars.menu.tags.includes(role)
  const showProfile = roleVars.menu.profile.includes(role)
  const showCats = roleVars.menu.cats.includes(role)
  const showType = roleVars.menu.type.includes(role)
  const showTypes = roleVars.menu.types.includes(role)
  const showNamspaces = roleVars.menu.namespaces.includes(role)
  const showTest = roleVars.menu.test.includes(role)
  const showAdminToggle = roleVars.menu.adminToggle.includes(role)

  function UserCard() {
    return (
      <Box
        sx={{
          p: 2,
          bgcolor: 'action.disabledBackground',
          cursor: 'pointer',
        }}
        onClick={(e) => {
          console.warn(`🚀 ~ e`, e)
          go(appRoutes.profile)
          toggleDrawer(false)
        }}
      >
        <Avatar
          alt={username || auth.displayName || 'username error'}
          src={avatarURL}
        />
        <Typography sx={{ mt: 1 }} variant="h6">
          {username || auth.displayName}
        </Typography>
        {/* <Typography variant="caption">{userEmail}</Typography> */}
      </Box>
    )
  }

  function TopItems() {
    return (
      <List>
        {!role && !isEmpty(auth) ? (
          <>
            <ListItem
              disablePadding
              onClick={() => goAndCloseDrawer({ path: '/apply' })}
              onKeyUp={(e) => {
                if (e.key === 'Enter' || e.key === 'o') {
                  goAndCloseDrawer({ path: '/apply' })
                }
              }}
            >
              <ListItemButton>
                <ListItemIcon>
                  <Icon>history_edu</Icon>
                </ListItemIcon>
                <ListItemText primary="Become Author!" />
              </ListItemButton>
            </ListItem>
            <Divider />
          </>
        ) : null}

        {[
          {
            name: 'Home',
            icon: 'home',
            path: '/',
            divider: true,
          },
        ].map((item, index) => {
          return (
            <DrawerItem
              key={index}
              item={item}
              closeDrawer={goAndCloseDrawer}
            />
          )
        })}
      </List>
    )
  }

  function TestItem(): React.ReactNode {
    return showTest || process.env.REACT_APP_ENV === 'development' ? (
      <>
        {[
          {
            name: 'Test',
            icon: 'biotech',
            path: '/test',
            divider: !isEmpty(auth),
          },
        ].map((item, index) => {
          return (
            <DrawerItem
              key={index}
              item={item}
              closeDrawer={goAndCloseDrawer}
            />
          )
        })}
      </>
    ) : null
  }

  function SystemItems(): React.ReactNode {
    return [
      {
        name: 'Profile',
        icon: 'account_circle',
        path: appRoutes.profile,
        disabled: !auth.uid,
        hide: !showProfile,
      },
      { name: 'Settings', icon: 'settings', disabled: true },
      { name: 'Sharing', icon: 'folder_shared', disabled: true },
    ].map((item: DrawerItemData, index) => (
      <DrawerItem key={index} item={item} closeDrawer={goAndCloseDrawer} />
    ))
  }

  function TypeItem(): React.ReactNode {
    return (
      <>
        {[
          {
            name: 'Types',
            icon: 'apps',
            path: '/types',
            divider: !isEmpty(auth),
          },
        ].map((item, index) => {
          return (
            <DrawerItem
              key={index}
              item={item}
              closeDrawer={goAndCloseDrawer}
            />
          )
        })}
      </>
    )
  }

  function CatItem(): React.ReactNode {
    return (
      <>
        {[
          {
            name: 'Categories',
            icon: 'view_comfy',
            path: '/cats',
            divider: !isEmpty(auth),
          },
        ].map((item, index) => {
          return (
            <DrawerItem
              key={index}
              item={item}
              closeDrawer={goAndCloseDrawer}
            />
          )
        })}
      </>
    )
  }

  function TypeItems(): React.ReactNode {
    return (
      <>
        <Divider />
        <ListItemButton onClick={handleClickExpand}>
          <ListItemIcon>
            <Icon>apps</Icon>
          </ListItemIcon>
          <ListItemText primary="Types" />
          {drawerTypesOpen ? (
            <Icon>expand_less</Icon>
          ) : (
            <Icon>expand_more</Icon>
          )}
        </ListItemButton>
        <Collapse in={drawerTypesOpen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding sx={{ pl: 2 }}>
            {showAdminToggle && (
              <ListItem
                disablePadding
                onClick={() => setAdminMode(!adminMode)}
                onKeyUp={(e) => {
                  if (e.key === 'Enter' || e.key === 'o') {
                    setAdminMode(!adminMode)
                  }
                }}
              >
                <ListItemButton>
                  <Switch
                    checked={adminMode}
                    onChange={() => setAdminMode(!adminMode)}
                    sx={{ ml: -1.8, mr: 1.35 }}
                  />
                  <ListItemText primary="Admin" />
                </ListItemButton>
              </ListItem>
            )}
            {showAdminToggle ? <Divider /> : null}
            {typeData.map((item, index) => {
              return (
                <TypeDrawerItem
                  key={index}
                  item={item}
                  closeDrawer={goAndCloseDrawer}
                />
              )
            })}
          </List>
        </Collapse>
      </>
    )
  }

  function PostItem(): React.ReactNode {
    return [
      { name: 'Posts', icon: 'list_alt', path: appRoutes.list },
      { name: 'Locations', icon: 'travel_explore', path: appRoutes.locations },
    ].map((item, index) => (
      <DrawerItem key={index} item={item} closeDrawer={goAndCloseDrawer} />
    ))
  }

  function MiddleItems(): React.ReactNode {
    return [
      {
        name: 'Users',
        icon: 'person',
        path: '/users',
        hide: !showUsersLink,
      },
      {
        name: 'Tags',
        icon: 'local_offer',
        path: '/tags',
        hide: !showTags,
      },
      {
        name: 'Namespaces',
        icon: 'abc',
        path: '/namespaces',
        hide: !showNamspaces,
      },
      { name: 'URLs', icon: 'http', path: '/urls', hide: !showUrls },
      {
        name: 'URIs',
        icon: 'link',
        path: '/uris',
        hide: !showUris,
      },
    ].map((item, index) => {
      return (
        <DrawerItem key={index} item={item} closeDrawer={goAndCloseDrawer} />
      )
    })
  }

  function MyStuff(): React.ReactNode {
    return [
      {
        name: 'My Stuff',
        icon: 'view_module',
        fullPath: `${appRoutes.posts}?userId=${auth && auth.uid}`,
      },
      // { name: 'Start', icon: 'trip_origin', path: '/types' },
      {
        name: 'Create',
        icon: 'create',
        path: appRoutes.create,
        divider: true,
      },
    ].map((item: DrawerItemData, index) => {
      return (
        <DrawerItem key={index} item={item} closeDrawer={goAndCloseDrawer} />
      )
    })
  }

  return (
    <>
      {!isEmpty(auth) ? UserCard() : null}

      <Divider />

      <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
        <nav>
          {TopItems()}

          {showMyStuff && MyStuff()}
        </nav>
        <nav>
          <List>
            {MiddleItems()}

            {!isEmpty(auth) ? PostItem() : null}

            {showTypes ? TypeItems() : null}

            {showCats ? CatItem() : null}
            {showType ? TypeItem() : null}

            {!isEmpty(auth) ? SystemItems() : null}

            {TestItem()}
          </List>
        </nav>
      </Box>
    </>
  )
}

const mapStateToProps = (state: RootState /*, ownProps*/) => {
  return {
    auth: state.firebase?.auth,
    profile: state.firebase?.profile,
    typeSelected: state.storage?.typeSelected,
    catSelected: state.storage?.catSelected,
    tagsSelected: state.storage?.tagsSelected,
    drawerTypesOpen: state.storage?.drawerTypesOpen,
  }
}

const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    toggleDrawer: (state: boolean) => dispatch(toggleDrawerAction(state)),
    changeType: (type: Types) => dispatch(changeTypeAction(type)),
    drawerTypesToggle: (open?: boolean) =>
      dispatch(drawerTypesToggleAction(open)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DrawerBody)
