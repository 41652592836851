import PostBody from '@Components/post/PostBody'
import PostCard from '@Components/post/PostCard'
import ViewPost from '@Components/post/ViewPost'
import { ExistingPostData } from '@Constants/post'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import LinearProgress from '@mui/material/LinearProgress'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import NotFound from '@Routes/system/NotFound'
import { AppDispatch, RootState } from '@State/store'
import get from 'lodash/get'
import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { connect, useSelector } from 'react-redux'
import {
  isEmpty,
  isLoaded,
  OrderByOptions,
  ReduxFirestoreQuerySetting,
  useFirestoreConnect,
  WhereOptions,
} from 'react-redux-firebase'
import { useParams } from 'react-router-dom'

function ViewObjectRoute({
  uid,
  postNamespace,
  mini,
  view,
  delay = 333,
}: {
  uid?: string
  postNamespace?: string
  mini?: boolean
  view?: boolean
  delay?: number
}) {
  const { namespace } = useParams<{ namespace: string }>()

  const namespaceToLoad = postNamespace || namespace.trim()

  const whereQuery: WhereOptions[] = [
    ['visibility', '==', 'public'],
    ['namespace', '==', namespaceToLoad],
  ]

  const postQuery: ReduxFirestoreQuerySetting = {
    collection: 'postsById',
    where: [...whereQuery],
    storeAs: `objects/${namespaceToLoad}`,
  }

  const namespacePosts = useSelector(
    ({ firestore: { ordered } }: RootState) => {
      return get(ordered, `objects/${namespaceToLoad}`)
    }
  )

  const post: ExistingPostData = namespacePosts && namespacePosts[0]

  let query = [postQuery]

  useFirestoreConnect(() => query)

  const notExists = isEmpty(namespacePosts) && isLoaded(namespacePosts)

  if (post) {
    return (
      <>
        <Helmet>
          <title>{post?.title}</title>
          <meta name="title" content={post?.title} />
          <meta name="description" content={post?.text} />
        </Helmet>

        <Box
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100vw',
            height: '100vh',
            py: 2,
            px: 1,
          }}
        >
          <Paper
            variant="outlined"
            sx={{
              height: '100%',
              p: 1,
            }}
          >
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              sx={{
                height: '100%',
                width: '100%',
              }}
            >
              <Container maxWidth="md">
                <PostCard post={post} />
              </Container>
            </Stack>
          </Paper>
        </Box>
      </>
    )
  } else if (notExists) {
    return <NotFound />
  } else {
    return <LinearProgress />
  }
}

const mapStateToProps = (state: RootState /*, ownProps*/) => {
  return {
    uid: state.firebase?.auth.uid,
  }
}

const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewObjectRoute)
