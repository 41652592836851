import React from 'react'

import Box from '@mui/material/Box'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Icon from '@mui/material/Icon'
import IconButton from '@mui/material/IconButton'
import Popper from '@mui/material/Popper'
import SearchPosts from '@Components/containers/search/SearchPosts'
import { ExistingPostData } from '@Constants/post'

export default function SearchPopper({
  onChoose,
  autoHighlight,
}: {
  onChoose?: (post: ExistingPostData) => void
  autoHighlight?: boolean
}) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget)
  }

  const handleClickAway = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'search-popup' : undefined

  return (
    <>
      <IconButton aria-describedby={id} type="button" onClick={handleClick}>
        <Icon>search</Icon>
      </IconButton>

      <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        placement="bottom"
        disablePortal
      >
        <ClickAwayListener onClickAway={handleClickAway}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              bgcolor: 'background.paper',
              zIndex: 99999,
              width: '100vw',
              py: 1,
            }}
          >
            <SearchPosts
              autoHighlight={autoHighlight}
              onChoose={(post) => {
                handleClickAway()
                if (onChoose) onChoose(post)
              }}
              autoFocus
            />
          </Box>
        </ClickAwayListener>
      </Popper>
    </>
  )
}
