import { useContext } from 'react'
import { useParams } from 'react-router-dom'

import MuiFab from '@mui/material/Fab'
import Icon from '@mui/material/Icon'

import { RouteContext } from '@Components/containers/RouteProvider'
import { SxProps } from '@mui/material/styles'
import Box from '@mui/material/Box'

export default function Fab({ fab, sx }: { fab: string; sx?: SxProps }) {
  const { id } = useParams<{ id: string }>()

  const { goCreate, goEditId, goEditNamespace, goViewId, goViewNamespace } =
    useContext(RouteContext)

  function goWhere() {
    switch (fab) {
      case 'add':
        goCreate()
        break
      case 'edit':
        // @ts-ignore
        goEditId(id)
        break
      case 'editNamespace':
        // Id is the namespace here
        // @ts-ignore
        goEditNamespace(id)
        break
      case 'visibilityNamespace':
        // Id is the namespace here
        // @ts-ignore
        goViewNamespace(id)
        break
      case 'visibility':
        // @ts-ignore
        goViewId(id)
        break

      default:
        console.error('Unknown fab:', fab)
        break
    }
  }

  // const disableFab = false

  return (
    <Box sx={sx}>
      <MuiFab
        color="primary"
        aria-label={fab}
        variant="circular"
        onClick={() => goWhere()}
        // disabled={disableFab}
      >
        <Icon>{fab}</Icon>
      </MuiFab>
    </Box>
  )
}
