import React, { useMemo } from 'react'

import { Color } from '@mui/material'
import Icon from '@mui/material/Icon'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import categories from '@Constants/config/categories'
import { capitalizeFirstLetter } from '@Utils/textFunc'
import { iconFontSize } from '@Styles/styleVars'

export default function CatButton({
  cat,
  mini,
  tiny,
  goCat,
  color,
  showTooltip,
}: {
  cat: string
  mini?: boolean
  tiny?: boolean
  showTooltip?: boolean
  color?:
    | 'inherit'
    | 'action'
    | 'disabled'
    | 'primary'
    | 'secondary'
    | 'error'
    | 'info'
    | 'success'
    | 'warning'
  goCat?: (cat, e) => void
}) {
  function getCatName() {
    return cat
      ? categories.find((catObj) => {
          const catVal = cat // capitalizeFirstLetter(cat)
          const resultFound = catObj.val === catVal
          if (resultFound) {
            return resultFound
          } else {
            return false
          }
        })
      : ''
  }
  const catItem = useMemo(() => getCatName(), [cat])
  const catName = catItem && catItem.val
  const catIcon = catItem && catItem.icon ? catItem.icon : 'view_comfy'
  const tooltipTitle = showTooltip
    ? catName
      ? `Click to change the currently selected category: '${catName}'`
      : `Click to select category`
    : `${capitalizeFirstLetter(cat)}`
  const fontSize = tiny ? 'tiny' : mini ? 'mini' : 'large'
  return (
    <IconButton onClick={(e) => goCat && goCat(cat, e)}>
      <Tooltip title={tooltipTitle}>
        <Icon
          color={color ? color : 'action'}
          style={{
            fontSize: iconFontSize(fontSize),
          }}
        >
          {catIcon}
        </Icon>
      </Tooltip>
    </IconButton>
  )
}
