import { useEffect } from 'react'
import { connect } from 'react-redux'

import Box from '@mui/material/Box'
import SwipeableDrawer from '@mui/material/SwipeableDrawer'
import DrawerBody from '@Components/ui/DrawerBody'
import { useBreakpoints } from '@Constants/config/globalStyles'
import { toggleDrawerAction } from '@State/actions/appStateActions'
import { AppDispatch, RootState } from '@State/store'

export const DRAWER_WIDTH = 211

interface StateProps {
  drawerOpen: boolean
}
interface DispatchProps {
  toggleDrawer: typeof toggleDrawerAction
}

interface OwnProps {
  persistent?: boolean
  autoToggle?: boolean
}

type Props = StateProps & DispatchProps & OwnProps

function Drawer({
  drawerOpen,
  toggleDrawer,
  autoToggle = false,
  persistent = false,
}: Props) {
  const drawerAnchor = 'left'
  const { matchesMedium, matchesLargest, matchesLarge } = useBreakpoints()

  useEffect(() => {
    if (autoToggle) {
      if (matchesLargest) {
        toggleDrawer(true)
      } else if (matchesLarge) {
        toggleDrawer(false)
      }
    }
  }, [autoToggle, matchesLarge, matchesLargest])

  return (
    <SwipeableDrawer
      anchor={drawerAnchor}
      open={drawerOpen}
      onClose={(e) => {
        toggleDrawer(false)
      }}
      onOpen={() => toggleDrawer(true)}
      variant={
        persistent ? 'persistent' : matchesMedium ? 'temporary' : 'persistent'
      }
    >
      <Box
        sx={{ width: DRAWER_WIDTH, bgcolor: 'background.paper' }}
        role="drawer"
      >
        <DrawerBody />
      </Box>
    </SwipeableDrawer>
  )
}

const mapStateToProps = (state: RootState /*, ownProps*/) => {
  return {
    drawerOpen: state.appState?.drawerOpen,
  }
}

const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    toggleDrawer: (state: boolean) => dispatch(toggleDrawerAction(state)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Drawer)
