import { connect } from 'react-redux'

import MuiAlert, { AlertColor } from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Snacks from '@mui/material/Snackbar'
import { closeNotifcationAction } from '@State/actions/appStateActions'
import { AppDispatch, RootState } from '@State/store'
import { NotificationMessage } from '@State/reducers/notificationReducer'

export function Snackbar({ messages, notificationOpen, closeNotifcation }) {
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    closeNotifcation()
  }
  const msgs = messages as NotificationMessage[]
  const message: NotificationMessage = msgs[msgs.length - 1]
  return (
    <Box
      sx={{
        width: '100%',
        '& > * + *': {
          mt: 2,
        },
      }}
    >
      {/* {msgs &&
        msgs.map((message, key) => {
          const { severity } = message
          return ( */}
      <Snacks
        // key={key}
        open={notificationOpen && msgs.length !== 0}
        onClose={handleClose}
        // autoHideDuration={severity === 'error' ? 6000 : 3000}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          severity={message?.severity as AlertColor}
        >
          {message?.text}
        </MuiAlert>
      </Snacks>
      {/* )
        })} */}
    </Box>
  )
}

const mapStateToProps = (state: RootState /*, ownProps*/) => {
  return {
    // auth: state.firebase?.auth
    messages: state.notifications?.messages,
    notificationOpen: state.notifications?.notificationOpen,
  }
}

const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    closeNotifcation: () => dispatch(closeNotifcationAction()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Snackbar)
