import { PostData } from "@Constants/post"
import { Types } from '@Constants/config/types'

export const JUDGE_POST = 'JUDGE_POST'


export interface GenericJudgement {
  created: string
  edited: string;
  updated?: string; // DEPRECATED
  emoteCount: number
  describeCount: number
  feels_badCount: number
  feels_neutralCount: number
  feels_goodCount: number
  feels_greatCount: number
  feels_sadCount: number
}


export interface JudgementData {
  created: string
  postId: string
  type: string
  userId: string
  emote: string;
  describe: string;
}

export interface EmoteJudgement {
  emote: string;
}


export interface DescribeJudgement {
  describe: string;
}

export interface JudgementByUid extends JudgementData, EmoteJudgement, DescribeJudgement { }


export interface JudgePostAction {
  type: typeof JUDGE_POST
  uid: string;
  id: string;
  data: {
    type: string
    likeType: string
    likeValue: string
  }
  variant?: string;
}


// Judgement
export function judgePostAction({ uid, id, variant, data }: Omit<JudgePostAction, 'type'>): JudgePostAction {
  return {
    type: JUDGE_POST,
    uid,
    id,
    data,
    variant,
  }
}

export type JudgementActions =
  JudgePostAction