import { CatData } from '@Constants/types/CatData';

// FIXME: Get actual string literals instead of `string` type
export type CatsValType = (typeof categories)[number]['val']
export type Cats = CatsValType

// @ts-expect-error
const categories: CatData[] = [
  {
    val: 'media',
    icon: 'tv'
  },
  {
    val: 'people',
    icon: 'supervisor_account'
  },
  {
    val: 'humans',
    modifier: 'human',
    icon: 'pan_tool'
  },
  {
    val: 'health',
    modifier: 'healthy',
    icon: 'local_hospital'
  },


  {
    val: 'philosophy',
    alias: 'wisdom',
    modifier: 'philosophic',
    icon: 'school'
  },
  {
    val: 'wisdom',
    icon: 'spa'
  },
  {
    val: 'journalism',
    alias: 'news',
    modifier: 'journalist',
    icon: 'public'
  },
  {
    val: 'globalism',
    icon: 'vpn_lock_sharp',
    modifier: 'globalist',
    // icon: 'language'
  },


  {
    val: 'history',
    modifier: 'historic',
    icon: 'account_balance'
  },
  {
    val: 'politics',
    icon: 'gavel',
    modifier: 'political',
    // icon: 'sports_kabaddi'
  },
  {
    val: 'weather',
    icon: 'storm',
    // icon: 'beach_access'
  },
  {
    val: 'finance',
    alias: 'money',
    modifier: 'financial',
    icon: 'payments',
    // icon: 'money',
  },


  {
    val: 'nature',
    modifier: 'natural',
    icon: 'eco'
  },
  {
    val: 'technology',
    icon: 'nfc',
    modifier: 'technological',
  },
  {
    val: 'robotics',
    modifier: 'robotic',
    icon: 'precision_manufacturing'
  },
  {
    val: 'electronics',
    alias: 'hardware',
    modifier: 'electronic',
    icon: 'mouse'
  },


  {
    val: 'science',
    alias: 'knowledge',
    modifier: 'scientific',
    icon: 'biotech '
  },
  {
    val: 'film',
    alias: 'movies',
    icon: 'theaters'
  },
  {
    val: 'food',
    icon: 'local_pizza'
  },
  {
    val: 'life',
    icon: 'nature_people'
  },


  {
    val: 'investigation',
    alias: 'investigations',
    modifier: 'investigational',
    icon: 'fingerprint'
  },
  {
    val: 'opinion',
    alias: 'subjective',
    modifier: 'opinionated',
    icon: 'record_voice_over'
  },
  {
    val: 'trope',
    alias: 'tropes',
    icon: 'smart_toy'
  },
  {
    val: 'meme',
    alias: 'memes',
    modifier: 'memetic',
    icon: 'support_agent'
  },


  {
    val: 'comedy',
    modifier: 'comedic',
    icon: 'mood'
  },
  {
    val: 'organisation',
    alias: 'organisations',
    modifier: 'organisational',
    icon: 'business_center'
  },
  {
    val: 'art',
    modifier: 'artistic',
    icon: 'palette'
  },
  {
    val: 'literature',
    icon: 'local_library'
  },


  {
    val: 'blog',
    alias: 'blogs',
    icon: 'book'
  },
  {
    val: 'story',
    alias: 'stories',
    icon: 'menu_book'
  },

  {
    val: 'timeline',
    icon: 'timeline'
  },
  {
    val: 'community',
    alias: 'communities',
    icon: 'group_work'
    // icon: 'deck'
  },


  {
    val: 'gaming',
    icon: 'sports_esports'
    // icon: 'videogame_asset'
  },
  {
    val: 'software',
    alias: 'apps',
    icon: 'wysiwyg'
  },
  {
    val: 'place',
    alias: 'places',
    icon: 'place'
  },
  {
    val: 'music',
    icon: 'speaker',
    modifier: 'musical',
    // icon: 'audiotrack'
  },


  {
    val: 'symbology',
    alias: 'logos',
    modifier: 'symbolic',
    icon: 'shield'
  },
  {
    val: 'conspiracy',
    alias: 'what',
    modifier: 'conspiratorial',
    icon: 'coronavirus'
  },
  {
    val: 'secret',
    alias: 'secrets',
    modifier: 'secretive',
    icon: 'vpn_key',
  },
  {
    val: 'mystery',
    alias: 'mysteries',
    modifier: 'mysterious',
    icon: 'meeting_room',
    // disabled: true
  },

  {
    val: 'supernatural',
    icon: 'auto_awesome'
  },
  {
    val: 'travel',
    modifier: 'traveling',
    icon: 'explore'
  },
  {
    val: 'animal',
    alias: 'animals',
    modifier: 'animalistic',
    icon: 'pets'
  },
  {
    val: 'national',
    icon: 'flag'
  },


  {
    val: 'engineering',
    icon: 'engineering'
  },
  {
    val: 'energy',
    alias: 'power',
    modifier: 'energic',
    icon: 'bolt'
  },
  {
    val: 'satire',
    modifier: 'satirical',
    icon: 'child_care'
  },
  {
    val: 'miscellaneous',
    alias: 'misc',
    icon: 'workspaces'
  },
] as const;

// Non-user choosable categories
export const sysCats = [
  {
    val: 'relation',
    icon: 'relation'
  },
  {
    val: 'video',
    icon: 'video'
  }
]

export default categories