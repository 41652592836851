import React, { useContext } from 'react'
import { connect } from 'react-redux'
import { useFirestore } from 'react-redux-firebase'

import { AutocompleteChangeReason } from '@mui/material/useAutocomplete'
import Box from '@mui/material/Box'
import ListItemButton from '@mui/material/ListItemButton'
import makeStyles from '@mui/styles/makeStyles'
import { AutocompleteField } from '@Components/containers/forms/fields/AutocompleteField'
import SearchPostsInput from '@Components/containers/search/SearchPostsInput'
import SearchPostsItem from '@Components/containers/search/SearchPostsItem'
import { RouteContext } from '@Components/containers/RouteProvider'
import { useBreakpoints } from '@Constants/config/globalStyles'
import { systemRoutes } from '@Constants/config/routeVars'
import { Types } from '@Constants/config/types'
import { ExistingPostData } from '@Constants/post'
import { AppDispatch, RootState } from '@State/store'

// import InputBase from '@mui/material/InputBase';
// import Icon from '@mui/material/Icon';
// import fetch from 'cross-fetch'; // *https://www.registers.service.gov.uk/registers/country/use-the-api*
// import { searchQueryAction } from '@State/actions/appStateActions';

// function sleep(delay = 0) {
//   return new Promise((resolve) => {
//     setTimeout(resolve, delay);
//   });
// }

interface StateProps {
  uid: string
  typeSelected: Types
}
interface DispatchProps {
  // searchQuery: (text: string) => void;
  // changeType: (type: Types) => void
  // changeCat: (cat: string) => void
  // changeTags: (tags: string[]) => void
}
interface OwnProps {
  autoFocus?: boolean
  autoSelect?: boolean
  autoHighlight?: boolean
  onChoose?: (postItem: ExistingPostData) => void
}

type Props = StateProps & DispatchProps & OwnProps

function SearchPosts({
  onChoose,
  autoFocus,
  autoSelect,
  autoHighlight,
  uid,
}: Props) {
  const { matchesMobile, matchesMedium, matchesLargest } = useBreakpoints()

  const useStyles = makeStyles((theme) => ({
    inputRoot: {
      color: 'inherit',
      width: '100%',
    },
  }))

  const [open, setOpen] = React.useState(false)
  const [resultOptions, setResultOptions] = React.useState<ExistingPostData[]>(
    []
  )
  const loading = Boolean(open && resultOptions.length === 0)
  const [query, setQuery] = React.useState('')
  const firestore = useFirestore()
  const classes = useStyles()

  const results: ExistingPostData[] = []

  const {
    goView,
    typeQuery,
    catQuery,
    getHref,
    whereQuery,
    convertWhereQuery,
  } = useContext(RouteContext)

  React.useEffect(() => {
    if (!loading) {
      return undefined
    }

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    ;(async () => {
      // console.log('async', typeQuery, query)

      const searchQuery =
        query.length > 0
          ? query.charAt(0).toUpperCase() + query.slice(1)
          : query

      const fbPath = firestore
        .collection('postsById')
        .where('title', '>=', searchQuery)
        .where('userId', '==', uid)
      // .where('title', '<=', query+ '\uF7FF')
      // .where('visibility', '==', 'public')

      // fbPath = convertWhereQuery(whereQuery, fbPath)

      await fbPath
        .limit(20)
        .get()
        .then((posts) => {
          // console.log('size', posts.size)
          // console.log("Posts found: ", searchQuery, posts.docs);
          if (posts.size > 0) {
            posts.docs.forEach((d, i) => {
              // console.log(d.data(),d.id)
              // @ts-expect-error
              results.push({
                ...d.data(),
                id: d.id,
              })
            })
          }
        })

      // await result

      if (results) {
        const opts = Object.keys(results).map((key, id) => {
          // console.log(results[key])
          return results[key]
        })
        // console.log('opts', opts)
        setResultOptions(opts)
      }
    })()

    return () => {
      // active = false;
    }
  }, [loading, firestore, query])

  React.useEffect(() => {
    if (!open) {
      setResultOptions([])
    }
  }, [open])

  // console.log(resultOptions)

  return (
    <Box
      sx={{
        color: 'inherit',
        width: {
          xs: '90vw',
          sm: '90vw',
          // inline:
          md: '52w',
          lg: '56vw',
          xl: '62vw',
        },
        pr: 1, // Should not be here
      }}
    >
      <AutocompleteField
        label="Search Posts"
        className={classes.inputRoot}
        open={open}
        autoHighlight={autoHighlight}
        autoSelect={autoSelect}
        setOpen={setOpen}
        results={resultOptions}
        renderInput={(params) => {
          return (
            <SearchPostsInput
              autoFocus={autoFocus}
              params={params}
              loading={loading}
              typeSelected={typeQuery}
              catSelected={catQuery}
            />
          )
        }}
        renderOption={(props, postItem, selected) => {
          console.log(props, postItem, selected)

          return (
            <ListItemButton
              {...props}
              onClick={(e) => {
                if (e.ctrlKey) return
                e.preventDefault()
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
                props.onClick(e)
              }}
              component="a"
              href={getHref(systemRoutes.view, postItem as ExistingPostData)}
            >
              <SearchPostsItem item={postItem} typeQuery={typeQuery} />
            </ListItemButton>
          )
        }}
        onInputChange={(e, searchQuery, reason) => {
          // console.log(searchQuery, reason)
          if (reason === 'input') {
            const finalSearchQuery =
              searchQuery.length > 0
                ? `${searchQuery.charAt(0).toUpperCase()}${searchQuery.slice(
                    1
                  )}`
                : searchQuery
            setQuery(finalSearchQuery)
            setResultOptions([])
          }
          if (reason === 'reset') {
            setQuery('')
            setResultOptions([])
          }
        }}
        onChange={(e, option, reason: AutocompleteChangeReason) => {
          if (reason === 'clear') {
            setQuery('')
            setResultOptions([])
          }

          const postItem = option as ExistingPostData
          if (reason === 'selectOption' && postItem) {
            if (onChoose) {
              onChoose(postItem)
            } else {
              goView(postItem)
            }
          }
        }}
      />
    </Box>
  )
}

const mapStateToProps = (state: RootState /*, ownProps*/) => {
  return {
    uid: state.firebase?.auth?.uid,
    typeSelected: state.storage?.typeSelected,
  }
}

const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    // searchQuery: (text: string) => dispatch(searchQueryAction(text))
    // changeType: (type: Types) => dispatch(changeTypeAction(type)),
    // changeCat: (value: string) => dispatch(changeCatAction(value)),
    // changeTags: (value: string[]) => dispatch(changeTagsAction(value)),
  }
}

export default connect<StateProps, DispatchProps, OwnProps, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(SearchPosts)
