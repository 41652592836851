import { getCoverImage } from '@Utils/metaFunc'
import { isUrlEmbed } from '@Utils/postDataFunc'

import { ExistingPostData } from '../constants/post'

export const iconFontSize = (size: 'tiny' | 'mini' | 'large') => {
  switch (size) {
    case 'tiny':
      return '1.2rem'
    case 'mini':
      return '26px'

    case 'large':
    default:
      return '38px'
  }
}

export const shouldHaveWordCloud = (item: ExistingPostData) => {
  const isTypeTextOrTerm = item?.type === 'text' || item?.type === 'term'
  const hasImgUrl = !!item?.imgUrl

  const hasEnoughWords =
    isTypeTextOrTerm && item?.text && item?.text?.length >= 10

  // TODO: If only favicon, and nothing else, then return true

  return hasEnoughWords && !hasImgUrl
}

export const showHero = (item: ExistingPostData) => {
  const imageUrl = getCoverImage(item)

  const isUrlEmbeded = isUrlEmbed(item)

  const isTypeLocation = item?.type === 'location'
  const isTypeGraph = item?.type === 'graph'

  const hasEnoughWords = shouldHaveWordCloud(item)
  const hasFavicon = !!item?.url

  const shouldHeaderBeShown =
    imageUrl ||
    isTypeLocation ||
    isTypeGraph ||
    hasFavicon ||
    hasEnoughWords ||
    isUrlEmbeded

  return shouldHeaderBeShown
}

export const isHeroClickable = (item: ExistingPostData) => {
  const isUrlEmbeded = isUrlEmbed(item)

  const isTypeLocation = item?.type === 'location'
  const isTypeGraph = item?.type === 'graph'
  const shouldHeaderBeShown = showHero(item)

  const hasEnoughWords = shouldHaveWordCloud(item)

  const anyNonClickables =
    hasEnoughWords || isTypeGraph || isTypeLocation || isUrlEmbeded
  const shouldHeaderBeClickable = shouldHeaderBeShown && !anyNonClickables

  return shouldHeaderBeClickable
}
