import TypeSelectorPicker from '@Components/shared/type/TypeSelectorPicker'
import { styled } from '@mui/material/styles'
import { Types } from '@Constants/config/types'
import Backdrop from '@mui/material/Backdrop'
import Grow from '@mui/material/Grow'
import Popover from '@mui/material/Popover'
import makeStyles from '@mui/styles/makeStyles'
import { RootState } from '@State/store'
import React from 'react'
import { connect } from 'react-redux'
import { useLocation } from 'react-router-dom'

import TypeIcon from '../../ui/TypeIcon'

const PREFIX = 'TypeSelector'

const classes = {
  backdrop: `${PREFIX}-backdrop`,
  body: `${PREFIX}-body`,
}

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.backdrop}`]: {
    zIndex: theme.zIndex.drawer + 1,
  },

  [`& .${classes.body}`]: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
}))

function TypeSelector({
  type,
  mini,
  tiny,
  isSubType = false,

  opened, // = false,

  typeSelected,
  subTypeSelected,
  onChange,
}: {
  type?: Types
  opened?: boolean
  onChange?: (type: Types) => void
  mini?: boolean
  tiny?: boolean
  isSubType?: boolean
  typeSelected: Types
  subTypeSelected: Types
}) {
  const [anchorEl, setAnchorEl] = React.useState<
    (EventTarget & HTMLButtonElement) | null
  >(null)
  const open = Boolean(anchorEl)

  const query = new URLSearchParams(useLocation().search || '')
  const typeQuery = type || query.get('type') || typeSelected

  const handleClick = (t, event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const goType = (typeValue: Types) => {
    if (onChange) onChange(typeValue)
    handleClose()
  }

  const handleBackdropClose = () => {
    handleClose()
  }

  return (
    <Root onClick={(e) => e.stopPropagation()}>
      {/* <Tooltip title="Choose type" className={classes.tooltip}> */}
      <TypeIcon
        mini={mini}
        tiny={tiny}
        type={isSubType ? subTypeSelected : typeQuery}
        goType={handleClick}
      />
      {/* </Tooltip> */}

      <Backdrop
        className={classes.backdrop}
        open={open || !!opened}
        onClick={handleBackdropClose}
      >
        <Popover
          id="typeselector"
          open={open || !!opened}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          TransitionComponent={Grow}
        >
          <div className={classes.body}>
            {/* <Tooltip title="Choose type" className={classes.tooltip}> */}
            <TypeSelectorPicker onChange={goType} isSubType={isSubType} />
            {/* </Tooltip> */}
          </div>
        </Popover>
      </Backdrop>
    </Root>
  )
}
const mapStateToProps = (state: RootState /*, ownProps*/) => {
  return {
    // auth: state.firebase?.auth,
    // theme: state.storage?.theme,
    typeSelected: state.storage?.typeSelected,
    subTypeSelected: state.storage?.subTypeSelected,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    // changeType: (type: Types) => dispatch(changeTypeAction( type )),
    // changeSubType: (value: string) => dispatch(changeSubTypeAction( value )),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TypeSelector)
