import {
  decorateCodeLine,
  deserializeHtmlCodeBlock,
  onKeyDownCodeBlock,
  withCodeBlock
} from '@udecode/plate'

import {
  HotkeyPlugin,
  InsertNodesOptions,
  KEY_DESERIALIZE_HTML,
  PlateEditor,
  TElement,
  Value,
  createPluginFactory,
  getPlugin,
  someNode
} from '@udecode/plate-core'

import {
  ELEMENT_CODE_BLOCK,
  ELEMENT_CODE_LINE,
  ELEMENT_CODE_SYNTAX
} from '@Components/shared/object-editor/ObjectEditor.types'


export interface CodeBlockPlugin extends HotkeyPlugin {
  syntax?: boolean;
  syntaxPopularFirst?: boolean;
  deserializers?: string[];
}

export interface TCodeBlockElement extends TElement {
  lang?: string;
}

export interface CodeBlockInsertOptions<V extends Value = Value> {
  defaultType?: string;
  level?: number;
  insertNodesOptions?: Omit<InsertNodesOptions<V>, 'match'>;
}

export const createCodeBlockPlugin = createPluginFactory<
  CodeBlockPlugin,
  Value,
  PlateEditor
>({
  key: ELEMENT_CODE_BLOCK,
  isElement: true,
  deserializeHtml: deserializeHtmlCodeBlock,
  handlers: {
    onKeyDown: onKeyDownCodeBlock,
  },
  withOverrides: withCodeBlock,
  options: {
    hotkey: ['mod+opt+8', 'mod+shift+8'],
    syntax: true,
    syntaxPopularFirst: false,
  },
  then: (editor) => ({
    inject: {
      pluginsByKey: {
        [KEY_DESERIALIZE_HTML]: {
          editor: {
            insertData: {
              query: () => {
                const code_line = getPlugin(editor, ELEMENT_CODE_LINE);

                return !someNode(editor, {
                  match: { type: code_line.type },
                });
              },
            },
          },
        },
      },
    },
  }),
  plugins: [
    {
      key: ELEMENT_CODE_LINE,
      isElement: true,
    },
    {
      key: ELEMENT_CODE_SYNTAX,
      isLeaf: true,
      decorate: decorateCodeLine,
    },
  ],
});
