import { ELEMENT_PARAGRAPH } from '@udecode/plate'
import { ELEMENT_IMAGE } from '@Components/shared/object-editor/plugins/media/imagePlugin'
import {
  ELEMENT_H1,
  ELEMENT_H2,
  ELEMENT_H3,
  ELEMENT_H4,
  ELEMENT_H5,
  ELEMENT_H6,
  ELEMENT_TEXT,
  ELEMENT_TITLE,
  ObjectEditorPlatePlugin
} from '@Components/shared/object-editor/ObjectEditor.types'


export const alignPlugin: Partial<ObjectEditorPlatePlugin<any>> = {
  inject: {
    props: {
      validTypes: [
        ELEMENT_TITLE,
        ELEMENT_TEXT,
        ELEMENT_PARAGRAPH,
        ELEMENT_H1,
        ELEMENT_H2,
        ELEMENT_H3,
        ELEMENT_H4,
        ELEMENT_H5,
        ELEMENT_H6,
        ELEMENT_IMAGE,
      ],
    },
  },
}
