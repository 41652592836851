import React from 'react'

import { Stack } from '@mui/material'
import MuiAlert from '@mui/material/Alert'
import Typography from '@mui/material/Typography'
import { defaultRoutes } from '@Constants/config/routeVars'

interface ErrorProps {
  errorType?: string
  children?: any
}

export default class ErrorBoundary extends React.Component<ErrorProps> {
  public state: {
    hasError: boolean
    error: Error | null
    errorInfo: string
    errorType: string
  }

  constructor(props: ErrorProps) {
    super(props)
    this.state = {
      hasError: false,
      error: null,
      errorInfo: '',
      errorType: props.errorType ? props.errorType : 'default',
    }
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch(error: Error, errorInfo) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
    this.setState({
      error,
      errorInfo,
    })
  }

  render() {
    const { error, hasError } = this.state
    if (hasError) {
      const errorText =
        process.env.REACT_APP_ENV === 'development'
          ? `Chunk Error`
          : `Website was updated`
      const errorPrefix =
        process.env.REACT_APP_ENV === 'development'
          ? `It is wrong:`
          : `Unanticipated deviation:`
      const errorString =
        (error && typeof error.toString === 'function' && error.toString()) ||
        'UNKONW ERROR'
      const { errorType, errorInfo } = this.state
      // @ts-expect-error
      const errorStack = errorInfo.componentStack
      const isChunkError = errorString.includes('ChunkLoadError')
      return (
        <Stack sx={{ height: '80vh', width: '100%', pb: 4 }}>
          <MuiAlert
            elevation={6}
            variant="filled"
            severity={isChunkError ? 'warning' : errorType ? 'error' : 'info'}
            sx={{ my: '10vh', py: '10vh', mx: 3 }}
          >
            {isChunkError ? (
              <Typography variant="h6">
                {errorText}, please reload page...
              </Typography>
            ) : (
              <Typography variant="h6">
                {errorPrefix}
                {errorType} - {errorString}
              </Typography>
            )}
            <a href={defaultRoutes.home}>Go HOME</a>
            <details style={{ whiteSpace: 'pre-wrap' }}>
              {errorString}
              <br />
              <pre style={{ whiteSpace: 'pre-wrap' }}>{errorStack}</pre>
            </details>
          </MuiAlert>
        </Stack>
      )
    }

    return this.props.children
  }
}
