import classNames from 'classnames'

import { SxProps } from '@mui/material'
import Typography from '@mui/material/Typography'
import MarkdownViewer from '@Components/shared/markdown/MarkdownViewer'
import { PostVariant } from '@Constants/post'

export type TypographyVariants =
  | 'button'
  | 'caption'
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'inherit'
  | 'overline'
  | 'subtitle1'
  | 'subtitle2'
  | 'body1'
  | 'body2'
  | undefined

export default function PostBody({
  text,
  id,
  variant,
  mini,
  isSubPost,
  limit,
  className = '',
  sx,
}: {
  text?: string
  id?: string
  variant?: PostVariant
  mini?: boolean
  isSubPost?: boolean
  limit?: number
  className?: string
  sx?: SxProps
}) {
  function getTextVariant(txtVariant): TypographyVariants {
    if (text) {
      switch (txtVariant) {
        case 'subpost':
          if (text.length <= 40) {
            return 'subtitle1'
          } else if (text.length <= 120) {
            return 'subtitle1'
          } else if (text.length <= 300) {
            return 'body2'
          } else if (text.length <= 500) {
            return 'body2'
          } else {
            return 'body2'
          }

        case 'post':
          if (text.length <= 40) {
            return 'h5'
          } else if (text.length <= 120) {
            return 'h6'
          } else if (text.length <= 300) {
            return 'body2'
          } else if (text.length <= 500) {
            return 'body2'
          } else {
            return 'body2'
          }

        default:
          return 'body1'
      }
    } else {
      return 'body1'
    }
  }

  const textVariant = variant ? getTextVariant(variant) : getTextVariant('post')

  return (
    <Typography
      className={classNames(className)}
      variant={mini ? 'body2' : textVariant ? textVariant : 'body1'}
      component="article"
      sx={sx}
    >
      <MarkdownViewer value={text} mini={mini} isSubPost={isSubPost} />
    </Typography>
  )
}
