import { ExistingPostData, PartialPostData } from '@Constants/post'
import { Namespace } from '@Constants/types/namespace'
import { RootState } from '@State/store'
import { useAuth } from '@Utils/reactHelpers'
import get from 'lodash/get'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  isEmpty,
  isLoaded,
  OrderByOptions,
  ReduxFirestoreQuerySetting,
  useFirestore,
  useFirestoreConnect,
  WhereOptions,
} from 'react-redux-firebase'

export const usePostId = (
  postId: string
): [
  ExistingPostData | null,
  // Dispatch<SetStateAction<ExistingPostData | null>>,
  boolean
] => {
  const [post, setPost] = useState<ExistingPostData | null>(null)
  const loading = Boolean(!post)
  const firestore = useFirestore()

  useEffect(() => {
    let active = true

    if (!loading) {
      return undefined
    }

    /* eslint @typescript-eslint/no-floating-promises: 0 */
    ;(async () => {
      let res: PartialPostData | null = null
      await firestore
        .collection('postsById')
        .doc(postId)
        .get()
        .then((p) => {
          if (p) {
            res = {
              ...p.data(),
              id: p.id,
            }
          }
        })
        .catch((error) => {
          console.error(`error`, error)
        })

      if (active && res) {
        setPost(res)
      }
    })()

    return () => {
      active = false
    }
  }, [loading, firestore])

  return [post, loading]
}

export const useSubPostId = (
  parentId: string,
  subPostId: string
): [
  ExistingPostData | null,
  // Dispatch<SetStateAction<ExistingPostData | null>>,
  boolean
] => {
  const [subPost, setSubPost] = useState<ExistingPostData | null>(null)

  const loading = Boolean(!subPost)
  const firestore = useFirestore()

  useEffect(() => {
    let active = true

    if (!loading) {
      return undefined
    }

    ;(async () => {
      let res: PartialPostData | null = null
      await firestore
        .collection('postsById')
        .doc(parentId)
        .collection('subPostsById')
        .doc(subPostId)
        .get()
        .then((post) => {
          if (post) {
            res = {
              ...post.data(),
              id: post.id,
            }
          }
        })
        .catch((error) => {
          console.error(`error`, error)
        })

      if (active && res) {
        setSubPost(res)
      }
    })()

    return () => {
      active = false
    }
  }, [loading, firestore])

  return [subPost, loading]
}

export const usePostIdWithSubposts = (
  postId: string,
  getSubPosts = true
): [ExistingPostData | null, ExistingPostData[] | [], boolean] => {
  const [loaded, setLoaded] = useState(false)

  const postQuery: ReduxFirestoreQuerySetting = {
    collection: 'postsById',
    doc: postId,
  }

  const subSort: OrderByOptions[] = [['added', 'asc']]

  /* eslint @typescript-eslint/no-unsafe-member-access: 0 */
  const remotePost = useSelector(
    ({ firestore: { data } }: RootState) => data?.postsById?.[postId]
  )

  let post = null
  const subPosts =
    useSelector(
      ({ firestore: { ordered } }: RootState) =>
        ordered && ordered[`subPosts/${postId}`]
    ) || []

  let query = [postQuery]

  if (remotePost) {
    post = {
      ...remotePost,
      id: postId,
    }
  }

  // Subposts
  if (remotePost && postId && getSubPosts) {
    const subPostQuery: ReduxFirestoreQuerySetting = {
      collection: 'postsById',
      doc: postId,
      subcollections: [
        {
          collection: 'subPostsById',
          orderBy: subSort,
        },
      ],
      storeAs: `subPosts/${postId}`,
    }
    query = [postQuery, subPostQuery]
  }

  useFirestoreConnect(() => query)

  useEffect(() => {
    if (post) {
      setLoaded(true)
    } else if (!remotePost) {
      setLoaded(false)
    }
  }, [post])

  return [post, subPosts, loaded]
}

export const usePostNamespaceWithSubposts = (
  namespace: string,
  getSubPosts?: boolean
): [ExistingPostData | null, ExistingPostData[] | [], boolean] => {
  const [loaded, setLoaded] = useState(false)

  const whereQuery: WhereOptions[] = [
    ['visibility', '==', 'public'],
    ['namespace', '==', namespace],
  ]

  const subSort: OrderByOptions[] = [['added', 'asc']]

  const postQuery: ReduxFirestoreQuerySetting = {
    collection: 'postsById',
    where: [...whereQuery],
    storeAs: `namespaces/${namespace}`,
  }

  const nsItem = useSelector(({ firestore: { ordered } }: RootState) =>
    get(ordered, `namespaces/${namespace}`)
  )
  const post: ExistingPostData = nsItem && nsItem[0]
  const subPosts: ExistingPostData[] = useSelector(
    ({ firestore: { ordered } }: RootState) =>
      ordered && ordered[`subPosts/${post?.id}`]
  )

  let query = [postQuery]

  // Subposts
  if (post?.id && getSubPosts) {
    const subPostQuery: ReduxFirestoreQuerySetting = {
      collection: 'postsById',
      doc: post.id,
      subcollections: [
        {
          collection: 'subPostsById',
          orderBy: subSort,
        },
      ],
      storeAs: `subPosts/${post.id}`,
    }
    query = [postQuery, subPostQuery]
  }

  useFirestoreConnect(() => query)

  useEffect(() => {
    if (post) {
      setLoaded(true)
    } else if (!nsItem) {
      setLoaded(false)
    }
  }, [post])
  // const notExists = isEmpty(nsItem) && isLoaded(nsItem)

  return [post, subPosts, loaded]
}

export const usePostTitleSearch = (
  title: string
): [
  ExistingPostData[],
  Dispatch<SetStateAction<ExistingPostData[]>>,
  boolean
] => {
  const [searchResults, setSearchResults] = useState<ExistingPostData[]>([])
  const loading = Boolean(searchResults.length === 0)
  const firestore = useFirestore()

  const { uid } = useAuth()

  useEffect(() => {
    let active = true

    if (!loading) {
      return undefined
    }

    ;(async () => {
      const results: PartialPostData[] = []
      await firestore
        .collection('postsById')
        .where('title', '>=', title)
        .where('userId', '==', uid)
        .limit(20)
        .get()
        .then((posts) => {
          if (posts.size > 0) {
            posts.docs.forEach((d) => {
              results.push({
                ...d.data(),
                id: d.id,
              })
            })
          }
        })
        .catch((error) => {
          console.error(`error`, error)
        })

      if (active && results) {
        setSearchResults(
          Object.keys(results).map((key) => {
            return results[key]
          })
        )
      }
    })()

    return () => {
      active = false
    }
  }, [loading, firestore, title])

  return [searchResults, setSearchResults, loading]
}

export const usePostNamespaceSearch = (
  namespace: string
): [Namespace[], Dispatch<SetStateAction<Namespace[]>>, boolean] => {
  const [searchResults, setSearchResults] = useState<Namespace[]>([])
  const loading = Boolean(searchResults.length === 0)
  const firestore = useFirestore()

  useEffect(() => {
    let active = true

    if (!loading) {
      return undefined
    }

    ;(async () => {
      const results: PartialPostData[] = []
      await firestore
        .collection('namespacesById')
        .where('namespace', '>=', namespace)
        .limit(20)
        .get()
        .then((posts) => {
          if (posts.size > 0) {
            posts.docs.forEach((d) => {
              results.push({
                ...d.data(),
                id: d.id,
              })
            })
          }
        })
        .catch((error) => {
          console.error(`error`, error)
        })

      if (active && results) {
        setSearchResults(
          Object.keys(results).map((key) => {
            return results[key]
          })
        )
      }
    })()

    return () => {
      active = false
    }
  }, [loading, firestore, namespace])

  return [searchResults, setSearchResults, loading]
}
