import { CSSProperties, forwardRef } from 'react'
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from 'react-router-dom'

import {
  ButtonProps,
  GlobalStyles,
  LinkProps,
  PaletteMode,
  Theme,
  useMediaQuery,
} from '@mui/material'
import { createTheme } from '@mui/material/styles'
import { useTheme } from '@mui/styles'
import makeStyles from '@mui/styles/makeStyles'
import { bottomBarCutoff } from '@Components/ui/BottomBar'

import { brandVars } from './brand'

declare module '@mui/material/styles/createPalette' {
  interface PaletteOptions {
    public?: PaletteColorOptions // Color reserved for any action that affects visibility to public
    danger?: PaletteColorOptions // Color for any dangerous action (should mostly be for non-users)
  }
  interface Palette {
    public: PaletteColor
    danger: PaletteColor
  }
}

declare module '@mui/material' {
  interface ButtonPropsColorOverrides {
    danger
    public
  }
  interface IconButtonPropsColorOverrides {
    danger
    public
  }
  // No worky?
  // interface IconPropsColorOverrides {
  //   danger
  //   public
  // }
}

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    dashed: true
  }
}

declare module '@mui/material/Chip' {
  interface ChipPropsVariantOverrides {
    namespace: true
  }
}

const LinkBehavior = forwardRef<
  any,
  Omit<RouterLinkProps, 'to'> & { href: RouterLinkProps['to'] }
>((props, ref) => {
  const { href, ...other } = props
  // Map href (MUI) -> to (react-router)
  return <RouterLink ref={ref} to={href} {...other} />
})

export const useCreateTheme = (themeName: string) => {
  const isDarkModeEnabled = useMediaQuery('(prefers-color-scheme: dark)')

  const isDarkTheme =
    themeName === 'dark' || (isDarkModeEnabled && themeName === 'default')

  const mainPrimaryColor = isDarkTheme
    ? brandVars.primaryColorDark
    : brandVars.primaryColor
  const mainSecondaryColor = isDarkTheme
    ? brandVars.secondaryColorDark
    : brandVars.secondaryColor
  const mainSuccessColor = isDarkTheme
    ? brandVars.successColorDark
    : brandVars.successColor
  const mainInfoColor = isDarkTheme
    ? brandVars.infoColorDark
    : brandVars.infoColor
  const mainWarningColor = isDarkTheme
    ? brandVars.warningColorDark
    : brandVars.warningColor
  const mainErrorColor = isDarkTheme
    ? brandVars.errorColorDark
    : brandVars.errorColor

  const tName = themeName as 'dark' | 'light' | 'default'
  const finalTheme = tName === 'default' ? 'dark' : (themeName as PaletteMode)
  const dark = finalTheme === 'dark'

  const themeObject = createTheme({
    typography: {
      fontFamily: [
        'Public Sans',
        'Roboto',
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        // '"Helvetica Neue"',
        // 'Arial',
        'sans-serif',
        // '"Apple Color Emoji"',
        // '"Segoe UI Emoji"',
        // '"Segoe UI Symbol"',
      ].join(','),
      // button: {
      //   fontSize: '1rem',
      // },
    },
    palette: {
      mode: finalTheme,
      primary: {
        main: mainPrimaryColor,
      },
      secondary: {
        main: mainSecondaryColor,
      },
      success: {
        main: mainSuccessColor,
      },
      info: {
        main: mainInfoColor,
      },
      warning: {
        main: mainWarningColor,
      },
      error: {
        main: mainErrorColor,
      },
      danger: {
        main: '#F00',
      },
      public: {
        main: '#ffd700',
      },
    },

    // spacing: '8px',
    // components: {
    //   MuiAppBar: {
    //     defaultProps: {
    //       enableColorOnDark: true,
    //     },
    //   },
    // },
    breakpoints: {
      values: {
        xs: 400,
        sm: 700,
        md: 900,
        lg: 1300,
        xl: 1536,
      },
    },
    components: {
      // Name of the component
      MuiSnackbar: {
        defaultProps: {
          autoHideDuration: 4500,
          anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
        },
      },
      MuiLink: {
        defaultProps: {
          component: LinkBehavior,
        } as LinkProps,
      },
      MuiButtonBase: {
        defaultProps: {
          LinkComponent: LinkBehavior,
        },
      },
      MuiChip: {
        styleOverrides: {
          root: {
            borderRadius: 3,
            // paddingTop: 6,
            // paddingBottom: 6,
            // height: 'auto',
          },
        },
        variants: [
          {
            props: { variant: 'namespace' },
            // FIXME: Inherit outlined?
            style: {
              backgroundColor: 'rgba(0,0,0,0)',
              border: '3px solid',
              borderRadius: 6,
              // FIXIT:
              // borderColor: dark ? 'palette.grey.A700' : 'palette.grey.A400',
              borderColor: dark ? '#616161' : '#bdbdbd',
            },
          },
        ],
      },
      MuiTooltip: {
        defaultProps: {
          arrow: true,
          followCursor: true,
          placement: 'top',
          enterDelay: 1000,
        },
      },
      // FIXME: Theme colors not respected
      // MuiAlert: {
      //   variants: [
      //     {
      //       props: { variant: 'filled', severity: 'info' },
      //       style: {
      //         color: 'red',
      //       },
      //     },
      //   ],
      // },
      MuiButton: {
        variants: [
          {
            props: { variant: 'contained', color: 'danger' },
            style: {
              color: 'black', // White too bright for bg color
            },
          },
          {
            props: { variant: 'contained', color: 'public' },
            style: {
              color: 'black', // White too bright for bg color
            },
          },
          {
            props: { variant: 'dashed' },
            style: {
              textTransform: 'none',
              border: `2px dashed`,
            },
          },
          {
            props: { variant: 'dashed', color: 'danger' },
            style: {
              border: `2px dashed red`,
            },
          },
          {
            props: { variant: 'dashed', color: 'warning' },
            style: {
              border: `2px dashed darkorange`,
            },
          },
        ],
        styleOverrides: {
          // Name of the slot
          root: {
            // Some CSS
            // fontSize: '1rem',
          },
        },
      },
    },
  })
  return themeObject
}

export const heroHeight = '25vh'
export const heroHeightLargeDesktop = '33vh'

export const headerHeight = 144
export const headerHeightLargeDesktop = headerHeight

export const topBarHeight = 64 // Check App.css if changing
const bottomSpacing = 32
export const bottomBarHeight = topBarHeight

export function getHeroDims(open: boolean): {
  height: string
  desktopHeight: string
} {
  if (open) {
    return {
      height: heroHeight,
      desktopHeight: heroHeightLargeDesktop,
    }
  } else {
    return {
      height: `calc(${heroHeight} /2)`,
      desktopHeight: `calc(${heroHeightLargeDesktop} /2)`,
    }
  }
}

export function getBodyHeight(desktop?: boolean) {
  const heightMinusBottomBar = topBarHeight + bottomBarHeight
  return `calc(100vh - ${desktop ? topBarHeight : heightMinusBottomBar}px)`
}

export const containerStyles = makeStyles((theme) => ({
  rootSpacing: {
    paddingTop: topBarHeight,
    paddingBottom: 0,
    [theme.breakpoints.down(bottomBarCutoff)]: {
      paddingBottom: bottomBarHeight,
    },
  },
  root: {
    flexGrow: 1,
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: bottomSpacing,
    [theme.breakpoints.down(bottomBarCutoff)]: {
      paddingBottom: bottomBarHeight,
    },
  },
  rootSpacingNoFlex: {
    paddingBottom: bottomSpacing,
    [theme.breakpoints.down(bottomBarCutoff)]: {
      paddingBottom: bottomBarHeight,
    },
  },
  rootMargins: {
    flexGrow: 1,
    flexDirection: 'column',
    display: 'flex',
    width: '100%',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.down(bottomBarCutoff)]: {
      paddingBottom: 2,
    },
  },
}))

export const useBreakpoints = () => {
  const muiTheme = useTheme()

  const matchesSmallest = useMediaQuery(muiTheme.breakpoints.down('xs'))
  const matchesMobile = useMediaQuery(muiTheme.breakpoints.down('sm'))
  const matchesMedium = useMediaQuery(muiTheme.breakpoints.down('md'))
  const matchesLarge = useMediaQuery(muiTheme.breakpoints.down('lg'))
  const matchesLargest = useMediaQuery(muiTheme.breakpoints.up('xl'))

  const largerThanMobile = useMediaQuery(muiTheme.breakpoints.up('sm'))
  const largerThanMedium = useMediaQuery(muiTheme.breakpoints.up('md'))
  const largerThanLarge = useMediaQuery(muiTheme.breakpoints.up('lg'))

  return {
    matchesSmallest,
    matchesMobile,
    matchesMedium,
    matchesLarge,
    matchesLargest,
    largerThanMobile,
    largerThanMedium,
    largerThanLarge,
  }
}

export const inputGlobalStyles = (themeObject: Theme) => (
  <GlobalStyles
    styles={{
      '#root, html, body': {
        backgroundColor: themeObject.palette.background.default,
        '&::-webkit-scrollbar-track': {
          boxShadow: `inset 0 0 6px rgba(0, 0, 0, 0.3)`,
          backgroundColor: themeObject.palette.background.default,
        },
        '&::-webkit-scrollbar': {
          width: 6,
          backgroundColor: themeObject.palette.background.default,
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: themeObject.palette.secondary.main,
        },
      },
      div: {
        '&::-webkit-scrollbar-track': {
          boxShadow: `inset 0 0 6px rgba(0, 0, 0, 0.3)`,
          backgroundColor: themeObject.palette.background.paper,
        },
        '&::-webkit-scrollbar': {
          width: 6,
          backgroundColor: themeObject.palette.background.paper,
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: themeObject.palette.secondary.main,
        },
      },
      a: {
        color: themeObject.palette.primary.light,
      },
    }}
  />
)

export const mainContainerMaxWidth = 'md'

export const defaultStyles = {
  transitionSlowest: '3s all',
  transitionSlower: '2s all',
  transitionSlow: '1s all',
  transitionSmooth: '0.8s all',
  transition: '0.6s all',
  transitionFast: '0.4s all',
  transitionFastest: '0.2 all',
}
