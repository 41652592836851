import map from 'lodash/map'
import React from 'react'
import { connect } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { styled } from '@mui/material/styles'
import Icon from '@mui/material/Icon'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import Tooltip from '@mui/material/Tooltip/Tooltip'
import categories from '@Constants/config/categories'
import { CatData } from '@Constants/types/CatData'
import { changeCatAction } from '@State/actions/storageActions'
import { RootState } from '@State/store'
import { capitalizeFirstLetter } from '@Utils/textFunc'

const PREFIX = 'CatPicker'

const classes = {
  root: `${PREFIX}-root`,
  typesContainer: `${PREFIX}-typesContainer`,
}

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {
    flexGrow: 1,
  },

  [`& .${classes.typesContainer}`]: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}))

function CatPicker({
  catSelected,
  onChange,

  flat,
  size = 'large',
  highlightIcon,

  changeCat,
}: {
  catSelected: string | null
  onChange?: (newCat: string, event: React.MouseEvent) => void

  size?: 'inherit' | 'medium' | 'large' | 'small' | undefined
  flat?: boolean
  highlightIcon?: string

  changeCat: (newCat: string | null) => void
}) {
  const allCats: CatData[] = categories
  // console.log(allCats.length)

  const query = new URLSearchParams(useLocation().search)
  const catQuery = query.get('cat') || catSelected

  // console.log(catSelected)
  const catDataA = [...allCats]
  const catDataB = [...allCats]
  const catDataC = [...allCats]
  const catDataD = [...allCats]
  const catDataE = [...allCats]
  const catDataF = [...allCats]
  const catDataG = [...allCats]

  if (flat) {
    const row = 10
    catDataA.splice(row, allCats.length)

    catDataB.splice(0, row)
    catDataB.splice(row, allCats.length)

    catDataC.splice(0, row * 2)
    catDataC.splice(row, allCats.length)

    catDataD.splice(0, row * 3)
    catDataD.splice(row, allCats.length)

    catDataE.splice(0, row * 4)
    catDataE.splice(row, allCats.length)
  } else {
    const row = 6
    catDataA.splice(row, allCats.length)

    catDataB.splice(0, row)
    catDataB.splice(row, allCats.length)

    catDataC.splice(0, row * 2)
    catDataC.splice(row, allCats.length)

    catDataD.splice(0, row * 3)
    catDataD.splice(row, allCats.length)

    catDataE.splice(0, row * 4)
    catDataE.splice(row, allCats.length)

    catDataF.splice(0, row * 5)
    catDataF.splice(row, allCats.length)

    catDataG.splice(0, row * 6)
    catDataG.splice(row, allCats.length)
  }

  function catChange(cat: string, e: React.MouseEvent) {
    e.stopPropagation()
    changeCat(cat)
    if (onChange) onChange(cat, e)
  }

  const catRow = (cats: CatData[]) => {
    return (
      <ToggleButtonGroup
        value={catQuery || catSelected}
        exclusive
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        onChange={(e, v) => catChange(v, e)}
        aria-label="category grid"
      >
        {map(cats, (cat, i) => {
          // console.log(type.val, catSelected)
          return (
            <Tooltip
              key={i}
              title={capitalizeFirstLetter(cat.val)}
              placement="top"
              followCursor
            >
              <ToggleButton
                value={cat.val}
                aria-label={cat.val}
                // disabled={type.disabled}
              >
                <Icon
                  fontSize={size}
                  color={
                    highlightIcon && cat.val === catSelected
                      ? 'secondary'
                      : cat.val === catQuery
                      ? 'primary'
                      : 'inherit'
                  }
                >
                  {cat.icon}
                </Icon>
              </ToggleButton>
            </Tooltip>
          )
        })}
      </ToggleButtonGroup>
    )
  }

  return (
    <Root className={classes.root}>
      <div className={classes.typesContainer}>
        {catRow(catDataA)}
        {catRow(catDataB)}
        {catRow(catDataC)}
        {catRow(catDataD)}
        {catRow(catDataE)}
        {!flat ? catRow(catDataF) : null}
        {!flat ? catRow(catDataG) : null}
      </div>
    </Root>
  )
}

const mapStateToProps = (state: RootState /*, ownProps*/) => {
  return {
    // auth: state.firebase?.auth,
    theme: state.storage?.theme,
    catSelected: state.storage?.catSelected,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    changeCat: (value: string) => dispatch(changeCatAction(value)),
  }
}
// @ts-expect-error
export default connect(mapStateToProps, mapDispatchToProps)(CatPicker)
