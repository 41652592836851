import { PostData } from '@Constants/post'
import { NAMESPACE_UPDATED, NamespaceActionTypes, UPDATE_NAMESPACE } from '@State/actions/namespaceActions'
import {
  ADD_TO_POST,
  CREATE_POST,
  DELETE_POST,
  DELETE_SUB_POST,
  EDIT_POST,
  EDIT_SUB_POST,
  POST_ADDED_TO,
  POST_CREATED,
  POST_DELETED,
  POST_EDITED,
  POST_PUBLISHED,
  PostActionTypes,
  POSTS_SEARCH_RESULTS,
  POSTS_SEARCHED,
  PUBLISH_POST,
  SEARCH_POSTS,
  SUB_POST_DELETED,
  SUB_POST_EDITED,
  LINK_POST,
  POST_LINKED,
} from '@State/actions/postActions'

// constants.actionsPrefix === '@@reactReduxFirebase'
// console.log(constants.actionTypes)
// type ReduxFirebaseActionTypes = any
// actionTypes.SET_LISTENER
// actionTypes.LISTENER_RESPONSE

export type PostReducerState = {
  loading: boolean;
  listening: boolean;
  postsSearching: boolean;
  postsSearchQuery: string;
  postsSearchResult: PostData[];
}


export default function postReducer(
  state: PostReducerState = {
    loading: false,
    listening: false,
    postsSearching: false,
    postsSearchQuery: '',
    postsSearchResult: []
  },
  action: PostActionTypes | NamespaceActionTypes // | ReduxFirebaseActionTypes
): PostReducerState {
  // const { SET_LISTENER } = constants.actionTypes
  switch (action.type) {
    case CREATE_POST:
    case ADD_TO_POST:
    case EDIT_POST:
    case EDIT_SUB_POST:
    case DELETE_POST:
    case DELETE_SUB_POST:
    case PUBLISH_POST:
    case UPDATE_NAMESPACE:
    case LINK_POST:
      // case '@@reduxFirestore/SET_LISTENER':
      return {
        ...state,
        loading: true
      }
    // case SET_LISTENER:
    //   if (action.meta && action.meta.collection === 'postsById') {
    //     return {
    //       ...state,
    //       listening: false
    //     }
    //   }
    //   return state;
    case POST_CREATED:
    case POST_ADDED_TO:
    case POST_EDITED:
    case SUB_POST_EDITED:
    case POST_DELETED:
    case SUB_POST_DELETED:
    case POST_PUBLISHED:
    case NAMESPACE_UPDATED:
    case POST_LINKED:
      return {
        ...state,
        loading: false
      }
    // case `@@reactReduxFirebase/LISTENER_RESPONSE`:
    //   if (action.meta && action.meta.collection === 'postsById') {
    //     return {
    //       ...state,
    //       listening: true
    //     }
    //   }
    //   return state;

    case SEARCH_POSTS:
      return {
        ...state,
        postsSearching: true,
        postsSearchQuery: action.query,
        postsSearchResult: []
      }
    case POSTS_SEARCHED:
      return {
        ...state,
        postsSearchQuery: '',
        postsSearching: false
      }
    case POSTS_SEARCH_RESULTS:
      // console.log('POSTS_SEARCH_RESULTS', action)
      return {
        ...state,
        postsSearchResult: action.results
      }
    default:
      return state;
  }
}
