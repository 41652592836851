import { styled } from '@mui/material/styles'
import { getEmbedVideo, MetaMedia } from '@Utils/metaFunc'
import React from 'react'

const PREFIX = 'VideoEmbed'

const classes = {
  videoContainer: `${PREFIX}-videoContainer`,
  video: `${PREFIX}-video`,
}

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.videoContainer}`]: {
    position: 'relative',
    width: '100%',
    height: '0',
    paddingBottom: '61%',
  },

  [`& .${classes.video}`]: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    left: 0,
    top: 0,
  },
}))

export default function VideoEmbed({ url }) {
  let metaMedia: MetaMedia | null

  if (url) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    metaMedia = getEmbedVideo(url)
    return (
      <Root className={classes.videoContainer}>
        {metaMedia &&
        metaMedia.brand === 'youtube' &&
        (metaMedia.type === 'video' || metaMedia.type === 'short') &&
        metaMedia.id ? (
          <iframe
            className={classes.video}
            title={`Youtube: ${metaMedia.id}`}
            width="auto"
            height="auto"
            src={metaMedia.embedVideo}
            frameBorder="0"
            // allow="autoplay; clipboard-write; encrypted-media; picture-in-picture"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          />
        ) : null}
      </Root>
    )
  } else {
    return null
  }
}
