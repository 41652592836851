export const getParentSize = (className: string): { width: number } => {
  const parentComponent = document.getElementsByClassName(className)
  // console.warn(`🚀 ~ getParentSize ~ parentComponent`, parentComponent)
  const firstComp = parentComponent[0] as HTMLElement
  // console.warn(`🚀 ~ getParentSize ~ firstComp`, firstComp)
  // console.warn(`🚀 ~ ImageElement ~ parentComponent`, parentComponent)
  if (className && parentComponent && firstComp?.offsetWidth) {
    const newWidth = firstComp.offsetWidth - 64 // Minus 2x padding-ish
    return {
      width: newWidth,
    }
  } else {
    return {
      width: 0,
    }
  }
}
