import { ELEMENT_DEFAULT } from '@udecode/plate'
import {
  PlateEditor,
  TElement,
  Value,
  WithPlatePlugin,
  getNode,
  insertElements,
  isElement,
  setElements
} from '@udecode/plate-core'

import { NormalizeTypesPlugin } from './createNormalizeTypesPlugin'


export const withNormalizeTypes = <
  V extends Value = Value,
  E extends PlateEditor<V> = PlateEditor<V>
>(
  editor: E,
  { options: { rules, onError } }: WithPlatePlugin<NormalizeTypesPlugin, V, E>
) => {
  const { normalizeNode } = editor;

  editor.normalizeNode = ([currentNode, currentPath]) => {
    if (!currentPath.length && rules) {
      const endCurrentNormalizationPass = rules.some(
        ({ exclusive, exclusiveReplacement, strictType, type, path }) => {

          if (exclusive) {
            // Replace any exclusive elements with ELEMENT_DEFAULT type or with exclusiveReplacement type
            editor?.children?.forEach((n, nodeRootPath) => {
              if (isElement(n) && n.type === strictType) {
                if (nodeRootPath !== path[0]) {
                  setElements(
                    editor,
                    { type: exclusiveReplacement || ELEMENT_DEFAULT },
                    {
                      at: [nodeRootPath],
                    }
                  );
                }
              }
            })
            // IDK
            // return true
          }

          const node = getNode<TElement>(editor, path);
          if (node) {
            if (strictType && isElement(node) && node.type !== strictType) {
              setElements(
                editor,
                { type: strictType },
                {
                  at: path,
                }
              );
              return true;
            }
          } else {
            try {
              insertElements(
                editor,
                {
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  type: strictType ?? type!,

                  children: [{ text: '' }],
                },
                { at: path }
              );
              return true;
            } catch (err) {
              onError?.(err);
            }
          }


          return false;
        }
      );

      if (endCurrentNormalizationPass) {
        return;
      }
    }

    return normalizeNode([currentNode, currentPath]);
  };

  return editor;
};
