import firebase from 'firebase/compat/app'
import { ofType } from 'redux-observable'
import { mapTo, mergeMap } from 'rxjs/operators'

import { epicRoutes } from '@Constants/config/routeVars'
import { sendNotifcationAction } from '@State/actions/appStateActions'
/* eslint-disable */
import { NAMESPACE_UPDATED, UPDATE_NAMESPACE } from '@State/actions/namespaceActions'
import { firestore } from '@State/firebase'


export const updateNamespaceEpic = (action$, store, dependencies) => action$.pipe(
  ofType(UPDATE_NAMESPACE),
  // @ts-ignore
  mergeMap(({ namespace, publish, id, uid, data, handleClose }) => {
    // console.log("Saving namespace: ", namespace);
    if (namespace && namespace.length > 0) {
      return updateNamespaces({
        namespace,
        publish,
        id,
        uid,
        type: data.type,
        cat: data.cat,
        dependencies
      })
        // .then(() => {
        //   return handleClose && handleClose(publish, namespace)
        // })
        // @ts-ignore
        .then(() => {
          // console.log(publish, namespace)
          // if (publish && namespace) {
          //   dependencies.history.push(epicRoutes.afterPublishNamespace(namespace))
          // } else {
          //   dependencies.history.push(epicRoutes.afterPublishNoNamespace(id))
          // }

        })
    } else {
      // handleClose && handleClose(publish)
      // if (publish) {
      //   dependencies.history.push(epicRoutes.afterPublishViewId(id))
      // }
    }
  }),
  mapTo({ type: NAMESPACE_UPDATED })
)

const updateNamespaces = ({
  namespace,
  publish,
  id,
  uid,
  type,
  cat,
  dependencies
}) => {
  // console.log(namespace)
  const namespacesByName = firestore
    .collection('namespacesByName') // So you can target a namespace literally
    .doc(namespace)

  const namespacesById = firestore
    .collection('namespacesById') // So you can serach in .namespace, sorta...
    .doc(id)

  if (publish) {
    return firestore.runTransaction((transaction) => {
      const timestamp = firebase.firestore.FieldValue.serverTimestamp()
      return transaction.get(namespacesByName).then((nsDoc) => {
        if (!nsDoc.exists) {
          return transaction.set(namespacesByName, {
            id,
            uid,
            type,
            cat,
            edited: timestamp,
            namespace
          })
        } else {
          dependencies.store.dispatch(sendNotifcationAction(`Unique event: Namespace was taken in the meantime :(`, 'warning'))
        }
      }).then(() => {
        return firestore.runTransaction((transaction2) => {
          return transaction2.get(namespacesById).then((nsDoc) => {
            if (!nsDoc.exists) {
              const timestamp2 = firebase.firestore.FieldValue.serverTimestamp()
              return transaction2.set(namespacesById, {
                namespace,
                uid,
                type,
                cat,
                edited: timestamp2
              })
            } else {
              dependencies.store.dispatch(sendNotifcationAction(`Unique event: Namespace was taken in the meantime :(`, 'warning'))
            }
          })
        })
      })
    })
  } else {
    return namespacesByName.delete().then(() => namespacesById.delete())
  }

}
