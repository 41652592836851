import { PostData } from '@Constants/post'

export const PUBLISH_TAGS = 'PUBLISH_TAGS'
export const UNPUBLISH_TAGS = 'UNPUBLISH_TAGS'


interface PublishTagsAction {
  type: typeof PUBLISH_TAGS
  uid: string
  id: string
  data: Partial<PostData>
}
interface UnpublishTagsAction {
  type: typeof UNPUBLISH_TAGS
  uid: string
  id: string
  data: Partial<PostData>
}

export function publishTagsAction({ uid, id, data }: Omit<PublishTagsAction, 'type'>): PublishTagsAction {
  return {
    type: PUBLISH_TAGS,
    uid,
    id,
    data
  }
}

export function unpublishTagsAction({ uid, id, data }: Omit<UnpublishTagsAction, 'type'>): UnpublishTagsAction {
  return {
    type: UNPUBLISH_TAGS,
    uid,
    id,
    data
  }
}

export type TagActionTypes =
  PublishTagsAction |
  UnpublishTagsAction
