import { SxProps } from '@mui/material'
import Box from '@mui/system/Box'
import { Plate, TEditableProps } from '@udecode/plate'
import {
  ObjectEditorType,
  ObjectEditorValue,
} from '@Components/shared/object-editor/ObjectEditor.types'
import { ExistingPostData } from '@Constants/post'

export const readOnlyProps: TEditableProps<ObjectEditorValue> = {
  spellCheck: false,
  autoFocus: false,
  readOnly: true,
  placeholder: 'No text…',
}

interface ReadOnlyObjectBodyProps {
  id?: string
  post?: ExistingPostData
  debug?: boolean
  sx?: SxProps
}

export const ReadOnlyObjectBody = ({
  id,
  sx = {},
}: ReadOnlyObjectBodyProps) => {
  return (
    <Box sx={sx}>
      <Plate<ObjectEditorValue, ObjectEditorType>
        id={id}
        editableProps={readOnlyProps}
      ></Plate>
    </Box>
  )
}
