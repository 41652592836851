import { forwardRef, useLayoutEffect, useState } from 'react'
import { TextareaAutosizeProps } from 'react-textarea-autosize'

import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { useCaptionState } from '@udecode/plate'
import Paper from '@mui/material/Paper'

/**
 * `<textarea />` component for React which grows with content.
 *
 * @see https://github.com/Andarist/react-textarea-autosize
 * @see https://github.com/Andarist/react-textarea-autosize/issues/337
 */
export const TextareaAutosize = forwardRef<
  HTMLTextAreaElement,
  TextareaAutosizeProps
>((props, ref) => {
  const [isRerendered, setIsRerendered] = useState(false)
  const { readOnly } = props

  useLayoutEffect(() => setIsRerendered(true), [])

  return isRerendered && !readOnly ? (
    // <TextareaAutosizer maxRows={4} {...props} ref={ref} />
    <Paper
      sx={{
        mt: '0.5rem',
        resize: 'none',
        textAlign: 'center',
        p: 1,
        width: '50%',
      }}
      variant="outlined"
    >
      <TextField
        variant="standard"
        label="Caption"
        // @ts-expect-error because
        ref={ref}
        // multiline
        // maxRows={2}
        {...props}
        style={{
          width: '100%',
        }}
      />
    </Paper>
  ) : (
    <Typography
      variant="caption"
      className={props.className}
      style={props.style}
    >
      {props.value}
    </Typography>
  )
})
