import firebase from 'firebase/compat/app';
import { firestore } from '@State/firebase';
import { getSavablePaths } from '@Utils/savableUrl';
import urlFunc from '@Utils/urlFunc';
import { UriData } from '@State/reducers/uriReducer';
import { FirebaseDate } from '@Utils/postDataFunc';
import { DocumentData, QueryDocumentSnapshot } from 'firebase/firestore';

const domainTransaction = (uri: string, userId?: string, remove?: boolean) => {
  const uriPath = firebase
    .database()
    .ref('uri');

  const urlObj = urlFunc(uri);
  // console.log('domainTransaction', urlObj)
  if (urlObj && urlObj.tld) {
    // TODO: update domain meta
    // Save Domain
    const domainPath = uriPath
      .child(urlObj.tld)
      .child('_tld');

    return domainPath.transaction((tldPathPost: UriData) => {
      const timestamp = firebase.database.ServerValue.TIMESTAMP as FirebaseDate
      let newTldPathPost: UriData = { ...tldPathPost }
      if (newTldPathPost) {
        if (remove) {
          newTldPathPost.urlCount--;
          newTldPathPost.edited = timestamp;
        } else {
          newTldPathPost.urlCount++;
          newTldPathPost.edited = timestamp;
        }
      } else {
        // @ts-expect-error
        newTldPathPost = {
          urlCount: 1,
          created: timestamp,
          domain: urlObj.host,
          ...(userId && {
            userId
          })
        };
      }
      return tldPathPost;
    });
  } else {
    console.error("domainTransaction: no tld");
    return new Promise(() => { return; });
  }
};


const uriTransaction = (uri: string, userId?: string, remove?: boolean) => {
  const uriPath = firebase
    .database()
    .ref('uri');

  const savablePath = getSavablePaths(uri);
  // const urlObj = urlFunc(uri)
  // console.log('uriTransaction', urlObj)
  const savePathRef = uriPath
    .child(savablePath)
    .child('_uri');

  return savePathRef.transaction((uriPathPost: UriData) => {
    // console.log('uriPathPost', uriPathPost)
    // console.log('savablePath', savablePath)
    const timestamp = firebase.database.ServerValue.TIMESTAMP as FirebaseDate
    if (uriPathPost && (uriPathPost && uriPathPost.hasOwnProperty('postCount') || uriPathPost.postCount === 0)) {
      if (remove) {
        uriPathPost.postCount--;
        uriPathPost.edited = timestamp;
      } else {
        uriPathPost.postCount++;
        uriPathPost.edited = timestamp;
      }
    } else {
      // @ts-expect-error
      uriPathPost = {
        postCount: 1,
        created: timestamp,
        uri,
        ...(userId && {
          userId
        })
      };
    }
    return uriPathPost;
  });
};

export const uriAndDomainTransactions = (uri: string, userId?: string, remove?: boolean) => {
  return domainTransaction(uri, userId, remove)
    .then(() => {
      return uriTransaction(uri, userId, remove);
    });
};

const setAndUpdateUriRef = (uri: string, postId: string, userId: string, uriPostRef: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>, uriDocRefId: string, exists: boolean, namespace?: string): Promise<string> => {
  // console.log('newUriDoc', uri, postId)
  const timestamp = firebase.firestore.FieldValue.serverTimestamp();
  // Save document, then update firebase path with uriRef
  return uriPostRef
    .set({
      uri,
      added: timestamp,
      postId,
      userId,
      namespace
    }, { merge: true }).then(() => {
      console.log('uriDocRefId', uriDocRefId)
      const savablePath = getSavablePaths(uri);
      const fbPath = firebase
        .database()
        .ref('uri')
        .child(savablePath)
        .child('_uri');

      const idToSave = uriDocRefId
      if (exists) {


        // MEH: This is wrong and probably not needed, although we might need it for
        // const uriObj = urlFunc(uri);
        // const { params } = uriObj;
        // fbPath.child(postId).update({
        //   uriRef: idToSave,
        //   uri,
        //   userId,
        //   postId,
        //   namespace,
        // //   edited: timestamp,
        //   ...(params && { ...params })
        // }).then(() => {
        //   // TODO: Chain with the rest, and account for void
        //   console.warn(`🚀 ~ fbPath.child ~ addRefRef`)
        // }).catch((e) => {
        //   console.error('uri error: ', e)
        // })

        // Add ref to postId
        return fbPath.update({
          uriRef: idToSave
        }).then(() => {
          return idToSave;
        })

      } else {
        // Update ref
        return fbPath.update({
          uriRef: idToSave
        }).then(() => {
          return idToSave
        })

      }
    });
};

export const createOrUpdateUriDoc = (uri: string, postId: string, userId: string, exists: boolean, uriDocRefId: string, postCount?: number, namespace?: string): Promise<string> => {
  // const urlObj = urlFunc(uri)
  // console.log('newUriDoc urlObj', urlObj)
  // console.log('newUriDoc', uri, postId)
  const timestamp = firebase.firestore.FieldValue.serverTimestamp();
  let docTask: Promise<firebase.firestore.DocumentReference<firebase.firestore.DocumentData>>
  let uriCollection: firebase.firestore.CollectionReference<firebase.firestore.DocumentData>
  let uriDocCollection: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>

  if (exists) {
    console.log('EXISTS');
    uriDocCollection = firestore
      .collection('urisById')
      .doc(uriDocRefId);

    const uriData = {
      edited: timestamp,
      postCount
    };
    // @ts-ignore
    docTask = uriDocCollection.set(uriData, { merge: true })
  } else {
    console.log('NEW');
    uriCollection = firestore
      .collection('urisById');

    const uriData = {
      uri,
      created: timestamp,
      userId,
      postCount: 1
    };
    docTask = uriCollection.add(uriData)
  }

  return docTask.then((uriDocRef: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>) => {
    console.log(`uriDocRef ${exists ? 'Exists' : 'Not Exist'}`, uriDocRef && uriDocRef.id)
    const remoteUriDocRefId = uriDocRef && uriDocRef.id || uriDocRefId;
    let uriPostRef: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>
    if (exists) {
      uriPostRef = uriDocCollection
        .collection('postsById')
        .doc(postId);
    } else {
      uriPostRef = uriCollection
        .doc(remoteUriDocRefId)
        .collection('postsById')
        .doc(postId);
    }
    // ADD Post to collection
    return setAndUpdateUriRef(uri, postId, userId, uriPostRef, remoteUriDocRefId, exists, namespace);
  })
}
