import {
  heroHeight,
  heroHeightLargeDesktop,
  useBreakpoints,
} from '@Constants/config/globalStyles'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import { useTheme } from '@mui/material/styles'
import React from 'react'
import { Timeline, Tweet } from 'react-twitter-widgets'

// @ts-expect-error Because no type defined?
import { eagerLoadTwitterLibrary } from 'react-twitter-widgets'

// eslint-disable-next-line @typescript-eslint/no-unsafe-call
eagerLoadTwitterLibrary()

export type TwitterEmbedProps = {
  tweetId: string
}

export const TwitterEmbed: React.FC<TwitterEmbedProps> = ({ tweetId }) => {
  const theme = useTheme()
  const { matchesLarge, matchesLargest } = useBreakpoints()
  const isDesktop = matchesLarge || matchesLargest
  const tweetHeight = isDesktop ? heroHeightLargeDesktop : heroHeight
  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      sx={{ height: '100%', width: '100%' }}
    >
      <Box>
        <Tweet
          tweetId={tweetId}
          onLoad={() => {
            console.log('Tweet loaded: ', tweetId)
          }}
          renderError={() =>
            `Your browser is blocking embeds, try disabling adblock. Or if you're using Brave: https://stackoverflow.com/a/73248929/1055809`
          }
          options={{
            theme: theme.palette.mode,
            width: 'auto',
            height: `calc(${tweetHeight} - 12px)`, // FIXME: height not working?
            dnt: true,
          }}
        />
        {/* <Timeline
        dataSource={{ sourceType: 'profile', screenName: 'elonmusk' }}
        onLoad={(e) => console.log(e)}
        renderError={() => 'Unable to fetch Timeline'}
        options={{
          theme: theme.palette.mode,
          width: '100%',
          height: 300,
        }}
      /> */}
      </Box>
    </Stack>
  )
}
