import debounce from 'lodash/debounce'
import throttle from 'lodash/throttle'
import {
  ReactChild,
  ReactChildren,
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react'
import { useStore } from 'react-redux'
import { CSSProp } from 'styled-components'

/* eslint-disable */
import { RootState } from '@State/store'


export type TypesOfReactChildren = ReactChildren | ReactElement | ReactNode | ReactChild


function useIsMounted() {
  const isMountedRef = useRef(true);
  useEffect(() => {
    return () => {
      isMountedRef.current = false;
    };
  }, []);
  return () => isMountedRef.current;
}

export function useDebounce(cb: () => void, delay: number) {
  const options = {
    leading: false,
    trailing: true
  };
  const inputsRef = useRef(cb);
  const isMounted = useIsMounted();
  useEffect(() => {
    // @ts-expect-error
    inputsRef.current = { cb, delay };
  });

  return useCallback(
    debounce(
      (...args) => {
        // Don't execute callback, if (1) component in the meanwhile
        // has been unmounted or (2) delay has changed
        // @ts-expect-error
        if (inputsRef.current.delay === delay && isMounted())
          // @ts-expect-error
          inputsRef.current.cb(...args);
      },
      delay,
      options
    ),
    [delay, debounce]
  );
}


export function useThrottle(cb, delay: number) {
  const options = { leading: true, trailing: false }; // pass custom lodash options
  const cbRef = useRef(cb);
  useEffect(() => {
    cbRef.current = cb;
  });
  return useCallback(
    throttle((...args) => cbRef.current(...args), delay, options),
    [delay]
  );
}

export function useDebounceAlt(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);
  const firstDebounce = useRef(true);

  useEffect(() => {
    if (value && firstDebounce.current) {
      setDebouncedValue(value);
      firstDebounce.current = false;
      return;
    }

    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => clearTimeout(handler);
  }, [value, delay]);

  return debouncedValue;
}

export const useDebouncedEffect = (effect, deps, delay) => {
  useEffect(() => {
    const handler = setTimeout(() => effect(), delay);

    return () => clearTimeout(handler);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...(deps || []), delay]);
}


interface StyleProp {
  [styleKey: string]: string | number | StyleProp
}



// Css helpers
export const getEachPropFromArray = (propArray: string[]) => {
  let styleObject = {}
  if (Array.isArray(propArray)) {
    propArray.forEach((propString: string | boolean) => {
      if (typeof propString === 'string') {
        const newProp = getStringprops(propString)
        // console.log(`🚀 ~ propArray.forEach ~ newProp`, newProp)
        styleObject = {
          ...styleObject,
          ...newProp
        }
      }
    })
  }
  return styleObject
}

const getStringprops = (cssProp: CSSProp) => {
  // console.log(`🚀 ~ getStringprops ~ cssProp`, cssProp)
  const styleProp: StyleProp = {}
  const splitted = cssProp.split(':')
  if (splitted.length === 2) {
    // console.warn(`🚀 string prop saved:`, splitted)
    styleProp[splitted[0]] = splitted[1]
  } else {
    // We're missing a bit of the css that Plate provides us, but we'll add our own
    // console.warn('Invalid', cssProp, splitted)
  }
  return styleProp
}

// Helper because css prop doesn't take
export const flattenCss = (cssArrays?: CSSProp[]) => {
  let styleProps: StyleProp = {}
  if (typeof cssArrays === 'object') {
    // console.groupCollapsed(`🚀 cssArrays`, cssArrays)
    cssArrays?.forEach((cssProps) => {
      const cssKeys = Object.keys(cssProps)
      if (
        cssKeys &&
        cssKeys.length >= 1 &&
        cssProps &&
        typeof cssProps === 'object' &&
        // Not Array
        !Array.isArray(cssProps)
      ) {
        // console.group(`🚀 cssKeys`, cssKeys)
        cssKeys.forEach((propName) => {
          const propValue = cssProps[propName]
          if (typeof propValue === 'number' || typeof propValue === 'string') {
            // console.log(`🚀 prop saved:`, propName, propValue)
            styleProps[propName] = propValue
          }
        })
        // console.groupEnd()
      }
      // String or Array
      else {
        if (typeof cssProps === 'string') {
          // console.group('String:', cssProps)
          const strProps = getStringprops(cssProps)
          styleProps = {
            ...styleProps,
            strProps,
          }
          // console.groupEnd()
        } else if (Array.isArray(cssProps)) {
          // console.group('Array:', cssProps)
          cssProps.forEach((propName) => {
            const propValue = cssProps[propName]
            if (
              typeof propValue === 'number' ||
              typeof propValue === 'string'
            ) {
              // console.log(`🚀 number or string prop saved:`, propName, propValue)
              styleProps[propName] = propValue
            } else {
              // console.warn("Else", propName)
            }
          })
          // console.groupEnd()
        } else if (typeof cssProps === 'boolean') {
          // console.log('boolean')
        } else {
          console.warn('Unhandled css:', cssProps)
        }
      }
    })
    // console.groupEnd()
  }
  return styleProps
}


export const useAuth = () => {
  const store = useStore()
  const state = store.getState() as RootState
  const {
    firebase: {
      auth
    },
  } = state
  return auth
}