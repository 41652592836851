import { useFocused, useReadOnly, useSelected } from 'slate-react'

import Tooltip from '@mui/material/Tooltip'
import { useElement } from '@udecode/plate'
import { Box } from '@udecode/plate-core'
import { ElementPopover } from '@udecode/plate-floating'
import { PlateFloatingMedia } from '@Components/shared/object-editor/components/MediaEmbedElement'
import { Caption } from '@Components/shared/object-editor/plugins/caption'
import {
  ELEMENT_IMAGE,
  Image,
  TImageElement,
} from '@Components/shared/object-editor/plugins/media/imagePlugin'
import { Media } from '@Components/shared/object-editor/plugins/MediaElements'
import { flattenCss } from '@Utils/reactHelpers'

import { mediaFloatingOptions } from '../mediaFloatingOptions'
import { getParentSize } from './getParentSize'
import { getImageElementStyles } from './ImageElement.styles'
import { ImageElementProps } from './ImageElement.types'

export const ImageElement = (props: ImageElementProps) => {
  const {
    children,
    nodeProps,
    caption = {},
    resizableProps,
    align = 'center',
    ignoreReadOnly = false,
  } = props
  const { as, ...rootProps } = props

  const element = useElement<TImageElement>()
  const { relativeWidth } = element
  const focused = useFocused()
  const selected = useSelected()
  const readOnly = useReadOnly()

  const styles = getImageElementStyles({
    ...props,
    align: element.align as undefined,
    focused,
    selected,
  })

  // Needed to get & save relative width of image when resizing
  const parentSize = getParentSize('plate-body')

  const parentWidth = parentSize.width
  // Check if still loading (fix init state)
  return (
    <ElementPopover
      content={<PlateFloatingMedia pluginKey={ELEMENT_IMAGE} />}
      floatingOptions={mediaFloatingOptions}
    >
      <Media.Root
        style={{
          ...flattenCss(styles?.root?.css),
          overflow: 'hidden', // Avoid x-scroll on slightly too big initial relativeWidth
        }}
        {...rootProps}
      >
        <figure
          style={{
            ...flattenCss(styles?.figure?.css),
            position: 'relative',
            width: '100%', // Fix resize for Images
            overflowX: 'hidden',
          }}
          className={`group ${styles?.figure?.className || ''}`}
          contentEditable={false}
        >
          <Media.Resizable
            parentWidth={parentWidth}
            style={{
              ...flattenCss(styles?.resizable?.css),
              ...(element.align === 'justify' && { marginRight: 0 }),
              ...(element.align === 'right' && { marginRight: 0 }),
              ...(!element.align && { marginLeft: 0 }),
            }}
            className={styles?.resizable?.className}
            handleComponent={{
              left: <Box className={styles?.handleLeft?.className} />,
              right: <Box className={styles?.handleRight?.className} />,
            }}
            align={align}
            readOnly={!ignoreReadOnly && readOnly}
            {...resizableProps}
          >
            <Tooltip
              title={
                // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                `Width: ${relativeWidth}%`
              }
            >
              <Image
                style={flattenCss(styles?.img?.css)}
                className={styles?.img?.className}
                {...nodeProps}
              />
            </Tooltip>
          </Media.Resizable>

          {!caption.disabled && (
            <Caption.Root
              style={{
                ...flattenCss(styles?.figcaption?.css),
                display: 'flex',
                justifyContent: 'center',
              }}
              className={styles?.figcaption?.className}
            >
              <Caption.Textarea
                style={{ ...flattenCss(styles?.caption?.css), width: '50%' }}
                className={styles?.caption?.className}
                placeholder={caption.placeholder ?? 'Caption this'}
                readOnly={(!ignoreReadOnly && readOnly) || !!caption.readOnly}
              />
            </Caption.Root>
          )}
        </figure>

        {children}
      </Media.Root>
    </ElementPopover>
  )
}
