import { GeoPoint } from 'firebase/firestore'
import { geohashForLocation } from 'geofire-common'
import { Coordinate } from 'ol/coordinate'

import { AnyPostData, ExistingPostData } from '@Constants/post'
import { GeoData } from '@Constants/types/geo'
import {
  LatLongArray,
  LongLatArray,
  defaultLatLong,
  defaultLongLat
} from '@Constants/types/location'


function gpsSuccess(gps: GeolocationPosition): LongLatArray {
  // console.log('gpsSuccess', gps)
  const coords = gps.coords
  return [coords.longitude, coords.latitude]
}

function gpsError(error: { [key: string]: any }) {
  switch (error.code) {
    case error.PERMISSION_DENIED:
      return "User denied the request for Geolocation."
    case error.POSITION_UNAVAILABLE:
      return "Location information is unavailable."
    case error.TIMEOUT:
      return "The request to get user location timed out."
    case error.UNKNOWN_ERROR:
    default:
      return "An unknown error occurred."

  }
}

export const geoArrayFromString = (longlat: string): number[] => longlat ? longlat.split(',').map((num) => parseFloat(num)) : defaultLongLat

export const getGeoFromLonLat = (lonlat: string): GeoData => {
  const geoArray = geoArrayFromString(lonlat)
  return {
    lon: geoArray[0],
    lat: geoArray[1],
  }
}

export const getGeoFromLonLatArray = (lonlat: number[]): GeoData => {
  return {
    lon: lonlat[0],
    lat: lonlat[1],
  }
}

export const locationCoords = (item?: Partial<ExistingPostData>): number[] => {
  // console.log(item)
  if (item) {
    if (item.longlat) {

      if (typeof item.longlat === 'string') {
        const longlat = item.longlat as string
        const cordsSplit = longlat.split(',').map((num) => parseFloat(num))

        return cordsSplit
      } else {
        return item.longlat
      }
    } else if (item.text) {
      const cordsSplit = item.text.split(',').map((num) => parseFloat(num))

      return cordsSplit
    } else {
      return defaultLongLat
    }
  } else {
    return defaultLongLat
  }
}


export function getLocation(cb: (geoCoordsArray: LongLatArray | string) => void) {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition((gps) => {
      const finalGPS = gpsSuccess(gps)
      cb(finalGPS)
    }, (err) => {
      const error = gpsError(err)
      cb(error)
    })
  } else {
    return "Geolocation not supported by this browser."
  }
}


export const getGeoData = (post: ExistingPostData) => {
  // Transform lonlat into geo
  if (post.longlat) {
    // console.warn(`🚀 ~ getGeo ~ final.longlat`, post.longlat)
    const geo = getGeoFromLonLat(post.longlat.toString())
    // console.warn(`🚀 ~ getGeoData ~ geo`, geo)
    const geoPoint = new GeoPoint(geo.lat, geo.lon)

    const geoHash = geohashForLocation([geo.lat, geo.lon])
    // console.warn(`🚀 ~ getGeo ~ geoHash`, geoHash)
    return {
      geo,
      geoPoint,
      geoHash,
    }
  } else {
    return null
  }
}

export const getLongLatFromPost = (post: AnyPostData) => {
  return post.longlat
}


// export function searchEPSG(query: string, cb: (code, name, proj4def, bbox) => void) {

//   fetch(`https://epsg.io/?format=json&q=${query}`)
//     .then(function (response) {
//       return response.json()
//     })
//     .then(function (json) {
//       const results = json.results
//       if (results && results.length > 0) {
//         for (let i = 0, ii = results.length; i < ii; i++) {
//           const result = results[i]
//           if (result) {
//             const code = result.code
//             const name = result.name
//             const proj4def = result.proj4
//             const bbox = result.bbox
//             if (
//               code &&
//               code.length > 0 &&
//               proj4def &&
//               proj4def.length > 0 &&
//               bbox &&
//               bbox.length === 4
//             ) {
//               cb(code, name, proj4def, bbox);
//               return;
//             }
//           }
//         }
//       }
//       cb(null, null, null, null);
//     }).catch((e) => {
//       console.error(`searchEPSG`, e)

//     })
// }


export const longLatToLatLong = (lola: LongLatArray): LatLongArray => {
  // console.warn(`🚀 ~ longLatToLatLong ~ lola`, lola)
  if (lola && lola.length === 2) {
    const lalo = [...lola].reverse() as LatLongArray
    return lalo
  }
  return defaultLatLong
}
export const latLongToLongLat = (lalo: LongLatArray): LatLongArray => {
  // console.warn(`🚀 ~ latLongToLongLat ~ lalo`, lalo)
  if (lalo && lalo.length === 2) {
    const lola = [...lalo].reverse() as LongLatArray
    return lola
  }
  return defaultLongLat
}

export const isLongLatArray = (lola: Coordinate | LongLatArray | number[]): lola is LongLatArray => {
  if (lola && lola.length === 2) {
    return true
  } else {
    return false
  }
}