import React from 'react'

import { CircularProgress } from '@mui/material'
import Box from '@mui/material/Box'

export function LoadingIcon(): React.ReactElement {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: `100%`,
        width: '100&',
        backgroundColor: 'transparent',
      }}
    >
      <CircularProgress />
    </Box>
  )
}
