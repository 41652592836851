import map from 'lodash/map'
import React from 'react'

import { useTheme } from '@mui/material/styles'
import {
  AnimatedAxis,
  AnimatedGrid,
  AnimatedLineSeries,
  Tooltip,
  XYChart,
} from '@visx/xychart'
import { customTheme } from '@Components/shared/graph/graphThemes'
import CustomChartBackground from '@Components/shared/graph/ChartBackground'
import { Datum } from '@Constants/types/graph'
import { isDataObject } from '@Utils/graphFunc'
import { roundDecimal } from '@Utils/stringFunc'

const accessors = {
  xAccessor: (d: Datum) => d.x,
  yAccessor: (d: Datum) => d.y,
}

const dataB: Datum[] = new Array(20).fill(null).map((_, i) => ({
  x: `2020-01-${i + 1 < 10 ? `0${i + 1}` : i + 1}`,
  y: Math.random() * i,
}))

export const XYChartComponent = ({
  title = 'Graph',
  height = 400,
  width = 400,
  margin = 10,
  background = false,
  data = dataB, // Accepts either one array of values, or an object with multiple arrays
  pattern = false,
  hilite,
}: {
  title?: string
  height?: number
  width?: number
  margin?: number
  background?: boolean
  data?: any
  pattern?: boolean
  hilite?: boolean
}) => {
  const theme = useTheme()
  const chartTheme = customTheme(theme)
  const infoColor = theme.palette.info.main
  const mainColor = theme.palette.text.secondary

  let lineComponents
  if (!isDataObject(data)) {
    lineComponents = (
      <AnimatedLineSeries dataKey={title} data={data} {...accessors} />
    )
  } else if (isDataObject(data)) {
    // console.log('series')
    lineComponents = map(data, (dataItem, key) => {
      return (
        <AnimatedLineSeries
          key={key}
          dataKey={key}
          data={dataItem}
          {...accessors}
        />
      )
    })
  } else {
    lineComponents = null
  }
  return (
    <XYChart
      height={height}
      width={width}
      margin={{ top: margin, right: margin, bottom: margin, left: margin }}
      xScale={{ type: 'band' }}
      yScale={{ type: 'linear' }}
      theme={chartTheme}
    >
      {background || pattern ? (
        <CustomChartBackground pattern={pattern} />
      ) : null}
      <AnimatedAxis
        orientation="bottom"
        stroke={theme.palette.text.secondary}
        // tickComponent={(props) => {
        //   return (
        //     <Text {...props} fill={'pink'}>
        //       {props.formattedValue}
        //     </Text>
        //   )
        // }}
      />
      <AnimatedGrid rows={false} columns={false} numTicks={4} />
      {lineComponents}
      <Tooltip
        snapTooltipToDatumX
        snapTooltipToDatumY
        showVerticalCrosshair
        showSeriesGlyphs
        renderTooltip={({ tooltipData, colorScale }) => {
          // console.warn(`🚀 ~ tooltipData`, tooltipData)

          return tooltipData && tooltipData.nearestDatum && colorScale ? (
            <div>
              <div style={{ color: colorScale(tooltipData.nearestDatum.key) }}>
                {tooltipData.nearestDatum.key}
              </div>

              {
                // @ts-expect-error
                accessors.xAccessor(tooltipData.nearestDatum.datum)
              }
              {', '}
              <br />
              {
                (roundDecimal(
                  // @ts-expect-error
                  accessors.yAccessor(tooltipData.nearestDatum.datum)
                ),
                2)
              }
            </div>
          ) : null
        }}
      />
    </XYChart>
  )
}
