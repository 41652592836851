import { RouteContext } from '@Components/containers/RouteProvider'
import Icon from '@mui/material/Icon'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import { iconFontSize } from '@Styles/styleVars'
import React, { useContext } from 'react'

interface OwnProps {}

type Props = OwnProps

export default function ClearFiltersButton({}: Props) {
  const { goClear } = useContext(RouteContext)

  return (
    <div>
      <Tooltip title="Clear filters">
        <IconButton onClick={() => goClear()}>
          <Icon
            style={{
              fontSize: iconFontSize('tiny'),
            }}
          >
            close
          </Icon>
        </IconButton>
      </Tooltip>
    </div>
  )
}
