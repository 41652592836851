import React from 'react'
import {
  findNodePath,
  focusEditor,
  removeNodes,
  TElement,
  useEditorRef,
} from '@udecode/plate-core'

import IconButton from '@mui/material/IconButton'
import Icon from '@mui/material/Icon'

export const RemoveNodeButton = ({
  element,
  ...props
}: {
  element: TElement
}) => {
  const editor = useEditorRef()

  return (
    <IconButton
      onClick={() => {
        const path = findNodePath(editor, element)
        removeNodes(editor, { at: path })
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        focusEditor(editor, editor.selection!)
      }}
      {...props}
    >
      <Icon>delete</Icon>
    </IconButton>
  )
}
