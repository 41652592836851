import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { ReactReduxFirebaseProvider } from 'react-redux-firebase'
import { Router } from 'react-router-dom'

import { AuthIsLoaded } from '@Components/containers/AuthProvider'
import RouteProvider from '@Components/containers/RouteProvider'
import { rrfProps } from '@State/firebase'
import { history, store } from '@State/store'
import printBuildInfo from '@Utils/printBuildInfo'

import App from './App'

printBuildInfo()
// console.log('env', process.env);
const container = document.getElementById('root')
// eslint-disable-next-line @typescript-eslint/no-unsafe-call
const root = createRoot(container)
// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <ReactReduxFirebaseProvider {...rrfProps}>
      <Router
        // @ts-ignore
        history={history}
      >
        <AuthIsLoaded>
          <RouteProvider>
            <App />
          </RouteProvider>
        </AuthIsLoaded>
      </Router>
    </ReactReduxFirebaseProvider>
  </Provider>
  // </React.StrictMode>
  // document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

// YT API
// handleClientLoad()
