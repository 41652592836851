import includes from 'lodash/includes'
import React, { useContext } from 'react'
import { isEmpty } from 'react-redux-firebase'
import { Redirect, Route } from 'react-router-dom'

import { AuthContext } from '@Components/containers/AuthProvider'
import { TypesOfReactChildren } from '@Utils/reactHelpers'

// Render routes

export const PrivateRouteRedirect = ({
  component: RouteComponent,
  ...rest
}) => {
  const { currentUser } = useContext(AuthContext)
  return (
    <Route
      {...rest}
      // @ts-ignore
      render={(routeProps) =>
        !isEmpty(currentUser) ? (
          <RouteComponent {...routeProps} {...rest} />
        ) : (
          <Redirect to={'/login'} />
        )
      }
    />
  )
}

export const DefaultRoute = ({ component: RouteComponent, ...rest }) => {
  return (
    <Route
      {...rest}
      // @ts-ignore
      render={(routeProps) => <RouteComponent {...routeProps} {...rest} />}
    />
  )
}

// Render children

export const RenderRoute = ({
  component: RouteComponent,
  children,
  roles,
  ...rest
}: {
  path: string | string[] | undefined
  component?: TypesOfReactChildren
  children?: TypesOfReactChildren
  exact?: boolean

  roles?: string[]
}) => {
  const { currentUser, userProfile } = useContext(AuthContext)
  const loggedIn = !isEmpty(currentUser)
  const hasProfile_AndRole =
    userProfile && roles && includes(roles, userProfile.role)
  const onlyForRoles = loggedIn && hasProfile_AndRole
  const ifRolesAreDefined_AndYouHaveTheRole = roles && onlyForRoles
  if (ifRolesAreDefined_AndYouHaveTheRole) {
    return <Route {...rest}>{children ? children : null}</Route>
  } else if (!roles) {
    // Roles are optional for this component
    return <Route {...rest}>{children ? children : null}</Route>
  } else {
    return null
  }
}

export const PrivateRouteShow = ({
  component: RouteComponent,
  children,
  roles,
  ...rest
}: {
  path: string | string[] | undefined
  component?: TypesOfReactChildren
  children?: TypesOfReactChildren
  roles?: string[]
  exact?: boolean
  fab?: string
}) => {
  const { currentUser, userProfile } = useContext(AuthContext)
  const show = rest && userProfile && includes(roles, userProfile.role)
  return (
    <Route
      {...rest}
      // @ts-ignore
      render={(routeProps) =>
        !isEmpty(currentUser) && show ? (
          // @ts-expect-error
          <RouteComponent {...routeProps} {...rest}>
            {children}
          </RouteComponent>
        ) : null
      }
    >
      {/* {!isEmpty(currentUser) && show && children ? React.cloneElement(children, { ...rest }) : null} */}
    </Route>
  )
}
