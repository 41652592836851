import React, { useContext } from 'react'
import { isEmpty, isLoaded } from 'react-redux-firebase'
import { useHistory } from 'react-router-dom'

import Avatar from '@mui/material/Avatar'
import Divider from '@mui/material/Divider'
import Icon from '@mui/material/Icon'
import IconButton from '@mui/material/IconButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Switch from '@mui/material/Switch'
import Tooltip from '@mui/material/Tooltip'
import makeStyles from '@mui/styles/makeStyles'
import { RouteContext } from '@Components/containers/RouteProvider'
import { UserRoles, roleVars } from '@Constants/config/roleVars'
import { appRoutes } from '@Constants/config/routeVars'
import { FirebaseAuthState } from '@State/store'

const useStyles = makeStyles((theme) => ({
  avatar: {
    marginRight: -16,
  },
  menu: {
    minWidth: 250,
  },
}))

export default function ProfileMenu({
  role,
  themeSelected,
  changeTheme,
  auth,
}: {
  role: UserRoles
  themeSelected: string
  changeTheme: (theme?: string) => void
  auth?: FirebaseAuthState
}) {
  const classes = useStyles()
  const history = useHistory()
  const { goLogin } = useContext(RouteContext)
  const [anchorEl, setAnchorEl] = React.useState(null)

  const { goUserPosts, goLogout, goCreate } = useContext(RouteContext)

  const handleClick = (event) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-argument
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  function goMyPosts() {
    handleMenuClose()
    if (auth) goUserPosts(auth.uid)
  }

  function goLoginPage(e) {
    goLogin()
  }

  function logout() {
    goLogout()
  }

  const handleMenuOption = (e: React.MouseEvent, option) => {
    handleMenuClose()
    switch (option) {
      case 'logout':
        return logout()
      default:
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        return history.push(option)
    }
  }

  if (!isEmpty(auth)) {
    // Pretty dumb solution
    const avatarName =
      auth && auth.displayName !== null ? auth.displayName : 'ERROR'
    const avatarURL = auth && auth.photoURL !== null ? auth.photoURL : 'ERROR'
    const email = auth && auth.email

    const showTest = roleVars.menu.test.includes(role)
    const showMyStuff = roleVars.menu.myStuff.includes(role)
    const showProfile = roleVars.menu.profile.includes(role)

    const authLoaded = isLoaded(auth)

    return (
      <div className={classes.avatar}>
        <Tooltip title="User Menu">
          <IconButton onClick={handleClick} aria-haspopup="true" size="large">
            <Avatar alt={avatarName} src={avatarURL} />
          </IconButton>
        </Tooltip>
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
          className={classes.menu}
        >
          <MenuItem onClick={(e) => changeTheme()}>
            <ListItemIcon>
              <Icon>
                {themeSelected === 'dark' ? 'brightness_high' : 'dark_mode'}
              </Icon>
            </ListItemIcon>
            <ListItemText primary="Dark theme" />
            <Switch checked={themeSelected === 'dark'} />
          </MenuItem>

          {showMyStuff && (
            <MenuItem
              component="a"
              href={appRoutes.create}
              onClick={(e) => {
                handleMenuClose()
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                if (e.ctrlKey) {
                  return
                } else {
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
                  e.preventDefault()
                  goMyPosts()
                }
              }}
            >
              <ListItemIcon>
                <Icon>view_module</Icon>
              </ListItemIcon>
              My Stuff
            </MenuItem>
          )}
          {showMyStuff && (
            <MenuItem
              component="a"
              href={appRoutes.create}
              onClick={(e) => {
                handleMenuClose()
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                if (e.ctrlKey) {
                  return
                } else {
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
                  e.preventDefault()
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                  goCreate()
                }
              }}
            >
              <ListItemIcon>
                <Icon>create</Icon>
              </ListItemIcon>
              <ListItemText primary="Create" />
            </MenuItem>
          )}
          {showMyStuff && <Divider />}

          <MenuItem
            component="a"
            href={appRoutes.profile}
            onClick={(e) => {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
              if (e.ctrlKey) {
                return
              } else {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
                e.preventDefault()
                // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                handleMenuOption(e, appRoutes.profile)
              }
            }}
          >
            <ListItemIcon>
              <Icon>person</Icon>
            </ListItemIcon>
            Profile
          </MenuItem>
          {/* <MenuItem onClick={(e) => handleMenuOption(e, '?account')} disabled>
            <ListItemIcon>
              <Icon>account_box</Icon>
            </ListItemIcon>
            Account
          </MenuItem> */}
          {showTest ? (
            <MenuItem
              component="a"
              href="/test"
              onClick={(e) => {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                if (e.ctrlKey) {
                  return
                } else {
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
                  e.preventDefault()
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-call
                  handleMenuOption(e, '/test')
                }
              }}
            >
              <ListItemIcon>
                <Icon>biotech</Icon>
              </ListItemIcon>
              Test
            </MenuItem>
          ) : null}
          <Divider />
          <Tooltip
            title={
              <div>
                Logout: <b>{email}</b>
              </div>
            }
          >
            <MenuItem
              onClick={(e) => handleMenuOption(e, 'logout')}
              disabled={!authLoaded}
            >
              <ListItemIcon>
                <Icon>power_settings_new</Icon>
              </ListItemIcon>
              Logout
            </MenuItem>
          </Tooltip>
        </Menu>
      </div>
    )
  } else {
    return (
      <Tooltip title="Login">
        <IconButton onClick={goLoginPage} size="large">
          <Icon>person</Icon>
        </IconButton>
      </Tooltip>
    )
  }
}
