import { createBlockquotePlugin, createListPlugin, createParagraphPlugin } from '@udecode/plate'
import { createCodeBlockPlugin } from '@Components/shared/object-editor/plugins/codeblock/codeBlockPlugin'
import { createHeadingPlugin } from '@Components/shared/object-editor/plugins/heading/createHeadingPlugin'
import { createSaveShortcutPlugin } from '@Components/shared/object-editor/plugins/saveShortcutPlugin'
import { createTextPlugin } from '@Components/shared/object-editor/plugins/text/createTextPlugin'
import { createTitlePlugin } from '@Components/shared/object-editor/plugins/title/createTitlePlugin'
import { createObjectEditorPlugins } from '@Components/shared/object-editor/ObjectEditor.types'


export const basicElementsPlugins = createObjectEditorPlugins(
  [
    createBlockquotePlugin(),
    createCodeBlockPlugin(),
    createHeadingPlugin({ options: { levels: 3 } }),
    createParagraphPlugin(),
    createListPlugin(),

    // Custom
    createTitlePlugin(),
    createTextPlugin(),
    createSaveShortcutPlugin()
  ]
);
