import { AlertColor } from '@mui/material/Alert'
import { appVars } from '@Constants/config/app'
import { AppActionTypes, CLOSE_NOTIFICATION, NOTIFICATION } from '@State/actions/appStateActions'


export interface NotificationMessage {
  text: string;
  severity: AlertColor | string;
  variant?: string;
}

export interface NotificationState {
  notificationOpen: boolean;
  messages: NotificationMessage[]
}

const initialNotificationState: NotificationState = {
  notificationOpen: false,
  messages: []
}

const NOTIFICATION_MAX_HISTORY = appVars.notificationMaxHistory

export default function notificationReducer(
  state = initialNotificationState,
  action: AppActionTypes
): NotificationState {
  // console.log('notificationReducer', state, action)

  if (action.type === NOTIFICATION) {
    let newArray: NotificationMessage[] = [...state.messages];
    const lngth = newArray.push({
      text: action.text,
      severity: action.severity ? action.severity : 'info',
      variant: action.variant ? action.variant : 'default'
    })
    if (lngth >= NOTIFICATION_MAX_HISTORY) {
      newArray = newArray.slice(1, lngth - 1)
    }

    return {
      ...state,
      messages: newArray,
      notificationOpen: true
    }
  } else if (action.type === CLOSE_NOTIFICATION) {
    return {
      ...state,
      notificationOpen: false
    }
  } else {
    return state
  }
}