import { Cats } from '@Constants/config/categories'
import { Types } from './types'

interface AppVars {
  things: string;
  post: string;
  posts: string;
  defaultPost: string;
  defaultType: Types;
  defaultCat: Cats;
  description: string;
  errorPrefix: string;
  errorSuffix: string;
  errorSymbol: string;

  notificationMaxHistory: number;
}

export const appVars: AppVars = {
  things: 'bytes', // Blank state
  post: 'byte',
  posts: 'bytes',
  defaultPost: process.env.REACT_APP_ENV === 'production' ? 'start' : 'test', // Namespace of initial post
  defaultType: 'text', // Used mainly to have an autofocus target
  defaultCat: 'art', // Used mainly to have an autofocus target
  description: 'Anarchy in byte form',
  errorPrefix: 'Oh... No ',
  errorSuffix: '!',
  errorSymbol: '§',

  notificationMaxHistory: 100
}