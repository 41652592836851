import { useBreakpoints } from '@Constants/config/globalStyles'
import { useTheme } from '@mui/material'
import Typography from '@mui/material/Typography/Typography'
import Box from '@mui/system/Box'
import React from 'react'
import { ReactFitty } from 'react-fitty'

interface OwnProps {
  title?: string
  children?: string
  wrapText?: boolean
  minSize?: number
  maxSize?: number
  className?: string
  sx?: Record<string, unknown>
  innerClassName?: string
  onClick?: (e) => void
}

export default function FlatTitle({
  title,
  children,
  wrapText = true,
  minSize = 16,
  maxSize = 128,
  className,
  sx,
  innerClassName,
  onClick,
}: OwnProps) {
  const { matchesMobile, largerThanMedium } = useBreakpoints()
  const minFontsize = matchesMobile ? minSize : largerThanMedium ? 64 : 32
  const maxFontsize = matchesMobile ? maxSize : 64
  const { zIndex } = useTheme()
  return (
    <Box
      className={className}
      sx={{
        maxHeight: '100%',
        zIndex: zIndex.appBar,
        width: '100%',
        height: '100%',
        ...sx,
      }}
    >
      <Typography
        variant="h2"
        component="h2"
        // component={ReactFitty}
        sx={{
          display: 'flex',
          textAlign: 'center',
          maxHeight: '100%',
          justifyContent: 'center',
          alignItems: 'center',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          width: '100%',
          height: '100%',
          p: 2,
        }}
      >
        <ReactFitty
          className={innerClassName}
          wrapText={wrapText}
          minSize={minFontsize}
          maxSize={maxFontsize}
          onClick={onClick}
        >
          {title || children}
        </ReactFitty>
      </Typography>
    </Box>
  )
}
