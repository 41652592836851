// import 'twin.macro'

import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Icon from '@mui/material/Icon'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import { useElement } from '@udecode/plate-core'
import { RemoveNodeButton } from '@Components/shared/object-editor/components/RemoveNodeButton'
import {
  FloatingMedia,
  useFloatingMediaSelectors,
} from '@Components/shared/object-editor/plugins/MediaElements'

export const PlateFloatingMedia = ({ pluginKey }: { pluginKey?: string }) => {
  const isEditing = useFloatingMediaSelectors().isEditing()
  const element = useElement()
  const boxSx = {
    p: 0,
  }
  return (
    <Paper
      variant="outlined"
      sx={{
        p: 0,
      }}
    >
      {!isEditing ? (
        <Box sx={boxSx}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            spacing={1}
            // sx={{ px: 1 }}
          >
            <FloatingMedia.EditButton>Edit link</FloatingMedia.EditButton>
            <Divider orientation="vertical" flexItem />
            <RemoveNodeButton element={element} />
          </Stack>
        </Box>
      ) : (
        <Box sx={boxSx}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            spacing={1}
          >
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              sx={{ m: 1, ml: 2 }}
            >
              <Icon>link</Icon>
            </Stack>

            <Divider orientation="vertical" flexItem />

            <FloatingMedia.UrlInput
              placeholder="Paste the embed link..."
              pluginKey={pluginKey}
            />
          </Stack>
        </Box>
      )}
    </Paper>
  )
}
