import { useCallback } from 'react'

import Button, { ButtonProps } from '@mui/material/Button'
import {
  AsProps,
  HTMLPropsAs,
  createComponentAs,
  createElementAs,
  useEditorRef,
} from '@udecode/plate-core'
import { triggerFloatingLinkEdit } from '@Components/shared/object-editor/components/FloatingLink/utils/triggerFloatingLinkEdit'

export const useFloatingLinkEditButton = (
  props: HTMLPropsAs<'button'>
): HTMLPropsAs<'button'> => {
  const editor = useEditorRef()

  return {
    onClick: useCallback(() => {
      triggerFloatingLinkEdit(editor)
    }, [editor]),
    ...props,
    // style: {
    //   ...props.style,
    // },
  }
}

export const FloatingLinkEditButton = createComponentAs<AsProps<'button'>>(
  (props) => {
    const htmlProps = useFloatingLinkEditButton(props)

    return createElementAs(Button, {
      ...htmlProps,
      variant: 'standard',
    })
  }
)
