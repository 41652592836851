import React from 'react'
import { useSelector } from 'react-redux'
import { UserProfile, isLoaded } from 'react-redux-firebase'

import { UserRoles } from '@Constants/config/roleVars'
import { FullscreenLoading } from '@Routes/system/FullscreenLoading'
import { AppRootState } from '@State/store'

export interface AuthProfile {
  id: string
  username: string
  role: UserRoles
  avatar: string
  description: string
  namespace?: string

  isEmpty: boolean
  isLoaded: boolean
}

export type AuthContextType = {
  currentUser: UserProfile
  userProfile: Partial<AuthProfile> | null
}

export const AuthContext = React.createContext<AuthContextType>({
  currentUser: {},
  userProfile: null,
})

export function AuthIsLoaded({ children }: { children: React.ReactChild }) {
  const auth = useSelector((state: AppRootState) => state.firebase.auth)
  const profile = useSelector((state: AppRootState) => state.firebase.profile)

  // TODO: What happens when connection times out?
  if (!isLoaded(auth)) {
    return <FullscreenLoading />
  }

  return (
    <AuthContext.Provider
      value={{
        currentUser: auth,
        userProfile: profile,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
