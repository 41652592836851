import SystemModal from '@Components/shared/modal/SystemModal'
import makeStyles from '@mui/styles/makeStyles'
import Typography from '@mui/material/Typography'
import { toggleLoginDialogAction } from '@State/actions/appStateActions'
import React from 'react'
import { connect } from 'react-redux'

import LoginForm from './LoginForm'
import Stack from '@mui/material/Stack'
import { Box } from '@mui/material'
import { RootState } from '@State/store'

export function LoginDialog({ appState, toggleLoginDialog }) {
  const { loginDialogOpen } = appState

  function afterLogin() {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    toggleLoginDialog(false)
  }

  return (
    <div>
      <SystemModal
        title="Please Login First"
        open={loginDialogOpen}
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        handleClose={() => toggleLoginDialog(false)}
      >
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          sx={{ p: 2, py: 8 }}
        >
          <Typography component="h6" variant="h6">
            Sign in with
          </Typography>

          <LoginForm afterLogin={afterLogin} />
        </Stack>
      </SystemModal>
    </div>
  )
}

const mapStateToProps = (state: RootState /*, ownProps*/) => {
  return {
    appState: state?.appState,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-argument
    toggleLoginDialog: (open) => dispatch(toggleLoginDialogAction(open)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginDialog)
