import checkFirebaseCompatible from '@Utils/regExp'

export const dashString = (str): string => {
  return str
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    ? str.toString().trim().split(' ').join('-') as string
    : ''
}

export const sentenceToNamespace = (sentence: string): string => {
  return sentence.split(' ')
    .join('-')
}

export const validNamespaceFilter = (val?: string) => {
  // Spaces
  if (val) {
    const correctedValueStrippedSpaces: string = val.replace(/^\s+|\s+$/g, '')
    const correctedValue: string = correctedValueStrippedSpaces
      .split('|') // remove pipes
      .join('')

      .split(',') // remove commas
      .join('')

      .split('–') // Special dash character
      .join('-')

      .split(' - ')
      .join('-') // Avoid double dashes

      .split('&') // replace & with and
      .join('-and-')

      .split(':')
      .join('-') // Replace colon with dash

      .split(';')
      .join('-') // Replace colon with dash

      .split('/')
      .join('-') // Replace slash with dash

      .split('\\')
      .join('-') // Replace backslash with dash

      .split(' ')
      .join('-') // Replace normal spaces with dashes

      .split('--')
      .join('-') // Don't allow consecutive dashes
    // TODO: If . (period) is last char, don't replace with -

    const omitDash: string = correctedValue.split(' - ').join('')
    const correctedValueDots: string = omitDash.split('.').join('-')
    const correctedValueApos: string = correctedValueDots.split("'").join('')
    return correctedValueApos.toLowerCase()
  } else {
    return ''
  }
}

export const validatedNamespaceFunc = (val?: string) => {
  const pathRegex = checkFirebaseCompatible()
  if (val) {
    const isGood = pathRegex.test(val)
    let newVal: string
    if (isGood) {
      newVal = val
      return newVal
    } else {
      newVal = validNamespaceFilter(val)
      const isStillGood = pathRegex.test(newVal)
      if (isStillGood) {
        return newVal
      } else {
        return ''
      }
    }
    // return newVal
  } else {
    return ''
  }
}

export const roundDecimal = (num: number | string, precision = 6) => {
  let theNum: number
  if (typeof num === 'string') {
    theNum = parseInt(num, 10)
  } else {
    theNum = num
  }
  return theNum.toPrecision(precision)
}