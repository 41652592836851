import '@Styles/App.css'
import '@Styles/index.css'
import '@Styles/mui.css'
import '@Styles/overrides.css'
// Open layers css
import 'ol/ol.css'
import 'rlayers/control/layers.css'

import React from 'react'
import { connect } from 'react-redux'

import {
  StyledEngineProvider,
  Theme,
  ThemeProvider,
} from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import Drawer from '@Components/containers/ui/Drawer'
import {
  FloatingWindow,
  FloatingWindowData,
} from '@Components/containers/ui/FloatingWindow'
import Snackbar from '@Components/containers/ui/Snackbar/Snackbar'
import TopBar from '@Components/containers/ui/TopBar/TopBar'
import LoginDialog from '@Components/profile/LoginDialog'
import VideoEmbed from '@Components/shared/embed/VideoEmbed'
import ErrorBoundary from '@Components/shared/system/ErrorBoundary'
import BottomBar from '@Components/ui/BottomBar'
import {
  inputGlobalStyles,
  useCreateTheme,
} from '@Constants/config/globalStyles'
import FabRoutes from '@Routes/system/FabRoutes'
import AppRoutes from '@Routes/AppRoutes'
import { DefaultRoute } from '@Routes/Routers'
import {
  minimizeloatingWindowAction,
  toggleFloatingWindowAction,
  updateFloatingWindowDataAction,
} from '@State/actions/storageActions'
import { StorageState } from '@State/reducers/storageReducer'
import { AppDispatch, RootState } from '@State/store'
import { LoadingIcon } from '@Routes/system/LoadingIcon'
import { useLocation } from 'react-router-dom'
import routeVars from '@Constants/config/routeVars'

const SubPeek = React.lazy(() => import('@Routes/page/SubPeek'))

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

interface StateProps {
  auth: any
  profile: any
  storage: StorageState
  drawerOpen: boolean
}

interface DispatchProps {
  toggleFloatingWindow: (open?: boolean) => void
  minimizeFloatingWindow: (minimize?: boolean) => void
  updateFloatingWindowData: (data: FloatingWindowData) => void
}

interface OwnProps {}

type PostProps = StateProps & DispatchProps & OwnProps

interface MyTheme {
  paleette: Record<string, unknown>
  spacing: string
}

function App({
  auth,
  profile,
  storage,
  drawerOpen,
  toggleFloatingWindow,
  minimizeFloatingWindow,
  updateFloatingWindowData,
}: PostProps) {
  const {
    theme,
    showFloatingWindow,
    floatingWindowMinimized,
    floatingWindowData,
    floatingWindowUrl,
  } = storage

  const { role } = profile
  const themeObject = useCreateTheme(theme)

  // DODO: Find better inverse path matching solution
  const { pathname } = useLocation()
  const pathRoot = pathname.split('/')
  const finalPath = `/${pathRoot[1]}/:namespace`
  const hideAppNav = routeVars.hideAppNav.includes(finalPath)

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={themeObject}>
        <CssBaseline />
        {inputGlobalStyles(themeObject)}
        <div>
          <ErrorBoundary>{hideAppNav ? null : <TopBar />}</ErrorBoundary>

          <ErrorBoundary>
            <Drawer />
          </ErrorBoundary>

          <ErrorBoundary>
            <AppRoutes drawerOpen={drawerOpen} />
          </ErrorBoundary>

          <React.Suspense fallback={<LoadingIcon />}>
            <DefaultRoute
              exact
              path={['/id/:id/id/:subId']}
              component={SubPeek}
            />
          </React.Suspense>

          <FabRoutes />

          <Snackbar />

          <LoginDialog />

          {auth && role ? hideAppNav ? null : <BottomBar /> : null}
        </div>

        {/* {showFloatingWindow ? (
          <FloatingWindow
            title="Float"
            toggleFloatingWindow={toggleFloatingWindow}
            updateFloatingWindowData={updateFloatingWindowData}
            floatingWindowData={floatingWindowData}
            minimizeFloatingWindow={minimizeFloatingWindow}
            floatingWindowMinimized={floatingWindowMinimized}
          >
            <VideoEmbed url={floatingWindowUrl} />
          </FloatingWindow>
        ) : null} */}
      </ThemeProvider>
    </StyledEngineProvider>
  )
}

const mapStateToProps = (state: RootState /*, ownProps*/) => {
  return {
    // appState: state.appState,
    auth: state.firebase?.auth,
    profile: state.firebase.profile,
    drawerOpen: state.appState.drawerOpen,
    storage: state.storage,
  }
}

const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    toggleFloatingWindow: (open?: boolean) =>
      dispatch(toggleFloatingWindowAction(open)),
    updateFloatingWindowData: (data: FloatingWindowData) =>
      dispatch(updateFloatingWindowDataAction(data)),
    minimizeFloatingWindow: (minimize?: boolean) =>
      dispatch(minimizeloatingWindowAction(minimize)),
  }
}

export default connect<StateProps, DispatchProps, OwnProps, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(App)
