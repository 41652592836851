import { ObjectEditorType, ObjectEditorValue } from '@Components/shared/object-editor/ObjectEditor.types';
import { useObjectEditorStore } from '@Components/shared/object-editor/ObjectEditorState';
import { sendNotifcationAction } from '@State/actions/appStateActions';
import { editPostAction } from '@State/actions/postActions';
import { store } from '@State/store';
import { PlateEditor, WithPlatePlugin, KeyboardHandlerReturnType, getBlockAbove, queryNode, createPluginFactory, Value, QueryNodeOptions, usePlateEditorState } from '@udecode/plate';
import { useSaveState, getUndo, useValidate, getValidation, getSaveState } from '@Utils/plateHelpers';

import isHotkey from 'is-hotkey';

export interface SaveShortutRule {
  hotkey: string;

  /**
   * Filter the block types where the rule applies.
   */
  query?: QueryNodeOptions;
}

export interface SaveShortcutPlugin {
  rules?: SaveShortutRule[];
}


export const onKeyDownSave = <
  V extends Value = Value,
  E extends PlateEditor<V> = PlateEditor<V>
>(
  editor: E,
  { options: { rules = [] } }: WithPlatePlugin<SaveShortcutPlugin, V, E>,
  ...rest
): KeyboardHandlerReturnType => (event) => {
  const saved = useObjectEditorStore.getState().saved
  const { onSave, disableSubmit } = getSaveState(editor as ObjectEditorType)
  const setSaved = (s: boolean) => useObjectEditorStore.setState({ saved: s })
  // Check if state is valid
  const { fields, invalid } = getValidation(editor as ObjectEditorType)
  const { hasRedos, hasUndos, undos, redos } = getUndo(editor as ObjectEditorType)
  const hasAnyDo = hasRedos || hasUndos

  // console.log(`🚀 ~ saved`, saved)
  // console.log(`🚀 ~ disableSubmit`, disableSubmit)


  rules.forEach(({ hotkey, query }) => {
    if (isHotkey(hotkey, event)) {
      event.preventDefault();
      event.stopPropagation();
      if (invalid || saved || disableSubmit) {
        // Trigger warning snackbar
        // TODO: Specify what is invalid
        store.dispatch(sendNotifcationAction(`${invalid ? 'Invalid' : disableSubmit ? 'Disabled' : saved ? 'Saved' : ''}`, 'warning'))
        return;
      }
      const { id, children } = editor
      console.warn("Save", children)
      // FIXME: Find better way to get postId & data?
      // Save current object
      store.dispatch(editPostAction({
        id,
        data: { object: children as ObjectEditorValue }
      }))
      // To disable Prompt after saving:
      onSave()
      setSaved(true)
    }
  });
};



export const createSaveShortcutPlugin = createPluginFactory<SaveShortcutPlugin>({
  key: 'KEY_SAVE',
  handlers: {
    onKeyDown: onKeyDownSave,
  },
  options: {
    rules: [{ hotkey: 'ctrl+s' }],
  },
});
