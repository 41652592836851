
import { BaseSelection } from 'slate'

import { ELEMENT_IMAGE } from '@Components/shared/object-editor/plugins/media/imagePlugin'
import { createNormalizeTypesPlugin } from '@Components/shared/object-editor/plugins/normalize/createNormalizeTypesPlugin'
import {
  ELEMENT_BLOCKQUOTE,
  ELEMENT_OL,
  ELEMENT_PARAGRAPH,
  ELEMENT_UL,
  MARK_BOLD,
  MARK_ITALIC
} from '@udecode/plate'
import { ELEMENT_ADD, ELEMENT_H1, ELEMENT_H2, ELEMENT_H3, ELEMENT_LINK, ELEMENT_PATH, ELEMENT_POST, ELEMENT_REF, ELEMENT_TAG, ELEMENT_TEXT, ELEMENT_TITLE, MENTION_TYPES } from '@Components/shared/object-editor/ObjectEditor.types'
import { createObjectEditorPlugins, ELEMENT_CODE_BLOCK } from '../ObjectEditor.types'
import { ELEMENT_MEDIA_EMBED } from './media/media-embed/createMediaEmbedPlugin'


// First few fields have a fixed position
export const POST_NORMALIZATION_RULES = [
  { path: [0], strictType: ELEMENT_TITLE, exclusive: true, exclusiveReplacement: ELEMENT_H2 },
  { path: [1], strictType: ELEMENT_TEXT, exclusive: true },
]

// Properties from Post are mapped to object
export const POST_NORMALIZATION_PROPS = [
  { path: 0, prop: 'title' },
  { path: 1, prop: 'text' }
]

// Generic edit buttons to disable on normalized nodes
export const GENERIC_FILTER_BUTTONS = [
  MARK_BOLD, MARK_ITALIC, // Disable these mark stylings until objectToPost is fixed
  ELEMENT_BLOCKQUOTE, ELEMENT_UL, ELEMENT_OL,
  ELEMENT_IMAGE, ELEMENT_CODE_BLOCK, ELEMENT_MEDIA_EMBED,
  ELEMENT_POST, ...MENTION_TYPES
]

export const GENERIC_BLOCK_FILTER_BUTTONS = [
  ELEMENT_BLOCKQUOTE, ELEMENT_UL, ELEMENT_OL,
  ELEMENT_IMAGE, ELEMENT_CODE_BLOCK, ELEMENT_MEDIA_EMBED,
  ELEMENT_POST
]

// Each element has a limited set of options
const FILTERED_NORMALIZATION_ELEMENT_BUTTONS = {
  [ELEMENT_TITLE]: [
    ...GENERIC_FILTER_BUTTONS, ELEMENT_LINK,
    ELEMENT_H1, ELEMENT_H2, ELEMENT_H3
  ],
  [ELEMENT_TEXT]: [
    ...GENERIC_FILTER_BUTTONS, ELEMENT_LINK,
    ELEMENT_H1, ELEMENT_H2, ELEMENT_H3
  ],
}


export const elementNormalizedAtPath = (pathQ?: unknown[]) => {
  let anyMatch = ''
  if (pathQ) {
    const rootPath = pathQ[0]
    POST_NORMALIZATION_RULES.forEach((rule) => {
      const { path, strictType } = rule
      if (path.length >= 1 && rootPath === path[0]) {
        anyMatch = strictType
      }
    })
    return anyMatch
  } else {
    return anyMatch
  }
}

export const elementNormalizedAtSelection = (selection: BaseSelection): string => {
  let anyMatch = ''
  if (selection?.anchor?.path) {
    const { anchor: { path: anchorPath } } = selection
    const rootPath = anchorPath[0]
    POST_NORMALIZATION_RULES.forEach((rule) => {
      const { path, strictType } = rule
      if (path.length >= 1 && rootPath === path[0]) {
        anyMatch = strictType
      }
    })
    return anyMatch
  } else {
    return anyMatch
  }
}

const getNormalizationFilterTypes = (normalizedNode: string): string[] => {
  const toFilter = FILTERED_NORMALIZATION_ELEMENT_BUTTONS[normalizedNode] || []
  return toFilter
}

export const getNormalizationExclusionTypes = (normalizedNode: string, type: string) => {
  const filteredTypes = normalizedNode
    ? getNormalizationFilterTypes(normalizedNode)
    : false
  if (filteredTypes) {
    return filteredTypes.includes(type)
  } else {
    return false
  }
}

export const postNormalizationPlugins = createObjectEditorPlugins(
  [
    createNormalizeTypesPlugin({
      options: {
        rules: POST_NORMALIZATION_RULES,
      },
    }),
  ]
)
