export const brandVars = {
  brandName: process.env.REACT_APP_TITLE || 'Default Name',

  primaryColor: process.env.REACT_APP_WEBSITE_THEME_COLOR || '#4092ce',
  primaryColorDark: process.env.REACT_APP_WEBSITE_THEME_COLOR_DARK || '#a31545',

  secondaryColor: process.env.REACT_APP_WEBSITE_THEME_COLOR_SECONDARY || '#f7a345',
  secondaryColorDark: process.env.REACT_APP_WEBSITE_THEME_COLOR_SECONDARY_DARK || '#339ba5',

  successColor: process.env.REACT_APP_WEBSITE_SUCCESS_COLOR || '#00FF00',
  successColorDark: process.env.REACT_APP_WEBSITE_SUCCESS_COLOR_DARK || '#00FF00',
  infoColor: process.env.REACT_APP_WEBSITE_INFO_COLOR || '#82a6c2',
  infoColorDark: process.env.REACT_APP_WEBSITE_INFO_COLOR_DARK || '#82a6c2',

  warningColor: process.env.REACT_APP_WEBSITE_WARNING_COLOR || '#bcaa47',
  warningColorDark: process.env.REACT_APP_WEBSITE_WARNING_COLOR_DARK || '#bcaa47',
  errorColor: process.env.REACT_APP_WEBSITE_ERROR_COLOR || '#d32f2f',
  errorColorDark: process.env.REACT_APP_WEBSITE_ERROR_COLOR_DARK || '#d32f2f',

  urlServiceName: 'Brave',
  urlService: 'https://search.brave.com/',

  imageServiceName: 'Imgur',
  imageService: 'https://imgur.com/',

  rootDomain: process.env.REACT_APP_ROOT_DOMAIN || 'localhost:3000'
}