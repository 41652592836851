import { floatingLinkActions, floatingLinkSelectors } from '@Components/shared/object-editor/components/FloatingLink/floatingLinkStore';
import { ELEMENT_LINK } from '@Components/shared/object-editor/ObjectEditor.types';
import { LinkPlugin } from '@Components/shared/object-editor/plugins/media/link/createLinkPlugin';
import { upsertLink } from '@udecode/plate';
import {
  focusEditor,
  getPluginOptions,
  PlateEditor,
  Value,
} from '@udecode/plate-core';


/**
 * Insert link if url is valid.
 * Text is url if empty.
 * Close floating link.
 * Focus editor.
 */
export const submitFloatingLink = <V extends Value>(editor: PlateEditor<V>) => {
  if (!editor.selection) return;

  const { isUrl } = getPluginOptions<LinkPlugin, V>(editor, ELEMENT_LINK);

  const url = floatingLinkSelectors.url();
  const isValid = isUrl?.(url);
  if (!isValid) return;

  const text = floatingLinkSelectors.text();
  // const target = floatingLinkSelectors.newTab() ? undefined : '_self';

  floatingLinkActions.hide();

  upsertLink(editor, {
    url,
    text,
    // target,
  });

  setTimeout(() => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    focusEditor(editor, editor.selection!);
  }, 0);

  return true;
};
