import { ELEMENT_UL } from '@udecode/plate'
import { ELEMENT_BLOCKQUOTE } from '@udecode/plate-block-quote'
import { ELEMENT_PARAGRAPH } from '@udecode/plate-paragraph'
// import { useMemo } from 'react'
// import { unified } from 'unified'
// import markdown from 'remark-parse';
// import slate from 'remark-slate';
import { trailingBlockPlugin } from '@Components/shared/object-editor/plugins/formatting/trailingBlockPlugin'
import {
  DEFAULT_IMAGE_WIDTH,
  ELEMENT_IMAGE
} from '@Components/shared/object-editor/plugins/media/imagePlugin'
import {
  POST_NORMALIZATION_PROPS,
  POST_NORMALIZATION_RULES
} from '@Components/shared/object-editor/plugins/normalizationPlugins'
import {
  ObjectEditorRootBlock,
  ObjectEditorValue
} from '@Components/shared/object-editor/ObjectEditor.types'
import { defaultLongLat } from '@Constants/types/location'

import {
  AnyPostData,
  ExistingPostData,
  PostWithObject,
  isExistingPost,
  isPostWithObject,
  AnyExistingPostData
} from '../constants/post'


export const DEFAULT_ROOT_NODES = [{
  type: ELEMENT_PARAGRAPH,
  children: [{
    text: "",
  }]
}]

export const normalizedPostObjectFromRules = (post?: AnyPostData): ObjectEditorValue => {
  const objectvalues: ObjectEditorValue = []
  POST_NORMALIZATION_RULES.forEach((rule) => {
    const { path, strictType } = rule
    const pathIndex = path[0]
    const postDefinition = POST_NORMALIZATION_PROPS[pathIndex]
    const propPath = postDefinition?.path
    const postProp = postDefinition?.prop
    if (propPath === pathIndex && post) {
      const text = post[postProp] || ''
      objectvalues[propPath] = {
        type: strictType,
        children: [
          {
            text
          }
        ]
      } as ObjectEditorRootBlock
    } else if (post) {
      console.error('No config for: ', rule)
    } else {
      throw new Error("config error")
    }
  })
  return objectvalues
}

const stripIds = (obj: ObjectEditorValue): ObjectEditorValue => {
  const stripped: ObjectEditorValue = []
  obj.forEach(node => {
    const newNode = { ...node }
    delete newNode.id
    stripped.push(newNode)
  });
  return stripped
}

export const objectToPost = (object: ObjectEditorValue): PostWithObject => {
  const normalObject = stripIds([...object])
  const newPostObj = { object: normalObject }
  POST_NORMALIZATION_RULES.forEach((rule) => {
    const { path } = rule
    const pathIndex = path[0]
    const postDefinition = POST_NORMALIZATION_PROPS[pathIndex]
    const rulePath = postDefinition?.path
    const postProp = postDefinition?.prop
    // postNormalizationProps like title and text always keep in sync with latest object for searchability
    // FIXME: Get serialized text values of the first node instead of only first leafnode (getNodeString?)
    // For title: Strip # form markdown, and exclude any marks
    // For text: serialize to markdown, including mentions etc.
    if (postProp && normalObject[rulePath]?.children[0]?.text) {
      newPostObj[postProp] = normalObject[rulePath].children[0].text as string || ''
    }
  })
  return {
    ...newPostObj,
    object: normalObject
  } as PostWithObject
}

export const getDefaultObjectsForPost = (post: ExistingPostData): ObjectEditorValue => {

  let elem
  // NOTE: Avoid three consecutive paragraphs, as it gives weird buggy behaviour somehow
  switch (post?.type) {
    case 'term':
      elem = []
      break;
    case 'text':
      elem = []
      break;
    case 'list':
      // elem = [{
      //   type: ELEMENT_PARAGRAPH,
      //   children: [{
      //     text: "",
      //   }]
      // }]
      elem = [{
        type: ELEMENT_UL,
        children: [
          {
            "type": "li",
            "children": [
              // @ts-ignore because generic textprop
              {
                "type": "lic", // Multi indent stuff probably
                // "text": "", // It wants text prop for generic values, but lic is not defined
                "children": [
                  {
                    "text": ""
                  }
                ],
              }
            ],
          },
        ]
      }]
      break;
    case 'media':
      // TODO: Use ELEMENT_MEDIA_EMBED if url is embeddable
      if (post?.imgUrl) {
        elem = [{
          type: ELEMENT_IMAGE,
          url: post.imgUrl,
          relativeWidth: DEFAULT_IMAGE_WIDTH,
          // ...(post?.isSource && {
          //   caption: [
          //     {
          //       text: 'Source'
          //     }
          //   ]
          // }),
          children: [{
            text: "",
          }]
        }]
      } else {
        elem = []
      }
      break;
    case 'object': // ELEMENT_OBJECT
      elem = []
      break;
    case 'entity': // ELEMENT_ENTITY
    case 'person': // ELEMENT_PERSON
      elem = []
      break;
    case 'graph': // ELEMENT_GRAPH
      elem = []
      break;
    case 'location':
      elem = []
      // elem = [{
      //   type: ELEMENT_PARAGRAPH, // ELEMENT_LOCATION with longlat coords, efaults to post.longlat|geopoint
      //   children: [{
      //     text: '',
      //     longlat: post?.longlat || post?.geoPoint || defaultLongLat
      //   }]
      // }]
      break;
    default:
      elem = []
  }
  // const defaultTrailingBlockFromPlugin = trailingBlockPlugin?.options?.type
  return [
    ...elem,
    ...DEFAULT_ROOT_NODES, // Always add extra element which will be inserted anyways because of trailingBlockPlugin
  ]
}


export const usePostToObject = (post?: AnyExistingPostData): ObjectEditorValue => {
  // Title and Text are duplicated within object
  const normalizedPostObject = normalizedPostObjectFromRules(post)

  const postHasObject = isExistingPost(post) &&
    isPostWithObject(post)

  const defaultObj = isExistingPost(post) ?
    getDefaultObjectsForPost(post) : []

  const finalObjects =
    postHasObject ?
      // postHasObject ?
      [
        // TODO: Make sure the title & text fields are overriding the normalized object fields? In case those fields were edited outside of ObjectEditor? Or assume only case to edit those separately is when initially creating post, after that we always have ObjectEditor...?
        ...post.object
      ]
      // : [...defaultObj]
      :
      [
        ...normalizedPostObject,
        ...defaultObj
      ]

  // FIXME: wrong deserialize type definitions
  // const mdToSlate = useMemo(
  //   () => {
  //     unified()
  //       .use(markdown)
  //       .use(slate)
  //       .process(text, (err, file) => {
  //         if (err) throw err
  //         console.log({ file })
  //         result = file?.result as ObjectEditorRootBlock[]
  //       })
  //     return result
  //   },
  //   [text]
  // )

  return [...finalObjects]
}