import { SxProps } from '@mui/material'
import Skeleton from '@mui/material/Skeleton'
import PublishButton from '@Components/containers/post/PublishButton'
import PostCard from '@Components/post/PostCard'
import { usePostIdWithSubposts } from '@Utils/usePost'

export default function PostById({
  postId,
  focused,
  sx,
  delay = 111,
}: {
  postId: string
  focused?: boolean
  sx?: SxProps
  delay?: number
}) {
  const [post, subPosts, loaded] = usePostIdWithSubposts(postId, false)

  // const notExists = isEmpty(post) && isLoaded(post)

  if (loaded && post) {
    return (
      <>
        <PostCard post={post} sx={sx} />
        {post.visibility === 'private' && focused ? (
          <PublishButton post={post} enabled sx={{ mt: 2 }} />
        ) : null}
      </>
    )
  } else if (!loaded && !post) {
    return (
      <Skeleton variant="rectangular" width="100%">
        <div style={{ paddingTop: '50%' }} />
      </Skeleton>
    )
  } else {
    return <>Not found: {postId}</>
  }
}
