import React, { useMemo } from 'react'
import { connect } from 'react-redux'
import { WhereOptions } from 'react-redux-firebase'
import { useHistory, useLocation } from 'react-router-dom'

import { DocumentData, Query } from '@firebase/firestore-types'
import { FilterData } from '@Components/containers/forms/ListFilterForm'
import { appRoutes, systemRoutes } from '@Constants/config/routeVars'
import { Types } from '@Constants/config/types'
import { ExistingPostData } from '@Constants/post'
import {
  changeCatAction,
  changeTagsAction,
  changeTypeAction,
  changeUidAction,
  changeVisAction,
} from '@State/actions/storageActions'
import { auth as fbAuth } from '@State/firebase'
import { AppDispatch, RootState } from '@State/store'
import { QueryObject, queryFunc } from '@Utils/queryFunc'
import { TypesOfReactChildren } from '@Utils/reactHelpers'

interface StateProps {
  typeSelected: Types
  catSelected: string
  tagsSelected: string[]
  uidSelected: string
  visSelected: string
  sortAscending: boolean
  sortByEdited: boolean
}
interface DispatchProps {
  changeType: (type: Types) => void
  changeCat: (cat: string) => void
  changeTags: (tags: string[]) => void
  changeVis: (visibility: string) => void
  changeUid: (uid: string) => void
}
interface OwnProps {
  children?: TypesOfReactChildren
}

type Props = StateProps & DispatchProps & OwnProps

export type RouteContextType = {
  history: () => void
  location: () => void
  query: () => URLSearchParams
  pathname: string
  typeQuery: Types
  catQuery: string
  tagQuery: string
  tagsQuery: string[]
  visQuery: string
  uidQuery?: string
  urlQuery?: string
  whereQuery: WhereOptions[]
  sourceIdQuery?: string

  convertWhereQuery: (
    queries: WhereOptions[],
    fbPath: Query<DocumentData>
  ) => Query<DocumentData>

  goType: (type: Types, only?: boolean) => void
  goCat: (cat: string, only?: boolean) => void
  goTag: (tag: string, only?: boolean) => void
  goUid: (uid: string, only?: boolean) => void
  goVis: (vis: string, only?: boolean) => void
  goAll: (data?: Partial<FilterData>, path?: string) => void
  goClear: (except?: string) => void

  goUser: (userId: string) => void
  goUserPosts: (userId: string) => void
  goItem: (post: ExistingPostData) => void // Pass in whole post resolve whether to go to id or namespace
  goItemOrPeek: (post: ExistingPostData, peek?: boolean) => void

  goRef: (ref: string, isPeek?: boolean) => void // For use with subPost .ref prop
  goUri: (urlId: string) => void
  goUrl: (url?: string) => void

  openSub: (post: ExistingPostData, parentId?: string) => void

  go: (path: string) => void
  goId: (id: string) => void
  goPost: (post: ExistingPostData) => void
  goPostView: (post: ExistingPostData, newWindow?: boolean) => void
  goEdit: (post: ExistingPostData, newNamespace?: string) => void
  goEditId: (id: string) => void
  goEditNamespace: (namespace: string) => void
  goView: (post: ExistingPostData, specificId?: string) => void
  goViewId: (id: string) => void
  goViewNamespace: (namespace: string, newWindow?: boolean) => void // For use with Post .namespace prop

  goObjectNamespace: (namespace: string) => void

  goPosts: (withQueries?: boolean) => void
  goTags: (withQueries?: boolean) => void
  goTagsPosts: (tags: string[]) => void

  goSource: (sourceData: any) => void

  goPopular: (withQueries?: boolean) => void
  goHome: () => void
  goWithQueries: (path: string) => void
  goBack: () => void
  goStart: (goWithQ?: boolean) => void
  goNew: (goWithQ?: boolean) => void
  goCreate: (goWithQ?: boolean) => void
  goLogin: (fromUrl?: string) => void
  goLogout: (fromUrl?: string) => void
  getHref: (
    routePath: string | string[],
    item?: ExistingPostData,
    fullUrl?: boolean
  ) => string
}

export const RouteContext = React.createContext<RouteContextType>({
  history: () => {
    return
  },
  location: () => {
    return
  },
  // @ts-expect-error useless duplicate typing
  query: () => {
    return
  },
  pathname: '/',
  typeQuery: '',
  catQuery: '',
  tagQuery: '',
  tagsQuery: [],
  visQuery: '',
  uidQuery: '',
  urlQuery: '',
  sourceIdQuery: '',
  whereQuery: [],
  convertWhereQuery: (): Query<DocumentData> => {
    // @ts-expect-error useless duplicate typing
    return
  },
  goType: (type) => {
    return
  },
  goCat: (cat) => {
    return
  },
  goTag: (tag) => {
    return
  },
  goUid: (uid) => {
    return
  },
  goVis: (vis) => {
    return
  },
  goAll: (data, path) => {
    return
  },
  goClear: () => {
    return
  },
  goUser: () => {
    return
  },
  goUserPosts: () => {
    return
  },
  goItem: () => {
    return
  },
  goItemOrPeek: () => {
    return
  },
  goRef: () => {
    return
  },
  goUri: () => {
    return
  },
  goUrl: () => {
    return
  },
  openSub: () => {
    return
  },
  go: () => {
    return
  },
  goId: () => {
    return
  },
  goPost: () => {
    return
  },
  goPostView: () => {
    return
  },
  goEdit: () => {
    return
  },
  goEditId: () => {
    return
  },
  goEditNamespace: () => {
    return
  },
  goHome: () => {
    return
  },
  goView: () => {
    return
  },
  goViewId: () => {
    return
  },
  goViewNamespace: () => {
    return
  },
  goObjectNamespace: () => {
    return
  },
  goPosts: () => {
    return
  },
  goTags: () => {
    return
  },
  goTagsPosts: () => {
    return
  },
  goSource: (sourceData: any) => {
    return
  },
  goPopular: () => {
    return
  },
  goWithQueries: () => {
    return
  },
  goBack: () => {
    return
  },
  goStart: () => {
    return
  },
  goNew: () => {
    return
  },
  goCreate: () => {
    return
  },
  goLogin: () => {
    return
  },
  goLogout: () => {
    return
  },
  getHref: () => {
    return '/'
  },
})

function RouteProvider({
  children,
  typeSelected,
  catSelected,
  tagsSelected,
  visSelected,
  uidSelected,

  sortAscending,
  sortByEdited,

  changeType,
  changeCat,
  changeTags,
  changeVis,
  changeUid,
}: Props) {
  const location = useLocation()
  const history = useHistory()
  const currentPath = location.pathname
  const query = new URLSearchParams(location.search)
  const queryMemo = useMemo<QueryObject>(
    () =>
      queryFunc({
        query,
        selected: {
          // Pass through all store state by default, but queryFunc will override any query params that are present for the query type
          typeSelected,
          catSelected,
          tagsSelected,
          visSelected,
          uidSelected,
          sortAscending,
          sortByEdited,
        },
      }),
    [
      query,
      typeSelected,
      catSelected,
      tagsSelected,
      visSelected,
      uidSelected,
      sortAscending,
      sortByEdited,
    ]
  )
  const {
    typeQuery,
    catQuery,
    tagQuery,
    tagsQuery,
    visQuery,
    urlQuery,
    uidQuery,
    sourceIdQuery,
    whereQuery,
    convertWhereQuery,
  } = queryMemo // Memo used later, TODO: Don't

  /////////////////////////////////
  // Queries
  /////////////////////////////////
  function goClearState(except?: string) {
    if (!(except === 'type')) changeType('')
    if (!(except === 'cat')) changeCat('')
    if (!(except === 'tags')) changeTags([])
    if (!(except === 'uid')) changeUid('')
    if (!(except === 'vis')) changeVis(uidQuery ? 'any' : 'public')
  }

  function queryBuilder(
    queriesObject: {
      type?: string
      cat?: string
      tags?: string[]
      uid?: string
      vis?: string
      sourceId?: string
    },
    newPath?: string
  ): string {
    const { type, cat, tags, uid, vis, sourceId } = queriesObject

    let queryString = newPath ? `${newPath}` : `${currentPath}`
    const hasQueries = type || cat || tags || uid || vis || sourceId

    queryString = hasQueries ? `${queryString}?` : `${queryString}`
    if (type) {
      queryString = `${queryString}&type=${type}`
    }
    if (cat) {
      queryString = `${queryString}&cat=${cat}`
    }
    if (tags && tags.length && tags[0]) {
      queryString = `${queryString}&tag=${tags[0]}`
    }
    if (uid) {
      queryString = `${queryString}&userId=${uid}`
    }
    if (sourceId) {
      queryString = `${queryString}&sourceId=${sourceId}`
    }
    if (vis && vis !== 'public' && uid) {
      queryString = `${queryString}&vis=${vis}`
    }
    console.log('queryBuilder', queryString)
    return queryString
  }

  function goType(type: Types, only?: boolean) {
    if (only) goClearState('type')
    changeType(type)

    history.push(
      queryBuilder({
        type,
        ...(!only && {
          cat: catQuery,
          tags: tagsQuery,
          vis: visQuery,
          uid: uidQuery,
        }),
      })
    )
  }

  function goCat(cat: string, only?: boolean) {
    if (only) goClearState('cat')
    changeCat(cat)

    history.push(
      queryBuilder({
        cat,
        ...(!only && {
          type: typeQuery,
          tags: tagsQuery,
          vis: visQuery,
          uid: uidQuery,
        }),
      })
    )
  }

  function goTag(tagName: string, only?: boolean) {
    if (only) goClearState('tags')
    if (tagName) changeTags([tagName])
    if (!tagName) changeTags([])

    history.push(
      queryBuilder({
        tags: [tagName],
        ...(!only && {
          cat: catQuery,
          type: typeQuery,
          vis: visQuery,
          uid: uidQuery,
        }),
      })
    )
  }

  function goTagsPosts(tags: string[]) {
    if (tags) changeTags(tags)
    if (!tags) changeTags([])

    history.push(
      queryBuilder(
        {
          tags,
        },
        appRoutes.posts
      )
    )
  }

  function goVis(vis: string, only?: boolean) {
    if (only) goClearState('vis')
    changeUid(vis)

    history.push(
      queryBuilder({
        vis,
        ...(only && {
          type: typeQuery,
          tags: tagsQuery,
          uid: uidQuery,
          cat: catQuery,
        }),
      })
    )
  }

  function goUid(uid: string, only?: boolean) {
    console.log('goUid', uid, only)
    if (only) goClearState('uid')
    changeUid(uid)

    history.push(
      queryBuilder({
        uid,
        ...(only && {
          type: typeQuery,
          tags: tagsQuery,
          vis: visQuery,
          cat: catQuery,
        }),
      })
    )
  }

  function goAll(data?: Partial<FilterData>, path?: string) {
    // console.log('goAll', data, path)
    // console.log('queryMemo', queryMemo)
    if (data && !path) {
      const { type, cat, tags, uid, vis } = data
      changeType(type || '')
      changeCat(cat || '')
      changeTags(tags || [])
      changeUid(uid || '')
      changeVis(vis || '')
      history.push(queryBuilder({ type, cat, tags, uid, vis }))
    } else if (!data) {
      // Get data from queryMemo
      const {
        typeQuery: type,
        catQuery: cat,
        tagsQuery: tags,
        uidQuery: uid,
        visQuery: vis,
      } = queryMemo
      changeType(type)
      changeCat(cat)
      changeTags(tags)
      changeUid(uid)
      changeVis(vis)
      if (path) {
        history.push(`${queryBuilder({ type, cat, tags, uid, vis }, path)}`)
      } else {
        history.push(queryBuilder({ type, cat, tags, uid, vis }))
      }
    } else if (data && path) {
      const { type, cat, tags, uid, vis } = data
      changeType(type || typeQuery)
      changeCat(cat || catQuery)
      changeTags(tags || tagsQuery)
      changeUid(uid || uidQuery)
      changeVis(vis || visQuery)

      const combined = {
        type: typeQuery,
        cat: catQuery,
        tags: tagsQuery,
        uid: uidQuery,
        vis: visQuery,
        ...data,
      }
      // console.log(combined)
      history.push(queryBuilder(combined, path))
    }
  }

  function goClear(except?: string) {
    goClearState(except)

    history.push(`${currentPath}`)
  }

  ////////////////////////////////////////////////////
  //  Push
  ///////////////////////////////////////////////////

  function getQueries() {
    return query && `?${query.toString()}`
  }

  function goUser(userId: string) {
    history.push(`/user/${userId}`)
  }

  function goItem(postItem: ExistingPostData) {
    // console.log('goItem', postItem)
    if (postItem.ref) {
      history.push(`/view/${postItem.ref}`)
    } else if (postItem.refId) {
      history.push(`/id/${postItem.refId}`)
    } else if (postItem.parentId) {
      history.push(`/id/${postItem.parentId}/id/${postItem.id}`)
    } else if (postItem.namespace) {
      history.push(`/view/${postItem.namespace}`)
    } else if (postItem.id) {
      history.push(`/id/${postItem.id}`)
    } else {
      console.error(`No Postitem`, postItem)
    }
  }

  function goRef(ref: string, peek?: boolean) {
    if (peek) {
      history.push(`/peek/${ref}${getQueries()}`)
    } else {
      history.push(`/view/${ref}`)
    }
  }

  function goItemOrPeek(postItem: ExistingPostData, peek = false) {
    // const routePrefix = postItem && postItem.type && postItem.type !== 'list' ? `/${postItem.type}/` : ''
    const nsPrefix = peek ? `peek/` : 'view/'
    const idPrefix = peek ? `peek/id/` : 'id/'
    // getQueries()
    if (postItem.namespace) {
      history.push(`/${nsPrefix}${postItem.namespace}`)
    } else if (postItem.id) {
      history.push(`/${idPrefix}${postItem.id}${getQueries()}`)
    } else {
      console.error('No postItem', postItem)
    }
  }

  function openSub(postItem: ExistingPostData, parentId?: string) {
    if (parentId && postItem && postItem.id) {
      history.push(`/id/${parentId}/id/${postItem.id}`)
    } else {
      console.error('No parentId')
      if (postItem.namespace) {
        history.push(`/view/${postItem.namespace}`)
      } else if (postItem.id) {
        history.push(`/id/${postItem.id}`)
      } else {
        console.error('no postItem', postItem)
      }
    }
  }

  function goId(id: string) {
    history.push(`/id/${id}${getQueries()}`)
  }

  function goPost(post: ExistingPostData) {
    const postId = post.id
    // const postNs = post.namespace
    // if (postNs) {
    //   history.push(`/post/${postNs}`)
    // } else {
    history.push(`/post/id/${postId}`)
    // }
  }

  function goPostView(post: ExistingPostData, newWindow?: boolean) {
    const postId = post.id
    const postNs = post.namespace
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    const url = `/view/post/id/${postId}`
    // DODO: Namespace route
    // if (postNs) {
    //   url = `/post/${postNs}`
    // } else {
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    // url = `/view/post/id/${postId}`
    // }

    if (newWindow) {
      window.open(url, '_blank')
    } else {
      history.push(url)
    }
  }

  function goUri(uriRefId: string) {
    history.push(`/uri/${uriRefId}`)
  }

  function goUrl(url: string) {
    window.open(url, '_blank')
  }

  function go(path: string) {
    history.push(path)
  }

  function goWithQueries(path: string) {
    goAll(undefined, path)
  }

  function goEdit(post: ExistingPostData, newNamespace?: string) {
    const { namespace, id } = post
    if (newNamespace) {
      // If namespace not yet on passed post
      history.push(`/edit/${newNamespace}`)
    } else if (namespace) {
      history.push(`/edit/${namespace}`)
    } else if (id) {
      history.push(`/edit/id/${id}`)
    } else {
      console.error('No namespace or id', post)
    }
  }

  function goEditId(id: string) {
    history.push(`/edit/id/${id}`)
  }

  function goEditNamespace(namespace: string) {
    if (namespace) {
      history.push(`/edit/${namespace}`)
    } else {
      console.error('No namespace', namespace)
    }
  }

  function goViewNamespace(namespace: string, newWindow?: boolean) {
    const path = `/view/${namespace}`
    if (newWindow) {
      window.open(path, '_blank')
    } else {
      history.push(path)
    }
  }

  function goObjectNamespace(namespace: string) {
    const objNs = systemRoutes.viewObject
      .replace(':namespace', namespace)
      .trim()
    const path = `${objNs} `
    console.log(`🚀 ~ goObjectNamespace ~ objNs`, objNs)
    console.log(`🚀 ~ goObjectNamespace ~ path`, path)
    history.push(path)
  }

  function goViewId(id: string) {
    history.push(`/id/${id}`)
  }

  function goView(post: ExistingPostData, specificId?: string) {
    console.log('goView', post)
    const { namespace, id } = post
    if (namespace) {
      goViewNamespace(namespace)
    } else if (specificId) {
      goViewId(specificId)
    } else if (id) {
      goViewId(id)
    } else {
      console.error('No namespace or id', post)
    }
  }

  function goPosts(withQueries?: boolean) {
    if (withQueries) {
      goWithQueries(appRoutes.posts)
    } else {
      go(appRoutes.posts)
    }
  }

  function goTags(withQueries?: boolean) {
    if (withQueries) {
      goWithQueries(`/tags`)
    } else {
      go(`/tags`)
    }
  }

  function goSource(sourceData: { sourceId?: string }) {
    history.push(
      queryBuilder(
        {
          sourceId: sourceData?.sourceId,
        },
        appRoutes.posts
      )
    )
  }

  function goPopular(withQueries?: boolean) {
    if (withQueries) {
      goWithQueries(`/popular`)
    } else {
      go(`/popular`)
    }
  }

  function goUserPosts(userId: string) {
    changeUid(userId)

    history.push(
      queryBuilder(
        {
          uid: uidQuery,
        },
        appRoutes.posts
      )
    )
  }

  function goHome() {
    history.push(`/`)
  }

  function goBack() {
    history.goBack()
  }

  function goStart(goWithQ?: boolean) {
    if (goWithQ) {
      goWithQueries(`/types`)
    } else {
      history.push(`/types`)
    }
  }

  function goNew(goWithQ?: boolean) {
    if (goWithQ) {
      goWithQueries(appRoutes.new)
    } else {
      history.push(appRoutes.new)
    }
  }

  function goCreate(goWithQ?: boolean) {
    if (goWithQ) {
      goWithQueries(appRoutes.create)
    } else {
      history.push(appRoutes.create)
    }
  }

  function goLogin(fromUrl?: string) {
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    const lastUrlQuery = `?${fromUrl}`
    history.push(`/login${fromUrl ? lastUrlQuery : ''}`)
  }

  function goLogout(fromUrl?: string) {
    // const lastUrlQuery = `?${fromUrl}`
    fbAuth.signOut().catch(() => {
      console.error('signOut error')
    })
    // history.push(`/${fromUrl ? lastUrlQuery : ''}`)
  }

  function getHref(
    routePath: string | string[],
    item?: ExistingPostData
    // fullUrl?: boolean
  ): string {
    let finalPath = '/'
    if (typeof routePath === 'string') {
      // TODO: Check if single path needs :id extractions
      finalPath = routePath
    } else if (item) {
      // map(where, (path) => {
      const idString = routePath[0]
      const namespaceString = routePath[1]
      const regex = new RegExp(/:id/gm)
      const { id, namespace } = item
      if (namespace) {
        finalPath = namespaceString.replace(/:id/gm, namespace)
      } else {
        finalPath = idString.replace(/:id/gm, id)
      }
      // console.log(path, item.id)
      // })
    } else {
      // No item
    }

    // if (fullUrl) {
    //   return `${brandVars.rootDomain}${finalPath}`
    // }

    return finalPath
  }

  const finalVals = useMemo(
    () => ({
      history,
      location,
      query,
      pathname: currentPath,

      typeQuery,
      catQuery,
      tagQuery,
      tagsQuery,
      uidQuery,
      visQuery,
      urlQuery,

      whereQuery,
      sourceIdQuery,
      convertWhereQuery,

      goType,
      goCat,
      goTag,
      goUid,
      goVis,
      goAll,
      goClear,

      goUser,
      goUserPosts,
      goItem,
      goItemOrPeek,
      goRef,
      goUri,
      goUrl,
      openSub,

      go,
      goId,
      goPost,
      goPostView,
      goEdit,
      goEditId,
      goEditNamespace,

      goView,
      goViewId,
      goViewNamespace,

      goObjectNamespace,

      goPosts,
      goTags,
      goTagsPosts,
      goSource,

      goPopular,
      goHome,
      goWithQueries,
      goBack,

      goStart,
      goNew,
      goCreate,
      goLogin,
      goLogout,
      getHref,
    }),
    [queryMemo, currentPath]
  )
  return (
    // @ts-expect-error
    <RouteContext.Provider value={finalVals}>{children}</RouteContext.Provider>
  )
}

const mapStateToProps = (
  {
    storage: {
      typeSelected,
      catSelected,
      tagsSelected,
      uidSelected,
      visSelected,
      sortAscendingEnabled,
      sortByEditedEnabled,
    },
  }: RootState /*, ownProps*/
) => {
  return {
    typeSelected,
    catSelected,
    tagsSelected,
    uidSelected,
    visSelected,
    sortAscending: sortAscendingEnabled,
    sortByEdited: sortByEditedEnabled,
  }
}

const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    changeType: (type: Types) => dispatch(changeTypeAction(type)),
    changeCat: (value: string) => dispatch(changeCatAction(value)),
    changeTags: (value: string[]) => dispatch(changeTagsAction(value)),
    changeVis: (visibility: string) => dispatch(changeVisAction(visibility)),
    changeUid: (value: string) => dispatch(changeUidAction(value)),
  }
}

export default connect<StateProps, DispatchProps, OwnProps, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(RouteProvider)
