/* eslint-disable */
// import firebase from "firebase/compat/app";
import { firestore } from '@State/firebase'
import { ofType } from 'redux-observable'
import { ajax } from 'rxjs/ajax'
import { map, mapTo, mergeMap } from 'rxjs/operators'

import { sendNotifcationAction } from '@State/actions/appStateActions'
import { fetchGithubUser, fetchGithubUserFulfilled } from '@State/actions/testActions'




export const fetchGithubUserEpic = action$ => action$.pipe(
  ofType(fetchGithubUser().type),
  mergeMap((action: any) =>
    ajax.getJSON(`https://api.github.com/users/${action.payload}`).pipe(
      map((response) => {
        // console.log("epic user", response)
        return fetchGithubUserFulfilled(response)
      })
    )
  )
);

export const testEpic = (action$, store, dependencies) => action$.pipe(
  ofType('TEST'),
  mergeMap(action => {
    // return firestore.collection('test')
    //   // .doc('qgCdtmw9Z9c2O9YitYUx2jl7mOE3')
    //   .add({ test: 'nope' })
    //   .then((docRef: any) => {
    // console.log("Test: ", docRef);
    return dependencies.store.dispatch(sendNotifcationAction('yo', 'info'))
    // })
    // .catch(function(error) {
    //     console.error("Error adding document: ", error);
    // })
  }),
  mapTo({ type: 'TEST_DONE' })
)


// export const testEpic = (action$, store, dependencies) => action$.pipe(
//   ofType('MOVE_STUFF'),
//   mergeMap(action => {
//     return firestore.collection('list')
//           .get()
//           .then((docRef) => {
//               // console.log("Stuff: ", docRef);
//               docRef.forEach((dat) => {
//                 console.log('Move:', dat.id, dat.data())
//                 firestore.collection('postsById').doc(dat.id).set(dat.data())

//                 firestore.collection('list').doc(dat.id).collection('list').get().then((sub) => {
//                   console.log('PostID', dat.id)
//                   sub.forEach((subData) => {
//                     console.log('Move SubPostId:', subData.id, subData.data())
//                     firestore.collection('postsById').doc(dat.id).collection('subPostsById').doc(subData.id).set(subData.data())
//                   })
//                 })
//               })
//               // dependencies.store.dispatch(sendNotifcationAction(docRef, 'info'))
//           })
//           .catch(function(error) {
//               console.error("Error: ", error);
//           })
//   }),
//   mapTo({ type: 'TEST_DONE'})
// )


// export const notificationEpic = (action$, { getState, dispatch }, { getFirebase }) =>
//   action$.ofType("TEST") // stream of notification actions
//     .debounceTime(2000) // debounce SEND_NOTIFICATION actions by 2 seconds
//     .do((action) => {
//       // push the notification object to the notifications ref
//       return getFirebase().push('/test', action.payload)
//     })
//     .mapTo({ type: 'TEST_SENT' });

