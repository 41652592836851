import React, { useContext } from 'react'
import { isEmpty, isLoaded } from 'react-redux-firebase'
import { useHistory, useParams } from 'react-router-dom'

import { CircularProgress, Stack } from '@mui/material'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import makeStyles from '@mui/styles/makeStyles'
import { AuthContext } from '@Components/containers/AuthProvider'
import LoginForm from '@Components/profile/LoginForm'
import { appRoutes } from '@Constants/config/routeVars'
import { useBreakpoints } from '@Constants/config/globalStyles'

const useStyles = makeStyles((theme) => ({
  notFoundTitle: {
    textAlign: 'center',
  },
  button: {
    marginTop: 32,
  },
  notFoundImage: {
    width: '80%',
    [theme.breakpoints.up('md')]: {
      maxHeight: '70vh',
    },
  },
}))

export default function NotFound({ unknown = false }) {
  const { namespace } = useParams<{ namespace: string }>()
  const classes = useStyles()
  const history = useHistory()
  const { userProfile } = useContext(AuthContext)
  const { matchesMobile, matchesMedium, matchesLargest } = useBreakpoints()
  // @ts-ignore
  const { role } = userProfile

  const loaded = isLoaded(userProfile)
  const loggedIn = loaded && !isEmpty(userProfile)

  const notLoggedInComponent = (
    <>
      <Typography className={classes.notFoundTitle} variant="h6">
        Not Logged In
      </Typography>
      <LoginForm />
    </>
  )

  return (
    <Stack
      display="flex"
      direction="column"
      justifyContent="center"
      alignItems="center"
      sx={{ mt: 3 }}
    >
      <>
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          sx={{ mb: 4 }}
        >
          <Typography
            variant={matchesMobile ? 'h5' : 'h3'}
            sx={{ mx: 2, textAlign: 'center' }}
          >
            {unknown ? 'It is not known' : `Not Found, Moved or Accesss Denied`}
          </Typography>
          <img className={classes.notFoundImage} src="/images/404.svg" />
        </Stack>
        {loggedIn ? (
          <Stack direction={matchesMobile ? 'column' : 'row'} spacing={3}>
            <>
              {namespace && role === 'admin' ? (
                <Button
                  onClick={() =>
                    history.push(`${appRoutes.create}?namespace=${namespace}`)
                  }
                >
                  Create Post with Namespace
                </Button>
              ) : null}
              <Button onClick={() => history.push(appRoutes.create)}>
                Create New Post
              </Button>
              <Button
                sx={{ m: 2 }}
                onClick={() => history.push(appRoutes.posts)}
              >
                Browse Posts
              </Button>
              <Button onClick={() => history.push(appRoutes.profile)}>
                Go to Profile
              </Button>
            </>
          </Stack>
        ) : loaded ? (
          notLoggedInComponent
        ) : (
          <CircularProgress />
        )}
      </>
    </Stack>
  )
}
