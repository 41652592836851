import { GraphPost, GraphPostData } from '@Constants/types/graph'
import { FirebaseDate } from '@Utils/postDataFunc'

import { ObjectEditorValue } from '@Components/shared/object-editor/ObjectEditor.types'
import { LocationPost, LocationPostData } from './types/location'
import { PostIds, SharedPostProps, SubPostDates } from '@Constants/types/post'


export type Brands = 'youtube' | 'odysee' | 'wikipedia' | 'twitch' | 'wiktionary' | 'vimeo' | 'rumble'
export type SourceTypes = Brands | string
export type WarningType = 'fraud' | 'criminal' | 'alledged' | 'history' | ''
export type PostVisibility = 'private' | 'public' | 'shared'

// Post
export interface PostData extends SharedPostProps { }

// All individual types that extend properties
interface AllPostTypes extends PostData, LocationPostData, GraphPostData { }

// Use for Create
export interface NewPostData extends AllPostTypes { }

// Use for Read Update Delete
export interface ExistingPostData extends PostIds, AllPostTypes {
}

// Subpost
interface SubPost extends SubPostData {
  parentId: string
}

interface UpgradedSubPost extends SubPostData {
  subPostId: string
}

interface ReffedSubPost extends SubPostData {
  refId?: string
  ref?: string
}

export interface NewSubPostData extends SubPostData {
  added: FirebaseDate
}


// Post types
export interface PostWithObject extends ExistingPostData {
  object: ObjectEditorValue
}

// Any
export type AnyPostData = NewPostData | ExistingPostData
export type PostAndSubPost = AnyPostData & SubPostData
export type PostOrSubPost = AnyPostData | SubPostData
export type AnyExistingPostData = ExistingPostData
export type OnlyPosts = NewPostData & ExistingPostData

// Post queries
export const isPostNew = (post: PostAndSubPost): post is FreshPost => !!(!post.edited || !post.subPostAdded && post.created || post.added)
export const isPostEdited = (post: AnyPostData): post is EditedPost => !!(post.edited || post.subPostAdded || post.published)
export const postHasSubPost = (post: AnyPostData): post is PostWithSubPost => !!(post.subPostAdded || post.subPostEdited)
export const postHasSubPosts = (post: AnyPostData): post is PostWithSubPost => !!(postHasSubPost(post) && post.postCount >= 2)


export const isSubPost = (post: PostOrSubPost): post is SubPost => !!(post.parentId)
// export const isSubPostNew = (post: AnyPostData): post is NewSubPostData => !!(post.added && !post.edited)
// export const isSubPostEdited = (post: AnyPostData): post is SubPost => !!(post.added && post.edited)
// // export const isExistingPost = (post?: AnyPostData): post is ExistingPostData => !!(post && (isPostNew(post) || isPostEdited(post)))

export const isExistingPost = (p?: OnlyPosts): p is ExistingPostData => !!(p?.id && p?.created)

export const isPostWithObject = (p: ExistingPostData): p is PostWithObject => !!p?.object

// export const isPostLocation = (post: AnyPostData): post is LocationPost => !!(post.longlat || post.geoPoint)
// export const isPostGraph = (post: AnyPostData): post is GraphPost => !!(post.values || post.data)


export interface PartialPostData extends Partial<ExistingPostData> { }
export interface SubPostData extends Omit<ExistingPostData, 'created'>, SubPostDates { }


// Component state
export type PostVariant = 'post' | 'subpost'



// Post states
interface FreshPost extends ExistingPostData {
  created: FirebaseDate
  added: FirebaseDate // Can also be subpost
}

interface EditedPost extends FreshPost {
  edited: FirebaseDate
  subPostAdded?: FirebaseDate
  published?: FirebaseDate
}

interface PostWithSubPost extends EditedPost {
  postCount: number
  subPostAdded: FirebaseDate
  subPostEdited?: FirebaseDate
  reverted?: FirebaseDate
}
