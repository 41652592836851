import { YOUTUBE_PREFIX } from '@Components/shared/object-editor/plugins/media/media-embed/parseVideoUrl';
import { PostData } from '@Constants/post'
import urlFunc from '@Utils/urlFunc'

import { MetaTypes, URLMeta, URLObjectType } from './urlFunc'

export interface MetaMedia {
  coverImage?: string;
  embedVideo?: string;
  type: MetaTypes;
  brand?: string;
  id?: string;
}

export function getMetaMedia(urlObj: Partial<URLObjectType> | null): MetaMedia | null {

  const meta: URLMeta | null = urlObj && urlObj.meta ? urlObj.meta : null

  if (meta) {

    let coverImage: string | undefined
    let embedVideo: string | undefined

    // Cover Image
    switch (meta.brand) {
      case 'youtube':
        if (meta.type === 'video') {
          coverImage = meta.id ? `https://img.youtube.com/vi/${meta.id}/0.jpg` : undefined
        } else if (meta.type === 'short') {
          coverImage = meta.id ? `https://i.ytimg.com/vi/${meta.id}/maxres2.jpg` : undefined
        }
        break;

      case 'vimeo':
        coverImage = meta.id ? `https://i.vimeocdn.com/video/${meta.id}_640.jpg` : undefined
        break;

      default:
        break;
    }

    // Embed Video
    switch (meta.brand) {
      case 'youtube':
        // console.log('meta', meta)
        if (meta.type === 'video') {
          embedVideo = meta.id ? `${YOUTUBE_PREFIX}/${meta.id}` : undefined
        } else if (meta.type === 'short') {
          embedVideo = meta.id ? `${YOUTUBE_PREFIX}/${meta.id}` : undefined
        }
        break;

      default:
        break;
    }

    // DODO: Default to favicon data
    // TODO: Default to scraped meta data
    return {
      coverImage,
      embedVideo,
      type: meta.type,
      brand: meta.brand,
      id: meta.id
    }
  } else {
    return meta
  }

}


export function getCoverImage(postItem: Partial<PostData>): string {
  if (postItem && postItem.url) {
    const urlObj = urlFunc(postItem.url)
    // console.log('urlObj', urlObj)
    const meta = getMetaMedia(urlObj)
    // console.log('meta', meta)
    const imgurl = postItem.imgUrl ?
      postItem.imgUrl
      : meta && meta.coverImage ?
        meta.coverImage : ''
    return imgurl
  } else if (postItem.imgUrl) {
    return postItem.imgUrl
  } else {
    return ''
  }
}

export function getEmbedVideo(url: string): MetaMedia | null {
  const urlObj = urlFunc(url)
  const meta = getMetaMedia(urlObj)
  return meta
}