import Favicon from '@Components/ui/Favicon'
import Backdrop from '@mui/material/Backdrop'
import Button from '@mui/material/Button'
import Fade from '@mui/material/Fade'
import Icon from '@mui/material/Icon'
import Modal from '@mui/material/Modal'
import makeStyles from '@mui/styles/makeStyles'
import React from 'react'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    flexDirection: 'column',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    // marginTop: 32,
    [theme.breakpoints.down('md')]: {},
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    // maxHeight: '90vh',
    // maxWidth: '100vh',
    maxHeight: '100%',
    maxWidth: '100%',
    overflowY: 'auto',
    overflowX: 'auto',
    cursor: 'pointer',
    backgroundColor: theme.palette.background.paper,
    // margin: theme.spacing(2, 4, 3),
    display: 'flex',
    flexDirection: 'column',
    // alignItems: 'center',
    // justifyContent: 'center',
  },
  image: {
    objectFit: 'contain',
    maxWidth: '90vh',
    maxHeight: '90vh',
  },
  source: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1.5),
    paddingRight: theme.spacing(1.3),
  },
}))

export default function ImageModal({
  url,
  open = false,
  handleClose,
}: {
  url: string
  open: boolean
  handleClose: (e) => void
}) {
  const classes = useStyles()

  function clickButton() {
    window.open(url, '_blank')
  }

  return (
    <div className={classes.root}>
      <Modal
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        // BackdropComponent={Backdrop}
        // BackdropProps={{
        //   timeout: 500,
        // }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <img
              className={classes.image}
              src={url}
              alt={url}
              onClick={clickButton}
            />
            <div className={classes.source}>
              <Button
                onClick={clickButton}
                startIcon={<Favicon url={url} />}
                endIcon={<Icon>open_in_new</Icon>}
                size="small"
                variant="outlined"
                // className={classes.button}
              >
                SRC
              </Button>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  )
}
