import CatButton from '@Components/ui/CatButton'
import { styled } from '@mui/material/styles'
import { Types } from '@Constants/config/types'
import Backdrop from '@mui/material/Backdrop'
import Grow from '@mui/material/Grow'
import Popover from '@mui/material/Popover'
import makeStyles from '@mui/styles/makeStyles'
import { RootState } from '@State/store'
import React from 'react'
import { connect } from 'react-redux'
import { useLocation } from 'react-router-dom'

import CatPicker from './CatPicker'

const PREFIX = 'CatSelector'

const classes = {
  backdrop: `${PREFIX}-backdrop`,
  body: `${PREFIX}-body`,
}

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.backdrop}`]: {
    zIndex: theme.zIndex.drawer + 1,
  },

  [`& .${classes.body}`]: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
}))

function CatSelector({
  flat,
  mini,
  tiny,
  highlightIcon,
  catSelected,
  onChange,
}: {
  flat?: boolean
  mini?: boolean
  tiny?: boolean
  highlightIcon?: string
  catSelected: string
  onChange?: (type: Types) => void
}) {
  const [anchorEl, setAnchorEl] = React.useState<
    (EventTarget & Element) | null
  >(null)
  const open = Boolean(anchorEl)
  const query = new URLSearchParams(useLocation().search)
  const catQuery = query.get('cat') || catSelected

  // console.log(catQuery, catSelected)

  const handleClick = (newCat: string, event: React.MouseEvent) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const goCat = (newCat: string, e) => {
    if (onChange) onChange(newCat)
    handleClose()
  }

  const handleBackdropClose = () => {
    handleClose()
  }

  return (
    <Root onClick={(e) => e.stopPropagation()}>
      <CatButton
        cat={catQuery}
        mini={mini}
        tiny={tiny}
        goCat={handleClick}
        showTooltip
      />

      <Backdrop
        className={classes.backdrop}
        open={open}
        onClick={handleBackdropClose}
      >
        <Popover
          id="love1"
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          TransitionComponent={Grow}
        >
          <div className={classes.body}>
            <CatPicker
              onChange={goCat}
              flat={flat}
              highlightIcon={highlightIcon}
            />
          </div>
        </Popover>
      </Backdrop>
    </Root>
  )
}
const mapStateToProps = (state: RootState /*, ownProps*/) => {
  return {
    catSelected: state.storage?.catSelected,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(CatSelector)
