import { KEYS_HEADING } from '@Components/shared/object-editor/ObjectEditor.types';
import {
  createPluginFactory,
  onKeyDownToggleElement,
  PlatePlugin,
} from '@udecode/plate-core';

import { HotkeyPlugin } from '@udecode/plate-core';

export interface HeadingPlugin extends HotkeyPlugin { }

export interface HeadingsPlugin {
  /**
   * Heading levels supported from 1 to `levels`
   */
  levels?: number;
}


/**
 * Enables support for headings with configurable levels
 * (from 1 to 6).
 */
export const createHeadingPlugin = createPluginFactory<HeadingsPlugin>({
  key: 'heading',
  options: {
    levels: 6,
  },
  then: (editor, { options: { levels } = {} }) => {
    const plugins: PlatePlugin<HeadingPlugin>[] = [];

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    for (let level = 1; level <= levels!; level++) {
      const key = KEYS_HEADING[level - 1];

      const plugin: PlatePlugin<HeadingPlugin> = {
        key,
        isElement: true,
        deserializeHtml: {
          rules: [
            {
              validNodeName: `H${level}`,
            },
          ],
        },
        handlers: {
          onKeyDown: onKeyDownToggleElement,
        },
        options: {},
      };

      if (level < 4 && level >= 2) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        plugin.options!.hotkey = [`mod+opt+${level}`, `mod+shift+${level}`];
      }

      plugins.push(plugin);
    }

    return {
      plugins,
    };
  },
});
