import { useReadOnly } from 'slate-react'

import { useTheme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Typography from '@mui/material/Typography'
import {
  ELEMENT_PARAGRAPH,
  MentionElement,
  StyledElement,
  createPlateUI,
  findNodePath,
  getNodeString,
  withProps,
} from '@udecode/plate'
import {
  ImageElement,
  MediaEmbedElement,
} from '@Components/shared/object-editor/components'
import { CodeBlockElement } from '@Components/shared/object-editor/components/CodeBlockElement/CodeBlockElement'
import { MentionInputElement } from '@Components/shared/object-editor/components/MentionInputElement/MentionInputElement'
import { PostElement } from '@Components/shared/object-editor/components/PostElement/PostElement'
import { ELEMENT_IMAGE } from '@Components/shared/object-editor/plugins/media/imagePlugin'
import { ELEMENT_MEDIA_EMBED } from '@Components/shared/object-editor/plugins/media/media-embed/createMediaEmbedPlugin'
import { ELEMENT_MENTION_INPUT } from '@Components/shared/object-editor/plugins/mention/createMentionPlugin'
import { elementNormalizedAtPath } from '@Components/shared/object-editor/plugins/normalizationPlugins'
import {
  ELEMENT_ADD,
  ELEMENT_CODE_BLOCK,
  ELEMENT_H1,
  ELEMENT_H2,
  ELEMENT_H3,
  ELEMENT_PATH,
  ELEMENT_POST,
  ELEMENT_REF,
  ELEMENT_TAG,
  ELEMENT_TEXT,
  ELEMENT_TITLE,
} from '@Components/shared/object-editor/ObjectEditor.types'
import { defaultStyles } from '@Constants/config/globalStyles'
import { getMentionColorByType, useValidate } from '@Utils/plateHelpers'

// Map down h1 to h3 ... h4 to h6 (h1 is reserved for main titles and default stuff, this way it's harder to mess up UIX)
const mapHeaderElem = (headerName: string): string => {
  if (headerName === 'title') return 'h2'
  const headerArray = headerName.split('')
  return `${headerArray[0]}${parseInt(headerArray[1], 10) + 2}`
}

export const HeaderElem = (props) => {
  return (
    <Box
      sx={{
        overflow: 'hidden', // Fixes overflow
        ':hover': {
          // FIXME: Make smooth animation; can't animate backgroundImage
          backgroundImage:
            'linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))',
        },
      }}
    >
      <Typography
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-argument
        variant={mapHeaderElem(props.element.type)}
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-argument
        component={mapHeaderElem(props.element.type)}
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        {...props.attributes}
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-argument
        style={props.style}
      >
        {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          props.children
        }
      </Typography>
    </Box>
  )
}

export const TitleElem = (props) => {
  const readOnly = useReadOnly()
  const { palette } = useTheme()
  const { fieldInvalid } = useValidate('title')
  const { editor, element } = props
  const normalizedElement = elementNormalizedAtPath(
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    findNodePath(editor, element)
  )
  const shouldHighlightAsNormalized = normalizedElement && !readOnly

  const body = (
    <Typography
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-argument
      variant={mapHeaderElem(props.element.type)}
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-argument
      component={mapHeaderElem(props.element.type)}
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      {...props.attributes}
      sx={{ position: 'relative' }}
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-argument
      style={props.style}
    >
      <StyledElement {...props}>
        {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          props.children
        }
      </StyledElement>
      {fieldInvalid && (
        <Typography
          variant="subtitle2"
          sx={{ position: 'absolute', top: 0, right: 0 }}
        >
          required
        </Typography>
      )}
    </Typography>
  )

  const sx = {
    p: 1,
    m: 0,
    mt: 1,
  }

  return readOnly ? (
    <Box sx={{ ...sx }}>{body}</Box>
  ) : (
    <Card
      sx={{
        overflow: 'hidden', // Fixes overflow
        ...sx,
        // @ts-ignore nou en
        ...(shouldHighlightAsNormalized && {
          backgroundColor: `${
            fieldInvalid ? palette.error.light : palette.background.paper
          }`,
          border: `1px solid rgba(255, 255, 255, 0.12) !important`,
          borderBottom: 'none !important',
          borderBottomLeftRadius: '0px !important',
          borderBottomRightRadius: '0px !important',
        }),
      }}
    >
      {body}
    </Card>
  )
}

export const TextElem = (props) => {
  const readOnly = useReadOnly()
  const { fieldInvalid } = useValidate('text')
  const { palette } = useTheme()
  const { editor, element } = props
  const normalizedElement = elementNormalizedAtPath(
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    findNodePath(editor, element)
  )
  const shouldHighlightAsNormalized = normalizedElement && !readOnly

  const body = (
    <Typography
      variant="body1"
      component="section"
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      className={props.className}
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      {...props.attributes}
      sx={{ position: 'relative' }}
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-argument
      style={props.style}
    >
      <StyledElement {...props}>
        {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          props.children
        }
      </StyledElement>

      {fieldInvalid && (
        <Typography
          variant="subtitle2"
          sx={{ position: 'absolute', top: 0, right: 0 }}
          {...props}
        >
          required
        </Typography>
      )}
    </Typography>
  )

  const sx = {
    p: 1,
    m: 0,
    mb: 2, // Extra space between normalized stuff and object body
  }

  return readOnly ? (
    <Box sx={{ ...sx }}>{body}</Box>
  ) : (
    <Card
      sx={{
        overflow: 'hidden', // Fix overflow
        ...sx,
        // @ts-ignore nou en
        ...(shouldHighlightAsNormalized && {
          backgroundColor: `${
            fieldInvalid ? palette.error.light : palette.background.paper
          }`,
          borderRadius: '4px',
          border: `1px solid rgba(255, 255, 255, 0.12) !important`,
          borderTop: 'none !important',
          borderTopLeftRadius: '0px !important',
          borderTopRightRadius: '0px !important',
        }),
      }}
    >
      {body}
    </Card>
  )
}

export const ParagraphElem = (props) => {
  const readOnly = useReadOnly()
  const { palette } = useTheme()
  const { editor, element } = props
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  const rootPath = findNodePath(editor, element)
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  const childLength = editor?.children?.length - 1 || 0
  const isLastNode = rootPath?.[0] === childLength
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  const isEmptyAndLastNode = !getNodeString(element) && isLastNode

  return readOnly && isEmptyAndLastNode ? null : (
    <Card
      sx={{
        my: 1,
        p: 1,
        backgroundColor: isEmptyAndLastNode ? 'transparent' : 'transparent',
        backgroundImage: isEmptyAndLastNode ? 'none' : 'none',
        boxShadow: isEmptyAndLastNode ? 'none' : 'none',
        transition: defaultStyles.transitionSmooth,
        ':hover': {
          // backgroundColor: isEmptyNode ? palette.background.paper : undefined,
          // FIXME: Make smooth animation; can't animate backgroundImage
          backgroundImage:
            'linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))',
        },
      }}
    >
      <Typography
        variant="body1"
        component="p"
        // className={props.className}
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        {...props.attributes}
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        style={props.style}
        sx={{
          overflow: 'hidden', // Fix overflow
        }}
      >
        <StyledElement {...props}>
          {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            props.children
          }
        </StyledElement>
      </Typography>
      {/* {isEmptyAndLastNode ? <Icon sx={{ marginLeft: '50%' }}>add</Icon> : null} */}
    </Card>
  )
}

const PathWrapper = (props) => {
  return (
    <Typography
      variant="subtitle1"
      component="span"
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      {...props.attributes}
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      styles={props.styles && props.styles.root}
    >
      <MentionElement {...props} />
    </Typography>
  )
}

const AddWrapper = (props) => {
  return (
    <Card variant="outlined" component="span" sx={{ p: 0, m: 0 }}>
      <Typography
        variant="body1"
        component="span"
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        {...props.attributes}
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        styles={props.styles && props.styles.root}
      >
        <MentionElement {...props} />
      </Typography>
    </Card>
  )
}

const RefWrapper = (props) => {
  return (
    <Typography
      variant="body1"
      component="span"
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      {...props.attributes}
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      styles={props.styles && props.styles.root}
    >
      <MentionElement {...props} />
    </Typography>
  )
}

const TagWrapper = (props) => {
  return (
    <Typography
      variant="body1"
      component="span"
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      {...props.attributes}
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      styles={props.styles && props.styles.root}
      tabIndex={0}
    >
      <MentionElement {...props} />
    </Typography>
  )
}

export const plateComponents = createPlateUI({
  [ELEMENT_H1]: HeaderElem,
  [ELEMENT_H2]: HeaderElem,
  [ELEMENT_H3]: HeaderElem,
  // [ELEMENT_H4]: HeaderElem,
  [ELEMENT_IMAGE]: withProps(ImageElement, {
    styles: {
      root: {
        overflow: 'hidden', // Avoid overflow when draggable
        paddingLeft: '16px', // Avoid resize hidden when draggable
        paddingRight: '16px', // Avoid resize hidden when draggable
      },
      // FIXME: Why u no worky:
      // img: {
      //   ':focus': {
      //     'box-shadow': `0 0 0 1px ${brandVars.secondaryColorDark}`,
      //   },
      // },
    },
  }),

  // Mentions
  [ELEMENT_PATH]: withProps(PathWrapper, {
    styles: {
      root: {
        display: 'inline',
        // padding: '0px 4px',
        backgroundColor: getMentionColorByType(ELEMENT_PATH),
      },
    },
  }),
  [ELEMENT_ADD]: withProps(AddWrapper, {
    styles: {
      root: {
        display: 'inline',
        // padding: '32px 12px',
        backgroundColor: `black`,
      },
    },
  }),
  [ELEMENT_REF]: withProps(RefWrapper, {
    styles: {
      root: {
        display: 'inline',
        // padding: '0px 4px',
        backgroundColor: getMentionColorByType(ELEMENT_REF),
      },
    },
  }),
  [ELEMENT_TAG]: withProps(TagWrapper, {
    styles: {
      root: {
        display: 'inline',
        // padding: '0px 4px',
        backgroundColor: getMentionColorByType(ELEMENT_TAG),
      },
    },
  }),
  [ELEMENT_MENTION_INPUT]: MentionInputElement,
  // [ELEMENT_MENTION_INPUT]: SearchInputElement,

  // Blocks
  [ELEMENT_CODE_BLOCK]: CodeBlockElement,
  [ELEMENT_MEDIA_EMBED]: MediaEmbedElement,
  [ELEMENT_PARAGRAPH]: withProps(ParagraphElem, {
    // as: 'p',
    // styles: {
    //   root: {
    //     // margin: 0,
    //     // padding: '4px 0',
    //   },
    // },
    prefixClassNames: 'p',
  }),
  [ELEMENT_TITLE]: withProps(TitleElem, {
    prefixClassNames: 'text',
  }),
  [ELEMENT_TEXT]: withProps(TextElem, {
    prefixClassNames: 'text',
  }),

  // Ext
  [ELEMENT_POST]: PostElement,
})
