import { useCallback } from 'react'

import IconButton from '@mui/material/IconButton'
import { unwrapLink } from '@udecode/plate'
import {
  AsProps,
  HTMLPropsAs,
  createComponentAs,
  createElementAs,
  focusEditor,
  useEditorRef,
} from '@udecode/plate-core'

export const useUnlinkButton = (
  props: HTMLPropsAs<'button'>
): HTMLPropsAs<'button'> => {
  const editor = useEditorRef()

  return {
    onClick: useCallback(() => {
      unwrapLink(editor)
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      focusEditor(editor, editor.selection!)
    }, [editor]),
    ...props,
  }
}

export const UnlinkButton = createComponentAs<AsProps<'button'>>((props) => {
  const htmlProps = useUnlinkButton(props)

  return createElementAs(IconButton, htmlProps)
})
