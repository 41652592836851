import { ExistingPostData, NewPostData, NewSubPostData, PartialPostData, PostData, SubPostData } from "@Constants/post"
import { Types } from '@Constants/config/types'
import { GraphPostData } from '@Constants/types/graph'
import { FormApi } from 'final-form'

export const CREATE_POST = 'CREATE_POST'
export const POST_CREATED = 'POST_CREATED'

export const EDIT_POST = 'EDIT_POST'
export const POST_EDITED = 'POST_EDITED'

export const DELETE_POST = 'DELETE_POST'
export const POST_DELETED = 'POST_DELETED'

export const ADD_TO_POST = 'ADD_TO_POST'
export const POST_ADDED_TO = 'POST_ADDED_TO'

export const DELETE_SUB_POST = 'DELETE_SUB_POST'
export const SUB_POST_DELETED = 'SUB_POST_DELETED'

export const EDIT_SUB_POST = 'EDIT_SUB_POST'
export const SUB_POST_EDITED = 'SUB_POST_EDITED'

export const UPGRADE_SUB_POST = 'UPGRADE_SUB_POST' // Makes subPost into post, and links it back with refId and original parent/subpost Ids, so either can find eachother
export const SUB_POST_UPGRADED = 'SUB_POST_UPGRADED' // The old subpost should not contain the original data, so only `refId` should be used to fetch and display the actual upgraded Post

export const REVERT_POST = 'REVERT_POST' // Undoes an Upgrade, and copies the post back over the old subpost data.
export const POST_REVERTED = 'POST_REVERTED'

export const LINK_POST = 'LINK_POST' // Adds an existing Post as a new reffed subPost to another existing post, and also backrefs the target postId in the same way so either can find eachother
export const UNLINK_POST = 'UNLINK_POST' // Removes the subPosts that ref eachother
export const POST_LINKED = 'POST_LINKED'
export const POST_UNLINKED = 'POST_UNLINKED'

export const PUBLISH_POST = 'PUBLISH_POST'
export const POST_PUBLISHED = 'POST_PUBLISHED'

export const SEARCH_POSTS = 'SEARCH_POSTS'
export const POSTS_SEARCH_RESULTS = 'POSTS_SEARCH_RESULTS'
export const POSTS_SEARCHED = 'POSTS_SEARCHED'


export interface CreatePostAction {
  type: typeof CREATE_POST
  uid: string;
  data: NewPostData;
  form?: FormApi;
}
export interface PostCreatedAction {
  type: typeof POST_CREATED
}

export interface EditPostAction {
  type: typeof EDIT_POST
  id: string;
  data: ExistingPostData | PartialPostData;
  form?: FormApi;
}
export interface PostEditedAction {
  type: typeof POST_EDITED
}

export interface DeletePostAction {
  type: typeof DELETE_POST
  uid?: string;
  postId: string;
  // postType?: string;
}
export interface PostDeletedAction {
  type: typeof POST_DELETED
}

export interface AddToPostAction {
  type: typeof ADD_TO_POST
  uid: string;
  data: NewSubPostData;
  id?: string;
  form?: FormApi;
}
export interface PostAddedToAction {
  type: typeof POST_ADDED_TO
}

export interface DeleteSubPostAction {
  type: typeof DELETE_SUB_POST
  parentId: string;
  postId: string;
  postType?: Types;
}
export interface SubPostDeletedAction {
  type: typeof SUB_POST_DELETED
}

export interface EditSubPostAction {
  type: typeof EDIT_SUB_POST
  parentId: string;
  postId: string;
  data: SubPostData;
}
export interface SubPostEditedAction {
  type: typeof SUB_POST_EDITED
}

export interface UpgradeSubPostAction {
  type: typeof UPGRADE_SUB_POST
  parentId: string;
  subPostId: string;
  item: SubPostData;
}

export interface SubPostUpgradedAction {
  type: typeof SUB_POST_UPGRADED
}

export interface RevertPostAction {
  type: typeof REVERT_POST
  postId: string;
  parentId: string;
  subPostId: string;
  item: SubPostData;
}

export interface PostRevertedAction {
  type: typeof POST_REVERTED
}

export interface LinkPostAction {
  type: typeof LINK_POST
  postId: string;
  parentId: string;
  item: ExistingPostData;
  uid: string;
  title: string
}

export interface PostLinkedAction {
  type: typeof POST_LINKED
}

export interface PublishPostAction {
  type: typeof PUBLISH_POST
  uid: string;
  id: string;
  data: ExistingPostData;
  namespace?: string;
  handleClose?: (
    cancel: boolean,
    publish?: boolean,
    newNamespace?: string
  ) => void
}
export interface PostPublishedAction {
  type: typeof POST_PUBLISHED
}

export interface SearchPostsAction {
  type: typeof SEARCH_POSTS
  query: string;
}

export interface PostsSearchedResultsAction {
  type: typeof POSTS_SEARCH_RESULTS
  results: PostData[]
}

export interface PostsSearchedAction {
  type: typeof POSTS_SEARCHED
}


export interface CreatePostActionType {
  uid: string;
  data: PostData;
  form?: FormApi;
}

export interface EditPostActionType {
  id: string;
  // uid: string;
  data: PostData;
  form?: FormApi;
}

// CRUD
export function createPostAction({ uid, data, form }: Omit<CreatePostActionType, 'type'>): CreatePostAction {
  return {
    type: CREATE_POST,
    uid,
    data,
    form
  }
}

export function editPostAction({ id, data, form }: Omit<EditPostAction, 'type'>): EditPostAction {
  return {
    type: EDIT_POST,
    id,
    data,
    form
  }
}

export function deletePostAction({ uid, postId }: Omit<DeletePostAction, 'type'>): DeletePostAction {
  return {
    type: DELETE_POST,
    uid,
    postId,
    // postType
  }
}

// Sub post
export function addToPostAction({ uid, id, data, form }: Omit<AddToPostAction, 'type'>): AddToPostAction {
  return {
    type: ADD_TO_POST,
    uid,
    id,
    data,
    form
  }
}

export function deleteSubPostAction({ parentId, postId, postType }: Omit<DeleteSubPostAction, 'type'>): DeleteSubPostAction {
  return {
    type: DELETE_SUB_POST,
    parentId,
    postId,
    postType
  }
}

export function editSubPostAction({ parentId, postId, data }: Omit<EditSubPostAction, 'type'>): EditSubPostAction {
  return {
    type: EDIT_SUB_POST,
    parentId,
    postId,
    data
  }
}

export function upgradeSubPostAction({ parentId, subPostId, item }: Omit<UpgradeSubPostAction, 'type'>): UpgradeSubPostAction {
  return {
    type: UPGRADE_SUB_POST,
    parentId,
    subPostId,
    item
  }
}

export function revertPostAction({ postId, parentId, subPostId, item }: Omit<RevertPostAction, 'type'>): RevertPostAction {
  return {
    type: REVERT_POST,
    postId,
    parentId,
    subPostId,
    item
  }
}
export function linkPostAction({ postId, parentId, item, uid, title }: Omit<LinkPostAction, 'type'>): LinkPostAction {
  return {
    type: LINK_POST,
    postId, // Post to link
    parentId, // Post to add link to
    item,
    uid,
    title
  }
}

export function searchPostsAction({ query }: Omit<SearchPostsAction, 'type'>): SearchPostsAction {
  return {
    type: SEARCH_POSTS,
    query
  }
}

export function postsSearchedResultsAction({ results }: Omit<PostsSearchedResultsAction, 'type'>): PostsSearchedResultsAction {
  return {
    type: POSTS_SEARCH_RESULTS,
    results
  }
}

export function postsSearchedAction(): PostsSearchedAction {
  return {
    type: POSTS_SEARCHED
  }
}


// Publish

export function publishPostAction({ uid, id, data, namespace, handleClose }: Omit<PublishPostAction, 'type'>): PublishPostAction {
  return {
    type: PUBLISH_POST,
    uid,
    id,
    data,
    namespace,
    handleClose
  }
}

export type PostActionTypes =
  CreatePostAction |
  PostCreatedAction |

  EditPostAction |
  PostEditedAction |

  DeletePostAction |
  PostDeletedAction |

  AddToPostAction |
  PostAddedToAction |

  EditSubPostAction |
  SubPostEditedAction |

  UpgradeSubPostAction |
  SubPostUpgradedAction |

  RevertPostAction |
  PostRevertedAction |

  LinkPostAction |
  PostLinkedAction |

  DeleteSubPostAction |
  SubPostDeletedAction |

  PublishPostAction |
  PostPublishedAction |

  SearchPostsAction |
  PostsSearchedResultsAction |
  PostsSearchedAction