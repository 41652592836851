import { useEffect } from 'react'
import { useFocused } from 'slate-react'

import {
  HTMLPropsAs,
  focusEditor,
  getPluginOptions,
  useComposedRef,
  useEditorRef,
  useHotkeys,
  useOnClickOutside
} from '@udecode/plate-core'
import { getSelectionBoundingClientRect } from '@udecode/plate-floating'
import { triggerFloatingLinkInsert } from '@Components/shared/object-editor/components/FloatingLink/utils/triggerFloatingLinkInsert'
import {
  LinkPlugin
} from '@Components/shared/object-editor/plugins/media/link/createLinkPlugin'

import {
  floatingLinkActions,
  floatingLinkSelectors,
  useFloatingLinkSelectors
} from './floatingLinkStore'
import { useFloatingLinkEscape } from './useFloatingLinkEscape'
import { useVirtualFloatingLink } from './useVirtualFloatingLink'
import { FloatingLinkProps } from './FloatingLink'
import { ELEMENT_LINK } from '@Components/shared/object-editor/ObjectEditor.types'


export const useFloatingLinkInsert = ({
  floatingOptions,
  ...props
}: FloatingLinkProps): HTMLPropsAs<'div'> => {
  const editor = useEditorRef();
  const focused = useFocused();
  const mode = useFloatingLinkSelectors().mode();
  const open = useFloatingLinkSelectors().open();

  const { triggerFloatingLinkHotkeys } = getPluginOptions<LinkPlugin>(
    editor,
    ELEMENT_LINK
  );

  useHotkeys(
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    triggerFloatingLinkHotkeys!,
    () => {
      triggerFloatingLinkInsert(editor, {
        focused,
      });
    },
    {
      enableOnContentEditable: true,
    },
    [focused]
  );

  const ref = useOnClickOutside(() => {
    if (floatingLinkSelectors.mode() === 'insert') {
      floatingLinkActions.hide();
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      focusEditor(editor, editor.selection!);
    }
  });

  const { update, style, floating } = useVirtualFloatingLink({
    open: open && mode === 'insert',
    getBoundingClientRect: getSelectionBoundingClientRect,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    whileElementsMounted: () => { },
    ...floatingOptions,
  });

  // wait for update before focusing input
  useEffect(() => {
    if (open) {
      update();
      floatingLinkActions.updated(true);
    } else {
      floatingLinkActions.updated(false);
    }
  }, [open, update]);

  useFloatingLinkEscape();

  return {
    style: {
      ...style,
      zIndex: 1,
    },
    ...props,
    ref: useComposedRef<HTMLElement | null>(props.ref, floating, ref),
  };
};
