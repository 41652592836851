import { Skeleton, SxProps } from '@mui/material'
import PublishButton from '@Components/containers/post/PublishButton'
import PostCard from '@Components/post/PostCard'
import { usePostNamespaceWithSubposts } from '@Utils/usePost'

export default function PostByNamespace({
  namespace,
  imageOnly,
  focused,
  sx,
  delay = 111,
}: {
  namespace: string
  imageOnly?: boolean
  focused?: boolean
  sx?: SxProps
  delay?: number
}) {
  const [post, subPosts, loaded] = usePostNamespaceWithSubposts(
    namespace,
    false
  )
  // const notExists = isEmpty(post) && isLoaded(post)

  if (loaded && post) {
    return (
      <>
        <PostCard imageOnly={imageOnly} post={post} sx={sx} />
        {post.visibility === 'private' && focused ? (
          <PublishButton post={post} enabled sx={{ mt: 2 }} />
        ) : null}
      </>
    )
  } else if (!loaded && !post) {
    return (
      <Skeleton variant="rectangular" width="100%">
        <div style={{ paddingTop: '50%' }} />
      </Skeleton>
    )
  } else {
    return <>Not found: {namespace}</>
  }
}
