import { useContext } from 'react'
import { useLocation } from 'react-router-dom'

import { useTheme } from '@mui/material/styles'
import BottomNavigation from '@mui/material/BottomNavigation'
import BottomNavigationAction from '@mui/material/BottomNavigationAction'
import Hidden from '@mui/material/Hidden'
import Icon from '@mui/material/Icon'
import { RouteContext } from '@Components/containers/RouteProvider'

export const bottomBarCutoff = 'md'

export default function BottomBar() {
  const location = useLocation()
  const path = location.pathname

  const { goHome, goPosts, goTags, goPopular } = useContext(RouteContext)
  const { zIndex } = useTheme()

  const handleChange = (event, newValue) => {
    // setValue(newValue);
    switch (newValue) {
      case 'home':
        goHome()
        break
      case 'posts':
        goPosts()
        break
      case 'tags':
        goTags()
        break
      case 'popular':
        goPopular()
        break

      default:
        break
    }
  }

  return (
    <Hidden mdUp={bottomBarCutoff === 'md'}>
      <BottomNavigation
        value={path}
        onChange={handleChange}
        sx={{
          position: 'fixed',
          bottom: 0,
          width: '100vw',
          zIndex: zIndex.appBar,
        }}
      >
        <BottomNavigationAction
          label="Home"
          value="home"
          icon={<Icon>home</Icon>}
        />
        <BottomNavigationAction
          label="Posts"
          value="posts"
          icon={<Icon>all_inbox</Icon>}
        />
        <BottomNavigationAction
          label="Tags"
          value="tags"
          icon={<Icon>local_offer</Icon>}
        />
        {/* <BottomNavigationAction
          label="Popular"
          value="popular"
          icon={<Icon>favorite</Icon>}
        /> */}
      </BottomNavigation>
    </Hidden>
  )
}
