export const HOTKEYS: Record<string, string> = {
  "mod+b": "bold",
  "mod+i": "italic",
  // "mod+u": "underline",
  // "mod+r": "strikeThrough"
};

// TODO: add a shortcut for indenting and dedenting list items
export const BLOCK_HOTKEYS: Record<string, string> = {
  // "mod+alt+1": "heading-one",
  // "mod+alt+2": "heading-two",
  // "mod+alt+3": "heading-three",
  "mod+alt+4": "heading-four",
  "mod+alt+5": "heading-five",
  "mod+alt+6": "heading-six",
  "mod+.": "ul_list",
  // "mod+/": "numbered-list",
  // "mod+`": "code"
};

export const LIST_TYPES: string[] = ["numbered-list", "bulleted-list", "ol_list", "ul_list", "ol", "ul"];

export const TEXT_ALIGN_TYPES = ['left', 'center', 'right', 'justify']