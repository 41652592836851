
export type UserRoles = 'admin' | 'executive'
  | 'editor' | 'author' | 'creator'
  | 'contributor' | 'user'

const ADMIN_ONLY: UserRoles[] = ['admin'] // DON'T CHANGE!
const authors: UserRoles[] = ['author', ...ADMIN_ONLY]
const creators: UserRoles[] = ['creator', ...authors]

export const roleVars = {
  visibility: {
    extension: [...ADMIN_ONLY]
  },
  actions: {
    listPosts: [...authors]
  },
  menu: {
    myStuff: [...authors], // My Stuff, Start & Create
    users: [...authors],
    uris: [...authors],
    urls: [...ADMIN_ONLY],
    tags: [...authors],
    cats: [...authors],
    type: [...authors],
    types: [...authors],
    namespaces: [...authors],
    profile: [...authors],
    adminToggle: [...ADMIN_ONLY],
    test: [...ADMIN_ONLY],
  },
  topBar: {
    clearFilters: [...authors],
    catSelector: [...authors],
    typeSelector: [...authors],
    searchBar: [...authors]
  },

  fab: {
    add: [...authors],
    view: [...authors],
    edit: [...authors],
    editNamespace: [...authors],
    visibilityNamespace: [...authors],
  },
  profile: {
    posts: [...authors],
    applications: [...ADMIN_ONLY]
  }
}