import ReactDOM from 'react-dom'
// Import the Slate editor factory.
import { Descendant, BaseEditor } from 'slate'
import { useFocused, useSelected, ReactEditor } from 'slate-react'

import { alpha } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Icon from '@mui/material/Icon'
import Typography from '@mui/material/Typography'
import makeStyles from '@mui/styles/makeStyles'
import VideoEmbed from '@Components/shared/embed/VideoEmbed'
import Favicon from '@Components/ui/Favicon'
import { GeoData } from '@Constants/types/geo'
// Import React dependencies.
import { Datum } from '@Constants/types/graph'
import urlFunc from '@Utils/urlFunc'

const useStyles = makeStyles((theme) => ({
  url: {
    display: 'inline-block',
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
  },

  noClickElem: {
    userSelect: 'none',
  },

  blockQuote: {
    margin: 0,
    marginTop: 16,
    marginBottom: 16,
    borderLeft: ' 0.25em solid #95a8bc',
    // color: '#6d7e8e',
    // backgroundColor: '#ced8e2',
    padding: '0 1em',
  },

  mention: {
    padding: '3px 3px 2px',
    margin: '0 1px',
    verticalAlign: 'baseline',
    display: 'inline-block',
    borderRadius: '4px',
    backgroundColor: theme.palette.background.default,
    fontSize: '0.9em',
    // boxShadow: selected && focused ? '0 0 0 2px #B4D5FF' : 'none',
  },
  codeElementPre: {
    backgroundColor: theme.palette.background.paper,
    whiteSpace: 'pre-wrap',
  },
  codeElement: {
    backgroundColor: theme.palette.background.default,
    whiteSpace: 'pre-wrap',
  },
}))

export const Mention = ({ attributes, children, element }) => {
  const classes = useStyles()
  const selected = useSelected()
  const focused = useFocused()
  return (
    <span
      {...attributes}
      className={classes.mention}
      contentEditable={false}
      style={{ border: '1px solid red' }}
    >
      @
      {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        element.character
      }
      {children}
    </span>
  )
}

export const CodeElement = (props) => {
  const classes = useStyles()
  return (
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    <pre {...props.attributes} className={classes.codeElementPre}>
      <code className={classes.codeElement}>
        {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          props.children
        }
      </code>
    </pre>
  )
}

export const DefaultElement = (props) => {
  return (
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    <Typography variant="body1" component="div" {...props.attributes}>
      {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        props.children
      }
    </Typography>
  )
}

// Put this at the start and end of an inline component to work around this Chromium bug:
// https://bugs.chromium.org/p/chromium/issues/detail?id=1249405
const InlineChromiumBugfix = () => (
  <span contentEditable={false} style={{ fontSize: 0 }}>
    ${String.fromCodePoint(160) /* Non-breaking space */}
  </span>
)

export const renderSlateElems = (
  props,
  history?: any,
  mini?: boolean,
  editing?: boolean
) => {
  const classes = useStyles()
  const { attributes, children, element } = props
  // console.log(props)
  // console.log('render props', props)
  // console.log('render type', props?.element?.type)
  // console.log('render elem', element)
  // console.log('render attributes', attributes)
  // console.log('render children', children)
  // TODO: Figure out how to preserve empty lines:
  // if (children === '' && parent.object === 'block' && parent.text === '') {
  //   return <span data-slate-empty-block>{'\n'}</span>
  // }

  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  switch (props?.element?.type) {
    case 'mention':
      return <Mention {...props} />

    case 'code':
      return <code {...attributes}>{children}</code>

    case 'p':
    case 'paragraph':
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      const variant = props?.element?.variant || 'p'
      return (
        <Typography
          {...attributes}
          variant={mini ? 'body2' : 'body1'}
          component={variant}
          // sx={{ py: 1 }}
        >
          {children}
        </Typography>
      )

    case 'u':
    case 'underline':
      return <u {...attributes}>{children}</u>

    case 'code-block':
    case 'code_block':
      return <CodeElement {...props} />

    case 'quote':
    case 'blockquote':
    case 'block_quote':
    case 'block-quote':
      return (
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        <blockquote {...attributes} className={classes.blockQuote}>
          {children}
        </blockquote>
      )

    case 'link':
    case 'a':
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
      const urlItem = urlFunc(element.url)
      // console.log('🚀 urlItem', urlItem)
      const selected = useSelected()

      const { isLocal } = urlItem
      const target = urlItem && isLocal ? '_self' : '_blank'
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/restrict-template-expressions
      const href = isLocal ? `/view/${element.url}` : element.url
      if (editing) {
        return (
          <span
            style={
              selected
                ? { color: 'purple', boxShadow: `0 0 0 3px #ccc` }
                : { color: 'purple' }
            }
          >
            <InlineChromiumBugfix />
            {children}
            <InlineChromiumBugfix />
          </span>
        )
      } else {
        return (
          // <Link {...attributes}>{children}</Link>
          // <PlateLink {...attributes} element={{ url: element.url }}>
          //   {children}
          //   {/* <Icon className={classes.noClickElem} contentEditable={false}>open_in_new</Icon> */}
          // </PlateLink>
          // <span style={{ color: 'blue' }}>{children}</span>
          <a
            href={href}
            target={target}
            onClick={(e) => {
              e.preventDefault()
              // console.log('🚀 isLocal', isLocal, history)
              if (isLocal) {
                // eslint-disable-next-line  @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
                history.push(href)
              } else if (!history) {
                console.error('no history obj passed')
              } else {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-argument
                window.open(element.url, '_blank')
              }
            }}
            // {...attributes}
            // style={selected ? { boxShadow: `0 0 0 3px #ccc` } : {}}
          >
            <InlineChromiumBugfix />
            {children}
            <InlineChromiumBugfix />
          </a>
        )
      }
    case 'bulleted-list':
    case 'ul':
    case 'ul_list':
      return <ul {...attributes}>{children}</ul>

    case 'ordered-list':
    case 'numbered-list':
    case 'ol':
    case 'ol_list':
      return <ol {...attributes}>{children}</ol>
    case 'list_item':
    case 'list-item':
    case 'li':
      return <li {...attributes}>{children}</li>

    case 'heading-one':
    case 'heading_one':
    case 'h1':
    case 'heading-two':
    case 'heading_two':
    case 'h2':
    case 'heading-three':
    case 'heading_three':
    case 'h3':
      return (
        <Typography variant="h4" component="h4" {...attributes}>
          {children}
        </Typography>
      )
    case 'heading-four':
    case 'heading_four':
    case 'h4':
      return (
        <Typography variant="h5" component="h5" {...attributes}>
          {children}
        </Typography>
      )
    case 'heading-five':
    case 'heading_five':
    case 'h5':
      return (
        <Typography variant="h6" component="h6" {...attributes}>
          {children}
        </Typography>
      )
    case 'heading-six':
    case 'heading_six':
    case 'h6':
      return (
        <Typography variant="subtitle2" component="div" {...attributes}>
          {children}
        </Typography>
      )
    default:
      return <DefaultElement {...props} />
  }
}

export const Leaf = (props) => {
  // console.log('Leaf', props)
  const { attributes, children, leaf } = props
  let childs = children

  // console.log('LEAF props', props)
  // console.log('LEAF', leaf)
  // console.log('LEAF children', children)
  // console.log('LEAF attributes', attributes)

  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  if (leaf.bold) childs = <strong>{childs}</strong>

  // if (leaf.code) childs = <code>{childs}</code>;

  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  if (leaf.italic) childs = <em>{childs}</em>

  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  if (leaf.underline) childs = <u>{childs}</u>

  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  if (leaf.strikeThrough)
    childs = <span style={{ textDecoration: 'line-through' }}>{childs}</span>

  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  if (leaf.code)
    childs = (
      <Box component="span" sx={{ backgroundColor: 'peru', px: 0.8, py: 0.2 }}>
        {childs}
      </Box>
    )

  return <span {...attributes}>{childs}</span>

  // return (
  //   <span
  //     {...props.attributes}
  //     style={{
  //       fontWeight: leaf.bold ? 'bold' : 'normal',
  //       fontStyle: leaf.italic ? 'italic' : 'normal',
  //     }}
  //   >
  //     {children}
  //   </span>
  // )
}

export const Link = (props) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-argument
  const urlItem = urlFunc(props.href)
  // console.log(props)
  // console.log(urlItem)
  const { node, children } = props
  const classes = useStyles()

  // TODO: Detect if link is not url but a ref
  return urlItem &&
    urlItem.params &&
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-argument
    Object.keys(urlItem.params).length > 0 &&
    urlItem.host ? (
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    <VideoEmbed {...props.attributes} url={urlItem.href} />
  ) : (
    <Typography
      variant="caption"
      color="textSecondary"
      className={classes.url}
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      {...props.attributes}
    >
      <Box
        display="inline"
        component="div"
        letterSpacing={2.4}
        fontWeight="fontWeightBold"
      >
        <Button
          target="blank"
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          href={props.href}
          // @ts-expect-error
          className={{ ...classes.aHref, ...classes.noClickElem }}
        >
          {urlItem.url ? (
            <Favicon
              // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
              url={urlItem.url}
              // @ts-expect-error
              className={{ ...classes.icon, ...classes.noClickElem }}
            />
          ) : null}

          {node
            ? // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
              node.title
              ? // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                node.title
              : children
              ? children
              : urlItem && urlItem.url
              ? urlItem.url
              : urlItem.href
            : 'error'}
          <Icon className={classes.noClickElem}>open_in_new</Icon>
        </Button>
      </Box>
    </Typography>
  )
}

export const Portal = ({ children }) => {
  return typeof document === 'object'
    ? // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      ReactDOM.createPortal(children, document.body)
    : null
}

export type CustomEditor = BaseEditor & ReactEditor & History

// this does nothing?
declare module 'slate-react' {
  interface CustomTypes {
    Title: CustomTitle
    Text: CustomText
    Term: TermBlock
    Media: MediaBlock
    Graph: GraphBlock
    Location: LocationBlock
    Editor: CustomEditor
  }
}
export type FormattedText = {
  text: string
  bold?: boolean
  italic?: boolean
  underline?: boolean
}

// Generic
interface GenericElement {
  children: CustomText[]
}

export type ParagraphBlock = {
  type: 'p' | 'paragraph'
  children: CustomText[]
  variant?: string // Mui Butotn variants
}

export type HeadingElement = {
  type: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
  children: CustomText[]
  level?: number
}

export type LinkElement = {
  type: 'a' | 'link'
  url: string
  children: CustomText[]
}

export type CustomTitle = FormattedText
export type CustomText = FormattedText

// export type CustomEditor = BaseEditor & ReactEditor & HistoryEditor
export type CustomElement =
  | GenericElement
  | CustomTitle
  | CustomText
  | ParagraphBlock
  | HeadingElement
  | LinkElement
  | Descendant

// Type blocks

export type TimelineElement = {
  // Is a variant
  type: 'timeline'
  series?: string[]
  children: CustomElement[]
  date?: Datum
  value?: string
}

interface TermBlock {
  type: 'term'
  series?: string[]
  children?: CustomElement[]
  url?: string
  coverUrl?: string
}

interface MediaBlock {
  type: 'media'
  series?: string[]
  children?: CustomElement[]
  url?: string
  coverUrl?: string
}

interface GraphBlock {
  type: 'graph'
  series?: string[]
  children?: CustomElement[]
  date?: Datum
  data?: Datum[]
}

interface LocationBlock {
  type: 'location'
  series?: string[]
  children?: CustomElement[]
  geoData?: GeoData[]
}

export type TypeBlocks =
  | TermBlock
  | MediaBlock
  | GraphBlock
  | LocationBlock
  | Descendant

export type SlateElements = CustomElement | TypeBlocks
