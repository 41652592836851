import {
  createObjectEditorPlugins,
  ELEMENT_ADD,
  ELEMENT_PATH,
  ELEMENT_REF,
  ELEMENT_TAG,
  ObjectEditorPlatePlugin,
  TRIGGER_ADD,
  TRIGGER_PATH,
  TRIGGER_REF,
  TRIGGER_TAG,
} from '@Components/shared/object-editor/ObjectEditor.types'
import { MentionElement } from '@udecode/plate-ui-mention'
import { useMentionData } from '@Utils/plateHelpers'
import { MentionPlugin } from '@Components/shared/object-editor/components/MentionInputElement/MentionInputElement'
import { createMentionPlugin } from '@Components/shared/object-editor/plugins/mention/createMentionPlugin'
import {
  TComboboxItemBase,
  TComboboxPath,
  TComboboxRef,
  TComboboxTag,
} from '@Components/shared/object-editor/components/Combobox/Combobox.types'
import { useState } from 'react'
import Popover from '@mui/material/Popover'
import SubPostByIds from '@Components/containers/post/SubPostByIds'
import { RefData } from '@Components/shared/object-editor/components/AsyncCombobox/RefCombobox'
import { PathData } from '@Components/shared/object-editor/components/AsyncCombobox/PathCombobox'
import PostByNamespace from '@Components/containers/post/PostByNamespace'
import PostById from '@Components/containers/post/PostById'
import { TagData } from '@Components/shared/object-editor/components/AsyncCombobox/TagCombobox'

interface RenderElem {
  value: string
  url: string
}

const PathPopover = ({
  anchorEl,
  handleClose,
  data,
}: {
  anchorEl: HTMLSpanElement | null
  handleClose: (e) => void
  data: PathData
}) => {
  const { parentId, subPostId } = data
  const internalOpen = Boolean(anchorEl)
  const id = internalOpen ? 'path-popover' : undefined

  return (
    <Popover
      id={id}
      open={internalOpen}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      onClick={(e) => e.stopPropagation()} // Allow clicking within without closing
      onKeyUp={(e) => {
        if (e.key === 'l') {
          handleClose(e)
        }
      }}
    >
      {parentId && subPostId ? (
        <SubPostByIds parentId={parentId} subPostId={subPostId} />
      ) : (
        <>No ids</>
      )}
    </Popover>
  )
}

const RefPopover = ({
  anchorEl,
  handleClose,
  data,
}: {
  anchorEl: HTMLSpanElement | null
  handleClose: (e) => void
  data: RefData
}) => {
  const { refId, ref } = data
  const internalOpen = Boolean(anchorEl)
  const id = internalOpen ? 'ref-popover' : undefined

  return (
    <Popover
      id={id}
      open={internalOpen}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      onClick={(e) => e.stopPropagation()} // Allow clicking within without closing
      onKeyUp={(e) => {
        if (e.key === 'l') {
          handleClose(e)
        }
      }}
    >
      {refId ? ( // Always prioritise id
        // TODO: if not exists anymore, fallback to ref/namespace
        <PostById postId={refId} />
      ) : ref ? (
        <PostByNamespace namespace={ref} />
      ) : (
        <>No ref value!</>
      )}
    </Popover>
  )
}

const TagPopover = ({
  anchorEl,
  handleClose,
  data,
}: {
  anchorEl: HTMLSpanElement | null
  handleClose: (e) => void
  data: TagData
}) => {
  const { postId, tags } = data
  const internalOpen = Boolean(anchorEl)
  const id = internalOpen ? 'ref-popover' : undefined

  return (
    <Popover
      id={id}
      open={internalOpen}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      onClick={(e) => e.stopPropagation()} // Allow clicking within without closing
      onKeyUp={(e) => {
        if (e.key === 'l') {
          handleClose(e)
        }
      }}
    >
      {postId ? <PostById postId={postId} /> : <>No postId value!</>}
    </Popover>
  )
}

export const pathMentionPlugin: Partial<
  ObjectEditorPlatePlugin<MentionPlugin>
> = {
  key: ELEMENT_PATH,
  component: MentionElement,
  props: (editor) => {
    const data = useMentionData(editor) as PathData

    const [anchorEl, setAnchorEl] = useState<HTMLSpanElement | null>(null)

    const handleClick = (
      event:
        | React.KeyboardEvent<HTMLSpanElement>
        | React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
      setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
      setAnchorEl(null)
    }

    const renderLabel = (element: RenderElem) => {
      return (
        <span
          title={element.url}
          style={{
            cursor: 'pointer',
          }}
          onKeyUp={(e) => {
            if (e.key === 'Enter' || e.key === 'o') {
              handleClick(e)
            }
          }}
          tabIndex={0}
        >
          {element.value}
          <PathPopover
            anchorEl={anchorEl}
            handleClose={handleClose}
            data={data}
          />
        </span>
      )
    }

    const onMentionClicked = (e: React.MouseEvent<HTMLButtonElement>) => {
      if (!anchorEl) {
        handleClick(e)
      } else {
        handleClose()
      }
    }

    return {
      onClick: onMentionClicked,
      renderLabel,
    }
  },
  options: {
    trigger: TRIGGER_PATH,
    inputCreation: { key: 'creationId', value: 'main' },
    createMentionNode: (item: TComboboxPath) => {
      console.log(`🚀 ~ create`, item)
      return {
        id: item.subPostId,
        value: item.title,
        parentId: item.parentId,
        subPostId: item.subPostId,
      }
    },
  },
}

export const addMentionPlugin: Partial<ObjectEditorPlatePlugin<MentionPlugin>> =
  {
    key: ELEMENT_ADD,
    component: MentionElement,
    props: (editor) => {
      const onMentionClicked = () => {
        console.log('Add:', editor)
      }

      const renderLabel = (element: RenderElem) => {
        return (
          <span
            title={element.url}
            style={{
              cursor: 'pointer',
            }}
          >
            {element.value}
          </span>
        )
      }

      return {
        onClick: onMentionClicked,
        renderLabel,
      }
    },
    handlers: {
      // onKeyDown: mentionOnKeyDownHandler({
      //   query: (editor) => {
      //     console.log(
      //       '🚀 ~ file: basicNodesPlugins.tsx ~ line 66 ~ editor',
      //       editor
      //     )
      //     return true
      //   },
      // }),
    },
    options: {
      trigger: TRIGGER_ADD,
      // hotkey:'mod+#',
      inputCreation: { key: 'creationId', value: 'main' },
      createMentionNode: (item: TComboboxItemBase) => {
        return {
          id: item.key,
          value: item.text,
          // url: item.email,
        }
      },
    },
  }

export const refMentionPlugin: Partial<ObjectEditorPlatePlugin<MentionPlugin>> =
  {
    key: ELEMENT_REF,
    component: MentionElement,
    props: (editor) => {
      const data = useMentionData(editor) as RefData

      const [anchorEl, setAnchorEl] = useState<HTMLSpanElement | null>(null)

      const handleClick = (
        event:
          | React.KeyboardEvent<HTMLSpanElement>
          | React.MouseEvent<HTMLButtonElement, MouseEvent>
      ) => {
        setAnchorEl(event.currentTarget)
      }

      const handleClose = () => {
        setAnchorEl(null)
      }

      const renderLabel = (element: RenderElem) => {
        return (
          <span
            title={element.url}
            style={{
              cursor: 'pointer',
            }}
            onKeyUp={(e) => {
              if (e.key === 'Enter' || e.key === 'o') {
                handleClick(e)
              }
            }}
            tabIndex={0}
          >
            {element.value}
            <RefPopover
              anchorEl={anchorEl}
              handleClose={handleClose}
              data={data}
            />
          </span>
        )
      }

      const onMentionClicked = (
        e: React.MouseEvent<HTMLButtonElement, MouseEvent>
      ) => {
        if (!anchorEl) {
          handleClick(e)
        } else {
          handleClose()
        }
      }

      return {
        onClick: onMentionClicked,
        renderLabel,
      }
    },
    handlers: {
      // onKeyDown: mentionOnKeyDownHandler({
      //   query: (editor) => {
      //     console.log(
      //       '🚀 ~ file: basicNodesPlugins.tsx ~ line 66 ~ editor',
      //       editor
      //     )
      //     return true
      //   },
      // }),
    },
    options: {
      trigger: TRIGGER_REF,
      // hotkey:'mod+#',
      inputCreation: { key: 'creationId', value: 'main' },
      createMentionNode: (item: TComboboxRef) => {
        console.log(`🚀 ~ item`, item)
        return {
          id: item.key,
          value: item.title,
          refId: item.refId,
          ref: item.ref,
        }
      },
    },
  }

export const tagMentionPlugin: Partial<ObjectEditorPlatePlugin<MentionPlugin>> =
  {
    key: ELEMENT_TAG,
    component: MentionElement,
    props: (editor) => {
      const data = useMentionData(editor) as TagData

      const [anchorEl, setAnchorEl] = useState<HTMLSpanElement | null>(null)

      const handleClick = (
        event:
          | React.KeyboardEvent<HTMLSpanElement>
          | React.MouseEvent<HTMLButtonElement, MouseEvent>
      ) => {
        setAnchorEl(event.currentTarget)
      }

      const handleClose = () => {
        setAnchorEl(null)
      }

      const renderLabel = (element: RenderElem) => {
        return (
          <span
            title={element.url}
            style={{
              cursor: 'pointer',
              // border: '6px solid blue',
            }}
            onKeyUp={(e) => {
              if (e.key === 'Enter' || e.key === 'o') {
                handleClick(e)
              }
            }}
            tabIndex={0}
          >
            {element.value}
            <TagPopover
              anchorEl={anchorEl}
              handleClose={handleClose}
              data={data}
            />
          </span>
        )
      }

      const onMentionClicked = (e: React.MouseEvent<HTMLButtonElement>) => {
        if (!anchorEl) {
          handleClick(e)
        } else {
          handleClose()
        }
      }

      return {
        onClick: onMentionClicked,
        renderLabel,
      }
    },
    options: {
      trigger: TRIGGER_TAG,
      // hotkey:'mod+#',
      inputCreation: { key: 'creationId', value: 'main' },
      createMentionNode: (item: TComboboxTag) => {
        console.log(`🚀 ~ item`, item)
        return {
          id: item.key,
          value: item.title,
          tags: item.tags,
          postId: item.postId,
        }
      },
    },
  }

export const mentionPlugins = createObjectEditorPlugins([
  createMentionPlugin(pathMentionPlugin),
  // createMentionPlugin(addMentionPlugin),
  createMentionPlugin(refMentionPlugin),
  createMentionPlugin(tagMentionPlugin),
])
