import { ObjectEditorPlatePlugin } from '@Components/shared/object-editor/ObjectEditor.types';
import { LinkPlugin } from '@Components/shared/object-editor/plugins/media/link/createLinkPlugin';
import { PlateFloatingLink } from '@Components/shared/object-editor/plugins/media/link/PlateFloatingLink';

export const linkPlugin: Partial<ObjectEditorPlatePlugin<LinkPlugin>> = {
  renderAfterEditable: PlateFloatingLink,
  options: {
    // isUrl: (url: string) => {
    //   return true
    // },
    // triggerFloatingLinkHotkeys: 'ctrl+shift+k'
  }
};
