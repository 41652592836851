import { alignPlugin } from '@Components/shared/object-editor/plugins/alignPlugin'
import { createAlignPlugin } from '@Components/shared/object-editor/plugins/formatting/createAlignPlugin'
import { createIndentPlugin } from '@Components/shared/object-editor/plugins/formatting/createIndentPlugin'
import { indentPlugin } from '@Components/shared/object-editor/plugins/indentPlugin'

import { createObjectEditorPlugins } from '../ObjectEditor.types'


export const readOnlyFormattingPlugins = createObjectEditorPlugins(
  [

    // Indent
    createIndentPlugin(indentPlugin),

    // Align
    createAlignPlugin(alignPlugin)
  ]
)
