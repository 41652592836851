import { cloneDeep } from 'lodash'
import { useState } from 'react'
import { BaseOperation } from 'slate'

import { findNodePath, setNodes, unsetNodes, usePlateEditorState, PlateEditor } from '@udecode/plate'
import { TMentionElement, TMentionInputElement } from '@Components/shared/object-editor/components/MentionInputElement/MentionInputElement'
import { POST_NORMALIZATION_RULES } from '@Components/shared/object-editor/plugins/normalizationPlugins'
import {
  COLOR_PATH,
  COLOR_REF,
  COLOR_TAG,
  ELEMENT_PATH,
  ELEMENT_REF,
  ELEMENT_TAG,
  ObjectEditorType,
  TRIGGER_PATH,
  TRIGGER_REF,
  TRIGGER_TAG
} from '@Components/shared/object-editor/ObjectEditor.types'
import { RefData } from '@Components/shared/object-editor/components/AsyncCombobox/RefCombobox'
import { PathData } from '@Components/shared/object-editor/components/AsyncCombobox/PathCombobox'
import { TagData } from '@Components/shared/object-editor/components/AsyncCombobox/TagCombobox'


export const getMentionColorByType = (type: string) => {
  const mentionColor = type === ELEMENT_REF ? COLOR_REF : type === ELEMENT_PATH ? COLOR_PATH : type === ELEMENT_TAG ? COLOR_TAG : 'red'
  return mentionColor
}

export const getMentionColorByTrigger = (element: TMentionElement) => {
  const trigger = element?.trigger
  const mentionColor = trigger === TRIGGER_REF ? COLOR_REF : trigger === TRIGGER_PATH ? COLOR_PATH : trigger === TRIGGER_TAG ? COLOR_TAG : 'red'
  return mentionColor
}

export const compareHistory = (undoOrRedo: BaseOperation[][], comparee: BaseOperation[][]): boolean => {
  const lastEntry = undoOrRedo[undoOrRedo.length - 1]
  const prevEntry = comparee[undoOrRedo.length - 1]
  return lastEntry?.length === prevEntry?.length
}


export const getUndo = (editor: ObjectEditorType): {
  undos: BaseOperation[][],
  redos: BaseOperation[][],
  hasUndos: boolean,
  hasRedos: boolean,
  history: History
} => {
  const history = editor?.history
  if (history) {
    const { undos, redos } = history
    const hasUndos = undos?.length >= 1
    const hasRedos = redos?.length >= 1
    return {
      undos,
      redos,
      hasUndos,
      hasRedos,
      // @ts-ignore idk what to type this
      history,
    }
  }
  return {
    undos: [],
    redos: [],
    hasUndos: false,
    hasRedos: false,
    history
  }
}

let LAST_SAVE_STATE: BaseOperation[][] = []
export const getSaveState = (editor: ObjectEditorType) => {
  const { hasRedos, hasUndos, undos, redos } = getUndo(editor)
  // const [lastUndoState, setLastUndoState] = useState<BaseOperation[][]>([])
  // FIXME: on undo/redo disables submit still
  const isSame = compareHistory(undos, LAST_SAVE_STATE)
  const hasAnyDo = hasRedos || hasUndos
  const disableSubmit = !hasAnyDo || isSame
  return {
    dirty: !isSame,
    hasUndos,
    hasRedos,
    disableSubmit, // TODO: If last items since save are only selection, disable
    onSave: () => {
      LAST_SAVE_STATE = cloneDeep(undos)
      // setLastUndoState(cloneDeep(undos))
    }
  }
}

export const useUndo = () => {
  const editor = usePlateEditorState() as ObjectEditorType
  return getUndo(editor)
}

export const useSaveState = () => {
  const editor = usePlateEditorState() as ObjectEditorType
  return getSaveState(editor)
}


export const getValidation = (editor: ObjectEditorType, type?: string): {
  fields: string[] // Index is rootPath, value is field type
  invalid: boolean
  fieldInvalid?: boolean // When passing type, returns specific invalid state
} => {
  const { children } = editor
  const invalidArray: string[] = []
  POST_NORMALIZATION_RULES.forEach((rule) => {
    const { path, strictType } = rule
    const pathIndex = path[0]
    const node = children?.[pathIndex]
    // We're assuming the type is correct due to normalization
    const firstNodeChild = node?.children?.[0]
    if (!firstNodeChild?.text) {
      invalidArray[pathIndex] = strictType
    }
  })
  return {
    fields: invalidArray,
    invalid: invalidArray.length >= 1,
    ...(type && {
      fieldInvalid: invalidArray.includes(type)
    })
  }
}


export const useValidate = (type?: string): {
  fields: string[] // Index is rootPath, value is field type
  invalid: boolean
  fieldInvalid?: boolean // When passing type, returns specific invalid state
} => {
  const editor = usePlateEditorState() as ObjectEditorType
  return getValidation(editor, type)
}


export const updateNodeField = (field, value) => {
  const editor = usePlateEditorState()
  const { element } = editor
  // @ts-ignore
  const path = findNodePath(editor, element)
  if (value) {
    setNodes(editor, { [field]: value }, { at: path })
  } else {
    unsetNodes(editor, [field], { at: path })
  }
}

export function useMentionData(editor) {
  const { element } = editor
  const data = element
  const { value } = data
  // console.log(`🚀 ~ useMentionData ~ data`, data)
  /* eslint @typescript-eslint/no-unsafe-member-access: 0 */
  switch (element?.type) {
    case ELEMENT_PATH:
      const { parentId, subPostId } = data
      return { parentId, subPostId } as PathData

    case ELEMENT_REF:
      const { ref, refId } = data
      return { ref, refId } as RefData

    case ELEMENT_TAG:
      const { tags, postId } = data
      return { postId, tags } as TagData

    default:
      return {};
  }
}