import { useEffect, useMemo, useState } from 'react'

import { SxProps } from '@mui/material'
import Stack from '@mui/material/Stack'
import { scaleLog } from '@visx/scale'
import { Text } from '@visx/text'
import { Wordcloud as WC } from '@visx/wordcloud'
import { defaultWords } from '@Components/shared/graph/WordCloud/data'
import { brandVars } from '@Constants/config/brand'

interface WordCloudProps {
  width?: number
  height?: number
  words?: string
  padding?: number
  spiralType?: SpiralType
  withRotation?: boolean
  showControls?: boolean
  sx?: SxProps
}

export interface WordData {
  text: string
  value: number
}

const colors = [
  brandVars.secondaryColorDark,
  brandVars.primaryColorDark,
  brandVars.infoColor,
]

function stripWords(text: string): string {
  if (text) {
    const wrds = text
      // FIRST:
      // Remove [link](things)
      .replace(/\([^()]*\)/g, '') // Replace everything within parenthesis
      .replace(/[\[\]]+/g, '') // Replace square backets, but not its content

      .split('–') // Special dash character for some reason
      .join('-')

      // .replace(/\./g, ' ') // Replace periods with space
      .replace(/[, _'"!?;]+/g, ' ') // Replace commas and semi etc. with space
      .replace(/[@#->]+/g, '') // Replace markdown chars
      .replace(/\d+/g, '') // Remove numbers

      // Cleanup
      .toLowerCase()
      .trim()

      // Split into words
      .split(/\s/)
      .map((word) => (word.length >= 3 || word.length >= 22 ? word : ''))
      .join(' ')
    const noShort = wrds.replace(
      /\b(?:an?|the|it|is|on|all|and|of|or|do|as|at|in|to|de|so|you|een|not?|can|but|het|that|van|met|with|op|[mh]e|,|we...)\b/gi,
      ''
    )

    return noShort
  } else {
    return ''
  }
}
function wordFreq(text: string): WordData[] {
  const wrds: string[] = text.replace(/\./g, '').split(/\s/)
  const freqMap: Record<string, number> = {}

  for (const w of wrds) {
    if (!freqMap[w]) freqMap[w] = 0
    freqMap[w] += 1
  }
  const wrdFreq = Object.keys(freqMap).map((wrd) => ({
    text: wrd,
    value: freqMap[wrd],
  }))
  return wrdFreq
}

function getRotationDegree() {
  const rand = Math.random()
  const degree = rand > 0.5 ? 60 : -60
  return rand * degree
}

const fixedValueGenerator = () => 0.5

type SpiralType = 'archimedean' | 'rectangular'

export default function WordCloud({
  words = defaultWords,
  width = 500,
  height = 300, // Minimal without Rotation: 200, otherwise 300
  padding = 3,
  spiralType = 'rectangular',
  withRotation = false,
  showControls,
  sx,
}: WordCloudProps) {
  const [spiralT, setSpiralType] = useState<SpiralType>(spiralType)
  const [wRotation, setWithRotation] = useState(withRotation)

  const strippedWords = useMemo(() => stripWords(words), [words])
  const theWords = useMemo(() => wordFreq(strippedWords), [strippedWords])
  // console.warn(`🚀 ~ theWords`, theWords)

  const fontScale = useMemo(
    () =>
      scaleLog({
        domain: [
          Math.min(...theWords.map((w) => w.value)),
          Math.max(...theWords.map((w) => w.value)),
        ],
        range: [20, 100],
      }),
    [theWords]
  )
  const fontSizeSetter = (datum: WordData) => fontScale(datum.value)

  useEffect(() => {
    setSpiralType(spiralType)
    setWithRotation(withRotation)
  }, [spiralType, withRotation])

  // FIXME: Avoid re-render when nothing changes
  const theCloudWords = useMemo(
    () => (wrds) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      return wrds.map((w, i) => (
        <Text
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          key={w.text}
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          fill={colors[i % colors.length]}
          textAnchor={'middle'}
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/restrict-template-expressions
          transform={`translate(${w.x}, ${w.y}) rotate(${w.rotate})`}
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          fontSize={w.size}
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          fontFamily={w.font}
        >
          {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            w.text
          }
        </Text>
      ))
    },
    []
  )

  return (
    <Stack
      justifyContent="space-between"
      alignItems="center"
      sx={{ userSelect: 'none', ...sx }}
    >
      <WC
        words={theWords}
        width={width}
        height={height}
        fontSize={fontSizeSetter}
        font={'Public Sans'}
        padding={padding}
        spiral={spiralT}
        rotate={wRotation ? getRotationDegree : 0}
        random={fixedValueGenerator}
      >
        {(cloudWords) => theCloudWords(cloudWords)}
      </WC>
      {showControls && (
        <div>
          <label>
            Spiral type &nbsp;
            <select
              onChange={(e) => setSpiralType(e.target.value as SpiralType)}
              value={spiralT}
            >
              <option key={'archimedean'} value={'archimedean'}>
                archimedean
              </option>
              <option key={'rectangular'} value={'rectangular'}>
                rectangular
              </option>
            </select>
          </label>
          <label>
            With rotation &nbsp;
            <input
              type="checkbox"
              checked={wRotation}
              onChange={() => setWithRotation(!wRotation)}
            />
          </label>
          <br />
        </div>
      )}
    </Stack>
  )
}
