import { nanoid } from 'nanoid'
import { ObjectEditorValue } from '@Components/shared/object-editor/ObjectEditor.types'

export const getNodesWithId = (nodes: ObjectEditorValue): ObjectEditorValue => {
  if (nodes) {
    nodes.forEach((node) => {
      node.id = nanoid()
      // @ts-ignore because it do
      delete node.__source // Cleanup hyperscript for development
      if (node?.children) {
        node?.children?.forEach((nod) => {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          delete nod.__source
        })
      }
    })
  }

  return nodes
}
