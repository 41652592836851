import { SxProps } from '@mui/material'
import Box from '@mui/material/Box'
import { PlateProvider } from '@udecode/plate'
import { getNodesWithId } from '@Components/shared/object-editor/getNodesWithId'
import { readOnlyPlugins } from '@Components/shared/object-editor/readOnlyPlugins'
import {
  ObjectEditorType,
  ObjectEditorValue,
} from '@Components/shared/object-editor/ObjectEditor.types'
import { ReadOnlyObjectBody } from '@Components/shared/object-editor/ReadOnlyObjectBody'
import ErrorBoundary from '@Components/shared/system/ErrorBoundary'
import { AnyExistingPostData } from '@Constants/post'
import { usePostToObject } from '@Utils/objectFunc'
import { testObjectEditorValues } from '@Utils/test/testObjectEditorValues'

export const cleanUpForDev = (nodes: ObjectEditorValue) => {
  nodes.forEach((node) => {
    // @ts-ignore because it do
    delete node.__source // Cleanup hyperscript for development
  })
  return nodes
}

const initialUniqueValues = cleanUpForDev(testObjectEditorValues)

interface ReadOnlyObjectProps {
  id?: string
  post?: AnyExistingPostData
  debug?: boolean
  sx?: SxProps
}

export const ReadOnlyObject = ({
  id,
  post,
  debug = false,
  sx = {},
}: ReadOnlyObjectProps) => {
  const postObj = usePostToObject(post)
  const initObj = getNodesWithId(postObj)
  const value = initObj
  return (
    <Box
      sx={{
        width: '100%',
        ...sx,
      }}
    >
      <ErrorBoundary errorType="Provider">
        <PlateProvider<ObjectEditorValue, ObjectEditorType>
          id={id}
          initialValue={value}
          plugins={readOnlyPlugins}
        >
          <Box
            sx={{
              // No space for editable content, internal space for selection within Plate, see: overrides.css
              px: 0,
              py: 0,
            }}
            className="readonly-object-body" // Doesn't need plate-body for dynamic resising
          >
            <ReadOnlyObjectBody id={id} />
          </Box>
        </PlateProvider>

        {debug ? (
          <pre style={{ whiteSpace: 'pre-wrap' }}>
            {JSON.stringify(value, null, 2)}
          </pre>
        ) : null}
      </ErrorBoundary>
    </Box>
  )
}
