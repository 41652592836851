import React, { useContext } from 'react'

import Stack from '@mui/material/Stack'
import CatSelector from '@Components/shared/cat/CatSelector'
import TypeSelector from '@Components/shared/type/TypeSelector'
import ClearFiltersButton from '@Components/shared/system/ClearFiltersButton'
import { roleVars } from '@Constants/config/roleVars'
import routeVars from '@Constants/config/routeVars'
import { useBreakpoints } from '@Constants/config/globalStyles'
import { RenderRoute } from '@Routes/Routers'

import { RouteContext } from '../../RouteProvider'

export function TopBarFilter({}) {
  const { goCat, goType, catQuery, typeQuery } = useContext(RouteContext)

  const { matchesMobile } = useBreakpoints()

  return (
    <Stack direction="row" spacing={matchesMobile ? 1 : 1}>
      <RenderRoute
        exact
        path={routeVars.topBar.clearFilters}
        roles={roleVars.topBar.clearFilters}
      >
        {catQuery || typeQuery ? <ClearFiltersButton /> : null}
      </RenderRoute>

      <RenderRoute
        exact
        path={routeVars.topBar.catSelector}
        roles={roleVars.topBar.catSelector}
      >
        <CatSelector
          onChange={goCat}
          tiny
          flat={!matchesMobile}
          highlightIcon={catQuery || undefined}
        />
      </RenderRoute>

      <RenderRoute
        exact
        path={routeVars.topBar.typeSelector}
        roles={roleVars.topBar.typeSelector}
      >
        <TypeSelector tiny onChange={(type) => goType(type)} />
      </RenderRoute>
    </Stack>
  )
}

export function TopBarFilterNoRoutes(): React.ReactElement {
  const { goCat, goType, catQuery, typeQuery } = useContext(RouteContext)

  const { matchesMobile } = useBreakpoints()

  return (
    <Stack direction="row" spacing={1}>
      {catQuery || typeQuery ? <ClearFiltersButton /> : null}

      <CatSelector
        onChange={goCat}
        tiny
        flat={!matchesMobile}
        highlightIcon={catQuery || undefined}
      />

      <TypeSelector tiny onChange={(type) => goType(type)} />
    </Stack>
  )
}
