import React from 'react'

import { alpha } from '@mui/material/styles'
import { AutocompleteRenderInputParams } from '@mui/material/Autocomplete'
import CircularProgress from '@mui/material/CircularProgress'
import Icon from '@mui/material/Icon'
import TextField from '@mui/material/TextField'
import Tooltip from '@mui/material/Tooltip'
import makeStyles from '@mui/styles/makeStyles'
import TypeIcon from '@Components/ui/TypeIcon'
import { appVars } from '@Constants/config/app'
import { Cats } from '@Constants/config/categories'
import { Types } from '@Constants/config/types'
import { shortTypeString } from '@Utils/typeFunc'

const useStyles = makeStyles((theme) => ({
  rootSearchInput: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    // marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      // marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    // padding: theme.spacing(0, 1.3),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputInput: {
    // padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    // border: 'none',
    [theme.breakpoints.up('sm')]: {
      width: '100%',
      '&:focus': {
        // width: '60vw',
      },
    },
  },
}))

export default function SearchPostsInput({
  params,
  loading,
  autoFocus,

  typeSelected = '',
  catSelected,
}: {
  params?: AutocompleteRenderInputParams
  loading?: boolean
  autoFocus?: boolean
  typeSelected?: Types
  catSelected?: Cats
}) {
  const classes = useStyles()
  const typeName = shortTypeString(typeSelected)
  const typeTitle = catSelected ? `type: '${typeName}'` : typeName
  const categoryTitle = catSelected ? `category: '${catSelected}'` : ``
  const searchTitle = `Search ${catSelected ? `${catSelected} ` : ''}${
    typeSelected ? typeName : appVars.things
  }…`

  return (
    <Tooltip
      title={
        typeSelected && !catSelected
          ? `Search for ${typeTitle}`
          : catSelected && !typeSelected
          ? `Search for ${categoryTitle}`
          : catSelected && typeSelected
          ? `Search for ${typeTitle} & ${categoryTitle}`
          : `Search for ${appVars.things}`
      }
      arrow
      placement="bottom" // Bottom because of topBar search?
    >
      <div className={classes.rootSearchInput}>
        <div className={classes.searchIcon}>
          {/* <TypeIcon
            type={typeSelected}
            tiny
            goType={() => {
              // This is purely for spacing of the icon
            }}
          /> */}
          <Icon sx={{ ml: 1 }}>search</Icon>
        </div>
        <TextField
          {...params}
          autoFocus={autoFocus}
          placeholder={searchTitle}
          className={classes.inputInput}
          // inputProps={{ 'aria-label': 'search' }}
          // inputRef={params.inputProps.ref}
          // variant="outlined"

          inputProps={{
            ...params?.inputProps,
            'aria-label': 'search',
          }}
          InputProps={{
            ...params?.InputProps,
            disableUnderline: true,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params?.InputProps.endAdornment}
              </>
            ),
          }}
          variant="standard"
        />
      </div>
    </Tooltip>
  )
}
