import {
  UriActionTypes, LOAD_URI, URI_LOADED,
  URI_EXISTS, SAVE_URI, URI_SAVED, URI_NOT_EXISTS
} from "@State/actions/uriActions"
import { FirebaseDate } from "@Utils/postDataFunc"

export type UriPath = {
  [pathName: string]: UriPath & UriData & TldData
}

export type UriData = {
  uri: string;
  uriRef: string;
  postCount: number;
  urlCount: number;

  created: FirebaseDate;
  edited?: FirebaseDate;

  domain?: string;
}

export type UriPostById = {
  uri: string;
  postId: string;
  added: FirebaseDate; d
  userId: string;
  namespace?: string;
}

export type TldData = {
  urlCount: string | number;
  domain?: string;
  created?: FirebaseDate;
  edited?: FirebaseDate;
}

export type UriByIdData = {
  id: string;
  uri: string;
  created: FirebaseDate;
  postCount: number;
}

export type UriState = {
  loading: boolean;
  uri: string;
  exists: boolean;
  urlData: UriData | null;
  saving: boolean;
  saved: boolean;
}

const initialUriState: UriState = {
  loading: false,
  uri: '',
  exists: false,
  urlData: null,
  saving: false,
  saved: false
}

export default function uriReducer(
  state = initialUriState,
  action: UriActionTypes
) {
  if (action.type === LOAD_URI) {
    return {
      ...state,
      loading: true,
      uri: action.uri,
      // exists: false
    }
  } else if (action.type === URI_LOADED) {
    // console.log('URI_LOADED', action)
    return {
      ...state,
      loading: false
      // exists: action.exists
    }
  } else if (action.type === URI_EXISTS) {
    // console.log('URI_EXISTS', action)
    return {
      ...state,
      loading: false,
      exists: true,
      urlData: action.data
    }
  } else if (action.type === URI_NOT_EXISTS) {
    // console.log('URI_NOT_EXISTS', action)
    return {
      ...state,
      loading: false,
      exists: false,
      urlData: null
    }
  } else if (action.type === SAVE_URI) {
    // console.log('SAVE_URI', action)
    return {
      ...state,
      saving: true,
      saved: false
    }
  } else if (action.type === URI_SAVED) {
    // console.log('SAVE_URI', action)
    return {
      ...state,
      saving: false,
      saved: true
    }
  } else {
    return state
  }
}