import { createImagePlugin } from '@Components/shared/object-editor/plugins/media/imagePlugin/createImagePlugin'
import { createLinkPlugin } from '@Components/shared/object-editor/plugins/media/link/createLinkPlugin'
import { linkPlugin } from '@Components/shared/object-editor/plugins/media/link/linkPlugin'
import { createMediaEmbedPlugin } from '@Components/shared/object-editor/plugins/media/media-embed/createMediaEmbedPlugin'
import { createObjectEditorPlugins } from '@Components/shared/object-editor/ObjectEditor.types'


export const readOnlyMediaPlugins = createObjectEditorPlugins(
  [
    // Insert
    createLinkPlugin(linkPlugin),

    // Images
    createImagePlugin(),
    createMediaEmbedPlugin(),

  ]
)
