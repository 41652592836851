export const mockText = 'Test text'

export const mockText1 = `Test text  - [test](link)
  # Header

  - List item
  - List item 2

  Some text\n\n`

export const mockText2 = 'Test *text* 2 with [link](Link)\n\n';

export const mockTextWithLink = 'A [Link](https://search.brave.com/) goes somewhere, with some extra newlines:\n\n';

export const mockTextLong = `Voluptate [consequatch](test) proident [aliquip](https://www.youtube.com/watch?v=b8NnPjRTtlU) dolo re voluptate consequat incididunt nisi duis.
# Valis
Sint exercitation pariatur nostrud sunt non in commodo commodo.

> Moc hadit eqem vorit eset valis.

#### Emit
Nulla sunt velit quis ut do ex cillum tempor occaecat officia anim ut ullamco culpa. Laboris duis sint laboris incididunt aute magna deserunt. Nisi quis ullamco enim enim sunt Lorem veniam. Dolore mollit quis nostrud incididunt labore tempor.

Lorem cupidatat pariatur elit ad:
- cillum
- adipisicing
- voluptate.

> Non ex mollit labore occaecat reprehenderit anim non id cupidatat sit reprehenderit. Veniam do duis et quis veniam Lorem irure mollit. Nisi proident consequat qui ad cillum nostrud ad aute ea ex consectetur. Veniam aliqua dolore id ut aute occaecat incididunt nisi quis eiusmod cupidatat nisi aute anim. Tempor voluptate nulla et ipsum. Sunt ipsum consequat id fugiat dolor ad culpa enim ut ex reprehenderit nisi proident eiusmod.

## Sequtur
Minim ut adipisicing anim et ut ipsum deserunt quis. Dolore dolore ullamco esse commodo velit. Ullamco amet ipsum laboris incididunt excepteur et id elit minim eiusmod officia et. Excepteur ea eiusmod ea laboris labore do.

Consequat nulla voluptate proident anim exercitation pariatur mollit pariatur culpa voluptate cillum. Incididunt excepteur excepteur aute aute ullamco elit sint est dolore ea consectetur. Nulla exercitation consectetur eiusmod consectetur tempor ea. Minim ex adipisicing quis amet cupidatat. Proident qui nostrud ea proident officia occaecat. In cupidatat adipisicing elit in mollit aliquip amet laborum culpa aute ullamco consequat.

Song relic anorvan charabams: !@#*$!@*# $)!@%) &*@%)!) ____

Them dashes: – -
`;
