import arrayMutators from 'final-form-arrays'
import { TextField as TextFieldFinal } from 'mui-rff'
import { Form, FormSpy } from 'react-final-form'

import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Typography from '@mui/material/Typography'
import makeStyles from '@mui/styles/makeStyles'
import { PostData } from '@Constants/post'
import { firestore } from '@State/firebase'
import checkFirebaseCompatible from '@Utils/regExp'
import { validNamespaceFilter } from '@Utils/stringFunc'

const validatedNamespaceFunc = (val: string) => {
  const pathRegex = checkFirebaseCompatible()
  if (val) {
    const isGood = pathRegex.test(val)
    let newVal: string
    if (isGood) {
      newVal = val
      return newVal
    } else {
      newVal = validNamespaceFilter(val)
      const isStillGood = pathRegex.test(newVal)
      if (isStillGood) {
        return newVal
      } else {
        return ''
      }
    }
    // return newVal
  } else {
    return ''
  }
}

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  formElem: {
    // margin: 12
    marginBottom: theme.spacing(2),
  },
  formButtonGroup: {
    display: 'flex',
    alignSelf: 'flex-end',
  },
  formButtons: {
    margin: 6,
  },
  loadingIcon: {
    marginLeft: 12,
    marginTop: 4,
  },
  warning: {
    paddingTop: -12,
  },
}))

function PublishForm({
  item,
  initialValues,
  published,
  handleSubmitPublishForm,
  handleClose,
  loading,
}: {
  item: Partial<PostData>
  initialValues?: Partial<PostData>
  handleSubmitPublishForm: ({
    data,
    namespace,
  }: {
    data: Partial<PostData>
    namespace: string
  }) => void
  handleClose: (
    cancel: boolean,
    publish?: boolean,
    newNamespace?: string
  ) => void
  published?: boolean
  loading?: boolean
}) {
  const classes = useStyles()

  function submit(data, form) {
    // TODO: Save `lastNamespace` and use when republishing
    handleSubmitPublishForm({
      data: {
        ...item,
        ...(item.namespace && {
          namespace: item.namespace,
        }),
      },
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
      namespace: validNamespaceFilter(data.namespace),
    })
  }

  function clickCancel() {
    handleClose(true)
  }

  const asyncValidateNamespace = async (value: string /*, dispatch */) => {
    const hasNamespaceAlready = Boolean(item.namespace)
    if (hasNamespaceAlready && item.namespace === value) {
      return ''
    }
    if (value) {
      const validatedValue = validatedNamespaceFunc(value)
      if (validatedValue) {
        // TODO: debounce

        const namespacesByName = firestore
          .collection('namespacesByName')
          .doc(validatedValue)

        let nameSpaceError: string
        await namespacesByName.get().then((doc) => {
          const remoteVal = doc.data()
          // console.log('remote namespace', remoteVal)
          if (remoteVal) {
            nameSpaceError = 'Namespace Taken'
          } else {
            nameSpaceError = ''
          }
        })
        // @ts-expect-error
        return nameSpaceError
      } else {
        return 'Illegal characters'
      }
    } else {
      return ''
    }
  }

  return (
    <Form
      onSubmit={submit}
      // @ts-expect-error
      mutators={{ ...arrayMutators }}
      autoComplete="off"
      className={classes.form}
      initialValues={initialValues ? initialValues : null}
      // validate={formValidate}
    >
      {({
        handleSubmit,
        // pristine,
        // form,
        submitting,
        invalid,
        values,
        validating,
      }) => {
        // eslint-disable-line @typescript-eslint/no-shadow
        // console.log('form', form)
        // console.log('values', values)
        // console.log('validating', validating)
        // console.log('pristine', pristine)
        const namespace = validNamespaceFilter(
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
          values.namespace || item.namespace
        )
        return (
          <form
            onSubmit={(data) => {
              // console.log('SUBMIT', data, form)
              handleSubmit(data) // eslint-disable-line @typescript-eslint/no-floating-promises
              //  form.reset();
            }}
          >
            <Typography variant="h5" gutterBottom>
              {namespace.toUpperCase()}
            </Typography>
            <TextFieldFinal
              label="Namespace"
              name="namespace"
              defaultValue={initialValues ? initialValues.namespace : ''}
              // autoFocus={!Boolean(published)}
              disabled={published}
              className={classes.formElem}
              fieldProps={{
                validate: asyncValidateNamespace,
              }}
              helperText={!invalid ? `Actual namespace: ${namespace}` : ''}
              inputProps={{
                maxLength: 280,
              }}
            />

            {published ? (
              <Typography
                variant="caption"
                color="secondary"
                className={classes.warning}
              >
                Warning: You will lose namespace, unless you can re-register it
                quickly after...
              </Typography>
            ) : (
              ''
            )}

            <div className={classes.formButtonGroup}>
              <Button
                color="inherit"
                variant="outlined"
                onClick={clickCancel}
                className={classes.formButtons}
              >
                Cancel
              </Button>
              {/* <Button variant="outlined" color="secondary" onClick={form.reset} className={classes.formButtons} disabled={pristine || submitting || invalid}>Reset</Button> */}
              <Button
                variant="contained"
                color={published ? 'warning' : 'public'}
                type="submit"
                className={classes.formButtons}
                disabled={invalid || validating || submitting || loading}
              >
                {published ? 'Unpublish' : 'Publish'}
              </Button>

              {submitting ? <CircularProgress /> : null}

              <FormSpy subscription={{ validating: true, pristine: true }}>
                {(props) => {
                  return props.validating && !Boolean(props.pristine) ? (
                    <CircularProgress className={classes.loadingIcon} />
                  ) : null
                }}
              </FormSpy>
            </div>
          </form>
        )
      }}
    </Form>
  )
}

export default PublishForm
