import ThresholdComponent from '@Components/shared/graph/Threshold/Threshold'
import React, { useEffect, useState } from 'react'
import Stack from '@mui/material/Stack'
import useDimensions from 'react-cool-dimensions'
import { Datum } from '@Constants/types/graph'
import { XYChartComponent } from '@Components/shared/graph/XYChart/XYChartComponent'
import { ExistingPostData } from '@Constants/post'

export const GraphHeader = ({
  height,
  heightFactor = 2.22,
  item,
  data,
}: {
  height?: number
  heightFactor?: number
  item?: ExistingPostData
  data?: Datum[]
}) => {
  const [windowHeight, setWindowHeight] = useState(window.innerHeight)

  const {
    observe,
    unobserve,
    width,
    height: h,
    entry,
  } = useDimensions<HTMLDivElement>({
    onResize: () =>
      // { observe, unobserve, width, height, entry }
      {
        // console.log(height)
      },
  })

  const setTheHeight = () => {
    // if (height >= window.innerHeight - 10 / 2) {
    // TODO: Figure out to calculate correct heigth of container
    setWindowHeight(window.innerHeight - 10)
    // }
  }

  useEffect(() => {
    setTheHeight()
    window.addEventListener('resize', () => setTheHeight())
    return () => {
      window.removeEventListener('resize', () => setTheHeight())
    }
  }, [h])

  return (
    <Stack ref={observe}>
      {data ? (
        <XYChartComponent
          title={item?.title}
          margin={10}
          width={width}
          height={height ? height : windowHeight / heightFactor}
          data={data}
        />
      ) : null}
    </Stack>
  )
}
