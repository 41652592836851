import { TMediaElement, useCaptionString } from '@udecode/plate'
import {
  createComponentAs,
  createElementAs,
  HTMLPropsAs,
  useElement,
} from '@udecode/plate-core'

export const useImage = (props?: HTMLPropsAs<'img'>): HTMLPropsAs<'img'> => {
  const { url, relativeWidth } = useElement<TMediaElement>()
  const captionString = useCaptionString()
  const w = relativeWidth as string
  return {
    src: url,
    alt: captionString,
    draggable: true,
    style: {
      border: '1px solid red',
      width: `${w}%`,
    },
    ...props,
  }
}

export const Image = createComponentAs((props) => {
  const htmlProps = useImage(props)
  return createElementAs('img', htmlProps)
})
