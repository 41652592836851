// @ts-nocheck
/* eslint-disable */
import { isHotkey } from 'is-hotkey'
import isUrl from 'is-url'
import { BaseEditor, BaseRange, Editor, Element as SlateElement, Point, Node, NodeEntry, Path, Range, Transforms } from 'slate'

import { BLOCK_HOTKEYS, HOTKEYS, LIST_TYPES, TEXT_ALIGN_TYPES } from './EditorConsts'

export const isBlockActive = (editor: Editor, format: string, blockType = 'type') => {
  const { selection } = editor
  if (!selection) return false

  const [match] = Array.from(Editor.nodes(editor, {
    at: Editor.unhangRange(editor, selection),
    match: (n) =>
      !Editor.isEditor(n) &&
      SlateElement.isElement(n) &&
      n[blockType] === format,
  }));

  return !!match;
};

export const isMarkActive = (editor: Editor, format: string) => {
  const marks = Editor.marks(editor);
  return marks ? marks[format] === true : false;
};

export const toggleBlock = (editor: Editor, format: string) => {
  const isActive = isBlockActive(
    editor,
    format,
    TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type'
  );
  const isList = LIST_TYPES.includes(format);

  Transforms.unwrapNodes(editor, {
    match: (n) =>
      !Editor.isEditor(n) &&
      SlateElement.isElement(n) &&
      LIST_TYPES.includes(n.type as string) &&
      !TEXT_ALIGN_TYPES.includes(format),
    split: true
  });

  let newProperties: Partial<SlateElement>
  if (TEXT_ALIGN_TYPES.includes(format)) {
    newProperties = {
      align: isActive ? undefined : format,
    }
  } else {
    newProperties = {
      type: isActive ? 'paragraph' : isList ? 'list_item' : format,
    }
  }
  Transforms.setNodes(editor, newProperties);

  if (!isActive && isList) {
    const block = { type: format, children: [] };
    Transforms.wrapNodes(editor, block);
  }
};

export const toggleMark = (editor: Editor, format: string) => {
  const isActive = isMarkActive(editor, format);

  if (isActive) Editor.removeMark(editor, format);
  else Editor.addMark(editor, format, true);
};



export const insertLink = (editor, url) => {
  if (editor.selection) {
    wrapLink(editor, url)
  }
}
export const isLinkActive = editor => {
  const [link] = Editor.nodes(editor, {
    match: n =>
      !Editor.isEditor(n) && SlateElement.isElement(n) &&
      (n.type === 'a' || n.type === 'link'),
  })
  return !!link
}

export const unwrapLink = editor => {
  Transforms.unwrapNodes(editor, {
    match: n =>
      !Editor.isEditor(n) && SlateElement.isElement(n) &&
      (n.type === 'a' || n.type === 'link'),
  })
}

export const wrapLink = (editor: CustomEditor, url) => {
  if (isLinkActive(editor)) {
    unwrapLink(editor)
  }

  const { selection } = editor
  const isCollapsed = selection && Range.isCollapsed(selection)
  const link: LinkElement = {
    type: 'link',
    url,
    children: isCollapsed ? [{ text: url }] : [],
  }

  if (isCollapsed) {
    Transforms.insertNodes(editor, link)
  } else {
    Transforms.wrapNodes(editor, link, { split: true })
    Transforms.collapse(editor, { edge: 'end' })
  }
}

export const withMentions = editor => {
  const { isInline, isVoid } = editor

  editor.isInline = element => {
    return element.type === 'mention' ? true : isInline(element)
  }

  editor.isVoid = element => {
    return element.type === 'mention' ? true : isVoid(element)
  }

  return editor
}

export const insertMention = (editor, character) => {
  const mention = {
    type: 'mention',
    character,
    children: [{ text: '' }],
  }
  Transforms.insertNodes(editor, mention)
  Transforms.move(editor)
}


export const withLinks = editor => {
  const { insertData, insertText, isInline } = editor

  editor.isInline = element => {
    return element.type === 'a' || element.type === 'link' ? true : isInline(element)
  }

  editor.insertText = text => {
    if (text && isUrl(text)) {
      wrapLink(editor, text)
    } else {
      insertText(text)
    }
  }

  editor.insertData = data => {
    const text = data.getData('text/plain')

    if (text && isUrl(text)) {
      wrapLink(editor, text)
    } else {
      insertData(data)
    }
  }


  return editor
}


const SHORTCUTS = {
  '*': 'list_item',
  '-': 'list_item',
  '+': 'list_item',
  '>': 'block-quote',
  '#': 'heading-one',
  '##': 'heading-two',
  '###': 'heading-three',
  '####': 'heading-four',
  '#####': 'heading-five',
  '######': 'heading-six',
}

export const withShortcuts = (editor) => {
  const { deleteBackward, insertText } = editor

  editor.insertText = (text) => {
    const { selection } = editor

    if (text === ' ' && selection && Range.isCollapsed(selection)) {
      const { anchor } = selection
      const block = Editor.above(editor, {
        match: (n) => Editor.isBlock(editor, n),
      })
      const path = block ? block[1] : []
      const start = Editor.start(editor, path)
      const range = { anchor, focus: start }
      const beforeText = Editor.string(editor, range)
      const type = SHORTCUTS[beforeText]

      if (type) {
        Transforms.select(editor, range)
        Transforms.delete(editor)
        const newProperties: Partial<SlateElement> = {
          type,
        }
        Transforms.setNodes<SlateElement>(editor, newProperties, {
          match: (n) => Editor.isBlock(editor, n),
        })

        if (type === 'list-item' || type === 'list_item') {
          const list: BulletedListElement = {
            type: 'ul_list',
            children: [],
          }
          Transforms.wrapNodes(editor, list, {
            match: (n) =>
              !Editor.isEditor(n) &&
              SlateElement.isElement(n) &&
              n.type === 'list_item',
          })
        }

        return
      }
    }

    insertText(text)
  }

  editor.deleteBackward = (...args) => {
    const { selection } = editor

    if (selection && Range.isCollapsed(selection)) {
      const match = Editor.above(editor, {
        match: (n) => Editor.isBlock(editor, n),
      })

      if (match) {
        const [block, path] = match
        const start = Editor.start(editor, path)

        if (
          !Editor.isEditor(block) &&
          SlateElement.isElement(block) &&
          block.type !== 'paragraph' &&
          Point.equals(selection.anchor, start)
        ) {
          const newProperties: Partial<SlateElement> = {
            type: 'paragraph',
          }
          Transforms.setNodes(editor, newProperties)

          if (block.type === 'list-item' || block.type === 'list_item') {
            Transforms.unwrapNodes(editor, {
              match: (n) =>
                !Editor.isEditor(n) &&
                SlateElement.isElement(n) &&
                n.type === 'ul_list',
              split: true,
            })
          }

          return
        }
      }

      deleteBackward(...args)
    }
  }

  return editor
}


export const hotkeyHandler = (event: any, editor: Editor) => {
  // console.log(event)
  for (const hotkey in HOTKEYS) {
    if (isHotkey(hotkey, event)) {
      event.preventDefault();
      const mark = HOTKEYS[hotkey];
      toggleMark(editor, mark);
    }
  }
  for (const hotkey in BLOCK_HOTKEYS) {
    if (isHotkey(hotkey, event)) {
      event.preventDefault();
      const block = BLOCK_HOTKEYS[hotkey];
      toggleBlock(editor, block);
    }
  }
  if (event.key === 'Escape') {
    event.preventDefault();
    console.log('ESC')
  }
};


const maxIndents = 1; // Don't change


// withLists handles behavior regarding ol and ul lists
// more specifically, withLists properly exits the list with `enter` or `backspace`
// from an empty list item, transforming the node to a paragraph
export const withLists = (editor) => {
  const { insertBreak, deleteBackward } = editor;

  const backspace = (callback) => {
    const { selection } = editor;

    // check that there is a current selection without highlight
    if (selection && Range.isCollapsed(selection)) {
      // find the 'closest' `list-item` element
      const [match] = Editor.nodes(editor, {
        match: (n) =>
          n.type === "list_item" &&
          n.children &&
          n.children[0] &&
          (!n.children[0].text || n.children[0].text === ""),
      });

      // check that there was a match
      if (match) {
        const [, path] = match;
        const start = Editor.start(editor, path);

        // if the selection is at the beginning of the list item
        if (Point.equals(selection.anchor, start)) {
          // 'lift' the list-item to the next parent
          liftNodes(editor);
          // check for the new parent
          const [listMatch] = Editor.nodes(editor, {
            match: (n) =>
              n.type === "bulleted-list" || n.type === "numbered-list" || n.type === "ul_list" || n.type === "ol_list",
          });
          // if it is no longer within a ul/ol, turn the element into a normal paragraph
          if (!listMatch) {
            Transforms.setNodes(
              editor,
              { type: "paragraph" },
              { match: (n) => n.type === "list_item" }
            );
          }
          return;
        }
      }
    }

    callback();
  };

  // override editor function for break
  editor.insertBreak = () => {
    backspace(insertBreak);
  };

  // override editor function for a backspace
  editor.deleteBackward = (unit) => {
    backspace(() => deleteBackward(unit));
  };

  return editor;
};

const liftNodes = (editor) => {
  // check for the new parent
  const [listMatch] = Editor.nodes(editor, {
    match: (n) => n.type === "bulleted-list" || n.type === "numbered-list" || n.type === "ul_list" || n.type === "ol_list",
  });
  // verify there is a list to lift the nodes
  if (listMatch) {
    // 'lift' the list-item to the next parent
    Transforms.liftNodes(editor, { match: (n) => n.type === "list_item" });
  }
};

export const undentItem = (editor) => {
  const { selection } = editor;
  console.log("🚀 ~ file: slateplugins.ts ~ line 304 ~ undentItem ~ selection", selection)

  // check that there is a current selection without highlight
  if (selection && Range.isCollapsed(selection)) {
    const [match] = Editor.nodes(editor, {
      match: (n) => n.type === "list_item",
    });

    // check that there was a match
    if (match) {
      console.log("🚀 ~ file: slateplugins.ts ~ line 314 ~ undentItem ~ match", match)
      // 'lift' the list-item to the next parent
      liftNodes(editor);
      // check for the new parent
      const [listMatch] = Editor.nodes(editor, {
        match: (n) => n.type === "bulleted-list" || n.type === "numbered-list" || n.type === "ul_list" || n.type === "ol_list",
      });
      // if it is no longer within a ul/ol, turn the element into a normal paragraph
      console.log("🚀 ~ file: slateplugins.ts ~ line 323 ~ undentItem ~ listMatch", listMatch)
      if (!listMatch) {
        Transforms.setNodes(
          editor,
          { type: "paragraph" },
          { match: (n) => n.type === "list_item" }
        );
      }
    }
  }
};

export const indentItem = (editor, maxDepth = maxIndents) => {
  const { selection } = editor;
  // FIXME: Avoid first item in list to be indented
  // check that there is a current selection without highlight
  if (selection && Range.isCollapsed(selection)) {
    const [match] = Editor.nodes(editor, {
      match: (n) => n.type === "list_item",
    });

    // check that there was a match
    if (match) {
      // wrap the list item into another list to indent it within the DOM
      const [listMatch] = Editor.nodes(editor, {
        mode: "lowest",
        match: (n) => n.type === "bulleted-list" || n.type === "numbered-list" || n.type === "ul_list" || n.type === "ol_list",
      });

      if (listMatch) {
        let depth = listMatch[1].length;
        if (depth <= maxDepth) {
          Transforms.wrapNodes(editor, {
            type: listMatch[0].type,
            children: [],
          });
        }
      }
    }
  }
};

