import Divider from '@mui/material/Divider'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import {
  LaunchIcon,
  LinkIcon,
  LinkOffIcon,
  ShortTextIcon,
} from '@udecode/plate-link'
import { plateButtonCss } from '@udecode/plate-ui-button'
import {
  FloatingIconWrapper,
  FloatingInputWrapper,
  FloatingVerticalDivider,
  floatingButtonCss,
  floatingInputCss,
} from '@udecode/plate-ui-toolbar'
import {
  FloatingLink,
  useFloatingLinkSelectors,
} from '@Components/shared/object-editor/components/FloatingLink'
import { flattenCss } from '@Utils/reactHelpers'
import Icon from '@mui/material/Icon'

export const PlateFloatingLink = (props) => {
  // const theme = useTheme()
  const isEditing = useFloatingLinkSelectors().isEditing()

  const rootStyle = {
    width: 'auto',
    zIndex: '20 !important',
    // backgroundColor: theme?.palette.background.default,
  }
  const iconSx = {
    mx: 1,
    my: 0.5,
  }
  const inputStyle = flattenCss(floatingInputCss)

  const input = (
    <Paper variant="outlined">
      <Stack
        style={rootStyle}
        direction="column"
        alignItems="center"
        sx={{ px: 0, py: 0.6 }}
      >
        <FloatingInputWrapper>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            sx={iconSx}
          >
            <Icon>link</Icon>
          </Stack>

          <FloatingLink.UrlInput style={inputStyle} placeholder="Paste link" />
        </FloatingInputWrapper>

        <Divider sx={{ width: '100%' }} />

        <FloatingInputWrapper>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            sx={iconSx}
          >
            <Icon>short_text</Icon>
          </Stack>
          <FloatingLink.TextInput placeholder="Text to display" />
        </FloatingInputWrapper>
      </Stack>
    </Paper>
  )

  const editContent = !isEditing ? (
    <Paper variant="outlined" sx={{ m: 0 }}>
      <Stack
        style={rootStyle}
        direction="row"
        alignItems="center"
        justifyContent="center"
        spacing={1}
        sx={{ px: 1 }}
      >
        <FloatingLink.EditButton>Edit link</FloatingLink.EditButton>

        <Divider orientation="vertical" flexItem />

        <FloatingLink.OpenLinkButton>
          <Icon>open_in_new</Icon>
        </FloatingLink.OpenLinkButton>

        <Divider orientation="vertical" flexItem />

        <FloatingLink.UnlinkButton>
          <Icon>link_off</Icon>
        </FloatingLink.UnlinkButton>
      </Stack>
    </Paper>
  ) : (
    input
  )

  return (
    <>
      <FloatingLink.InsertRoot>{input}</FloatingLink.InsertRoot>

      <FloatingLink.EditRoot>{editContent}</FloatingLink.EditRoot>
    </>
  )
}
