import firebase from 'firebase/compat/app'
import difference from 'lodash/difference'
import { ofType } from 'redux-observable'
import { mapTo, mergeMap } from 'rxjs/operators'

/* eslint-disable */
import { firestore } from '@State/firebase'
import { editPostAction } from '@State/actions/postActions'
import { sendNotifcationAction } from '@State/actions/appStateActions'


// import { sendNotifcationAction } from "@State/actions/appStateActions";

export const publishTagsEpic = (action$, store, dependencies) => action$.pipe(
  ofType('PUBLISH_TAGS'),
  mergeMap(({ id, uid, data }) => {
    const postRef = firestore
      .collection('postsById')
      .doc(id)

    return postRef.get().then((post) => {
      const postData = post.data()
      return editPostTags({
        id,
        uid,
        tags: postData && postData.tags,
        oldTags: [],
        type: data.type,
        cat: data.cat
      }).then(() => {
        dependencies.store.dispatch(editPostAction({ id, data: { tagsPublished: true } }))
        dependencies.store.dispatch(sendNotifcationAction(`Tags Published`, 'success'))
      })
    })
  }),
  mapTo({ type: 'TAGS_PUBLISHED' })
)

export const unpublishTagsEpic = (action$, store, dependencies) => action$.pipe(
  ofType('UNPUBLISH_TAGS'),
  mergeMap(({ id }) => {
    const postRef = firestore
      .collection('postsById')
      .doc(id)

    return postRef.get().then((post) => {
      const postData = post.data()
      return deletePostTags({
        id,
        oldTags: postData && postData.tags
      }).then(() => {
        dependencies.store.dispatch(editPostAction({ id, data: { tagsPublished: false } }))
        dependencies.store.dispatch(sendNotifcationAction(`Tags Unpublished`, 'success'))
      })
    })

  }),
  mapTo({ type: 'TAGS_UNPUBLISHED' })
)

const forEachTagEdit = async ({ id, uid, tags, type, cat }) => {
  const tagsByTagname = firestore
    .collection('tagsByName')

  const timestamp = firebase.firestore.FieldValue.serverTimestamp()
  const tagBatch = firestore.batch();

  if (tags) {
    tags.map((tagname) => {
      const arrayUnion = firebase.firestore.FieldValue.arrayUnion;
      const tagRef = tagsByTagname.doc(tagname)
      tagBatch.set(tagRef, {
        edited: timestamp,
        types: arrayUnion(type),
        cats: arrayUnion(cat), // We don't remove any entries, whatever value touches the tag, stays...
        postCount: firebase.firestore.FieldValue.increment(+1),
      }, { merge: true })

      const tagPostIdsRef = tagsByTagname.doc(tagname).collection('postIds').doc(id)
      return tagBatch.set(tagPostIdsRef, {
        uid,
        type,
        cat,
        edited: timestamp
      })
    })
  }

  return tagBatch.commit()

}

const forEachTagRemove = ({ id, tags }) => {
  const tagsByTagname = firestore
    .collection('tagsByName')

  const tagRemoveBatch = firestore.batch();

  if (tags) {
    tags.map((tagname: string) => {
      const tagRemoveRef = tagsByTagname.doc(tagname).collection('postIds').doc(id)
      const tagRef = tagsByTagname.doc(tagname)
      tagRef.set({
        postCount: firebase.firestore.FieldValue.increment(-1),
      }, { merge: true })
      tagRemoveBatch.delete(tagRemoveRef)
    })
  }

  return tagRemoveBatch.commit()
}

const editPostTags = ({ id, uid, tags, oldTags, type, cat }) => {
  const timestamp = firebase.firestore.FieldValue.serverTimestamp()
  const tagsByPostId = firestore
    .collection('tagsByPostId')
    .doc(id)

  if (tags && tags.length) {
    return firestore.runTransaction((transaction) => {
      return transaction.get(tagsByPostId).then((tagDoc) => {
        if (!tagDoc.exists) {
          transaction.set(tagsByPostId, {
            uid,
            tags,
            type,
            cat,
            created: timestamp
          })
        } else {
          transaction.update(tagsByPostId, {
            uid,
            tags,
            type,
            cat,
            edited: timestamp
          })
        }
      }).then(() => {
        return forEachTagEdit({ id, uid, tags, type, cat }).then(() => {
          const removedTags = difference(oldTags, tags)
          return forEachTagRemove({ id, tags: removedTags })
        })
      })
    })
  } else {
    const removedTags = difference(oldTags, tags)
    return tagsByPostId.delete().then(() => {
      return forEachTagRemove({ id, tags: removedTags })
    })

  }
}

const deletePostTags = ({ id, oldTags }) => {
  const tagsByPostId = firestore
    .collection('tagsByPostId')
    .doc(id)
  return tagsByPostId.delete().then(() => {
    return forEachTagRemove({ id, tags: oldTags })
  })

}