import React from 'react'
import { Switch, useHistory } from 'react-router-dom'

import { useTheme } from '@mui/material/styles'
import Box from '@mui/system/Box'
import { DRAWER_WIDTH } from '@Components/containers/ui/Drawer'
import { containerStyles, useBreakpoints } from '@Constants/config/globalStyles'
import {
  appRoutes,
  defaultRoutes,
  objectPages,
  postIdOrNamespace,
  systemRoutes,
} from '@Constants/config/routeVars'
// import Peek from '@Routes/page/Peek'
// import SubPeek from '@Routes/page/SubPeek'
import { FullscreenLoading } from '@Routes/system/FullscreenLoading'
import { DefaultRoute, PrivateRouteRedirect } from '@Routes/Routers'
import ViewPostRoute from '@Routes/post/ViewPostRoute'
import ViewObjectRoute from '@Routes/post/ViewObjectRoute'

const PostRoute = React.lazy(() => import('@Routes/post/PostRoute'))

const LoginPage = React.lazy(() => import('@Routes/user/LoginPage'))
const Profile = React.lazy(() => import('@Routes/user/Profile'))
const User = React.lazy(() => import('@Routes/user/User'))

const CreatePost = React.lazy(() => import('@Routes/post/CreatePost'))
const CreateRoute = React.lazy(() => import('@Routes/post/CreateRoute'))
const EditPostId = React.lazy(() => import('@Routes/post/EditPostId'))
const EditPostNamespace = React.lazy(
  () => import('@Routes/post/EditPostNamespace')
)
const ViewUri = React.lazy(() => import('@Routes/page/ViewUri'))
const ViewPostId = React.lazy(() => import('@Routes/post/ViewPostId'))
const ViewPostNamespace = React.lazy(
  () => import('@Routes/post/ViewPostNamespace')
)

const FilterRoute = React.lazy(() => import('@Routes/list/FilterRoute'))
const TypesRoute = React.lazy(() => import('@Routes/list/TypesRoute'))
const UrlsBrowse = React.lazy(() => import('@Routes/list/UrlsBrowse'))
const URISBrowse = React.lazy(() => import('@Routes/list/URISBrowse'))
const Locations = React.lazy(() => import('@Routes/list/Locations'))
const TreePage = React.lazy(() => import('@Routes/list/TreePage'))
const Lists = React.lazy(() => import('@Components/containers/list/Lists'))
const PostsList = React.lazy(() => import('@Routes/list/PostsList'))
const NamespaceList = React.lazy(() => import('@Routes/list/NamespaceList'))
const TagsGrid = React.lazy(() => import('@Routes/list/TagsGrid'))
const Popular = React.lazy(() => import('@Routes/list/Popular'))
const CatsRoute = React.lazy(() => import('@Routes/list/CatsRoute'))
const JudgementList = React.lazy(() => import('@Routes/list/JudgementList'))
const ListUsers = React.lazy(() => import('@Routes/list/ListUsers'))

const Home = React.lazy(() => import('@Routes/system/Home'))
const ApplyFor = React.lazy(() => import('@Routes/system/ApplyFor'))
const Invites = React.lazy(() => import('@Routes/system/Invites'))
const SearchRoute = React.lazy(() => import('@Routes/system/SearchRoute'))
const TermsOfUse = React.lazy(() => import('@Routes/system/TermsOfUse'))
const Thanks = React.lazy(() => import('@Routes/system/Thanks'))
const NotFound = React.lazy(() => import('@Routes/system/NotFound'))

const TestRoute = React.lazy(() => import('@Routes/TestRoute'))

export default function AppRoutes({
  children,
  drawerOpen,
}: {
  children?: React.ReactChildren
  drawerOpen: boolean
}) {
  const history = useHistory()
  const theme = useTheme()
  const container = containerStyles()

  const { matchesMobile, matchesMedium } = useBreakpoints()

  const rest = {
    history,
  }

  return (
    <Box
      id="scroll"
      className={container.rootSpacing}
      sx={{
        // width: '100%',
        flexGrow: 1,
        transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        ...(drawerOpen &&
          !matchesMedium && {
            ml: `${DRAWER_WIDTH}px`,
            transition: theme.transitions.create('margin', {
              easing: theme.transitions.easing.easeOut,
              duration: theme.transitions.duration.enteringScreen,
            }),
          }),
      }}
    >
      <React.Suspense fallback={<FullscreenLoading />}>
        {/* <AnimatedSwitch
          atEnter={{ opacity: 0 }}
          atLeave={{ opacity: 0 }}
          atActive={{ opacity: 1 }}
          className="switch-wrapper"
          runOnMount
        > */}
        <Switch>
          <DefaultRoute exact path={defaultRoutes.home} component={Home} />
          <DefaultRoute exact path="/login" component={LoginPage} />
          <PrivateRouteRedirect exact path="/apply" component={ApplyFor} />
          <PrivateRouteRedirect exact path="/thanks" component={Thanks} />
          <PrivateRouteRedirect
            exact
            path="/terms-of-use"
            component={TermsOfUse}
            {...rest}
          />
          <PrivateRouteRedirect
            exact
            path={appRoutes.profile}
            component={Profile}
          />
          <PrivateRouteRedirect exact path="/invites" component={Invites} />
          <PrivateRouteRedirect
            exact
            path="/posts-list"
            component={PostsList}
          />
          <PrivateRouteRedirect exact path="/locations" component={Locations} />
          <DefaultRoute
            exact
            path="/judgements"
            component={JudgementList}
            {...rest}
          />
          <DefaultRoute exact path={appRoutes.create} component={CreateRoute} />
          <DefaultRoute exact path="/popular" component={Popular} />
          <DefaultRoute exact path="/users" component={ListUsers} />
          <DefaultRoute exact path="/tags" component={TagsGrid} />
          <PrivateRouteRedirect exact path="/urls" component={UrlsBrowse} />
          <PrivateRouteRedirect exact path="/uris" component={URISBrowse} />
          <DefaultRoute exact path="/uri/:id" component={ViewUri} />
          <DefaultRoute exact path="/tree" component={TreePage} />
          <DefaultRoute exact path="/tree/:id" component={TreePage} />
          <DefaultRoute
            exact
            path="/search"
            component={SearchRoute}
            {...rest}
          />
          <DefaultRoute
            exact
            path="/filter"
            component={FilterRoute}
            {...rest}
          />
          <DefaultRoute exact path="/cats" component={CatsRoute} {...rest} />
          <DefaultRoute exact path="/types" component={TypesRoute} {...rest} />
          <DefaultRoute exact path="/namespaces" component={NamespaceList} />
          <PrivateRouteRedirect
            exact
            path={appRoutes.new}
            component={CreatePost}
            {...rest}
          />
          <DefaultRoute exact path="/test" component={TestRoute} {...rest} />
          <PrivateRouteRedirect
            exact
            path="/edit/id/:id"
            component={EditPostId}
            {...rest}
          />
          <PrivateRouteRedirect
            exact
            path="/edit/:namespace"
            component={EditPostNamespace}
            {...rest}
          />
          <PrivateRouteRedirect
            exact
            path={[appRoutes.posts, '/peek/:namespace', '/peek/id/:id']}
            component={Lists}
            {...rest}
          />

          <DefaultRoute
            exact
            path={systemRoutes.postId}
            component={PostRoute}
            {...rest}
          />
          <DefaultRoute
            exact
            path={systemRoutes.viewPostId}
            component={ViewPostRoute}
            {...rest}
          />

          <DefaultRoute
            exact
            path={['/id/:id', '/id/:id/id/:subId']}
            component={ViewPostId}
            {...rest}
          />

          <DefaultRoute
            exact
            path={[
              '/view/:namespace',
              '/view/:namespace/view/:subNamespace',
              '/post/:namespace/sub/:subNamespace',
            ]}
            component={ViewPostNamespace}
            {...rest}
          />

          <DefaultRoute
            exact
            path={objectPages}
            component={ViewObjectRoute}
            {...rest}
          />

          <DefaultRoute
            exact
            path={['/user/:uid', '/peek/:namespace', '/peek/id/:id']}
            component={User}
            {...rest}
          />
          {/* <DefaultRoute
            exact
            path={['/peek/id/:id', '/peek/:namespace']}
            component={Peek}
            {...rest}
          /> */}

          <DefaultRoute path="*" component={NotFound} unknown />
        </Switch>
        {/* </AnimatedSwitch> */}

        {children}
      </React.Suspense>
    </Box>
  )
}
