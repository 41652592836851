import get from 'lodash/get'
import { useContext, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  OrderByOptions,
  ReduxFirestoreQuerySetting,
  WhereOptions,
  useFirestoreConnect
} from 'react-redux-firebase'
import { useLocation } from 'react-router-dom'
// @ts-expect-error
import { getSnapshotByObject } from 'redux-firestore'

import { DocumentData, Query, WhereFilterOp } from '@firebase/firestore-types'
import { RouteContext } from '@Components/containers/RouteProvider'
import { Types } from '@Constants/config/types'
import { ExistingPostData } from '@Constants/post'
import { RootState } from '@State/store'


export interface SelectedState {
  titleSelected?: string;
  urlSelected?: string;
  typeSelected: Types;
  catSelected: string;
  tagsSelected?: string[];
  visSelected?: string;
  uidSelected?: string;
  sourceIdSelected?: string;
  hasSubPosts?: boolean
  sortAscending?: boolean;
  sortByEdited?: boolean;
}

export interface QueryObject {
  tagsQuery: string[]
  tagQuery: string
  catQuery: string
  typeQuery: Types
  uidQuery: string
  visQuery: string
  urlQuery?: string
  titleQuery?: string
  whereQuery: WhereOptions[]
  orderByQuery: OrderByOptions[]
  sourceIdQuery?: string
  convertWhereQuery: (queries: WhereOptions[], fbPath: Query<DocumentData>) => Query<DocumentData>
}

export interface QueryFuncProps {
  query: URLSearchParams
  selected?: SelectedState
  order?: string
}

const convertWhereQuery = (queries: WhereOptions[], fbPath: Query<DocumentData>) => {
  queries.map((opts: WhereFilterOp[]) => {
    // console.log('opts', opts[0], opts[1], opts[2])
    fbPath = fbPath.where(opts[0], opts[1], opts[2])
  })
  return fbPath
}

export const queryFunc = ({
  query,
  selected,
  order
}: QueryFuncProps): QueryObject => {
  // console.log('query', query)
  // console.log('selected', selected)
  // TODO: If any query values, ignore xSelected values
  const typeQuery = (query.get('type') || selected?.typeSelected || '')
  const catQuery = query.get('cat') || selected?.catSelected || ''
  const visQuery = query.get('vis') || selected?.visSelected || ''
  const tagQuery = query.get('tag') || ''
  const tagsQuery = (tagQuery && [tagQuery]) || (selected?.tagsSelected) || []
  // const sortQuery = query.get('sort');
  const uidQuery = query.get('userId') || selected?.uidSelected || ''
  const titleQuery = query.get('title') || selected?.titleSelected || ''
  const sourceIdQuery = query.get('sourceId') || selected?.sourceIdSelected || ''
  // const sortDirectionQuery = query.get('sortDirection') as OrderByOptions[1];
  const hasSubPosts = selected?.hasSubPosts

  const sortAscendingQuery = query.get('sortAsc') || selected?.sortAscending
  const sortByEditedQuery = query.get('sortBy') || selected?.sortByEdited

  const urlQuery = query.get('url') || selected?.urlSelected || '' // We only index url queries icw userId. Public urls are searchable through other means

  const whereQuery: WhereOptions[] = [];
  const orderByQuery: OrderByOptions[] = []

  const anyFilter = typeQuery || catQuery || visQuery || tagQuery

  /** Query filters */

  // UserId
  if (uidQuery) {
    whereQuery.push(['userId', '==', uidQuery])
  }

  // Title
  if (titleQuery) {
    whereQuery.push(['title', '>=', titleQuery])
  }

  // Type
  if (typeQuery) {
    whereQuery.push(['type', '==', typeQuery])
  } else {
    // whereQuery.push(['type', '>=', '']) // all types
  }

  // Source Id
  if (sourceIdQuery) {
    whereQuery.push(['sourceId', '==', sourceIdQuery])
  }

  // Cat
  if (catQuery) {
    whereQuery.push(['cat', '==', catQuery])
  }

  // Has SubPosts
  if (hasSubPosts && uidQuery) {
    whereQuery.push(['postCount', '>=', 1])
  }

  // Tag
  const hasSingleTag = tagQuery
  const hasMultipleTags = tagsQuery && tagsQuery.length && !hasSingleTag
  if (hasMultipleTags) {
    whereQuery.push(['tags', 'array-contains-any', tagsQuery ? tagsQuery : []])
  }
  if (hasSingleTag) {
    whereQuery.push(['tags', 'array-contains', tagQuery])
  }

  // console.log('visQuery', visQuery, uidQuery)
  // Vis
  if (visQuery === 'any') {
    if (!uidQuery) { // Fallback, when `any` and no uid query, default to public
      whereQuery.push([
        'visibility',
        '==',
        'public'
      ])
    }
    // Else no vis field to query both priv/pub with your userId
  } else if (visQuery === 'private') {
    whereQuery.push(['visibility', '==', 'private'])
  } else {
    // Default to public, also if somehow no vis is set
    whereQuery.push(['visibility', '==', 'public'])
  }

  // console.log("🚀whereQuery", whereQuery)
  // Sort
  if (titleQuery) {
    // Nothing
    orderByQuery.push(['title', 'asc'])
  } else if (sourceIdQuery) {
    // orderByQuery.push(['sourceId', 'asc'])
  }
  else if (hasSubPosts && uidQuery) {
    orderByQuery.push([
      'postCount',
      sortAscendingQuery ? 'asc' : 'desc',
    ])
  }
  else {
    orderByQuery.push([
      sortByEditedQuery ? 'edited' : uidQuery ? 'created' : 'published',
      sortAscendingQuery ? 'asc' : 'desc',
    ])
  }

  if (urlQuery) {
    whereQuery.push(['url', '==', urlQuery])
  }

  return {
    typeQuery,
    catQuery,
    tagsQuery,
    tagQuery,
    uidQuery,
    visQuery,
    urlQuery,
    titleQuery,
    whereQuery,
    orderByQuery,
    sourceIdQuery,
    convertWhereQuery
  }
}

export const useQueryFunc = ({
  type,
  cat,
  tags,
  vis,
  uid,
  url,
  sourceData,
  sortAscending,
  sortByEdited
}: {
  type?: Types
  cat?: string
  tags?: string[]
  // tag: string
  vis?: string
  uid?: string
  url?: string
  sourceData?: { sourceId?: string }
  sortAscending?: boolean
  sortByEdited?: boolean
}): QueryObject => {
  const query = new URLSearchParams(useLocation().search)

  const {
    typeQuery,
    catQuery,
    // tagQuery,
    tagsQuery,
    uidQuery,
    visQuery,
    urlQuery,
    sourceIdQuery,
  } = useContext(RouteContext)

  const queryMemo = useMemo(
    () =>
      queryFunc({
        query,
        selected: {
          // Use specified filters, or fallback to RouteContext Provided values (store & query params)
          typeSelected: type ? type : typeQuery,
          catSelected: cat ? cat : catQuery,
          tagsSelected: tags ? tags : tagsQuery,
          visSelected: vis ? vis : visQuery,
          urlSelected: url ? url : urlQuery,
          uidSelected: uid ? uid : uidQuery,
          sourceIdSelected: sourceData?.sourceId,
          sortAscending,
          sortByEdited,
        },
      }),
    [
      query,
      type,
      typeQuery,
      cat,
      catQuery,
      tags,
      vis,
      url,
      urlQuery,
      uidQuery,
      sourceIdQuery,
      sortAscending,
      sortByEdited,
    ]
  )
  return queryMemo
}

export const useQuery = ({
  collection = 'postsById',
  storeAs,
  limit = 50,
  type,
  cat,
  tags,
  vis,
  uid,
  url,
  sourceData,
  sortAscending,
  sortByEdited,
}: {
  collection?: string,
  storeAs?: string,
  limit?: number,
  type?: Types
  cat?: string
  tags?: string[]
  // tag: string
  vis?: string
  uid?: string
  url?: string
  sourceData?: { sourceId?: string }
  sortAscending?: boolean
  sortByEdited?: boolean
}) => {

  const queryFuncMemo = useQueryFunc({
    sortAscending,
    sortByEdited,
    type,
    cat,
    tags,
    vis,
    uid,
    url,
    sourceData
  })
  const { whereQuery, orderByQuery } = queryFuncMemo

  const storeAsPath = storeAs ? storeAs : collection

  const itemsQuery: ReduxFirestoreQuerySetting = {
    collection,
    storeAs: storeAsPath,
    limit,
    ...(whereQuery && whereQuery.length && { where: whereQuery }),
    ...(sortByEdited && {
      orderBy: [['edited', sortAscending ? 'desc' : 'asc']],
    }),
  }

  useFirestoreConnect([itemsQuery])

  const response: ExistingPostData[] = useSelector(
    ({ firestore: { ordered } }: RootState) => get(ordered, storeAsPath)
  )

  return {
    response,
    queries: queryFuncMemo
  }
}


export const useQueryWithCursor = ({
  collection = 'postsById',
  storeAs,
  limit = 50,
  type,
  cat,
  tags,
  vis,
  uid,
  url,
  sourceData,
  sortAscending,
  sortByEdited,
}: {
  collection?: string,
  storeAs?: string,
  limit?: number,
  type?: Types
  cat?: string
  tags?: string[]
  // tag: string
  vis?: string
  uid?: string
  url?: string
  sourceData?: { sourceId?: string }
  sortAscending?: boolean
  sortByEdited?: boolean
}) => {
  const [firstPage, setFirstPage] = useState(true)
  const [lastCursor, setLastCursor] = useState(null)
  const [firstCursor, setFirstCursor] = useState(null)

  const queryFuncMemo = useQueryFunc({
    sortAscending,
    sortByEdited,
    type,
    cat,
    tags,
    vis,
    uid,
    url,
    sourceData
  })
  const { whereQuery, orderByQuery } = queryFuncMemo

  const storeAsPath = storeAs ? storeAs : collection

  const itemsQuery: ReduxFirestoreQuerySetting = {
    collection,
    storeAs: storeAsPath,
    limit,
    ...(whereQuery && whereQuery.length && { where: whereQuery }),
    ...(sortByEdited && {
      orderBy: [['edited', sortAscending ? 'desc' : 'asc']],
    }),
    // @ts-ignore
    ...(lastCursor && {
      startAfter: lastCursor,
      limit,
    }),
    // @ts-ignore
    ...(firstCursor && {
      endBefore: firstCursor,
      limitToLast: limit,
    }),
  }

  useFirestoreConnect([itemsQuery])

  const response: ExistingPostData[] = useSelector(
    ({ firestore: { ordered } }: RootState) => get(ordered, storeAsPath)
  )

  const nextPage = () => {
    setFirstCursor(null)
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-call
    setLastCursor(getSnapshotByObject(response[limit - 1]))
    setFirstPage(false)
  }

  // const prevPage = () => {
  //   setLastCursor(null)
  //   setFirstCursor(getSnapshotByObject(response[0]))
  // }

  const isProbablyLastPageButMightAlsoBeFirst = (response &&
    response.length &&
    response.length < limit &&
    !firstPage) as boolean

  return {
    response,
    queries: queryFuncMemo,
    nextPage,
    isProbablyLastPageButMightAlsoBeFirst,
    // prevPage
  }
}

