import { useEffect } from 'react'

import { TElement, UseElementPropsOptions } from '@udecode/plate'
import {
  HTMLPropsAs,
  PlateRenderElementProps,
  Value,
  createComponentAs,
  createElementAs,
  useEditorRef,
  useElement,
  useElementProps,
} from '@udecode/plate-core'
import { ELEMENT_MEDIA_EMBED } from '@Components/shared/object-editor/plugins/media/media-embed/createMediaEmbedPlugin'
import { Resizable } from '@Components/shared/object-editor/plugins/media/resizable'

import { useMediaStore } from './mediaStore'
import { parseMediaUrl } from './parseMediaUrl'
import { TMediaElement } from './types'

export type MediaRootProps = PlateRenderElementProps<Value, TMediaElement> &
  HTMLPropsAs<'div'> & {
    pluginKey?: string
  }

export const useMedia = ({
  pluginKey = ELEMENT_MEDIA_EMBED,
  ...props
}: MediaRootProps): HTMLPropsAs<'iframe'> => {
  const editor = useEditorRef()
  const element = useElement<TMediaElement>()
  const setUrlData = useMediaStore().set.urlData()
  const { url: elementUrl } = element

  useEffect(() => {
    const parsed = parseMediaUrl(editor, {
      pluginKey,
      url: elementUrl,
    })

    if (parsed) {
      setUrlData(parsed)
    }
  }, [editor, elementUrl, pluginKey, setUrlData])

  return useElementProps(props as UseElementPropsOptions<TElement, 'iframe'>)
}

export const MediaRoot = createComponentAs<
  PlateRenderElementProps<Value, TMediaElement> & HTMLPropsAs<'div'>
>((props) => {
  const htmlProps = useMedia(props)

  return createElementAs('div', htmlProps)
})

export const Media = {
  Root: MediaRoot,
  Resizable,
}
