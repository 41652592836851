import { ExistingPostData } from '@Constants/post'
import { roleVars, UserRoles } from '@Constants/config/roleVars'
import { Tooltip, Icon } from '@mui/material'
import React from 'react'

export function ExtensionIcon({
  role,
  post,
}: {
  role?: UserRoles
  post: ExistingPostData
}): React.ReactElement {
  return (
    <>
      {role && roleVars.visibility.extension.includes(role) && post.ext ? (
        <Tooltip title="Added with extension">
          <Icon>folder_special</Icon>
        </Tooltip>
      ) : null}
    </>
  )
}
