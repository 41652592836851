import {
  EmbedUrlData,
  useMediaStore,
} from '@Components/shared/object-editor/plugins/MediaElements'
import { createElementAs, HTMLPropsAs } from '@udecode/plate-core'

export const useMediaEmbedVideo = ({
  ...props
}: EmbedUrlData): HTMLPropsAs<'iframe'> => {
  //FIXME: IDK Why this relies on store, and when it is supposed to be set
  const { url } = useMediaStore().get.urlData() || props?.url

  return {
    title: 'embed',
    frameBorder: '0',
    allowFullScreen: true,
    src: url,
    ...props,
  }
}

export const MediaEmbedVideo = (props: EmbedUrlData) => {
  const htmlProps = useMediaEmbedVideo(props)

  return createElementAs('iframe', htmlProps)
}
