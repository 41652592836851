import React, { useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { Icon, Tooltip } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import { SxProps } from '@mui/system'
import Favicon from '@Components/ui/Favicon'
import { appVars } from '@Constants/config/app'
import { useBreakpoints } from '@Constants/config/globalStyles'
import { appRoutes } from '@Constants/config/routeVars'
import { ExistingPostData, SourceTypes } from '@Constants/post'
import { getEmbedVideo } from '@Utils/metaFunc'
import { capitalizeFirstLetter } from '@Utils/textFunc'
import urlFunc from '@Utils/urlFunc'
import ImageModal from '@Components/shared/modal/ImageModal'
import VideoModal from '@Components/shared/modal/VideoModal'
import { RouteContext } from '@Components/containers/RouteProvider'

const buttonSX = {
  mr: 2,
  mb: { md: 2, lg: 0 },
  '&:last-child': {
    marginRight: 0,
  },
}
/* eslint @typescript-eslint/restrict-template-expressions: 0 */
export const LinkButton = ({
  url,
  href,
  isSource = false,
  mini = false,
  sx,
  ...rest
}: {
  href: string
  url?: string
  isSource?: boolean
  mini?: boolean
  sx?: SxProps
}) => {
  const urlObj = url ? urlFunc(url) : href ? urlFunc(href) : ''
  const urlType = urlObj && urlObj.meta && urlObj.meta.type
  return (
    <Tooltip title={`Link to: ${url}`}>
      <Button
        href={href}
        component="a" // IMPORTANT! Otherwise href will be treated as local url
        size={mini ? 'small' : 'large'}
        target="_blank"
        variant="outlined"
        color="inherit"
        startIcon={
          <Favicon size={mini ? 'small' : 'large'} url={url || href} />
        }
        endIcon={<Icon>open_in_new</Icon>}
        sx={sx}
        {...rest}
      >
        {
          isSource
            ? `${urlType === 'default' ? '' : urlType} Source`
            : `${urlType === 'default' ? '' : urlType} Link` // ${url.host}`
        }
      </Button>
    </Tooltip>
  )
}

export const SourceButton = ({
  sourceType,
  sourceId,
  mini,
  sx,
  ...rest
}: {
  sourceType?: SourceTypes
  sourceId?: string
  mini?: boolean
  sx?: SxProps
}) => {
  const history = useHistory()
  const getSource = () => {
    switch (sourceType) {
      case 'youtube':
        return `https://www.youtube.com/${sourceId}`

      default:
        return ``
        break
    }
  }
  const getLocalLink = () => {
    return `${appRoutes.name}/${sourceType}`
  }
  const goSource = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    switch (sourceType) {
      case 'youtube':
        window.open(getSource())
        break

      default:
        history.push(getLocalLink())
        break
    }
  }

  const getSourceText = () => {
    switch (sourceType) {
      case 'youtube':
        // TODO: When user/x show user?
        return `Channel Source`
      default:
        return `Source`
    }
  }

  const src = getSource()

  return (
    <Tooltip title={`Link to: ${src ? src : `${sourceType}`}`}>
      <Button
        href={src ? src : getLocalLink()}
        component="a" // IMPORTANT! Otherwise href will be treated as local url
        size={mini ? 'small' : 'large'}
        target="_blank"
        variant="outlined"
        color="inherit"
        startIcon={
          src ? <Favicon size={mini ? 'small' : 'large'} url={src} /> : null
        }
        endIcon={<Icon>source</Icon>}
        onClick={goSource}
        sx={sx}
        {...rest}
      >
        {getSourceText()}
      </Button>
    </Tooltip>
  )
}

export const UriButton = ({
  uriRef,
  onClick,
  url,
  mini = false,
  sx,
  ...rest
}: {
  uriRef: string
  onClick?: (uriRef: string) => void
  url?: string
  mini?: boolean
  sx?: SxProps
}) => {
  const { goUri } = useContext(RouteContext)
  return (
    <Tooltip title={`Show other stuff linking to same URL: ${url}`}>
      <Button
        size={mini ? 'small' : 'large'}
        variant="outlined"
        color="inherit"
        startIcon={<Icon>link</Icon>}
        endIcon={<Icon>open_in_full</Icon>}
        onClick={() => {
          if (onClick) {
            onClick(uriRef)
            return
          }
          if (uriRef) {
            goUri(uriRef)
            return
          }
        }}
        sx={sx}
        {...rest}
      >
        {capitalizeFirstLetter(appVars.posts)}
      </Button>
    </Tooltip>
  )
}

export const RefButton = ({
  refNamespace, // ref is not allowed to be used as prop
  onClick,
  mini = false,
  sx,
  title,
  ...rest
}: {
  refNamespace: string
  onClick?: (ref: string) => void
  mini?: boolean
  sx?: SxProps
  title?: string
}) => {
  // TODO: Figure out if links to current page/post, and disable/hide button?
  const { goId } = useContext(RouteContext)
  return (
    <Tooltip title={`Namespace: ${refNamespace}`}>
      <Button
        onClick={() => {
          if (onClick) {
            onClick(refNamespace)
            return
          }
          if (refNamespace) {
            goId(refNamespace)
            return
          }
        }}
        startIcon={<Icon>abc</Icon>}
        endIcon={<Icon>open_in_full</Icon>}
        size={mini ? 'small' : 'large'}
        color="inherit"
        variant="outlined"
        sx={sx}
        {...rest}
      >
        {title || refNamespace}
      </Button>
    </Tooltip>
  )
}

export const RefIdButton = ({
  refId,
  onClick,
  mini = false,
  sx,
  ...rest
}: {
  refId: string
  onClick?: (refId: string) => void
  mini?: boolean
  sx?: SxProps
}) => {
  const { goId } = useContext(RouteContext)
  return (
    <Tooltip title={`Post Id: ${refId}`}>
      <Button
        onClick={() => {
          if (onClick) {
            onClick(refId)
            return
          }
          if (refId) {
            goId(refId)
            return
          }
        }}
        startIcon={<Icon>link</Icon>}
        endIcon={<Icon>open_in_full</Icon>}
        size={mini ? 'small' : 'large'}
        color="inherit"
        variant="outlined"
        sx={sx}
        {...rest}
      >
        Linked Post
      </Button>
    </Tooltip>
  )
}

export const ImgButton = ({
  imgUrl,
  mini = false,
  sx,
  ...rest
}: {
  imgUrl: string
  mini?: boolean
  sx?: SxProps
}) => {
  const [imageOpen, setImageOpen] = useState(false)

  function clickButton() {
    setImageOpen(true)
  }
  return (
    <>
      <Tooltip title={`Image Url: ${imgUrl}`}>
        <Button
          onClick={clickButton}
          startIcon={<Favicon size={mini ? 'small' : 'large'} url={imgUrl} />}
          endIcon={<Icon>fullscreen</Icon>}
          size={mini ? 'small' : 'large'}
          variant="outlined"
          color="inherit"
          sx={sx}
          {...rest}
        >
          Image
        </Button>
      </Tooltip>
      <ImageModal
        open={imageOpen}
        handleClose={(e) => setImageOpen(false)}
        url={imgUrl}
      />
    </>
  )
}

export const VideoButton = ({
  url,
  mini = false,
  sx,
  ...rest
}: {
  url: string
  mini?: boolean
  sx?: SxProps
}) => {
  const [videoOpen, setVideoOpen] = useState(false)

  function clickButton() {
    setVideoOpen(true)
  }

  return (
    <>
      <Tooltip title={`Video Url: ${url}`}>
        <Button
          onClick={clickButton}
          startIcon={<Favicon size={mini ? 'small' : 'large'} url={url} />}
          endIcon={<Icon>slideshow</Icon>}
          size={mini ? 'small' : 'large'}
          variant="outlined"
          color="inherit"
          sx={sx}
          {...rest}
        >
          Video
        </Button>
      </Tooltip>
      <VideoModal
        open={videoOpen}
        handleClose={(e) => setVideoOpen(false)}
        url={url}
      />
    </>
  )
}

export const LinkButtons = ({
  postItem,
  mini = false,
  direction,
  spacing = 1,
  sx = buttonSX,
}: {
  postItem: ExistingPostData
  mini?: boolean
  direction?: 'row' | 'row-reverse' | 'column' | 'column-reverse'
  spacing?: number
  sx?: SxProps
}) => {
  const { largerThanMobile } = useBreakpoints()
  const buttonArray: React.ReactElement[] = []
  // const onClickRef = () => {}
  // const onClickRefId = () => {}
  // const onClickUriRef = () => {}
  const metaMedia = postItem?.url ? getEmbedVideo(postItem.url) : ''

  if (postItem.imgUrl) {
    if (typeof postItem.imgUrl === 'string') {
      buttonArray.push(<ImgButton mini={mini} imgUrl={postItem.imgUrl} />)
    }
  }
  if (metaMedia && metaMedia.type === 'video') {
    if (postItem.url && typeof postItem.url === 'string') {
      buttonArray.push(<VideoButton mini={mini} url={postItem.url} />)
    }
  }
  if (postItem.uriRef) {
    buttonArray.push(
      <UriButton
        mini={mini}
        url={postItem?.url}
        uriRef={postItem.uriRef}
        // onClick={onClickUriRef}
      />
    )
  }
  if (postItem.ref) {
    buttonArray.push(
      <RefButton
        mini={mini}
        refNamespace={postItem.ref}
        // onClick={onClickRef}
      />
    )
  }
  if (postItem.refId) {
    buttonArray.push(
      <RefIdButton
        mini={mini}
        refId={postItem.refId}
        // onClick={onClickRefId}
      />
    )
  }
  if (postItem.url) {
    if (typeof postItem.url === 'string') {
      // const { href } = postItem?.url
      //   ? urlFunc(postItem.url)
      //   : { href: postItem?.url ? postItem?.url : '/' }
      buttonArray.push(
        <LinkButton mini={mini} url={postItem.url} href={postItem?.url} />
      )
    }
    // TODO:  check if postItem has urlArray?
    // TODO:  check if postItem has imgUrlArray?
  }
  return (
    <Stack
      direction={direction ? direction : largerThanMobile ? 'row' : 'column'}
      spacing={spacing}
      sx={{ ...sx }}
    >
      {buttonArray}
    </Stack>
  )
}
