// import 'katex/dist/katex.min.css'

import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { createEditor } from 'slate'
import { Editable, Slate, withReact } from 'slate-react'

import { SxProps } from '@mui/material'
import Box from '@mui/material/Box'
import { deserializeMD } from '@udecode/slate-plugins-md-serializer'
import { RouteContext } from '@Components/containers/RouteProvider'
import { withLinks } from '@Components/shared/markdown/slateplugins'
import ErrorBoundary from '@Components/shared/system/ErrorBoundary'

import { Leaf, renderSlateElems } from './SlateElements'

export default function MarkdownViewer({
  value = '',
  mini,
  isSubPost,
  debug,
  sx,
}: {
  value?: string
  mini?: boolean
  isSubPost?: boolean
  debug?: boolean
  sx?: SxProps
}) {
  const { goViewNamespace } = useContext(RouteContext)
  const history = useHistory()
  const slateEditor = useMemo(() => withLinks(withReact(createEditor())), [])

  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  let mdToSlate = deserializeMD(slateEditor, value)
  // console.log('mdToSlate', mdToSlate)

  const small = isSubPost || mini

  const [internalValue, setInternalValue] = useState<Node[]>(
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
    mdToSlate.length === 0 // mdToSlate)
      ? // >= 1 ? mdToSlate :
        [
          {
            type: 'paragraph',
            children: [{ text: '...' }],
          },
        ]
      : mdToSlate
  )

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    mdToSlate = deserializeMD(slateEditor, value) || []
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    setInternalValue(mdToSlate)
  }, [value])

  const renderElement = useCallback(
    (props) => {
      return renderSlateElems(props, history, small)
    },
    [small]
  )

  // Define a leaf rendering function that is memoized with `useCallback`.
  const renderLeaf = useCallback((props) => {
    return <Leaf {...props} />
  }, [])

  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
  slateEditor.children = internalValue

  return (
    <Box sx={sx}>
      <ErrorBoundary>
        <Slate
          editor={slateEditor}
          // @ts-expect-error
          value={internalValue}
          // @ts-expect-error
          onChange={(val) => setInternalValue(val)}
        >
          <Editable
            readOnly
            placeholder="Markdown Preview"
            renderElement={renderElement}
            renderLeaf={renderLeaf}
          />
        </Slate>
      </ErrorBoundary>
      {debug ? (
        <pre style={{ whiteSpace: 'pre-wrap' }}>
          {JSON.stringify(value, null, 2)}
        </pre>
      ) : null}
    </Box>
  )
}
