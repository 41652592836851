import { useContext } from 'react'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import { isEmpty, isLoaded } from 'react-redux-firebase'
import { useParams } from 'react-router-dom'

import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import LinearProgress from '@mui/material/LinearProgress'
import Paper from '@mui/material/Paper'
import { RouteContext } from '@Components/containers/RouteProvider'
import PostButtons from '@Components/post/PostButtons'
import PostHeroBody from '@Components/post/PostHeroBody'
import {
  containerStyles,
  defaultStyles,
  getHeroDims,
  mainContainerMaxWidth,
  useBreakpoints,
} from '@Constants/config/globalStyles'
import NotFound from '@Routes/system/NotFound'
import { editPostAction } from '@State/actions/postActions'
import { AppDispatch, RootState } from '@State/store'
import { showHero } from '@Styles/styleVars'
import { usePostIdWithSubposts } from '@Utils/usePost'

import { ReadOnlyObject } from '../../components/shared/object-editor/ReadOnlyObject'

interface StateProps {
  uid?: string
}
interface DispatchProps {
  editPost: (attrs) => void
}
interface OwnProps {
  postId?: string
  debug?: boolean
}

type Props = StateProps & DispatchProps & OwnProps

function ViewPostRoute({ postId, editPost, uid, debug = true }: Props) {
  const container = containerStyles()
  const { id } = useParams<{ id: string; namespace: string }>()
  const { goPost } = useContext(RouteContext)
  const postIdToLoad = postId || id
  const [post, subPosts, loaded] = usePostIdWithSubposts(postIdToLoad)
  const isOwner = post?.userId === uid
  const notExists = isEmpty(post) && isLoaded(post) && loaded

  const { matchesLarge, matchesLargest } = useBreakpoints()
  const isDesktop = matchesLarge || matchesLargest
  const shouldHeaderBeShown = post ? showHero(post) : false

  const theHeight = getHeroDims(true)

  if (loaded && post) {
    return (
      <Container
        className={container.rootMargins}
        maxWidth={mainContainerMaxWidth}
      >
        <Helmet>
          <title>{post?.title}</title>
          <meta name="title" content={post?.title} />
          <meta name="description" content={post?.text} />
        </Helmet>

        <Paper
          sx={{
            position: 'relative',
            overflow: 'hidden',
            transition: defaultStyles.transition,
            height: shouldHeaderBeShown
              ? isDesktop
                ? theHeight.desktopHeight
                : theHeight.height
              : 0,
          }}
        >
          <PostHeroBody item={post} collapsed noTitle />
        </Paper>
        <Box>
          <ReadOnlyObject post={post} id={post.id} />
        </Box>
        <Box sx={{ mt: 2 }}>
          <PostButtons
            mini={!isDesktop}
            item={post}
            // toggleRightDrawerAction={toggleRightDrawer}
          />
        </Box>
      </Container>
    )
  } else if (!loaded) {
    return <LinearProgress />
  } else if (notExists) {
    return <NotFound />
  } else {
    return <>idk what happened</>
  }
}

const mapStateToProps = (state: RootState /*, ownProps*/) => {
  return {
    uid: state.firebase?.auth?.uid,
  }
}

const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    editPost: ({ id, data, form }) =>
      dispatch(editPostAction({ id, data, form })),
  }
}

export default connect<StateProps, DispatchProps, OwnProps, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(ViewPostRoute)
