import map from 'lodash/map'

import { appVars } from '@Constants/config/app'
import categories from '@Constants/config/categories'
import { Types, subTypeData, typeData } from '@Constants/config/types'
import { CatData } from '@Constants/types/CatData'
import { FormDataItem, formData } from '@Constants/types/FormData'
import { TypeData } from '@Constants/types/TypeData'

import { capitalizeFirstLetter } from './textFunc'


// TODO: Write tests
// TODO: Use lodash

export function getTypeItem(typeQuery?: string, customTypeData?: TypeData[]): TypeData | null {
  const dat = customTypeData ? customTypeData : [...subTypeData, ...typeData]
  const typeItem = dat.find((t) => t.val === typeQuery)
  if (typeItem) {
    return typeItem
  } else {
    return null
  }
}

export function getCatItem(catQuery?: string): CatData | null {
  const dat = [...categories]
  const catItem = dat.find((t) => t.val === catQuery)
  if (catItem) {
    return catItem
  } else {
    return null
  }
}

export function shortTypeString(typeQuery: Types, noS?: boolean, customTypeData?: TypeData[]): string {
  const typeItem = getTypeItem(typeQuery, customTypeData)
  const typeTit = typeItem ?
    typeItem.plural ?
      noS ? typeItem.val : typeItem.plural
      : `${typeItem.val}${noS ? '' : 's'}`
    : appVars.things
  return typeTit
}

export function singularSubTypeString(typeQuery: string, customTypeData?: TypeData[]): string {
  const typeItem = getTypeItem(typeQuery, customTypeData)
  const typeStart = typeItem ?
    typeItem.singular ?
      typeItem.singular : 'a'
    : ''
  return `${typeStart} ${typeItem ? capitalizeFirstLetter(typeItem.val) : appVars.things}`
}

export function typeTitle({ type,
  cat,
  tag,
  url,
  noCap,
  noS, // For when plural don't need s
  userId,
  vis
}: {
  type?: Types,
  cat?: string,
  tag?: string | string[],
  url?: string,
  noCap?: boolean, // Don't capitalize
  noS?: boolean, // For when plural don't need s
  userId?: string,
  vis?: string
}): string {
  const typeTit = shortTypeString(type || '', noS)

  const catItem = getCatItem(cat)
  const catVal = catItem ? catItem.modifier ? catItem.modifier : catItem.val : ''

  const titleArray = [
    catVal,
    tag,
    typeTit
  ]

  const finalTitle: string[] = []
  map(titleArray, (word) => {
    let correctedValueStrippedSpaces: string
    if (typeof word === 'string' && word.length > 0) {
      correctedValueStrippedSpaces = word.replace(/^\s+|\s+$/g, '')
      finalTitle.push(correctedValueStrippedSpaces)
    }
  })

  // console.log('finalTitle', finalTitle)
  const fin = finalTitle
    .toString()
    .split(',')
    .join(' ')

  const who = vis === 'public' ? 'Public' : vis === 'private' ? 'Your' : 'Any'

  const formattedTitle = noCap ? fin
    : capitalizeFirstLetter(userId ? `${who} ${url ? 'links' : fin}` : fin)


  // console.log('formattedTitle', formattedTitle)
  return formattedTitle
}


export const getFormData = (fieldName: string): FormDataItem | null => {
  const fieldRef = formData[fieldName] ? formData[fieldName] : null
  if (fieldRef !== null) {
    return fieldRef
  } else {
    // console.error('Invalid fieldname: ', fieldName)
    return null
  }
}


export const getFormDataField = (fieldValue: string, fieldName: string): boolean | string => {
  const fieldRef = getFormData(fieldName)
  if (fieldRef !== null && fieldRef[fieldValue]) {
    return fieldRef[fieldValue]
  } else {
    // console.error('Invalid fieldValue or fieldName: ', fieldValue, fieldName)
    return false
  }
}


export const isFieldRequired = (fieldName: string, type?: Types): boolean => {
  const requiredField = getFormDataField('required', fieldName)
  if (requiredField !== null && type) {
    const required = typeof requiredField === 'boolean' ? requiredField : requiredField.indexOf(type) !== -1
    return required
  } else {
    // console.error('Invalid fieldname: ', fieldName)
    return false
  }
}



export function getTypeItemProp(fieldProp: string, type?: string): string {
  const typeItem = getTypeItem(type)
  if (typeItem && typeItem[fieldProp]) {
    return typeItem[fieldProp]
  } else {
    // console.error('Invalid fieldProp: ', fieldProp)
    return ''
  }
}


// 'Term' === 'getDefaultValue('title', 'term')
// 'place' === getDefaultValue('cat', 'person')
export const getTypeField = (fieldName: string, type?: string): string => {
  const typeFieldsRef = getTypeItemProp('fields', type)

  if (typeFieldsRef && typeFieldsRef[fieldName]) {
    const typeField = typeFieldsRef ? typeFieldsRef[fieldName] : ''
    return typeField
  } else {
    // console.error('Invalid fieldName: ', fieldName)
    return ''
  }
}


export const getTypeFieldValue = (fieldValue: string, fieldName: string, type?: string,): string => {
  const typeField = getTypeField(fieldName, type)
  if (typeField && typeField[fieldValue]) {
    return typeField[fieldValue]
  } else {
    // console.error('Invalid fieldName: ', fieldName)
    return ''
  }
}