import React from 'react'

import Icon from '@mui/material/Icon'
import { SxProps } from '@mui/system'
import categories, { Cats, sysCats } from '@Constants/config/categories'
import { iconFontSize } from '@Styles/styleVars'
import IconButton from '@mui/material/IconButton'
import { capitalizeFirstLetter } from '@Utils/textFunc'
import Tooltip from '@mui/material/Tooltip'

export default function CatIcon({
  cat,
  mini,
  tiny,
  color = 'inherit',
  edit,
  showTooltip,
  toolTipText,
  goCat,
  sx,
}: {
  cat: string
  color?:
    | 'inherit'
    | 'action'
    | 'disabled'
    | 'primary'
    | 'secondary'
    | 'error'
    | 'info'
    | 'success'
    | 'warning'
  edit?: boolean
  mini?: boolean
  tiny?: boolean
  showTooltip?: boolean
  toolTipText?: string
  goCat?: (type: Cats, e) => void
  sx?: SxProps
}) {
  const getCatName = () => {
    return (
      cat &&
      [...categories, ...sysCats].find((cate) => {
        const catVal = cat
        const resultFound = cate.val === catVal
        if (resultFound) {
          return resultFound
        } else {
          return false
        }
      })
    )
  }
  const catName = getCatName()

  const catIcon = catName && catName.icon ? catName.icon : 'view_comfy'

  const defaultToolTipTxt = showTooltip
    ? catName
      ? `Category: '${catName.val}'`
      : `Click to select type`
    : `${capitalizeFirstLetter(cat)}`

  const toolTipTxt = toolTipText ? toolTipText : defaultToolTipTxt

  const fontSize = tiny ? 'tiny' : mini ? 'mini' : 'large'

  const icon = (
    <Icon
      color={color}
      style={{
        fontSize: iconFontSize(fontSize),
      }}
      sx={sx}
    >
      {catIcon}
    </Icon>
  )

  if (goCat) {
    return (
      <IconButton
        onClick={
          !edit
            ? (e) => {
                e.stopPropagation()
                goCat(cat, e)
              }
            : undefined
        }
        disabled={edit}
        sx={sx}
      >
        <Tooltip title={toolTipTxt}>{icon}</Tooltip>
      </IconButton>
    )
  } else if (showTooltip || toolTipTxt) {
    return (
      <Tooltip title={toolTipTxt} placement="bottom-end">
        {icon}
      </Tooltip>
    )
  } else {
    return icon
  }
}
