import { connect } from 'react-redux'

import Box from '@mui/system/Box'
import Fab from '@Components/containers/ui/Fab'
import { RootState } from '@State/store'

function FabFixed({ fab }: { fab: string }) {
  return (
    <Box
      sx={{
        position: 'fixed',
        bottom: {
          xs: 16 + 56,
          sm: 16 + 56,
          md: 16,
          lg: 16,
        },
        right: 16,
      }}
    >
      <Fab fab={fab} />
    </Box>
  )
}

const mapStateToProps = (state: RootState /*, ownProps*/) => {
  return {
    // context: state.appState.context
    // typeSelected: state.storage?.typeSelected,
    // catSelected: state.storage?.catSelected,
    // tagsSelected: state.storage?.tagsSelected,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    // clickFab: context => dispatch({
    //   type: 'CLICK_FAB',
    //   context
    // })
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FabFixed)
