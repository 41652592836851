import FabFixed from '@Components/containers/ui/FabFixed'
import { roleVars } from '@Constants/config/roleVars'
import routeVars from '@Constants/config/routeVars'
import { PrivateRouteShow } from '@Routes/Routers'

export default function FabRoutes() {
  return (
    <>
      <PrivateRouteShow
        exact
        path={routeVars.fab.view}
        roles={roleVars.fab.view}
        component={FabFixed}
        fab="visibility"
      />
      <PrivateRouteShow
        exact
        path={routeVars.fab.visibilityNamespace}
        roles={roleVars.fab.visibilityNamespace}
        component={FabFixed}
        fab="visibilityNamespace"
      />
      <PrivateRouteShow
        exact
        path={routeVars.fab.add}
        roles={roleVars.fab.add}
        component={FabFixed}
        fab="add"
      />
      <PrivateRouteShow
        exact
        path={routeVars.fab.edit}
        roles={roleVars.fab.edit}
        component={FabFixed}
        fab="edit"
      />
      <PrivateRouteShow
        exact
        path={routeVars.fab.editNamespace}
        roles={roleVars.fab.editNamespace}
        component={FabFixed}
        fab="editNamespace"
      />
    </>
  )
}
