// @ts-nocheck
/* eslint-disable */
import { buildChartTheme } from '@visx/xychart';

import { brandVars } from '@Constants/config/brand'

export const customTheme = ({ palette }) => buildChartTheme({
  // colors
  backgroundColor: palette.background.paper, // Tooltip & annotation bgColor
  colors: [
    // 'rgba(255,231,143,0.8)',
    palette.info.main,
    palette.primary.main,
    palette.secondary.main,
    // brandVars.secondaryColorDark,
    // brandVars.primaryColorDark,
  ], // categorical colors, mapped to series via `dataKey`s

  // // labels
  svgLabelBig: { fill: palette.text.primary },
  svgLabelSmall: {
    fill: palette.text.primary
  }, // SVGTextProps;
  // htmlLabel?: HTMLTextStyles;
  // // lines
  xAxisLineStyles: palette.text.secondary, // LineStyles;
  // yAxisLineStyles?: LineStyles;
  // xTickLineStyles?: LineStyles;
  // yTickLineStyles?: LineStyles;
  tickLength: 8,
  // // grid
  gridColor: palette.text.secondary,
  gridColorDark: palette.text.primary, // used for axis baseline if x/yxAxisLineStyles not set
  gridStyles: { stroke: palette.text.secondary }
})
