import firebase from 'firebase/compat/app'
import { ofType } from 'redux-observable'
import { Observable } from 'rxjs'
import { mapTo, mergeMap } from 'rxjs/operators'

import { sendNotifcationAction } from '@State/actions/appStateActions'
import { JUDGE_POST, JudgePostAction } from '@State/actions/judgementActions'
import { firestore } from '@State/firebase'
import { RootState, StoreDependencies } from '@State/store'
import { unjudgeByPostId, judgeByPostId } from '@State/epics/judgement.helpers'


export const likePostEpic = (action$: Observable<JudgePostAction>, state$: RootState, { store }: StoreDependencies) => action$.pipe(
  ofType(JUDGE_POST),
  mergeMap(({
    id,
    uid,
    variant,
    data: {
      likeType,
      likeValue,
      type
    }
  }) => {

    const judgementsByPostIdRef = firestore
      .collection('judgementsByPostId')
      .doc(id)

    const judgementsByUidRef = judgementsByPostIdRef
      .collection('judgementsByUid')
      .doc(uid)


    if (variant === 'unjudge') {

      // return unjudgeByUid({
      //   judgementsByUidRef,
      //   userPostIdRef,
      //   likeType,
      //   likeValue
      // }).then(() => {
      return unjudgeByPostId({
        judgementsByPostIdRef,
        judgementsByUidRef,
        likeType,
        likeValue
      }).then(() => {
        store.dispatch(sendNotifcationAction('Unjudged', 'success'))
      })
      // })

    } else {

      // return judgeByUid({
      //   judgementsByUidRef,
      //   userPostIdRef,
      //   likeType,
      //   likeValue,
      //   type,
      //   uid,
      //   id
      // })
      //   .then(() => {
      return judgeByPostId({
        judgementsByPostIdRef,
        judgementsByUidRef,
        likeType,
        likeValue,
        type,
        uid,
        id
      }).then(() => {
        store.dispatch(sendNotifcationAction('Judged', 'success'))
      })

      // })

    }

  }),
  mapTo({ type: 'POST_JUDGED' })
)
