import { capitalizeFirstLetter } from '@Utils/textFunc';
import reduce from 'lodash/reduce'

import { sentenceToNamespace } from '@Utils/stringFunc'
import { brandVars } from '@Constants/config/brand';

export interface URLObjectType {
  url: string;
  href?: string;
  origin?: string;
  pathname?: string;
  encodedPathname?: string;
  host?: string;
  search?: string;
  params?: Params;
  tld: string;

  meta?: URLMeta
  isLocal?: boolean;
}

export type MetaTypes =
  'video' | 'short' | 'image' | 'stream' |
  'playlist' |
  'channel' | 'social' | 'profile' |
  'embed' | 'wiki' |
  'results' | 'relations' |
  'default'

export type Brands =
  'youtube' | 'odysee' | 'vimeo' | 'rumble' | 'twitch' |
  'wikipedia' | 'wiktionary' |
  'twitter'

export interface URLMeta {
  type: MetaTypes;
  brand?: Brands;
  id?: string;
  anchor?: string; // Subsection of page, or current video id in playlist
  index?: string; // Index of playlist
}

interface Params {
  [prop: string]: string;
}


export function specificEncoder(path: string) {
  // Limit to specific chars to be encoded  (not @ or /)
  // TODO: Perhaps encode with encodeURIComponent(), and then decode / back
  const replacedPath = path.replace(/[.]/g, '%2e') // Replace dots so path is savable in firebase
    .replace(/[$]/g, '%24') // Replace $
  return replacedPath
}


export function breakDownParams(params?: string | null): Params {
  // console.log('params', params)
  if (params && params.split('?')) {
    const stripQ = params
      .split('?')[1];

    const paramsSplit = stripQ && stripQ
      .split('&');

    const allParams: Params = reduce(paramsSplit, (all: Params, item: string): Params => {
      const paramArray = item
        .split('=');
      const prop = paramArray[0];
      const val = paramArray[1];
      all[prop] = val;
      return all;
    }, {})

    // console.log('allPARAMS', allParams)
    return allParams
  } else {
    return {}
  }
}

export function getUrlMetadata(urlObj: Partial<URLObjectType>): URLMeta {
  let type: MetaTypes
  let id: string | undefined
  let list: string | undefined
  let anchor: string | undefined
  let index: string | undefined
  switch (urlObj.host) {
    case 'www.youtube.com':
    case 'm.youtube.com':
    case 'youtube.com':
    case 'youtu.be':
      const videoId = (urlObj?.params?.v) ? urlObj.params.v : undefined
      type = 'video' // Default to video
      list = urlObj?.params?.list // ? urlObj.params.list : undefined
      index = urlObj?.params?.index // ? urlObj.params.list : undefined
      if (!videoId) {
        // If shortened like `youtu.be`, get id from pathname instead of param
        id = (urlObj.pathname) ? urlObj.pathname.substring(1) : undefined
      } else {
        id = videoId
      }
      if (list) {
        type = 'playlist'
        id = list
        anchor = videoId
      }

      // Check if any known pathnames match
      if (urlObj.pathname) {
        const pathArray = urlObj.pathname.split('/')
        // Youtube search results:
        if (urlObj.params && urlObj.params.search_query) {
          type = 'results'
        }
        // Channel
        if (
          pathArray.includes('channel')
          || pathArray.includes('c')
          || pathArray.includes('user')
          || pathArray.includes('videos')
        ) {
          type = 'channel'
          // Get channel id
          if (
            pathArray.includes('channel')
            || pathArray.includes('c')
            || pathArray.includes('user')
            && pathArray[2]
          ) {
            id = pathArray[2]
          }
        } else if (pathArray.includes('results')) {
          type = 'results'
        } else if (pathArray.includes('community')) {
          type = 'social'
        } else if (pathArray.includes('channels')) {
          type = 'relations'
        } else if (pathArray.includes('about')) {
          type = 'profile'
        } else if (pathArray.includes('shorts')) {
          type = 'short'
          id = pathArray[2]
        }

      }
      return {
        brand: 'youtube',
        type,
        id,
        ...(anchor && { anchor }),
        ...(index && { index })
      }
    case 'www.vimeo.com':
    case 'm.vimeo.com':
    case 'vimeo.com':
      id = (urlObj.pathname) ? urlObj.pathname.substring(1) : undefined
      return {
        brand: 'vimeo',
        type: 'video',
        id
      }
    case 'odysee.com':
    case 'www.odysee.com':
      // id = (urlObj.pathname) ? urlObj.pathname.substring(1) : undefined
      return {
        brand: 'odysee',
        type: 'video',
        // id
      }
    case 'rumble.com':
    case 'www.rumble.com':
      // id = (urlObj.pathname) ? urlObj.pathname.substring(1) : undefined
      return {
        brand: 'rumble',
        type: 'video',
        // id
      }
    case 'www.twitter.com':
    case 'twitter.com':
      type = 'embed'
      if (urlObj.pathname) {
        const pathArray = urlObj.pathname.split('/')
        if (
          pathArray.includes('status')
        ) {
          id = pathArray[pathArray.length - 1]
        } else if (pathArray.length >= 1) {
          type = 'profile'
          id = pathArray[pathArray.length - 1]
        }
      }
      return {
        brand: 'twitter',
        type,
        id
      }
    // TODO: check only domain, ignore possible subdomains
    case 'wikipedia.org':
    case 'en.wikipedia.org':
      return {
        brand: 'wikipedia',
        type: 'wiki'
      }
    case 'wiktionary.org':
    case 'en.wiktionary.org':
      return {
        brand: 'wiktionary',
        type: 'wiki'
      }
    case 'www.twitch.tv':
    case 'twitch.tv':
      return {
        brand: 'twitch',
        type: 'stream'
      }
    default:
      // Fallback
      switch (urlObj.tld) {
        case 'org/wikipedia':
        case 'org/wikipedia/en':
          return {
            brand: 'wikipedia',
            type: 'wiki'
          }
        case 'org/wiktionary':
        case 'org/wiktionary/en':
          return {
            brand: 'wiktionary',
            type: 'wiki'
          }
      }
      return {
        type: 'default'
      }
  }
}


export function extractFromUrl(parsedUrl: URL, url: string): URLObjectType {
  // console.log("🚀 ~ parsedUrl", parsedUrl)
  // TODO: Strip www from host
  const final = {
    url,
    isLocal: parsedUrl.host.split(':').length >= 1 ?
      false : parsedUrl.host.split('.').length > 1 ?
        false : true,
    href: parsedUrl.href,
    origin: parsedUrl.origin, // TODO: Get origin from mailto addresses
    pathname: parsedUrl.pathname,
    encodedPathname: specificEncoder(parsedUrl.pathname),
    host: parsedUrl.host,
    search: parsedUrl.search,
    params: parsedUrl.search ? breakDownParams(parsedUrl.search) : {},
    // savablePath: validateSavablePath(parsedUrl.href),
    tld: parsedUrl.host
      .split('.')
      .reverse()
      .join('/')
  }

  return {
    ...final,
    meta: getUrlMetadata(final)
  }
}


export function urlFunc(url: string): Partial<URLObjectType> {
  try {
    const parsedUrl: URL = new URL(url)
    if (url && parsedUrl) {
      return extractFromUrl(parsedUrl, url)
    }
  } catch (error) {
    try {
      const parsedUrl: URL = new URL(url)
      return extractFromUrl(parsedUrl, url)
    } catch {
      // When url is not URL, then assume is local path
      return {
        isLocal: true,
        url
      }
    }
  }
  return {
    url
  }
}

export default urlFunc


export function checkUrlAllowed(url: string): {
  allowed: boolean
  reason: string
} {
  try {
    const urlObj = new URL(url)
    const isOwn = [urlObj.host, urlObj.hostname].includes(brandVars.rootDomain)

    // TODO: Detect local netwrok IP ranges, and maybe also if it's an IP? But maybe saving IP might be useful?
    const isNotPublic = [urlObj.host, urlObj.hostname].includes('extensions')
    const isLocal = [urlObj.host, urlObj.hostname].includes('localhost:3000') // FIXME

    // TODO: Maybe flip around so we allow everything, and specify which we don't? For now allow only known protocols?
    const isHttpOrAccepted = ['http:', 'https:', 'ftp:'].includes(urlObj.protocol)
    return {
      allowed: !(isOwn || isNotPublic) && isHttpOrAccepted,
      reason:
        !isHttpOrAccepted ? 'it is not a valid URL'
          : isOwn ? 'it is within own domain'
            : isNotPublic || isLocal ? 'it is not a public url' : 'blacklisted'
    }
  } catch (e) {
    console.error('URL Error', e)
    return {
      allowed: false,
      reason: 'invalid URL'
    }
  }
}


export function processUrlForMarkdownLink(url: string): string | undefined {
  try {
    const urlItem = urlFunc(url)
    // console.log('processUrl', urlItem)
    // TODO: Prepend local links with `/`, or special character to instantly recognize?
    // BUT: Users can enter anything here, so process later on the link components?
    return urlItem.isLocal ? url : urlItem.url
  } catch {
    return '/'
  }
}


export function cleanLinkStringForMarkdown(selectionString: string | null): string {
  if (selectionString === null) {
    return ''
  }
  const cleanString = selectionString.trim() // Often spaces are included in selection, this is fine for the text, but not the link
  const urlObj = urlFunc(cleanString)
  let finalString = cleanString
  if (urlObj.isLocal) {
    // Never modify urls, only namespace are forced lowercase
    finalString = finalString.toLowerCase()
    // TODO: use validNamespaceFilter?
    finalString = sentenceToNamespace(finalString)
  }
  return finalString
}


export function getPostIdFromPath(pathname: string) {
  const splitPath = pathname.split('/')
  let postId = ''
  if (splitPath && splitPath[1] === 'id' || splitPath[1] === 'edit') {
    if (splitPath[1] === 'id') {
      postId = splitPath[2]
    }
    if (splitPath[1] === 'edit' && splitPath[2] === 'id') {
      postId = splitPath[3]
    }
  }
  // DODO: Other paths
  // else if (splitPath[2] === 'id') {

  // }
  return postId
}

export function getNamespaceFromPath(pathname: string) {
  const splitPath = pathname.split('/')
  let namespace = ''
  if (splitPath && splitPath[1] === 'view' || splitPath[1] === 'edit') {
    if (splitPath[2] !== 'id') {
      namespace = splitPath[2]
    }
  } else {
    // Nothing
  }
  return namespace
}

export function stripCrap(str: string) {
  return str.replace(/[#$?@:]+/g, '').replace(/[\-]+/g, ' ').trim()
}

// Should make every title unique
export function getTitleFromUrl(url: string) {
  // TODO: Integrate with browser extension to get metadata
  // TODO: Integrate with backend service to fetch metadata
  const urlObj = url ? urlFunc(url) : null
  if (urlObj && !urlObj.isLocal) {
    const { tld, pathname, meta } = urlObj
    const cleanHost = tld?.split('/')[1]
    const suffix = meta?.type !== 'default' ? meta?.brand : cleanHost
    const pathWords = pathname !== '/' ? pathname?.split('/').join(' ') : 'Link'
    return capitalizeFirstLetter(`${stripCrap(pathWords || '')} - ${suffix || ''}`)
  } else {
    return null
  }
}


// export function fetchXML(url) {
//   const response = fetchUrl(url)
//   console.log('resp', response)
//   return response.then((res) => {
//     const parsed = parseXML(res.text())
//     console.log('res', res)
//     console.log('parsed', parsed)
//     return parsed
//   })
// }

// export function parseXML(xml): Document {
//   console.log('xml', xml)
//   if (xml) {
//     if (window.DOMParser) {
//       const parser = new DOMParser();
//       const xmlDoc = parser.parseFromString(xml, "text/xml");
//       return xmlDoc
//     }
//     else {
//       const xmlDoc = new ActiveXObject("Microsoft.XMLDOM");
//       xmlDoc.async = false;
//       xmlDoc.loadXML(xml);
//       return xmlDoc
//     }
//   } else {
//     throw Error("Invalid xml")
//   }
// }