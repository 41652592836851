import { connect } from 'react-redux'

import Box from '@mui/material/Box'
import PublishForm from '@Components/containers/forms/PublishForm'
import { PostData } from '@Constants/post'
import { publishPostAction } from '@State/actions/postActions'
import { AppDispatch, RootState } from '@State/store'

interface StateProps {
  loading: boolean
}
interface DispatchProps {
  publishPost: ({ uid, id, data, namespace, handleClose }) => void
}
interface OwnProps {
  uid: string
  id: string
  item: Partial<PostData>
  published: boolean
  handleClose: (
    cancel: boolean,
    publish?: boolean,
    newNamespace?: string
  ) => void
}

type Props = StateProps & DispatchProps & OwnProps

function PublishItem({
  uid,
  id,
  published,
  item,
  publishPost,
  handleClose,
  loading,
}: Props) {
  function publishAction(data: Partial<PostData>, namespace: string) {
    const newData = {
      ...data,
      type: item.type,
    }
    publishPost({ uid, id, data: newData, namespace, handleClose })
  }

  return (
    <Box sx={{ minWidth: 420, p: 2 }}>
      <PublishForm
        handleSubmitPublishForm={({ data, namespace }) =>
          publishAction(data, namespace)
        }
        published={published}
        item={item}
        handleClose={handleClose}
        loading={loading}
        initialValues={{
          namespace: item.namespace ? item.namespace : item.title,
        }}
      />
    </Box>
  )
}

const mapStateToProps = (state: RootState /*, ownProps*/) => {
  return {
    // auth: state.firebase?.auth,
    loading: state.posts?.loading,
  }
}

const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    /* eslint @typescript-eslint/no-unsafe-argument: 0 */
    publishPost: (args) => dispatch(publishPostAction(args)),
  }
}

export default connect<StateProps, DispatchProps, OwnProps, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(PublishItem)
