import Favicon from '@Components/ui/Favicon'
import TypeIcon from '@Components/ui/TypeIcon'
import MarkdownViewer from '@Components/shared/markdown/MarkdownViewer'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import Grid from '@mui/material/Grid'
import makeStyles from '@mui/styles/makeStyles'
import Typography from '@mui/material/Typography'
import { getCoverImage } from '@Utils/metaFunc'
import React from 'react'
import CatIcon from '@Components/ui/CatIcon'
import { ExistingPostData } from '@Constants/post'
import { Box, Divider } from '@mui/material'

const useStyles = makeStyles((theme) => ({
  body: {
    // flex: '1 0 auto',
    display: 'flex',
    flexDirection: 'column',
    width: 'calc(100% - 4px)',
    height: 'calc(100% - 16px)',
    overflow: 'hidden',
    paddingBottom: '8px',
  },
  typeIconContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: 16,
  },

  favicon: {},
  image: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      // width: '23vw',
    },
  },
  img: {
    display: 'flex',
    // maxHeight: '100px',
    // maxWidth: '15vw',
    // width: '100%',
    height: '100%',
    width: '23vw',
    objectFit: 'contain',
    [theme.breakpoints.down('sm')]: {
      objectFit: 'cover',
      // width: '23vw',
      // height: '100%',
    },
  },
  favicons: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    // justifyContent: 'flex-end'
  },
  cardActions: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    // justifyContent: 'flex-end'
  },
}))

export default function SearchPostsItem({
  item,
  typeQuery,
}: {
  item: ExistingPostData
  typeQuery?: string | ''
}) {
  const classes = useStyles()
  const imageUrl = getCoverImage(item)
  const faviconSrc = `https://www.google.com/s2/favicons?sz=${256}&domain=${
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    item?.url
  }`
  return (
    <Box sx={{ width: '100%' }}>
      <Grid container spacing={0}>
        <Grid item xs={1} className={classes.typeIconContainer}>
          {typeQuery ? (
            <CatIcon cat={item.cat} mini />
          ) : (
            <TypeIcon type={item.type} mini />
          )}
        </Grid>

        <Grid
          item
          xs={imageUrl ? 6 : 9}
          sx={{ maxHeight: 220, overflow: 'hidden' }}
        >
          <CardContent className={classes.body}>
            <Typography component="h6" variant="h6">
              {item.title}
            </Typography>
            {/* <Typography variant="subtitle1" color="textSecondary"> */}
            <MarkdownViewer
              value={item.text && item.text.substring(0, 220)}
              mini
              isSubPost
            />
            {/* </Typography> */}
          </CardContent>
        </Grid>

        {imageUrl && (
          <Grid item xs={3} className={classes.favicons}>
            <CardActions
              // disableSpacing
              className={classes.cardActions}
            >
              {item?.url ? (
                <Favicon
                  url={item.url}
                  size="small"
                  fetchSize={256}
                  className={classes.favicon}
                />
              ) : item?.imgUrl ? (
                <Favicon
                  size="small"
                  fetchSize={256}
                  url={item.imgUrl}
                  className={classes.favicon}
                />
              ) : null}
            </CardActions>
          </Grid>
        )}

        <Grid item xs={2} className={classes.image}>
          {imageUrl ? (
            <img className={classes.img} src={imageUrl} alt={imageUrl} />
          ) : (
            <img
              className={classes.img}
              src={faviconSrc}
              alt={item.title}
              style={{ objectFit: 'contain' }}
            />
          )}
        </Grid>
      </Grid>

      {/* <div className={classes.details}> */}
      <Divider />

      {/* </div> */}
    </Box>
  )
}
