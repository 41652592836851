import { HotkeyPlugin, createPluginFactory, onKeyDownToggleElement } from '@udecode/plate-core'
import { ELEMENT_TITLE } from '@Components/shared/object-editor/ObjectEditor.types'


/**
 * Title node type for mandatory normalized field
 */
export const createTitlePlugin = createPluginFactory<HotkeyPlugin>({
  key: ELEMENT_TITLE,
  isElement: true,
  handlers: {
    onKeyDown: onKeyDownToggleElement,
  },
  deserializeHtml: {
    rules: [
      {
        validNodeName: 'title',
      },
    ],
    // query: (el) => el.style.fontFamily !== 'Consolas',
  },
});
