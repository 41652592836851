import { RouteContext } from '@Components/containers/RouteProvider'
import { subTypeData, typeData, Types } from '@Constants/config/types'
import { TypeData } from '@Constants/types/TypeData'
import Icon from '@mui/material/Icon'
import { styled } from '@mui/material/styles'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import Tooltip from '@mui/material/Tooltip'
import {
  changeSubTypeAction,
  changeTypeAction,
} from '@State/actions/storageActions'
import { AppDispatch, RootState } from '@State/store'
import { capitalizeFirstLetter } from '@Utils/textFunc'
import map from 'lodash/map'
import React, { useContext, useMemo } from 'react'
import { connect } from 'react-redux'

const PREFIX = 'TypeSelectorPicker'

const classes = {
  root: `${PREFIX}-root`,
  typesContainer: `${PREFIX}-typesContainer`,
}

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {
    flexGrow: 1,
  },

  [`& .${classes.typesContainer}`]: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}))

function TypeSelectorPicker({
  typeSelected,
  subTypeSelected,
  onChange,

  changeType,
  changeSubType,

  flat,
  size,
  isSubType,
}: {
  typeSelected: Types | null
  subTypeSelected: Types | null
  onChange?: (newType: Types, event: React.MouseEvent) => void
  changeType: (newType: Types | null) => void
  changeSubType: (newType: Types | null) => void
  size?: number // in em
  flat?: boolean
  isSubType?: boolean
}) {
  const allTypes: TypeData[] = isSubType ? subTypeData : typeData

  const { typeQuery } = useContext(RouteContext)

  const typeDataA = [...allTypes]
  const typeDataB = [...allTypes]
  const typeDataC = [...allTypes]

  if (!flat) {
    typeDataA.splice(3, 8)

    typeDataB.splice(0, 3)
    typeDataB.splice(3, 6)

    typeDataC.splice(0, 6)
  }

  function typeChange(
    e: React.MouseEvent<HTMLElement, MouseEvent>,
    type: string
  ) {
    e.stopPropagation()
    const newType: string | null =
      (type === typeQuery || type === subTypeSelected) &&
      (typeQuery !== null || subTypeSelected !== null)
        ? null
        : type

    if (isSubType) {
      changeSubType(newType)
    } else {
      changeType(newType)
    }

    if (onChange) {
      onChange(type, e)
    }
  }

  const typeRow = (types: TypeData[]) => (
    <ToggleButtonGroup
      value={isSubType ? subTypeSelected : typeQuery}
      exclusive
      onChange={(e, v: string) => typeChange(e, v)}
      aria-label="type grid"
    >
      {map(types, (type, i) => {
        // console.log(type)
        // if (type.val === typeSelected) console.log(type.val)
        return (
          <Tooltip
            key={i}
            title={capitalizeFirstLetter(type.val)}
            placement="top"
            followCursor
          >
            <ToggleButton
              value={type.val}
              aria-label={type.val}
              // disabled={type.disabled}
            >
              <Icon
                fontSize="large"
                style={{
                  fontSize: size ? `${size}em` : undefined,
                }}
                color={
                  type.val === typeSelected ||
                  (isSubType && type.val === subTypeSelected)
                    ? 'secondary' // selected from state
                    : type.val === typeQuery
                    ? 'inherit' // if only Url query value
                    : 'inherit' // not selected
                }
              >
                {type.icon}
              </Icon>
            </ToggleButton>
          </Tooltip>
        )
      })}
    </ToggleButtonGroup>
  )

  return (
    <Root className={classes.root}>
      <div className={classes.typesContainer}>
        {typeRow(typeDataA)}
        {!flat ? typeRow(typeDataB) : null}
        {!flat ? typeRow(typeDataC) : null}
      </div>
    </Root>
  )
}

const mapStateToProps = (state: RootState /*, ownProps*/) => {
  return {
    // auth: state.firebase?.auth,
    theme: state.storage?.theme,
    typeSelected: state.storage?.typeSelected,
    subTypeSelected: state.storage?.subTypeSelected,
  }
}

const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    changeType: (type: Types) => dispatch(changeTypeAction(type)),
    changeSubType: (type: Types) => dispatch(changeSubTypeAction(type)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
  // @ts-expect-error
)(TypeSelectorPicker)
