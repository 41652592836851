import create from 'zustand'
import { devtools, persist } from 'zustand/middleware'

interface ObjectEditorState {
  saved: boolean
  setSaved: (saved: boolean) => void
}

export const useObjectEditorStore = create<ObjectEditorState>()(
  devtools(
    (set) => ({
      saved: true,
      setSaved: (saved) => set((state) => ({ saved })),
    }),
    {
      name: 'object-editor-storage',
    }

  )
)