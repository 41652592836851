import { FormApi } from 'final-form'
import React, { ChangeEvent, ReactNode } from 'react'

import {
  AutocompleteChangeReason,
  AutocompleteInputChangeReason,
} from '@mui/material/useAutocomplete'
import Autocomplete, {
  AutocompleteRenderInputParams,
} from '@mui/material/Autocomplete'
import Popper from '@mui/material/Popper'
import TextField from '@mui/material/TextField'
import { ExistingPostData, PartialPostData, PostData } from '@Constants/post'
import { Namespace } from '@Constants/types/namespace'

const SearchPopper = (props) => {
  return (
    <Popper
      {...props}
      placement="bottom-end"
      disablePortal={false}
      modifiers={[
        {
          name: 'flip',
          enabled: true,
          options: {
            altBoundary: true,
            rootBoundary: 'document',
            padding: 8,
          },
        },
        {
          name: 'preventOverflow',
          enabled: true,
          options: {
            altAxis: true,
            altBoundary: true,
            tether: true,
            rootBoundary: 'viewport',
            padding: 8,
          },
        },
      ]}
    />
  )
}

interface OwnProps<T> {
  label: string
  open: boolean
  setOpen: (open: boolean) => void
  results: Namespace[] | ExistingPostData[] | PostData[]
  required?: boolean
  resultProp?: string
  defaultValue?: PartialPostData | string | null
  noOptionsText?: string
  fullWidth?: boolean
  autoHighlight?: boolean
  autoSelect?: boolean
  clearOnBlur?: boolean
  goSearch?: (form: FormApi) => void
  onInputChange?: (
    e: ChangeEvent<EventTarget>,
    searchQuery: string,
    reason: AutocompleteInputChangeReason
  ) => void
  onChange?: (
    e: ChangeEvent<EventTarget>,
    value: Namespace | ExistingPostData | PostData | null,
    reason: AutocompleteChangeReason,
    details: string
  ) => void
  // formSubmit?: (data, form: FormApi) => void
  renderInput?: (params: AutocompleteRenderInputParams) => ReactNode
  renderOption?: (props, option, state) => ReactNode
  className?: string
  inputprops?: unknown
  getOptionLabel?: (option: any) => string
}

type Props<T> = OwnProps<T>

export function AutocompleteField<T>({
  label,
  open,
  setOpen,
  results,
  required,
  resultProp,
  defaultValue,
  noOptionsText = 'No matches',
  fullWidth = true,
  autoSelect = false,
  autoHighlight = false,
  clearOnBlur = true,
  goSearch,
  onInputChange,
  onChange,
  // formSubmit,
  renderInput,
  renderOption,
  className,
  inputprops,
  getOptionLabel,
}: Props<T>) {
  const loading = Boolean(open && results.length === 0)

  const [value, setValue] = React.useState<ExistingPostData | null>(null)

  // function submit(data, form) {
  //   if (formSubmit) formSubmit(data, form)
  // }

  const getLabel = (postItem) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return resultProp ? postItem[resultProp] : postItem.title
  }

  const getOption = (selectedItem, item): boolean => {
    if (resultProp) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      return selectedItem[resultProp] === item[resultProp]
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      return selectedItem.title === item.title
    }
  }

  const defaultRenderOption = (props, postItem, state) => (
    <li {...props}>{getLabel(postItem)}</li>
  )

  return (
    <Autocomplete
      // name={label}
      // label={label}
      // value={value}

      className={className}
      sx={{ color: 'inherit', width: '100%' }}
      // style={{ paddingBottom: 16 }}
      // @ts-ignore
      options={results}
      noOptionsText={noOptionsText}
      defaultValue={defaultValue}
      fullWidth={fullWidth}
      autoSelect={autoSelect}
      autoHighlight={autoHighlight}
      clearOnBlur={clearOnBlur}
      blurOnSelect
      clearOnEscape
      // disableCloseOnSelect
      // PopperComponent={SearchPopper}
      disablePortal
      getOptionLabel={getOptionLabel || getLabel}
      // getOptionValue={getLabel}

      isOptionEqualToValue={getOption}
      loading={loading}
      // onKeyDown={(e) => {
      //   if (e.key === 'Enter' && goSearch) {
      //     goSearch(form)
      //   }
      // }}

      renderOption={renderOption ? renderOption : defaultRenderOption}
      // Fixes undefined "required" prop
      renderInput={
        renderInput
          ? renderInput
          : (params) => (
              <TextField
                {...params}
                required={required}
                label={label}
                variant="filled"
              />
            )
      }
      ListboxProps={{
        style: {
          maxHeight: '90vh',
          maxWidth: '90vw',
        },
      }}
      onOpen={(e) => {
        setOpen(true)
      }}
      onClose={(e) => {
        setOpen(false)
      }}
      open={open}
      onInputChange={(e, s, r) => {
        if (onInputChange) onInputChange(e, s, r)
      }}
      onChange={(e, v, r, d) => {
        // @ts-ignore
        if (onChange) onChange(e, v, r, d)
      }}
    />
  )
}
