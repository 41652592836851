import { useHistory } from 'react-router-dom'

export const EDIT_USERNAME = 'EDIT_USERNAME'
export const TOGGLE_AVATAR = 'TOGGLE_AVATAR'
export const SEND_APPLICATION = 'SEND_APPLICATION'
export const GRANT_ROLE = 'GRANT_ROLE'
export const UNGRANT_ROLE = 'UNGRANT_ROLE'

export interface ApplicationFormData {
  uid: string
  name: string
  email: string
  motivation: string
  expertise: string
  type: string
  granted?: boolean
  url?: string
  isMine?: string
}

export interface EditUserNameAction {
  type: typeof EDIT_USERNAME
  uid: string
  username: string
  description?: string
  namespace?: string
}
export interface ToggleAvatarAction {
  type: typeof TOGGLE_AVATAR
  uid: string
  avatar: string
}
export interface SendApplicationAction {
  type: typeof SEND_APPLICATION
  uid: string
  data: ApplicationFormData
}
export interface GrantRoleAction {
  type: typeof GRANT_ROLE
  uid: string
  role: string
}
export interface UngrantRoleAction {
  type: typeof UNGRANT_ROLE
  uid: string
  role: string
}



export function editUsernameAction({ uid, username, description, namespace }: Omit<EditUserNameAction, 'type'>): EditUserNameAction {
  return {
    type: EDIT_USERNAME,
    uid,
    username,
    description,
    namespace
  }
}

export function toggleAvatarAction({ uid, avatar }: Omit<ToggleAvatarAction, 'type'>): ToggleAvatarAction {
  return {
    type: TOGGLE_AVATAR,
    uid,
    avatar
  }
}

export function sendApplicationAction({ uid, data }: Omit<SendApplicationAction, 'type'>): SendApplicationAction {
  return {
    type: SEND_APPLICATION,
    uid,
    data
  }
}

export function grantRoleAction({ uid, role }: Omit<GrantRoleAction, 'type'>): Omit<GrantRoleAction, ''> {
  return {
    type: GRANT_ROLE,
    uid,
    role
  }
}

export function ungrantRoleAction({ uid, role }: Omit<UngrantRoleAction, 'type'>): UngrantRoleAction {
  return {
    type: UNGRANT_ROLE,
    uid,
    role
  }
}
