import { Feature } from 'ol'
import { Circle } from 'ol/geom'
import { fromLonLat } from 'ol/proj'
import { Fill, Stroke, Style } from 'ol/style'
import CircleStyle from 'ol/style/Circle'

import { LongLatArray } from '@Constants/types/location'
import { Coordinate } from 'ol/coordinate'


export const Poi = () => {
  const fill = new Fill({ color: 'rgba(0,0,200,0.4' })
  const stroke = new Stroke({ color: 'rgba(0,0,200,0.6' })
  const style = new Style({
    fill,
    stroke,
    image: new CircleStyle({
      radius: 8,
      fill,
      stroke,
    }),
  })
  return style
}
export const PoiGradient = (longlat: Coordinate, radius = 6, invert = false) => {
  const circleFeature = new Feature({
    geometry: new Circle(fromLonLat(longlat), radius * 1000),
  })
  circleFeature.setStyle(
    new Style({
      renderer(coordinates, state) {
        // @ts-ignore
        const [[x, y], [x1, y1]] = coordinates as [[number, number], [number, number]]
        const ctx = state.context
        const dx = x1 - x
        const dy = y1 - y
        // const radius = Math.sqrt(dx * dx + dy * dy)

        const innerRadius = 0
        const outerRadius = radius * 1.4

        const gradient = ctx.createRadialGradient(
          x,
          y,
          outerRadius,
          x,
          y,
          innerRadius
        )
        const addColors = () => {
          gradient.addColorStop(0, 'rgba(200,0,0,0)')
          gradient.addColorStop(0.4, 'rgba(200,0,0,0.4)')
          gradient.addColorStop(0.8, 'rgba(200,0,0,0.8)')
          ctx.fillStyle = gradient
          ctx.fill()
        }
        // To invert circle, add Colors first, then the rest
        if (invert) {
          addColors()
        }

        ctx.beginPath()
        ctx.arc(x, y, radius, 0, 2 * Math.PI, true)

        if (!invert) {
          addColors()
        }

        ctx.arc(x, y, radius, 0, 2 * Math.PI, true)
        // ctx.strokeStyle = 'rgba(200,0,0,0.1)'
        // ctx.stroke()
      },
    })
  )
  return circleFeature
}
