import { appVars } from '@Constants/config/app'
import { PostFormFields } from '@Constants/types/PostFormFields'

import { BasicTypeData, SubpostTypeData, TypeData } from '../types/TypeData'


type ThingsType = typeof appVars.things; // Things represents blank type state (not the same as '', where type is not set)

export type Types = PostTypes | SubpostTypes | BasicTypes | '' | ThingsType;

export type PostTypes =
  'term' | 'text' | 'list' |
  'graph' | 'object' | 'media' |
  'person' | 'entity' | 'location'

export type SubpostTypes =
  'comment' | 'dialog' | 'review' |
  'article' | 'feed' | 'connection' |
  'question' | 'event' | 'collection'

export type BasicTypes =
  'value' | 'vector' | 'array' |
  'layer' | 'context' | 'view' |
  'answer' | 'date' | 'vertex' |
  'link' | 'source' | 'suggestion' | 'edit'

export type Fields = keyof PostFormFields

export type FieldTypes = 'bio' | 'connection' | 'value'

export const viewType: TypeData = {
  val: 'view',
  adjective: 'viewable',
  description: 'Views can be anything from viewpoints to portals, to any composition of content',
  icon: 'panorama_wide_angle',
  fields: {
    title: {
      alias: 'View name'
    },
    text: {
      alias: 'View description'
    },
  },
}

export const typeData: TypeData[] = [
  {
    val: 'term',
    adjective: 'termable',
    description: 'A short word, term, phrase, saying, prose or sentence',
    icon: 'short_text',
    fields: {
      title: {
        alias: 'Term'
      },
      text: {
        alias: 'Description',
        // maxLength: 420
      },
      cat: {
        defaultValue: 'philosophy'
      },
    },
    variants: {
      word: {
        fields: ['title']
      },
      quote: {
        fields: ['title']
      },
      definition: {
        fields: ['title', 'text']
      },
      thing: {
        fields: ['title', 'text']
      },
    }
  },
  {
    val: 'list',
    adjective: 'listable',
    description: 'Lists of anything like words, stories, events, people, places or objects, or whatever',
    icon: 'list',
    // icon: 'toc',
    fields: {
      title: {
        alias: 'List name'
      },
      text: {
        alias: 'List description'
      },
    },
    variants: {
      todo: {
        fields: ['title', 'text', 'series'],
      },
      feed: {
        fields: ['title', 'text', 'uri']
      },
      events: {
        fields: ['title', 'text', 'datum']
      },
    },
    shortcuts: ['value'] // To jump to next item automatically after 'enter'ing value
  },
  {
    val: 'text',
    adjective: 'textual',
    description: 'Stories, thought-experiments separate paragraphs, things',
    icon: 'notes',
    fields: {
      title: {
        alias: 'Title'
      },
      text: {
        alias: 'Text'
      },
    },
    variants: {
      story: {
        fields: ['title', 'text', 'series'],
      },
      paragraph: {
        fields: ['series', 'text']
      },
      page: {
        fields: ['title', 'text']
      },
    },
  },



  {
    val: 'object',
    adjective: 'objective',
    description: 'Anything that can be described in objective terms',
    icon: 'emoji_objects',
    fields: {
      title: {
        alias: 'Object name'
      },
      text: {
        alias: 'Object description'
      },
    },
    variants: {
      anything: { // Any varint possible, is equivalent to series / tag
        fields: [], // All fields are available
      },
    },
  },

  {
    val: 'media',
    plural: 'media',
    adjective: 'playable',
    description: 'Media can be any playable thing from videos, to games, to other multimedia',
    icon: 'category',
    fields: {
      title: {
        alias: 'Media title'
      },
      text: {
        alias: 'Media description'
      },
      cat: {
        // defaultValue: 'music'
      }
    },
    variants: {
      anything: { // Any varint possible, is equivalent to series / tag
        fields: [], // All fields are available
      },
    },
  },

  {
    val: 'entity',
    plural: 'entities',
    description: 'Any organised entity like a businesses, corporation, governments, clubs or groups',
    icon: 'corporate_fare',
    // icon: 'store',
    fields: {
      title: {
        alias: 'Entity name'
      },
      text: {
        alias: 'Entity function'
      },
      cat: {
        defaultValue: 'organisation'
      },
    },
    variants: {
      anything: { // Any varint possible, is equivalent to series / tag
        fields: [], // All fields are available
      },
    },
  },


  {
    val: 'person',
    plural: 'people',
    adjective: 'personal',
    description: 'People, fictional or real, alive or dead, born or unborn',
    icon: 'self_improvement',
    // icon: 'emoji_people',
    // icon: 'local_library',
    fields: {
      title: {
        alias: 'Name'
      },
      text: {
        alias: 'Biography'
      },
      cat: {
        defaultValue: 'people'
      },
      url: {
        useAdjective: true
      }
    },
    variants: {
      bio: {
        fields: ['title', 'text', 'uri'],
      },
      autobio: {
        fields: ['title', 'text', 'uri', 'uid'], // hidden uid field to verify it's user
      },
    },
  },

  {
    val: 'graph',
    adjective: 'graphable',
    description: 'Graphs, timelines, charts, of present, future, or past events',
    icon: 'timeline',
    fields: {
      title: {
        alias: 'Graph name'
      },
      text: {
        alias: 'Graph description'
      },
    },
    variants: {
      timeline: {
        fields: ['title', 'date', 'value'],
      },
      chart: {
        fields: ['title', 'text', 'datum']
      },
      datum: {
        fields: ['title', 'datum']
      },
      xyz: {
        fields: ['title', 'xyz']
      },
    }
    // icon: 'show_chart',
    // icon: 'schema',
  },


  {
    val: 'location',
    adjective: 'locatable',
    description: 'Places like bars, restaurants, meeting spots, clubs, squares, businesses and emergency services',
    icon: 'navigation',
    // icon: 'explore',
    fields: {
      title: {
        alias: 'Location name'
      },
      text: {
        alias: 'Location description'
      },
      cat: {
        defaultValue: 'place'
      },
    },
    variants: {
      country: {
        fields: ['title', 'text', 'geo'],
      },
      coordinates: {
        fields: ['title', 'text', 'xyz']
      },
      xyz: {
        fields: ['title', 'xyz']
      },
    }
  },

  // {
  //   val: 'media',
  //   description: 'Medias of things',
  //   icon: 'panorama_wide_angle'
  // },
  // {
  //   val: 'knowledge',
  //   description: 'Knowledges of things',
  //   icon: 'local_library',
  //   disabled: true
  // },
  // {
  //   val: 'Wisdom',
  //   description: 'Wisdoms of things',
  //   icon: 'self_improvement',
  //   disabled: true
  // },
]

export const subTypeData: SubpostTypeData[] = [
  {
    val: 'comment',
    description: 'Comments of things',
    icon: 'mode_comment',
    fields: {
      title: {
        alias: 'Comment title'
      },
      text: {
        alias: 'Comment'
      },
    },
  },
  {
    val: 'dialog',
    description: 'Dialogs of things',
    icon: 'forum',
    fields: {
      title: {
        alias: 'Dialog subject'
      },
      text: {
        alias: 'Subject'
      },
    },
    // disabled: true
  },
  {
    val: 'review',
    description: 'Review of things',
    icon: 'rate_review',
    fields: {
      title: {
        alias: 'Review title'
      },
      text: {
        alias: 'Review'
      },
    },
  },

  {
    val: 'article',
    description: 'An article about anything',
    icon: 'subject',
    fields: {
      title: {
        alias: 'Article title'
      },
      text: {
        // alias: 'Text',
        // fieldType: 'value'
      },
    },
  },
  {
    val: 'feed',
    description: 'Feeds of things',
    // icon: 'subject'
    icon: 'dynamic_feed',
    fields: {
      title: {
        alias: 'Feed name'
      },
      text: {
        alias: 'Feed description'
      },
    },
  },
  {
    val: 'connection',
    description: 'Connections of things',
    icon: 'cable',
    fields: {
      title: {
        alias: 'Connection',
        fieldType: 'connection'
      },
      text: {
        alias: 'Connection context'
      },
      subTypes: ['reference']
    },
    // icon: 'memory',
  },

  // {
  //   val: 'symbol',
  //   description: 'Symbols of things',
  //   icon: 'help_outline',
  //   // icon: 'bar_chart',
  //   disabled: true
  // },

  {
    val: 'question',
    description: 'Ask a questions about anything',
    icon: 'quiz',
    fields: {
      title: {
        alias: 'Question'
      },
      text: {
        alias: 'Context'
      },
    },
  },

  {
    val: 'event',
    singular: 'an',
    description: 'Can be future, present, or historical events',
    icon: 'event',
    // disabled: true,
    fields: {
      title: {
        alias: 'Event name'
      },
      text: {
        alias: 'Description'
      },
    },
  },

  {
    val: 'collection',
    description: 'Collections can contain all kinds of things',
    icon: 'widgets',
    fields: {
      title: {
        alias: 'Collection name'
      },
      text: {
        alias: 'Description'
      },
    },
  },

]

// Non-user selectable system types
export const basicTypeData: BasicTypeData[] = [
  {
    val: 'value',
    description: 'A value',
    icon: 'text_snippet',
    fields: {
      title: {
        alias: 'Value Name'
      },
      text: {
        alias: 'Value',
        fieldType: 'value'
      },
    },
  },
  {
    val: 'vector',
    description: 'A vector, a direction.',
    icon: 'text_snippet',
    fields: {
      title: {
        alias: 'Value Name'
      },
      text: {
        alias: 'Value',
        fieldType: 'value'
      },
    },
  },
  {
    val: 'array',
    description: 'An array of items',
    icon: 'data_array',
    fields: {
      title: {
        alias: 'Array Name'
      },
      text: {
        alias: 'Value',
        fieldType: 'value'
      },
    },
  },
  {
    val: 'layer',
    description: 'A layer',
    icon: 'data_array',
    fields: {
      title: {
        alias: 'Array Name'
      },
      text: {
        alias: 'Value',
      },
    },
  },
  {
    val: 'context',
    description: 'A context',
    icon: 'data_array',
    fields: {
      title: {
        alias: 'Array Name'
      },
      text: {
        alias: 'Value',
      },
      ref: {
        alias: 'Namespace with context'
      },
      refId: {
        alias: 'Post Id with context'
      }
    },
  },
  {
    val: 'view',
    description: 'A view',
    icon: 'data_array',
    fields: {
      title: {
        alias: 'Array Name'
      },
      text: {
        alias: 'Value',
      },
      layout: 'default'
    },
  },
  {
    val: 'answer',
    description: 'An answer to something',
    icon: 'data_array',
    fields: {
      title: {
        alias: 'Short Answer'
      },
      text: {
        alias: 'Long Answer',
        fieldType: 'text'
      },
      ref: {
        alias: 'Namespace with answer'
      },
      refId: {
        alias: 'Post Id with answer'
      }
    },
  },
  {
    val: 'date',
    description: 'A date',
    icon: 'data_array',
    fields: {
      title: {
        alias: 'Array Name'
      },
      text: {
        alias: 'Value',
        fieldType: 'value'
      },
    },
  },
  {
    val: 'vertex',
    description: 'A 3d vertex',
    icon: 'change_history',
    disabled: true
  },
  {
    val: 'link',
    description: 'A url',
    icon: 'link'
  },
  {
    val: 'source',
    description: 'A source',
    icon: 'source'
  },
  {
    val: 'suggestion',
    description: 'An suggestion for change',
    icon: 'diff'
  },
  {
    val: 'edit',
    description: 'An edit suggestion',
    icon: 'edit'
  },
]