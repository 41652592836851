import React from 'react'
import { useReadOnly } from 'slate-react'
import { CSSProp } from 'styled-components'

import FormControl from '@mui/material/FormControl'
import MenuItem from '@mui/material/MenuItem'
import OutlinedInput from '@mui/material/OutlinedInput'
import Select from '@mui/material/Select'
import {
  CODE_BLOCK_LANGUAGES,
  CODE_BLOCK_LANGUAGES_POPULAR,
} from '@udecode/plate-code-block'
import { getPluginOptions, useEditorRef } from '@udecode/plate-core'
import { CodeBlockPlugin } from '@Components/shared/object-editor/plugins/codeblock/codeBlockPlugin'
import { ELEMENT_CODE_BLOCK } from '@Components/shared/object-editor/ObjectEditor.types'

export const CodeBlockSelectElement = ({
  lang,
  onChange,
  ...props
}: {
  lang?: string
  onChange: (codeType: string) => void
  className?: string
  css?: CSSProp
}) => {
  // console.log(`🚀 ~ props`, props)
  const [value, setValue] = React.useState(lang)
  const editor = useEditorRef()

  if (useReadOnly()) return null

  const { syntaxPopularFirst } = getPluginOptions<CodeBlockPlugin>(
    editor,
    ELEMENT_CODE_BLOCK
  )

  const noValueText = `Plain text`

  return (
    <FormControl
      sx={{
        minWidth: 120,
        float: 'right',
      }}
      size="small"
    >
      <Select
        label="Type"
        displayEmpty
        input={<OutlinedInput />}
        renderValue={(selected) => {
          // console.log(`🚀 ~ selected`, selected)
          if (selected?.length === 0 || !selected) {
            // If initial value is undefined
            return <em>{noValueText}</em>
          }
          return selected
        }}
        value={value || ''}
        onClick={(e) => {
          e.stopPropagation()
        }}
        onChange={(e) => {
          onChange(e.target.value)
          setValue(e.target.value)
        }}
        contentEditable={false}
        {...props}
      >
        <MenuItem value="">
          <em>{noValueText}</em>
        </MenuItem>
        {syntaxPopularFirst &&
          Object.entries(CODE_BLOCK_LANGUAGES_POPULAR).map(([key, val]) => (
            <MenuItem key={key} value={key}>
              {val}
            </MenuItem>
          ))}
        {Object.entries(CODE_BLOCK_LANGUAGES).map(([key, val]) => (
          <MenuItem key={key} value={key}>
            {val}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
