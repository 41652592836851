import { UriData } from "@State/reducers/uriReducer";
import { PartialPostData } from '@Constants/post'

export const LOAD_URI = 'LOAD_URI'
export const URI_LOADED = 'URI_LOADED'
export const URI_EXISTS = 'URI_EXISTS'
export const URI_NOT_EXISTS = 'URI_NOT_EXISTS'

export const SAVE_URI = 'SAVE_URI'
export const URI_SAVED = 'URI_SAVED'
export const DELETE_URI = 'DELETE_URI'


export interface LoadUriAction {
  type: typeof LOAD_URI
  uri: string;
}

interface UriLoadedAction {
  type: typeof URI_LOADED
  exists: boolean;
  result: UriData
}

interface UriExistsAction {
  type: typeof URI_EXISTS
  uri: string;
  data: UriData;
}

interface UriNotExistsAction {
  type: typeof URI_NOT_EXISTS
  uri: string;
}

export interface SaveUriAction {
  type: typeof SAVE_URI
  uri: string;
  postId: string;
  userId: string;
  namespace: string;
}

interface UriSavedAction {
  type: typeof URI_SAVED
  result: UriData
}

export interface DeleteUriAction {
  type: typeof DELETE_URI,
  uri: string;
  postId: string;
  uriDocRef: string;
}


// App state
export function loadUriAction(uri: string): LoadUriAction {
  return {
    type: LOAD_URI,
    uri
  };
}
export function uriExistsAction(uri: string, data: UriData): UriExistsAction {
  return {
    type: URI_EXISTS,
    uri,
    data
  };
}
export function uriNotExistsAction(uri: string): UriNotExistsAction {
  return {
    type: URI_NOT_EXISTS,
    uri
  };
}

export function uriLoadedAction(result: UriData, exists: boolean): UriLoadedAction {
  return {
    type: URI_LOADED,
    exists,
    result
  };
}

export function saveUriAction(uri: string, postId: string, userId: string, namespace: string): SaveUriAction {
  return {
    type: SAVE_URI,
    uri,
    postId,
    userId,
    namespace
  };
}

export function uriSavedAction(result: UriData): UriSavedAction {
  return {
    type: URI_SAVED,
    result
  };
}

export function deleteUriAction(uri: string, postId: string, uriDocRef: string): DeleteUriAction {
  return {
    type: DELETE_URI,
    uri,
    postId,
    uriDocRef
  };
}


export type UriActionTypes =
  LoadUriAction |
  UriExistsAction |
  UriNotExistsAction |
  UriLoadedAction |
  SaveUriAction |
  UriSavedAction |
  DeleteUriAction;