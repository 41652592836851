import {
  Resizable as ReResizable,
  ResizableProps as ReResizableProps,
} from 're-resizable'
import { useCallback } from 'react'
import { useReadOnly } from 'slate-react'

import {
  AsProps,
  createComponentAs,
  findNodePath,
  isDefined,
  select,
  setNodes,
  useEditorRef,
  useElement,
} from '@udecode/plate-core'
import { TMediaElement } from '@Components/shared/object-editor/plugins/MediaElements'

import { TResizableElement } from './TResizableElement'

export interface ResizableProps
  extends Omit<ReResizableProps, 'as'>,
    AsProps<'div'> {
  /**
   * Node alignment.
   */
  align?: 'left' | 'center' | 'right'

  readOnly?: boolean

  parentWidth: number
}

const calcRelativeWidth = (width: number, parentWidth: number) => {
  const percentage = (width / parentWidth) * 100
  // Avoid more than 100 and less than 10
  return Math.round(percentage > 100 ? 100 : percentage < 10 ? 10 : percentage)
}

export const useResizable = ({
  align = 'center',
  readOnly,
  parentWidth,
  ...props
}: ResizableProps): ReResizableProps => {
  const element = useElement<TMediaElement>()
  // console.warn(`🚀 ~ element`, element)
  const editor = useEditorRef()
  const _readOnly = useReadOnly()
  readOnly = isDefined(readOnly) ? readOnly : _readOnly
  const { width: nodeWidth = 100, relativeWidth } = element ?? {}

  const setNodeWidth = useCallback(
    (widthPixels: number) => {
      // console.warn(`🚀 ~ widthPixels`, widthPixels)
      const path = findNodePath(editor, element)
      if (!path) return

      if (widthPixels === nodeWidth) {
        // Focus the node if not resized
        select(editor, path)
      } else {
        const relWidth = calcRelativeWidth(widthPixels, parentWidth)
        setNodes<TResizableElement>(
          editor,
          {
            // width: widthPixels,
            relativeWidth: relWidth,
          },
          { at: path }
        )
      }
    },
    [editor, element, nodeWidth, parentWidth]
  )

  const defaultProps: ReResizableProps = {
    minWidth: 92,
    size: { width: `${relativeWidth || 100}%`, height: '100%' },
    maxWidth: '100%',
    lockAspectRatio: true,
    resizeRatio: align === 'center' ? 2 : 1,
    enable: {
      left: ['center', 'left'].includes(align),
      right: ['center', 'right'].includes(align),
    },
    handleStyles: {
      left: { left: 0 },
      right: { right: 0 },
    },
    // Not needed
    // onResize: (e, direction, ref) => {
    //   // setWidth(ref.offsetWidth)
    // },
    onResizeStop: (e, direction, ref) => setNodeWidth(ref.offsetWidth),
  }

  if (readOnly) {
    return {
      ...defaultProps,
      ...props,
      enable: {
        left: false,
        right: false,
        top: false,
        bottom: false,
        topLeft: false,
        bottomLeft: false,
        topRight: false,
        bottomRight: false,
      },
    }
  }

  return { ...defaultProps, ...props }
}

export const Resizable = createComponentAs<ResizableProps>((props) => {
  const resizableProps = useResizable(props)

  return <ReResizable {...resizableProps} />
})
