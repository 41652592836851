import { useContext, useState } from 'react'
import { Form } from 'react-final-form'
import { useReadOnly } from 'slate-react'

import Chip from '@mui/material/Chip'
import Icon from '@mui/material/Icon'
import IconButton from '@mui/material/IconButton'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import ToggleButton from '@mui/material/ToggleButton'
import Typography from '@mui/material/Typography'
import {
  PlateRenderElementProps,
  findNodePath,
  focusEditor,
  removeNodes,
  setNodes,
  unsetNodes,
} from '@udecode/plate'
import SearchPostNamespaceField from '@Components/containers/forms/fields/SearchPostNamespaceField'
import SearchPostTitleField from '@Components/containers/forms/fields/SearchPostTitleField'
import PostById from '@Components/containers/post/PostById'
import PostByNamespace from '@Components/containers/post/PostByNamespace'
import { RouteContext } from '@Components/containers/RouteProvider'
import {
  ObjectEditorPostElement,
  ObjectEditorValue,
} from '@Components/shared/object-editor/ObjectEditor.types'
import { defaultStyles } from '@Constants/config/globalStyles'

interface PostElementFormValues {
  postId?: string
  namespace?: string
}

export const PostElement = ({
  attributes,
  children,
  ...rest
}: PlateRenderElementProps<ObjectEditorValue, ObjectEditorPostElement>) => {
  const [searchByNamespace, setSearchByNamespace] = useState(false)
  const { editor, element } = rest

  // For actual exclusive focus highlight, we need to compare the paths, otherwise multiple elems will keep the focused state
  const currentSelectionPath = editor?.selection?.focus.path[0]

  const rootPath = findNodePath(editor, element)
  const currentElementIsAtSelection = rootPath?.[0] === currentSelectionPath
  // const postNode = someNode(editor, {
  //   match: { type: ELEMENT_POST },
  // })
  const focused = currentElementIsAtSelection // && postNode

  const { goViewNamespace } = useContext(RouteContext)

  const readOnly = useReadOnly()

  const initFormValues = {
    namespace: element?.namespace || '',
    postId: element?.postId || '',
  }

  const updatePostId = (postId: string) => {
    if (postId) {
      setNodes<ObjectEditorPostElement>(editor, { postId }, { at: rootPath })
    } else {
      unsetNodes<ObjectEditorPostElement>(editor, ['postId'], { at: rootPath })
    }
  }

  const updateNamespace = (namespace: string) => {
    if (namespace) {
      setNodes<ObjectEditorPostElement>(editor, { namespace }, { at: rootPath })
    } else {
      unsetNodes<ObjectEditorPostElement>(editor, ['namespace'], {
        at: rootPath,
      })
    }
  }
  const submitForm = (formValues: PostElementFormValues) => {
    const { namespace, postId } = formValues
    if (namespace) {
      updateNamespace(namespace)
    } else if (postId) {
      updatePostId(postId)
    } else {
      console.log('Nothing set: ', formValues)
      updateNamespace('')
      updatePostId('')
    }
  }
  const hasAnyPostSet = element?.namespace || element?.postId
  const hasNoPostSet = !(element?.namespace || element?.postId)
  const readOnlyAndNothingSet = readOnly && hasNoPostSet

  const clickTag = () => {
    if (hasAnyPostSet) {
      // goViewNamespace(element?.namespace, true)
      updateNamespace('')
      updatePostId('')
    }
  }

  const deleteSelf = () => {
    const path = findNodePath(editor, element)
    removeNodes(editor, { at: path })
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    focusEditor(editor, editor.selection!)
  }

  const containerProps = {
    width: {
      xs: '100%',
      sm: '100%',
      md: '80%',
      lg: '70%',
      xl: '70%',
    },
  }

  if (readOnlyAndNothingSet) {
    // Because it's possible to add an empty post node, we don't want antyhing to show up on readOnly
    return null
  } else {
    return (
      <Stack
        {...attributes}
        // Need contentEditable=false or Firefox has issues with certain input types.
        contentEditable={false}
        sx={{
          // For spacing between editor body elements
          m: 1,
          mt: 2,
          transition: defaultStyles.transitionFast,
          cursor: !readOnly && !focused ? 'pointer' : 'default',
        }}
        justifyContent="center"
        alignItems="center"
      >
        {hasAnyPostSet ? (
          <Paper
            variant="outlined"
            sx={{ ...containerProps, border: !focused ? 'none' : undefined }}
          >
            <Stack
              direction="column"
              justifyContent="start"
              alignItems="center"
              sx={{
                m: readOnly || !focused ? 0 : 2,
                mt: 0,
                transition: defaultStyles.transitionFast,
              }}
            >
              {!readOnly && (
                <Typography
                  variant="h6"
                  sx={{
                    m: focused ? 2 : 0,
                    overflow: 'hidden',
                    height: focused ? '36px' : 0,
                    transition: defaultStyles.transitionFast,
                    opacity: focused ? 1 : 0,
                  }}
                >
                  {element?.namespace ? (
                    <>
                      Namespace:
                      <Chip
                        variant="namespace"
                        label={element?.namespace}
                        onDelete={clickTag}
                        deleteIcon={<Icon>delete</Icon>}
                        sx={{
                          ml: 2,
                          opacity: focused ? 1 : 0,
                          transition: defaultStyles.transition,
                        }}
                      />
                    </>
                  ) : element?.postId ? (
                    <>
                      PostId:
                      <Chip
                        variant="outlined"
                        label={element?.postId}
                        onDelete={clickTag}
                        deleteIcon={<Icon>delete</Icon>}
                        sx={{
                          ml: 2,
                          opacity: focused ? 1 : 0,
                          transition: defaultStyles.transition,
                        }}
                      />
                    </>
                  ) : null}
                </Typography>
              )}

              {element?.namespace ? (
                <PostByNamespace
                  namespace={element?.namespace}
                  focused={focused}
                  sx={{
                    width: '100%',
                    // maxHeight: readOnly ? 'none' : 380,
                  }}
                />
              ) : element?.postId ? (
                <PostById
                  postId={element?.postId}
                  focused={focused}
                  sx={{
                    width: '100%',
                    // maxHeight: readOnly ? 'none' : 380,
                  }}
                />
              ) : null}
            </Stack>
          </Paper>
        ) : (
          <Form onSubmit={submitForm} initialValues={initFormValues}>
            {({ handleSubmit, values }) => {
              // console.log(`🚀 ~ values`, values)
              return (
                <Paper variant="outlined" sx={containerProps}>
                  {!readOnly && hasNoPostSet && (
                    <>
                      <Stack
                        direction="row"
                        justifyContent="start"
                        alignItems="center"
                        spacing={1}
                        sx={{ p: 2 }}
                      >
                        <ToggleButton
                          value="check"
                          selected={searchByNamespace}
                          onChange={() => {
                            setSearchByNamespace(!searchByNamespace)
                          }}
                        >
                          <Icon>abc</Icon>
                        </ToggleButton>
                        {searchByNamespace ? (
                          <SearchPostNamespaceField
                            autoFocus={focused}
                            fieldName="namespace"
                            label="Search namespace"
                            onChange={(ns) => {
                              // console.log(`🚀 ~ ns`, ns)
                              if (handleSubmit) {
                                handleSubmit()?.catch((error) => console.error)
                              }
                            }}
                          />
                        ) : (
                          <SearchPostTitleField
                            autoFocus={focused}
                            fieldName="postId"
                            label="Search title"
                            onChange={(postId) => {
                              // console.log(`🚀 ~ postId`, postId)
                              if (handleSubmit) {
                                handleSubmit()?.catch((error) => console.error)
                              }
                            }}
                          />
                        )}
                        <IconButton onClick={deleteSelf}>
                          <Icon>delete</Icon>
                        </IconButton>
                      </Stack>
                    </>
                  )}
                </Paper>
              )
            }}
          </Form>
        )}
        {children}
      </Stack>
    )
  }
}
