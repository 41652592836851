import { HotkeyPlugin, createPluginFactory, onKeyDownToggleElement } from '@udecode/plate-core'
import { ELEMENT_TEXT } from '@Components/shared/object-editor/ObjectEditor.types'


/**
 * Text node type for mandatory normalized field
 */
export const createTextPlugin = createPluginFactory<HotkeyPlugin>({
  key: ELEMENT_TEXT,
  isElement: true,
  handlers: {
    onKeyDown: onKeyDownToggleElement,
  },
  deserializeHtml: {
    rules: [
      {
        validNodeName: 'text',
      },
    ],
    // query: (el) => el.style.fontFamily !== 'Consolas',
  },
});
