import { PostData } from "@Constants/post"

export const UPDATE_NAMESPACE = 'UPDATE_NAMESPACE'
export const NAMESPACE_UPDATED = 'NAMESPACE_UPDATED'

interface UpdateNamespaceAction {
  type: typeof UPDATE_NAMESPACE
  uid: string;
  id: string;
  data: PostData;
  publish: boolean;
  namespace: string;
  handleClose?: () => void;
}
interface NamespaceUpdatedAction {
  type: typeof NAMESPACE_UPDATED
}

export function updateNamespaceAction({ uid, id, data, namespace, publish, handleClose }: Omit<UpdateNamespaceAction, 'type'>): UpdateNamespaceAction {
  return {
    type: UPDATE_NAMESPACE,
    uid,
    id,
    data,
    publish,
    namespace,
    handleClose
  }
}

export type NamespaceActionTypes =
  UpdateNamespaceAction |
  NamespaceUpdatedAction