import { useEffect, useState } from 'react'
import { useFocused, useReadOnly, useSelected } from 'slate-react'

import { Avatar, Skeleton, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Tooltip from '@mui/material/Tooltip'
import { useElement } from '@udecode/plate'
import { ElementPopover } from '@udecode/plate-floating'
import { getParentSize } from '@Components/shared/object-editor/components/ImageElement/getParentSize'
import { Caption } from '@Components/shared/object-editor/plugins/caption'
import { MediaEmbed } from '@Components/shared/object-editor/plugins/media/media-embed/components/MediaEmbed'
import { ELEMENT_MEDIA_EMBED } from '@Components/shared/object-editor/plugins/media/media-embed/createMediaEmbedPlugin'
import { TMediaEmbedElement } from '@Components/shared/object-editor/plugins/media/media-embed/types'
import { useMediaStore } from '@Components/shared/object-editor/plugins/MediaElements/mediaStore'
import { Media } from '@Components/shared/object-editor/plugins/MediaElements/Media'
import { flattenCss } from '@Utils/reactHelpers'

import { mediaFloatingOptions } from '../mediaFloatingOptions'
import { getMediaEmbedElementStyles } from './MediaEmbedElement.styles'
import { MediaEmbedElementProps } from './MediaEmbedElement.types'
import { PlateFloatingMedia } from './PlateFloatingMedia'

const MediaLoading = ({ ...props }) => {
  return (
    <Box {...props}>
      <Box sx={{ margin: 1 }}>
        <Skeleton variant="circular">
          <Avatar />
        </Skeleton>
      </Box>
      <Box sx={{ width: '100%' }}>
        <Skeleton width="100%">
          <Typography>.</Typography>
        </Skeleton>
      </Box>
      <Skeleton variant="rectangular" width="100%" sx={{ borderRadius: 3 }}>
        <div style={{ paddingTop: '46.2061%' }} />
      </Skeleton>
    </Box>
  )
}

export const MediaEmbedElement = (props: MediaEmbedElementProps) => {
  const { children, nodeProps } = props

  const { as, ...rootProps } = props

  const { palette } = useTheme()

  const focused = useFocused()
  const selected = useSelected()
  const readOnly = useReadOnly()

  const { provider } = useMediaStore().get.urlData()

  const element = useElement<TMediaEmbedElement>()

  const { relativeWidth } = element

  const styles = getMediaEmbedElementStyles({
    ...props,
    provider,
    selected,
    focused,
    readOnly,
  })

  // Needed to get & save relative width of image when resizing
  const parentSize = getParentSize('plate-body')

  const parentWidth = parentSize.width

  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setLoaded(true)
    }, 1000)
  }, [])

  return (
    <ElementPopover
      content={<PlateFloatingMedia pluginKey={ELEMENT_MEDIA_EMBED} />}
      floatingOptions={mediaFloatingOptions}
    >
      <Media.Root
        style={{
          ...flattenCss(styles?.root?.css),
          overflow: 'hidden', // Avoid x-scroll on slightly too big initial relativeWidth
        }}
        {...rootProps}
      >
        <figure
          style={{
            ...flattenCss(styles?.figure?.css),
            // minHeight: '10vh',
            ...(provider === 'twitter' && {
              margin: '0 auto !important',
              // padding: '2px',
            }),
          }}
          className="group"
          contentEditable={false}
        >
          <Media.Resizable
            parentWidth={parentWidth}
            style={{
              ...flattenCss(styles?.resizable?.css),
              ...(provider === 'twitter' && {
                // So that resizing does something
                backgroundColor: palette.background.paper,
                borderRadius: 6,

                // width: 'auto',
                // minHeight: '200px !important', // This doesn't work forsomereason, see: .slate-MediaEmbedElement-resizable__twitter css class
              }),
            }}
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            className={`${styles.resizable?.className} ${
              provider === 'twitter'
                ? 'slate-MediaEmbedElement-resizable__twitter'
                : ''
            }`}
            maxWidth={provider === 'twitter' ? 450 : '100%'}
            minWidth={provider === 'twitter' ? 200 : 100}
            handleComponent={{
              // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
              left: <Box className={styles.handleLeft?.className} />,
              // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
              right: <Box className={styles.handleRight?.className} />,
            }}
          >
            <Box
              style={{
                paddingBottom: provider !== 'twitter' ? '56.2061%' : undefined,
              }} // Important to make embed visible
              className={styles.iframeWrapper?.className}
            >
              {provider === 'twitter' && !loaded && (
                <MediaLoading
                  sx={{
                    position: 'absolute',
                    height: '100%',
                    width: '100%',
                    p: 3,
                    // zIndex: -1,
                  }}
                />
              )}

              <Tooltip
                title={
                  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                  `Width: ${relativeWidth}%`
                }
              >
                <MediaEmbed
                  style={flattenCss(styles.iframe?.css)}
                  className={styles.iframe?.className}
                  {...nodeProps}
                />
              </Tooltip>
            </Box>
          </Media.Resizable>

          <Caption.Root
            style={{
              ...flattenCss(styles?.figcaption?.css),
              display: 'flex',
              justifyContent: 'center',
            }}
            className={styles.figcaption?.className}
          >
            <Caption.Textarea
              style={{ ...flattenCss(styles?.caption?.css), width: '50%' }}
              className={styles.caption?.className}
              placeholder="Caption this"
            />
          </Caption.Root>
        </figure>

        {children}
      </Media.Root>
    </ElementPopover>
  )
}
