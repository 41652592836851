import { ELEMENT_BLOCKQUOTE, ELEMENT_PARAGRAPH, IndentPlugin } from '@udecode/plate'
import {
  ELEMENT_CODE_BLOCK,
  ELEMENT_TEXT,
  ObjectEditorPlatePlugin
} from '@Components/shared/object-editor/ObjectEditor.types'


export const indentPlugin: Partial<ObjectEditorPlatePlugin<IndentPlugin>> = {
  inject: {
    props: {
      validTypes: [
        ELEMENT_TEXT,
        ELEMENT_PARAGRAPH,
        ELEMENT_BLOCKQUOTE,
        ELEMENT_CODE_BLOCK,
      ],
    },
  },
};
