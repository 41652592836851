import { RouteContext } from '@Components/containers/RouteProvider'
import LoadingButton from '@mui/lab/LoadingButton'
import Button from '@mui/material/Button'
import Icon from '@mui/material/Icon'
import makeStyles from '@mui/styles/makeStyles'
import { auth, fbAuth, googleProvider } from '@State/firebase'
import { onAuthStateChanged } from 'firebase/auth'
import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { authState } from 'rxfire/auth'
import { filter } from 'rxjs/operators'

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    pointerEvents: 'none',
    cursor: 'not-allowed',
    filter: 'blur(8px)',
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    // maxWidth: '60%'
  },
}))

export default function LoginForm({
  afterLogin,
}: {
  afterLogin?: (user) => void
}) {
  const classes = useStyles()
  // const history = useHistory()
  const [loading, setLoading] = useState(true)
  const [loggedIn, setLoggedIn] = useState(false)
  const { goLogout } = useContext(RouteContext)

  // const handleLogin = useCallback(
  //   async event => {
  //     event.preventDefault();
  //     const { email, password } = event.target.elements;
  //     try {
  //       await app
  //         .auth()
  //         .signInWithEmailAndPassword(email.value, password.value);
  //       history.push("/");
  //     } catch (error) {
  //       alert(error);
  //     }
  //   },
  //   [history]
  // );

  useEffect(() => {
    onAuthStateChanged(fbAuth, (user) => {
      setLoading(false)
      if (user) {
        setLoggedIn(true)
      } else {
        setLoggedIn(false)
      }
    })
  }, [])

  useEffect(() => {
    const loggedIn$ = authState(auth).pipe(filter((user) => !!user))
    loggedIn$.subscribe((user) => {
      // TODO: Redirect if on /login, or last url before login
      // console.log('Login:', user, 'Redirecting to root');
      // history.push("/");
      setLoggedIn(!!user)
    })
  }, [])

  function LoginGoogle() {
    if (loggedIn) {
      goLogout()
    } else {
      auth
        .signInWithPopup(googleProvider)
        .then((user) => {
          // TODO: Redirect if on /signin, or last url before login
          // console.log('Login Google:', user)
          if (afterLogin) afterLogin(user)
          setLoggedIn(!!user)
        })
        .catch((error) => {
          console.error(error)
        })
    }
  }

  // const { currentUser } = useContext(AuthContext);
  //
  // if (currentUser) {
  //   return <Redirect to="/" />;
  // }

  return (
    <div>
      <LoadingButton
        type="submit"
        fullWidth
        variant="contained"
        loading={loading}
        loadingPosition="start"
        color={loggedIn ? 'secondary' : 'primary'}
        className={classes.submit}
        startIcon={loggedIn ? <Icon>logout</Icon> : <Icon>login</Icon>}
        onClick={LoginGoogle}
        // disabled={loading}
      >
        {loggedIn ? 'Logout Google' : 'Google Login'}
      </LoadingButton>

      {/* <div className={classes.paper}>
        <form onSubmit={handleLogin}  className={classes.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Sign In
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href="#" variant="body2" to="/forgot">
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link href="#" variant="body2" to="/signup">
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid>
        </form>
      </div> */}
    </div>
  )
}
