import React from 'react'

import { Box, CardMedia } from '@mui/material'
import Typography from '@mui/material/Typography'
import ParentSize from '@visx/responsive/lib/components/ParentSize'
import MapConstrained from '@Components/map/MapConstrained'
import PostCat from '@Components/post/PostCat'
import { TwitterEmbed } from '@Components/shared/embed/TwitterEmbed'
import { GraphHeader } from '@Components/shared/graph/GraphHeader'
import WordCloud from '@Components/shared/graph/WordCloud/WordCloud'
import Favicon from '@Components/ui/Favicon'
import TypeIcon from '@Components/ui/TypeIcon'
import {
  defaultStyles,
  getHeroDims,
  useBreakpoints,
} from '@Constants/config/globalStyles'
import { ExistingPostData } from '@Constants/post'
import { shouldHaveWordCloud, showHero } from '@Styles/styleVars'
import { locationCoords } from '@Utils/geoFunc'
import { getCoverImage, getMetaMedia } from '@Utils/metaFunc'
import urlFunc from '@Utils/urlFunc'

export default function PostHeroBody({
  collapsed,
  item,
  clickHero,
  noTitle,
}: {
  item: ExistingPostData
  collapsed?: boolean
  clickHero?: (e: React.MouseEvent) => void
  noTitle?: boolean
}): React.ReactElement {
  const { largerThanMedium, largerThanMobile } = useBreakpoints()

  const headerHeight = getHeroDims(!collapsed).height
  const headerHeightLargeDesktop = getHeroDims(!collapsed).desktopHeight

  const theUrl = item?.url

  const shouldHeaderBeShown = showHero(item)
  const urlItem = theUrl ? urlFunc(theUrl) : null
  const metaMedia = getMetaMedia(urlItem)
  const faviconSrc = theUrl
    ? `https://www.google.com/s2/favicons?sz=${256}&domain=${theUrl}`
    : ''
  const hasFavicon = !!theUrl

  const coverImage = item ? getCoverImage(item) : ''

  const imageUrl = coverImage
    ? coverImage
    : metaMedia?.coverImage
    ? metaMedia.coverImage
    : hasFavicon
    ? faviconSrc
    : ''

  const canShowWordCloud = shouldHaveWordCloud(item)
  const isTypeLocation = item?.type === 'location'
  const isTypeGraph = item?.type === 'graph'

  const urlEmbed = urlItem ? urlItem.meta : null
  const isUrlEmbed = urlEmbed
    ? urlEmbed.brand === 'twitter' && urlEmbed.type === 'embed'
    : false
  const urlEmbedID = isUrlEmbed ? urlEmbed?.id : null

  // TODO: also check brand
  const isTypeVideo =
    urlItem &&
    urlItem.meta &&
    (urlItem.meta.type === 'video' || urlItem.meta.type === 'short')

  const faviconSize = largerThanMobile
    ? collapsed
      ? '10vh'
      : '14vh'
    : collapsed
    ? '10vw'
    : '16vw'
  const faviconTop = largerThanMedium
    ? `calc(50% - ${faviconSize} / 2)`
    : `calc(50% - ${faviconSize} / 2)`

  const faviconLeft = largerThanMedium
    ? `calc(100% / 2 - ${faviconSize} / 2)`
    : `calc(100% / 2 - ${faviconSize} / 2)`

  const opacity = collapsed ? 0.66 : 1

  const heroes = (
    <>
      {isTypeLocation && item.longlat ? (
        <Box
          sx={{
            width: '100%',
            height: '100%',
            minHeight: '10vh',
            opacity,
          }}
        >
          <MapConstrained
            coords={locationCoords(item)}
            // FIXME: zoom not dynamically updating
            defaultZoom={collapsed ? 2 : 5}
            controls={!collapsed}
          />
        </Box>
      ) : isTypeGraph ? (
        <Box
          sx={{
            width: '100%',
            height: '100%',
            opacity,
          }}
        >
          <GraphHeader data={item.data} item={item} heightFactor={4} />
        </Box>
      ) : isUrlEmbed && urlEmbedID ? (
        <Box
          sx={{
            width: '100%',
            height: '100%',
            opacity,
          }}
          onClick={(e) => {
            console.warn(`🚀 ~ e`, e)
            e.stopPropagation()
          }}
        >
          <TwitterEmbed tweetId={urlEmbedID} />
        </Box>
      ) : canShowWordCloud ? (
        <Box
          sx={{
            width: '100%',
            height: '100%',
            opacity,
            overflow: 'hidden',
          }}
        >
          <ParentSize debounceTime={2000} enableDebounceLeadingCall={false}>
            {(parent) => {
              // FIXME: Disappearing words when resizing
              // FIXME: Really slow re-render when resizing
              return parent.width && parent.height ? (
                <WordCloud
                  width={parent.width}
                  height={parent.height}
                  words={item?.text}
                  // withRotation
                />
              ) : null
            }}
          </ParentSize>
        </Box>
      ) : shouldHeaderBeShown ? (
        <CardMedia
          // className={cardMediaClass}
          image={imageUrl}
          onClick={clickHero ? clickHero : () => null}
          sx={{
            height: '100%',
            opacity,
            paddingTop: collapsed
              ? '10vh'
              : largerThanMedium
              ? headerHeightLargeDesktop
              : headerHeight,
          }}
        />
      ) : null}
      {isTypeVideo && theUrl && shouldHeaderBeShown ? (
        // Overlay youtube play icon
        <Favicon
          size="large"
          url={theUrl}
          sx={{
            display: 'flex',
            position: 'absolute',
            marginLeft: faviconLeft,
            top: faviconTop,
            height: largerThanMedium ? faviconSize : 'auto',
            width: faviconSize,
            transition: defaultStyles.transitionFast,
            opacity,
          }}
        />
      ) : null}
    </>
  )

  const icons = (
    <>
      {item?.type && (
        <TypeIcon
          type={item.type}
          tiny
          sx={{
            position: 'absolute',
            top: collapsed ? 0 : -150,
            left: 0,
            opacity: collapsed ? 1 : 0,
            ml: 1,
            mt: 1,
            transition: defaultStyles.transition,
          }}
        />
      )}
      {item?.cat && (
        <PostCat
          cat={item.cat}
          mini
          sx={{
            position: 'absolute',
            top: collapsed ? 0 : -150,
            left: 26,
            opacity: collapsed ? 1 : 0,
            ml: 1,
            mt: 0.5,
            transition: defaultStyles.transition,
          }}
        />
      )}
    </>
  )

  const titleElem = (
    <Typography
      variant={largerThanMedium ? 'h3' : 'h5'}
      sx={{
        position: 'absolute',
        bottom: collapsed ? 0 : -150,
        left: 0,
        opacity: collapsed ? 1 : 0,
        pr: 1, // Padding for ellipsis
        pl: 1,
        pb: 1,
        transition: defaultStyles.transition,
        width: '100%',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
      }}
    >
      {item?.title}
    </Typography>
  )

  if (largerThanMobile) {
    return (
      <>
        {shouldHeaderBeShown && heroes}

        {/* <Container
          sx={{
            // flexGrow: 1,
            // flexDirection: 'column',
            // display: 'flex',
            width: '100%',
            height: '100%',
          }}
          maxWidth={mainContainerMaxWidth}
        > */}
        {shouldHeaderBeShown && icons}

        {noTitle ? null : shouldHeaderBeShown && titleElem}
        {/* </Container> */}
      </>
    )
  } else {
    return (
      <>
        {heroes}

        {icons}

        {noTitle ? null : titleElem}
      </>
    )
  }
}
