import firebase from 'firebase/compat/app'
import { ofType } from 'redux-observable'
import { Observable } from 'rxjs'
import { mapTo, mergeMap, switchMap } from 'rxjs/operators'

import { editPostAction } from '@State/actions/postActions'
import {
  DELETE_URI,
  LOAD_URI,
  SAVE_URI,
  URI_LOADED,
  URI_SAVED,
  UriActionTypes,
  uriExistsAction,
  uriNotExistsAction,
  LoadUriAction,
  SaveUriAction,
  DeleteUriAction
} from '@State/actions/uriActions'
import { firestore } from '@State/firebase'
import { RootState, StoreDependencies } from '@State/store'
import { getSavablePaths } from '@Utils/savableUrl'
import { UriData } from '@State/reducers/uriReducer'
import { uriAndDomainTransactions, createOrUpdateUriDoc } from './uri.helpers'


export const loadUriEpic = (action$: Observable<LoadUriAction>, state$: RootState, { store }: StoreDependencies
) => action$.pipe(
  ofType(LOAD_URI),
  // @ts-expect-error Because switchmap
  switchMap(({ uri }) => {
    const savablePath = getSavablePaths(uri)
    if (savablePath) {
      console.log('LOAD_URI:', savablePath)
      return firebase
        .database()
        .ref('uri')
        .child(savablePath)
        .child('_uri')
        .once('value', (snapshot) => {
          const data = snapshot.val() as UriData
          console.log('remote data:', data)
          if (data === null && store) {
            store.dispatch(uriNotExistsAction(uri))
          } else if (store) {
            store.dispatch(uriExistsAction(uri, data))
          }
        })
    } else {
      return new Error('LOAD_URI Error')
    }
  }),
  mapTo({ type: URI_LOADED })
)

// TODO: Add subpost ref to url

export const saveUriEpic = (action$: Observable<SaveUriAction>, state$: RootState, { store }: StoreDependencies) => action$.pipe(
  ofType(SAVE_URI),
  mergeMap(({ uri, postId, userId, namespace }) => {
    // Create or update realtime firebase path & domain entries,
    // then create or update firestore entry,
    // and add a postsById entry there.
    // Then also add uriDocRef to Post
    // TODO: Also save isSource?
    // FIXME: path not sanitised
    const savablePath = getSavablePaths(uri)
    const fbPath = firebase
      .database()
      .ref('uri')
      .child(savablePath)
      .child('_uri')

    // Double check existance in rt db
    return fbPath.once('value', (snapshot) => {
      const snapShot = snapshot?.val() as UriData
      const uriDocRefId = snapShot?.uriRef
      const existsWithDocRef = snapshot.exists() ? !!uriDocRefId : false

      return uriAndDomainTransactions(uri, userId)
        .then((transaction) => {
          const uriSnapshot = transaction.snapshot.val() as UriData
          console.log(`${existsWithDocRef ? 'Exists' : 'Not Exist'}`, uriSnapshot, uriDocRefId)
          const postCount = uriSnapshot?.postCount
          return createOrUpdateUriDoc(uri, postId, userId, existsWithDocRef, uriDocRefId, postCount, namespace)
            .then((docRefId) => {
              return store.dispatch(editPostAction({
                id: postId,
                data: {
                  uriRef: docRefId
                }
              }))
            })
        })
    })
  }),
  mapTo({ type: URI_SAVED })
)



export const deleteUriEpic = (action$: Observable<DeleteUriAction>, state$: RootState, { store }: StoreDependencies) => action$.pipe(
  ofType(DELETE_URI),
  mergeMap(({ uri, postId, uriDocRef }) => {

    return uriAndDomainTransactions(uri, undefined, true).then((transaction) => {
      const uriSnapshot = transaction.snapshot.val() as UriData
      // console.log('DELETE_URI', uriSnapshot)
      const postCount = uriSnapshot && uriSnapshot.postCount

      const uriCollection = firestore
        .collection('urisById')
        .doc(uriDocRef)

      return uriCollection.set({
        postCount
      }, {
        merge: true
      }).then(() => {
        const uriPostRef = uriCollection
          .collection('postsById')
          .doc(postId)

        return uriPostRef
          .delete()

      }).then(() => {
        // Set uriRef to null in Post
        // TODO: Delete uriRef field?
        return store.dispatch(editPostAction({
          id: postId,
          data: {
            uriRef: null
          }
        }))
      })

    })

  }),
  mapTo({ type: 'DELETE_URI_DONE' })
)
